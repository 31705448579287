import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import SpinDiv from "../components/SpinDiv";
import { AddAdministrative } from "./AddPerformance/AddAdministrative";
import { AddHumanResource } from "./AddPerformance/AddHumanResource";
import { AddOperationsManager } from "./AddPerformance/AddOperationsManager";
import { AddWriting } from "./AddPerformance/AddWriting";
import { AddItSupport } from "./AddPerformance/AddItSupport";
import {AddOfficeAssistance} from "./AddPerformance/AddOfficeAssistance"
import { throttle,debounce } from "../users/debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import {  getDepartmentalPerformance, getPerformancesDepartments, getDepartment } from "../../services/departmentPerformanceService";
import { faDashcube } from "@fortawesome/free-brands-svg-icons";
import AddQualityControl from "./AddPerformance/AddQualityControl";
import DeletePerformance from "./DeletePerformance";
export class DeptPerformanceDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      department_id: props.match.params.id,
      department:'',
      performances:[],
      employees:[],
      start_year: 2018,
      current_year: new Date().getFullYear(),
      year:'',
      month:'',
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      total: 0,
      //department_name:''
    };
    this.searchDebounced = debounce(this.searchPerformances, 500);
    this.searchThrottled = throttle(this.searchPerformances, 500);
  }

  componentDidMount() {
    this.searchPerformances();
    this.getDepartment();
    this.getEmployees();
  }

  searchPerformances = () => {
    const { page, rows, search, performances, department_id,employee_id,  year, month } = this.state;
    this.setState({ loading: true });
    getDepartmentalPerformance({ page, rows, search, department_id,employee_id, year, month }).then(
      
      (res) => {
        this.setState({
          performances: res.departments_performances.data,
          page: res.departments_performances.current_page,
          total: res.departments_performances.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getEmployees = () => {
    this.setState({ loading: true });
    const {department_id}= this.state;
    console.log(department_id);
    getPerformancesDepartments({department_id}).then(
      
      (res) => {
        this.setState({
          //departments: res.departments,
          employees:res.employees,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };
 


  getDepartment = () => {
      const {department_id} = this.state;
    getDepartment(department_id).then(
      
      (res) => {
        this.setState({
          department: res.department,
          department_name:res.department.name,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  slug = (slug) => {
 
    var b = slug.toLowerCase().replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
        return b;
  }

   monthName = (mon)=> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }

  
  toggleEdit = (editPerformance) => {
    this.setState({ editPerformance });
   
  };
 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchPerformances();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
  

  


  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  

  onFilter = async (e, filter) => {
    await this.setState({ [filter]: e });
    await this.searchPerformances();
  
  };

  toggleAddPerformance = (addDeptPerformance) => {
    this.setState({ addDeptPerformance});
    this.searchPerformances();
   
  };
  toggleCloseAddPerformance=(addDeptPerformance)=>{
    this.setState({ addDeptPerformance: !this.state.addDeptPerformance });
  }

  toggleDeletePerformance=(deletePerformance)=>{
    this.setState({ deletePerformance });
  }

  toggle = () => {
    this.setState({ deletePerformance: !this.state.deletePerformance });
  }
  
  
  

  render() {
    const { performances,department, deletePerformance, employees, addDeptPerformance, singlePerformance, addPerformance,start_year, year, current_year, month, months,showFilter, total, page, rows, search, loading } = this.state;
   
    return (
      <>
       {deletePerformance && (
          <DeletePerformance
            saved={this.searchPerformances}
            performance={deletePerformance}
            toggle={this.toggle}
          />
        )}

       
       {addDeptPerformance !== undefined ? addDeptPerformance.name == 'Administrative' && (
          <AddAdministrative
            saved={this.searchPerformances}
            department={addDeptPerformance}
            toggle={this.toggleCloseAddPerformance}
            
          />
        ):''}

        {addDeptPerformance !== undefined ? addDeptPerformance.name == 'Office Assistance' && (
          <AddOfficeAssistance
            saved={this.searchPerformances}
            department={addDeptPerformance}
            toggle={this.toggleCloseAddPerformance}
            
          />
        ):''}

         {addDeptPerformance !== undefined ? addDeptPerformance.name == 'Operations Manager' && (
          <AddOperationsManager
            saved={this.searchPerformances}
            department={addDeptPerformance}
            toggle={this.toggleCloseAddPerformance}
            
          />
        ):''}

        {addDeptPerformance !== undefined ? addDeptPerformance.name == 'Quality Control Manager' && (
          <AddQualityControl
            saved={this.searchPerformances}
            department={addDeptPerformance}
            toggle={this.toggleCloseAddPerformance}
            
          />
        ):''}

         {addDeptPerformance !== undefined ? addDeptPerformance.name == 'Writing' && (
          <AddWriting
            saved={this.searchPerformances}
            department={addDeptPerformance}
            toggle={this.toggleCloseAddPerformance}
            
          />
        ):''}

          {addDeptPerformance !== undefined ? addDeptPerformance.name == 'IT Support' && (
          <AddItSupport
            saved={this.searchPerformances}
            department={addDeptPerformance}
            toggle={this.toggleCloseAddPerformance}
            
          />
        ):''}

         {addDeptPerformance !== undefined ? addDeptPerformance.name == 'Human Resources Management' && (
          <AddHumanResource
            saved={this.searchPerformances}
            department={addDeptPerformance}
            toggle={this.toggleCloseAddPerformance}
            
          />
        ):''}


       
        {loading && <SpinDiv text={"Loading..."} />}
        
      

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">{department.name} Performance</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddPerformance(department)}>
                    Create Performance
                  </Button>
                  
          
         
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row >
          <Col md="3" >
          <h5 style={{paddingTop:20}} className="mb-0"> {department.name} Performance
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col md={3} style={{marginBottom:10}}>
              
            <Form.Group id="date">
              <Form.Label> Filter By Employee:{" "}</Form.Label>
                <Form.Select
                  //value={order}
                  type="select"
                  
                  onChange={(e) => this.onFilter(e.target.value, "employee_id")}
                >
                  <option value="">Filter By Employees</option>
                  {employees.map((p, index) => (
                    <option value={p.id} key={p}>
                      {p.name}
                    </option>
                  ))}
                </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} style={{marginBottom:10}}>
              
            <Form.Group id="date">
              <Form.Label> Filter By Month:{" "}</Form.Label>
                <Form.Select
                  //value={order}
                  type="select"
                  
                  onChange={(e) => this.onFilter(e.target.value, "month")}
                >
                  <option value="">Filter Month</option>
                  {months.map((p, index) => (
                    <option value={index+1} key={p}>
                      {p}
                    </option>
                  ))}
                </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} style={{marginBottom:10}}>
            <Form.Group id="date">
                  <Form.Label> Filter By Year:{" "}</Form.Label>
              <Form.Select
                
                type="select"
                
                onChange={(e) => this.onFilter(e.target.value, "year")}
              >
                <option value="">Filter Year</option>
                {this.range(start_year, current_year).slice(0).reverse().map((p, index) => (
                  <option value={p} key={p}>
                    {p}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Month</th>
                  <th className="border-0">Year</th>
                   <th className="border-0">Action</th>
                  
                 
              
                </tr>
              </thead>
              <tbody>
                
                {performances.map((performance, key) => {
               
                  return (
                    <tr style={{fontWeight:"bold"}}>
                      <td><span style={{fontWeight:"bold",textTransform:"capitalize"}}>{performance.name}</span></td>
                      <td>{this.monthName(performance.month)}</td> 
                      <td>{performance.year}</td>
                      
                      <td>
                        {console.log(this.slug(performance.department_name))}
                        <ButtonGroup>
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            this.props.history.push(`/${this.slug(performance.department_name)}/${performance.id}/${performance.year}/${performance.month}`)
                          }}
                          size="sm"
                        >
                          view
                        </Button>
                        <Button
                          variant="outline-danger"
                          onClick={() => {//console.log('111')
                            this.toggleDeletePerformance(performance)
                          }}            
                          size="sm"
                        >
                          Delete
                        </Button>
                       
                      
                        </ButtonGroup>
                      </td>
                      
                      
                    </tr>
                  );
                })}
              </tbody>
              <Row>
                
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {performances.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  Filter By Employee
                </div>}
              {performances.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} performances`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
            </Table>
           
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default DeptPerformanceDetail;
