import React from "react";
import {

  Modal,
  Media

} from "reactstrap";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { toast } from 'react-toastify';
import SpinDiv from "../components/SpinDiv";
import { Avatar, Image } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Progress } from 'antd';
import { faLocationArrow, faMapMarker, faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import EditAttendance from './EditAttendance'

class SingleAttendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attendance: props.singleAttendance,
      loading: false,
      search: '',
      validation: {},
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  getDuration = (x) => {

    return <Progress type="circle" percent={100} format={() => x + 'hrs'} />
  }



  toggleEditAttendance = (editAttendance) => {
    this.setState({ editAttendance });


  };
  savedAttendance = () => {
    this.setState({ singleAttendance: !this.state.singleAttendance });
  }

  toggleCloseAttendance = (editAttendance) => {
    this.setState({ editAttendance: !this.state.editAttendance });

  }




  render() {
    const { toggle } = this.props
    const { loading, attendance, editAttendance, saving } = this.state;

    return (


      <>
        {editAttendance && (
          <EditAttendance
            attendance={editAttendance}
            toggle={this.toggleCloseAttendance}
          />
        )}
        <Modal
          className="modal-dialog-top"
          isOpen={attendance != null}
          toggle={() => !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          <div className="modal-header" style={{ padding: '1rem', fontSize: 20, fontWeight: 'bold' }}>
            Attendance Details
          </div>
          <div className="modal-body" style={{ border: '1px solid #eee' }}>
            <Row>
              <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                  <Row style={{ marginBottom: 30 }}>
                    <Col md={1}>
                      <Media className="align-items-center">
                        <a
                          className="avatar rounded-circle mr-3"
                          href="#p"
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            style={{
                              maxHeight: 50,
                              maxWidth: 50,
                              borderRadius: '100%',
                            }}
                            alt="..."
                            src={
                              attendance.image ||
                              require("../../assets/img/brand/user.jpg")
                            }
                          />

                        </a>
                      </Media>
                    </Col>
                    <Col md={6} style={{ fontWeight: 'bold', paddingBottom: 5, textTransform: 'capitalize' }}>
                      {attendance.name}
                    </Col>
                    <Col md={2}>

                    </Col>
                    <Col md={3}>
                      <div className="btn-toolbar mb-2 mb-md-0">
                        <ButtonGroup>
                          <Button variant="outline-secondary" size="sm" onClick={() => this.toggleEditAttendance(attendance)}>
                            Edit
                          </Button>

                          <Button variant="outline-danger" size="sm">
                            Delete
                          </Button>



                        </ButtonGroup>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

            </Row>
            <Row>
              <Col md={12}>
                <Card border="light" className="shadow-sm mb-4">
                  <Card.Body className="pb-0">
                    <Row style={{ marginBottom: 30 }}>
                      <Col md={12} style={{ fontWeight: 'bold', fontSize: 20 }}>
                        Date: {attendance.day}-{attendance.month}-{attendance.year}
                      </Col>

                    </Row>
                    <Row>
                      <Col md={9}>
                        <Card.Body className="pb-0" style={{ backgroundColor: '#FCFCFC', fontWeight: 'bold', borderRadius: 10 }}>
                          <div style={{ fontWeight: 'bold' }}>Clock In</div>
                          <h5>{attendance.clock_in}</h5>
                        </Card.Body>
                      </Col>
                      <Col md={3}>
                        <FontAwesomeIcon icon={faMapMarker} /> {attendance.working_from}
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: 30 }}>
                      <Col md={4}>

                      </Col>
                      <Col md={8}>
                        {this.getDuration(attendance.duration)}

                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card.Body className="pb-0" style={{ backgroundColor: '#FCFCFC', fontWeight: 'bold', borderRadius: 10 }}>
                          <div style={{ fontWeight: 'bold' }}>Clock Out</div>
                          <h5>{attendance.clock_out}</h5>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

              </Col>
              <Col md={6}>



              </Col>
            </Row>

          </div>
          <div className="modal-footer" style={{ padding: '1rem' }}>

            <Button
              size="sm"
              variant='outline-primary'
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Close
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default SingleAttendance;
