import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getMessages(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}messages`, requestOptions).then(authService.handleResponse);
}

export function getClients(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}message/clients`, requestOptions).then(authService.handleResponse);
}

export function addMessage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}send/message`, requestOptions).then(authService.handleResponse);
}

export function editDepartment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatedepartment/${data.id}`, requestOptions).then(authService.handleResponse);
}


