import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';
const authuser=JSON.parse(localStorage.getItem('user'));


export function searchEmployees(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}searchusers`, requestOptions).then(authService.handleResponse);
}

export function getEmployees(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}payroll`, requestOptions).then(authService.handleResponse);
}

export function addSalary(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}savesalary`, requestOptions).then(authService.handleResponse);
}



export function getSalary(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    //return fetch(`${settings.API_URL}salary/${id}`, requestOptions).then(authService.handleResponse);
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}salary/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/salary`, requestOptions).then(authService.handleResponse);
    }
}

export function addCommission(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}savecommission`, requestOptions).then(authService.handleResponse);
}

export function deleteCommission(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletecommission/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteDeduction(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletededuction/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteAllowance(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteallowance/${id}`, requestOptions).then(authService.handleResponse);
}

export function addDeduction(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}savededuction`, requestOptions).then(authService.handleResponse);
}

export function getCommission(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    //return fetch(`${settings.API_URL}commission/${id}`, requestOptions).then(authService.handleResponse);
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}commission/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/commission`, requestOptions).then(authService.handleResponse);
    }
}

export function getDeduction(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
        
    };
    //return fetch(`${settings.API_URL}deduction/${id}`, requestOptions).then(authService.handleResponse);
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}deduction/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/deduction`, requestOptions).then(authService.handleResponse);
    }
}

export function addLoan(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}saveloan`, requestOptions).then(authService.handleResponse);
}

export function getLoan(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   

    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}loan/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/loan`, requestOptions).then(authService.handleResponse);
    }
}

export function getLoan2(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}loan2/${id}`, requestOptions).then(authService.handleResponse);
}

export function getDeduction2(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}deduction2`, requestOptions).then(authService.handleResponse);
}

export function addAllowance(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}saveallowance`, requestOptions).then(authService.handleResponse);
}

export function getAllowance(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    //return fetch(`${settings.API_URL}allowance/${id}`, requestOptions).then(authService.handleResponse);
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}allowance/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/allowance`, requestOptions).then(authService.handleResponse);
    }
}

export function editAllowance(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}editallowance/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function editCommission(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}editcommission/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function editSalary(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}editsalary/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function editLoan(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}editloan/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function editDeduction(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}editdeduction/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function deleteSalary(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletesalary/${id}`, requestOptions).then(authService.handleResponse);
}

