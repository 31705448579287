import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";
import { getDepartments } from "../../services/departmentService";
import { toast } from "react-toastify";
import AddDepartments from "./AddDepartments";

import EditDepartment from "./EditDepartment";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
  Form,
} from "@themesberg/react-bootstrap";

import SpinDiv from "../components/SpinDiv";
import DeleteDepartment from "./DeleteDepartment";

export class DepartmentIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      departments: [],
      used_departments:['Office Assistance', 
      'Administrative',
      'Writing','IT Support', 'Human Resources Management','Operations Manager','Quality Control Manager'],
      value:"",
      total: 0,
    }
  }

  componentDidMount() {
   this.getDepartments();
  }
  

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  };
  getDepartments = () => {

    const { page, rows, search} = this.state;
    this.setState({ loading: true });
    getDepartments({ page, rows, search }).then(
      (res) => {
        this.setState({
          loading: false,
          departments:res.departments.data,
          total:res.departments.total
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  

  toggleAddDepartment = () => {
    this.setState({ addDepartments: !this.state.addDepartments });
  };

  toggleDeleteDepartment=(deleteDepartment)=>{
    this.setState({ deleteDepartment });
  }
  
  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  toggleEdit = (editDepartment) => {
    this.setState({ editDepartment });
  };

  toggle = () => {
    this.setState({ deleteDepartment: !this.state.deleteDepartment });
  }

  
  
  render() {
    const {
      departments,
      total,
      addDepartments,
      editDepartment,
      deleteDepartment,
      used_departments,
      rows,
      search,
      loading,
      filtering
    } = this.state;
    return (
      <>
      
        {addDepartments && (
          <AddDepartments
            saved={this.getDepartments}
            addDepartments={addDepartments}
            toggle={() => this.setState({ addDepartments: null })}
          />
        )}

        {editDepartment && (
          <EditDepartment
            saved={this.getDepartments}
            editDepartment={editDepartment}
            toggle={() => this.setState({ editDepartment: null })}
          />
        )}

        {deleteDepartment && (
          <DeleteDepartment
            saved={this.getDepartments}
            department={deleteDepartment}
            toggle={this.toggle}
          />
        )}

       

        {loading && <SpinDiv text={"Loading..."} />}
        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#products">Departments</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => this.toggleAddDepartment()}
                  >
                    Create Departments
                  </Button>
                  {/* <Button variant="outline-primary" size="sm"  
                  onClick={() => {this.props.history.push('/emloyees')}}

                  >
                    Employees
                  </Button> */}
                  
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="7">
            <h6>Departments({total})</h6>
           
          </Col>
          
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                id="show"
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                value={search}
                onChange={(e) => this.onChange(e.target.value, "search")}
                autoFocus
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    this.getDepartments();
                    this.setState({
                      search: "",
                    });
                  }
                }}
              />
              <Button
                className="btn-icon btn-2"
                color="secondary"
                style={{ maxHeight: 45 }}
                size="sm"
                onClick={this.getDepartments}
              >
                <i className="fa fa-search" />
              </Button>
            </div>
          </Col>
        </Row>
        
        <Card border="light" className="shadow-sm mb-4">
          
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Department</th>
                  <th className="border-0">No of Employees</th>
                </tr>
              </thead>
              <tbody>
            
                {departments.map((department, key) => {
                  return (
                    <tr key={key}>
                      <td>{department.name}</td>
                      <td>{department.employees_count}</td>
                      <td>
                           <ButtonGroup>
                          
                            {used_departments.find(used_department => used_department === department.name) ? '' : <>
                            <Button
                              variant="outline-primary" 
                              onClick={() => this.toggleEdit(department)}
                              size="sm"
                            >
                              Edit
                            </Button> <Button
                            variant="outline-danger"
                            onClick={() => {
                              this.toggleDeleteDepartment(department)
                            }}            
                            size="sm"
                          >
                            Delete
                          </Button>
                            </> }
                           
                           </ButtonGroup>
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default DepartmentIndex;
