import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import moment from "moment";
import {Button } from 'antd';
import { getProjects } from "../../services/timeLogService";
import { getTasks, getMembers} from "../../services/timeLogService";
import { AsyncPaginate } from "react-select-async-paginate";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import axios from 'axios'
import { TimePicker } from 'antd';

export class EditTimeLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      id: props.timelog.id,
      task_id: props.timelog.task_id,
      title:props.timelog.title,
      employee_id: props.timelog.employee_id,
      project_id: props.timelog.project_id,
      status:props.timelog.status == 1 ? true :false,
      start_date:moment(props.timelog.start_date).format( 'MMM D, YYYY'),
      end_date: moment(props.timelog.end_date).format('MMM D, YYYY'),
      edit:false,
      start_time: props.timelog.start_time,
      end_time: props.timelog.end_time,
      employees:[],
      projects:[],
      tasks:[],
      initialProject:{label:props.timelog.project_name, value:props.timelog.project_id}
  
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.getProjects();
    this.getMembers();
    this.getTasks();

  }


  getProjects = () => {
    this.setState({
      projects: this.props.projects.filter(p => p.progress < 100).map((opt) => ({
        label: opt.title,
        value: opt.id,
      }))
    });
  
  };
 

 

  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });



  loadOptions = (data) => async (search, prevOptions) => {
    await this.sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };


  
  

  getMembers = () => {
    this.setState({ loading: true });
    const {task_id} = this.state;
    getMembers({task_id}).then(
     
      (res) => {
        this.setState({
          employees: res.members.map((opt) => ({
            label: opt.name,
            value: opt.id,
          })),
        
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getTasks = () => {
    this.setState({ loading: true });
    const {project_id} = this.state;
    getTasks({project_id}).then(
     
      (res) => {
        this.setState({
          tasks: res.tasks.map((opt) => ({
            label: opt.title,
            value: opt.id,
          })),
        
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  
  

  handleProjectChange =  (initialProject) => {

   
    this.setState({
      initialProject
    
    });
 
  }



 
  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })
  }

  onSaveTimeLog = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {title, project_id, end_time, start_time, task_id,
      employee_id, start_date, end_date, validation} = this.state

   
   await this.setState({
     validation: {
       ...validation,
       title: title !== '' && title !== undefined,
       project_id: project_id !== '' && project_id !== undefined,
       task_id: task_id !== '' && task_id !== undefined,
       employee_id: employee_id !== '' && employee_id !== undefined,
       start_date: start_date !== '' && start_date !== undefined,
       start_time: start_time !== '' && start_time !== undefined,
       end_time: end_time !== '' && end_time !== undefined,
       end_date: end_date !== '' && end_date !== undefined,
     },
   });
   if (Object.values(this.state.validation).every(Boolean)) {
     this.saveTimeLog();
   } else {
     const errors = Object.keys(this.state.validation).filter((id) => {
       return !this.state.validation[id];
     });
     //await toast.configure({hideProgressBar: true, closeButton: false});
     toast.dismiss();
     toast.configure({ hideProgressBar: true, closeButton: false });

     toast(
       <div style={{ padding: "10px 20px" }}>
         <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
         {errors.map((v) => (
           <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
             * {this.validationRules(v)}
           </p>
         ))}
       </div>
     )
   }
    
  };

  handleTaskChange = (e, state) => {
    this.setState({ task_id: e });
   
  };

  


  handleMemberChange = (member) => {
    this.setState({ employee_id: member.value});
   
  }



  validationRules = (field) => {
    if (field == 'employee_id') {
      return "Please Select  Employee"
    } else if (field == 'title') {
      return "Title is required"
    } else if (field == 'project_id') {
      return "Project is required"
    } else if (field == 'task_id') {
      return "Task is required"
    } else if (field == 'start_date') {
      return "Start Date is required";
    } else if (field == 'end_date') {
      return "End Date is required";
    }else if (field == 'start_time') {
      return "start time is required";
    }  else if (field == 'end_time') {
      return "End Time is required";
    } 
  };

  onChange2 = async (e, state) => {
   
    await this.getTasks();
    await this.setState({ [state]: e, employee_id:'', employees:[]});
  };

  onChange3 = async (e, state) => {
    await this.setState({ [state]: e });
    await this.getMembers();
  };




  saveTimeLog = () => {

    this.setState({ saving: true });
    const { project_id, id, title, task_id,
      start_date, end_date, start_time, end_time, status, employee_id } = this.state;

    let data = new FormData();
    data.set("start_date", start_date);
    data.set("id", id);
    data.set("end_date", end_date);
    data.set("title", title);
    data.set("start_time", start_time);
    data.set("end_time", end_time);
    data.set("project_id", project_id);
    data.set("task_id", task_id);
    data.set("employee_id", employee_id);
    data.set("status", status == true ? 1 : 0);
    return axios
      .post(
        `${settings.API_URL}updatetimelog/${id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Time Log Created");
        },
        (err) => {

          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            this.showToastError('Timelog could not be created')
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );

    
  };

  

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  

  

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  






  render() {

    const { toggle, timelog } = this.props;

    const { saving, project,title, initialProject, employee_id,  employees, tasks, task_id, project_id, 
      start_date, end_date, start_time, end_time, status, projects, loading, edit } = this.state;
      console.log(initialProject);
    return (
      <>
    
     
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={timelog != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {timelog && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Time Log"}



                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            <Row>
                <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}> Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      value={title}
                      placeholder='Enter Title'
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>

              <Row>
                <Col md={4}>

                  <Form.Group>
                    <Form.Label>Projects </Form.Label>

                {/* 
                    <AsyncPaginate
                      maxMenuHeight={120}
                      value={initialProject}
                      loadOptions={this.loadOptions(projects)}
                      onInputChange={this.handleProjectChange}
                    
                      onChange={async (property, value) => {
                        console.log(property);
                        await this.setState({
                          project_id: property.value,
                          selectedTitle: property.label,
                        });
                        await this.getTasks();
                      }}
                    /> */}

                      <Form.Select
                      onChange={async (e) => {
                        await this.onChange2(e.target.value, "project_id");
                      }}
                      value={project_id}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Project</option>
                      {projects.length == 0 && ''}
                      {projects.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>

                  </Form.Group>


                </Col>
                <Col md={4}>

                  <Form.Group>
                    <Form.Label>Task </Form.Label>
                    
                    <Form.Select
                      onChange={async (e) => {
                        await this.onChange3(e.target.value, "task_id");
                      }}
                      value={task_id}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Task</option>
                      {tasks.length == 0 && ''}
                      {tasks.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>

                    {/* <AsyncPaginate

                      loadOptions={this.loadOptions(tasks)}
                      onChange={this.handleTaskChange}
                    /> */}
                  </Form.Group>


                </Col>
                
                <Col md={4}>

                  <Form.Group>
                    <Form.Label>Members </Form.Label>


                    <Form.Select
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "employee_id");
                      }}
                      value={employee_id}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Employee</option>
                      {employees.length == 0 && ''}
                      {employees.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>


                </Col>




              </Row>
              
              <Row>

              </Row>

              <Row>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                      value={start_date}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Due Date
                    </label>
                    <ReactDatetime
                      value={end_date}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'end_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                
                          <Col md={4}>
                            <Form.Group id="Lastname">
                              <Form.Label>Start Time</Form.Label>
                                <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </InputGroup.Text>
                                <TimePicker defaultValue={moment(start_time, 'HH:mm:ss A')}  
                                  use24Hours 
                                  onChange={(time, start_time) => this.onChange(start_time, "start_time")} 
                                />
                              </InputGroup>

                            </Form.Group>
                            
                          </Col>
                          <Col md={4}>
                            <Form.Group id="Lastname">
                              <Form.Label>End Time</Form.Label>
                                <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </InputGroup.Text>
                                <TimePicker  defaultValue={moment(end_time, 'HH:mm:ss A')} use24Hours onChange={(time, end_time) => this.onChange(end_time, "end_time")}
                                />
                              </InputGroup>

                            </Form.Group>
                            
                          </Col>
                        </Row>
                        <Row>
                  <Col md={6} style={{ paddingTop: 15 }}>
          
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Approve Timelog<br/>
                      <small>
                        </small></Form.Label>
                      <Input
                        className="custom-control-input"
                        checked={status}
                        onChange={async (e) => {
                          await this.onChange(e.target.checked, "status");
                        }}
                        size='md'
                        type="checkbox"
                      />
                    </Form.Group>

                  </Col>
                </Row>


              {edit && (
                <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveTimeLog}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
              )}              
              
            
            
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditTimeLog;
