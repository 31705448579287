import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import {getEmployees } from "../../services/salaryService";
import SpinDiv from "../components/SpinDiv";
//import { throttle, debounce } from "throttle-debounce";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';

export class SalaryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      employees: [],
      employee:'',
      roles:[],
      total: 0,
    

    };
    this.searchDebounced = debounce(this.searchEmployees, 500);
    this.searchThrottled = throttle(this.searchEmployees, 500);
  }

  componentDidMount() {
    this.getEmployees();
  }

  getEmployees = () => {
    const { page, rows, search, employees } = this.state;
    this.setState({ loading: true });
    getEmployees({ page, rows, search, employees }).then(
      (res) => {
        console.log(res.employees.data);
        
        this.setState({
          employees: res.employees.data,
          page: res.employees.current_page,
          //roles:res.roles,
          total: res.employees.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  searchEmployees = () => {
    const { page, rows, search, employees } = this.state;
    this.setState({ loading: false });
    getEmployees({ page, rows, search, employees }).then(
      (res) => {
        console.log(res.employees.data);
        
        this.setState({
          employees: res.employees.data,
          page: res.employees.current_page,
          //roles:res.roles,
          total: res.employees.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  
  

  
  toggleEdit = (editEmployee) => {
    this.setState({ editEmployee });
   
  };
 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.getEmployees();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  

  // toggleAddEmployee = () => {
  //   this.setState({ addUser: !this.state.addUser });
  //   this.getEmployees()
  // };

  toggleEditEmployee = () => {
    this.setState({ editEmployee: !this.state.editEmployee });
    this.getEmployees();
    //this.getEmployee();
  }
  toggle = () => {
    this.setState({ deleteUser: !this.state.deleteUser });
  }
  formatCurrency(x) {
    if (x !== null && x !== 0 && x !== undefined) {
      const parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${parts.join(".")}`;
    }
    return '0';
  }


  render() {
    const { employees, employee, total, page, rows, search, loading,editEmployee } = this.state;
    
    return (
      <>

       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">salaries</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Employees
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
              {/* <Button
                className="btn-icon btn-2"
                color="secondary"
                style={{ maxHeight: 45 }}
                size="sm"
                onClick={this.getEmployees}
              >
                <i className="fa fa-search" />
              </Button> */}
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Payroll type</th>
                  <th className="border-0">Basic Salary</th>
                </tr>
              </thead>
              <tbody>
                
                {employees.map((employee, key) => {
               
                  return (
                    <tr style={{fontWeight:"bold"}}>
                      <th scope="row">
                        <td>
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#p"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                maxHeight: 50,
                                maxWidth: 50,
                                borderRadius: '100%',
                              }}
                              alt="..."
                              src={
                                employee.image ||
                                require("../../assets/img/brand/user.jpg")
                              }
                            />
                           
                          </a>
                          </Media>
                        </td>
                          <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{employee.name}</span></td>
                        
                      </th>
                      <td >Monthly salary</td>
                      <td>{employee.salaries !== null ? this.formatCurrency(employee.salaries.basic_salary) : ''}</td>
                      
                      <td>
                        <ButtonGroup>
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            this.props.history.push(
                              "/salaries/" +
                                employee.id
                            );
                          }}
                          size="sm"
                        >
                          View
                        </Button>
                      
                        </ButtonGroup>
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
              <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {employees.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Employees Salaries
                </div>}
              {employees.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} employees`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
            </Table>
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default SalaryIndex;
