import React, { Component } from "react";
import {  FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../../components/SpinDiv";
import "antd/dist/antd.css";
import { toast } from "react-toastify";
import moment from "moment";
import ReactDatetime from "react-datetime";
import {faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from 'antd';
import {addDepartmentPerformance,getPerformancesDepartments, getDepartmentalPerformance} from "../../../services/departmentPerformanceService"

const desc = ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];

export class AddQualityControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //appearance:3,
      employee_productivity:3,
      quality_of_work:3,
      policy_compliance:3,
      safety_management:3,
      risk_management:3,
      department:props.department,
      department_id:props.department.id,
      employees:[],
      department:props.department,
      department_id:props.department.id,
      department_name:'Quality Control Manager'
    };
  }
  onChange = (e, state) => {
    this.setState({[state]: e });
  };

  componentDidMount() {
    this.searchDepartments();
  }

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  searchDepartments = () => {
    this.setState({ loading: true });
    const {department_name} = this.state;
    getPerformancesDepartments({department_name}).then(
      
      (res) => {
        this.setState({
          //departments: res.departments,
          employees:res.employees,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };
 


  onSaveAdministrative = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {teamwork,
      
      employee_productivity,
      quality_of_work,
      policy_compliance,
      safety_management,
      risk_management,
      week,
      employee_id,
      department_id,date, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        teamwork: teamwork !== '' && teamwork !== undefined,
        employee_id: employee_id !== '' && employee_id !== undefined,
        safety_management: safety_management !== '' && safety_management !== undefined,
        risk_management: risk_management !== '' && risk_management !== undefined,
        quality_of_work: quality_of_work !== '' && quality_of_work !== undefined,
        employee_productivity: employee_productivity !== '' && employee_productivity !== undefined,
        policy_compliance: policy_compliance !== '' && policy_compliance !== undefined,
        week: week !== '' && week !== undefined,
        department_id: department_id !== '' && department_id !== undefined,
        date: date !== '' && date !== undefined,
        
        
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveAdministrative();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    
    if (field == 'teamwork') {
      return "Team work is required"
    } else if (field == 'employee_productivity') {
      return "Content Quality/Value Field is required"
    } else if (field == 'quality_of_work') {
      return "Customers Satisfaction rating is required"
    }else if (field == 'policy_compliance') {
      return "Work Efficiency Field rating is required"
    }else if(field === "date"){
      return "Date  is required";
    }else if(field === "safety_management"){
      return "Response Time rating is required";
    }else if(field === "risk_management"){
      return "Customer Complaints rating is required";
    }else if(field === "employee_id"){
      return "Employee is required";
    }else if(field === "week"){
      return "Week is required";
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })

  }

  saveAdministrative= () => {
    this.setState({ saving: true });
    const {
      employee_id,
      teamwork,
      
      employee_productivity,
      quality_of_work,
      policy_compliance,
      safety_management,
      risk_management,
      week,
      department_id,date} = this.state;
      let year= moment(date,"MMM, YYYY").year()
      let month = moment(date,"MMM, YYYY").month()+1;
    addDepartmentPerformance({
      department_id: department_id,
      employee_id:employee_id,
      employee_productivity: employee_productivity,
      teamwork: teamwork,
      quality_of_work: quality_of_work,
      policy_compliance: policy_compliance,
      safety_management: safety_management,
      risk_management: risk_management,
      week: week,
      year: year,
      month: month,


    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Performance Saved Suceesfully");
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('An error occured')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };


  render() {
    const {toggle } = this.props;

    const { saving,loading,department,
      employees, teamwork,
      
      employee_productivity,
      quality_of_work,
      policy_compliance,
      safety_management,
      risk_management,
      
    } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={department != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth:800, }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5> Create {department.name} Performance </h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Employees</Form.Label>

                    <Form.Select
                      id="state"
                      required
                     
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "employee_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >
                      

                      <option value="">Choose Employee</option>
                      {employees.length == 0 && <option value="">loading</option>}
                      {employees.map((p, index) => (
                        <option value={p.user_id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </Col>
                  <Col md={6} >
                    <Form.Group className="mb-2">
                      <Form.Label>Week</Form.Label>

                      <Form.Select
                        id="state"
                        required
                      
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "week");
                        }}
                        style={{
                          marginRight: 10,
                          width: "100%",
                        }}
                      >

                        <option value="">Choose Week</option>
                        <option value="1">Week 1</option>
                        <option value="2">Week 2</option>
                        <option value="3">Week 3</option>
                        <option value="4">Week 4</option>
                        
                      </Form.Select>
                    </Form.Group>

                  </Col>
                  
                </Row>
                <Row>
              
      
                <Col md={6} className="mb-3">
                    <Form.Label>Content Quality/Value Rate</Form.Label>
                    <h5>
                        <Rate style={{color:'green'}} tooltips={desc}  onChange={e=>this.onChange(e, 'employee_productivity')}  value={employee_productivity} />
                        {employee_productivity ? <span  className="ant-rate-text">{desc[employee_productivity - 1]}</span> : ''}
                    </h5>
                    </Col>
                    <Col md={6} className="mb-3">
                    <Form.Label>Team Work</Form.Label>
                    <h5>
                        <Rate style={{color:'green'}} tooltips={desc} onChange={e=>this.onChange(e, 'teamwork')} value={teamwork} />
                        {teamwork ? <span className="ant-rate-text">{desc[teamwork - 1]}</span> : ''}
                    </h5>
                    </Col>
                </Row>
                
                <Row style={{paddingTop:10}}>
                    
                    <Col md={6} className="mb-3">
                    <Form.Label>Customers Satisfaction</Form.Label>
                    <h5>
                        <Rate style={{color:'green'}} tooltips={desc} onChange={e=>this.onChange(e, 'quality_of_work')} value={quality_of_work} />
                        {quality_of_work ? <span className="ant-rate-text">{desc[quality_of_work - 1]}</span> : ''}
                    </h5>
                    </Col>
                    <Col md={6} className="mb-3">
                    <Form.Label>Work Efficiency</Form.Label>
                    <h5>
                        <Rate style={{color:'green'}} tooltips={desc} onChange={e=>this.onChange(e, 'policy_compliance')} value={policy_compliance} />
                        {policy_compliance ? <span className="ant-rate-text">{desc[policy_compliance - 1]}</span> : ''}
                    </h5>
                    </Col>
                </Row>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Label>Response Time</Form.Label>
                      <h5>
                          <Rate style={{color:'green'}} tooltips={desc} onChange={e=>this.onChange(e, 'safety_management')} value={safety_management} />
                          {safety_management ? <span className="ant-rate-text">{desc[safety_management - 1]}</span> : ''}
                      </h5>
                    </Col>

                    <Col md={6} className="mb-3">
                      <Form.Label>Customer Complaints</Form.Label>
                      <h5>
                          <Rate style={{color:'green'}} tooltips={desc} onChange={e=>this.onChange(e, 'risk_management')} value={risk_management} />
                          {risk_management ? <span className="ant-rate-text">{desc[risk_management - 1]}</span> : ''}
                      </h5>
                    </Col>
                    
                
                </Row>
                <Row>
                <Col md={6} className="mb-3">
                    <Form.Group id="date">
                      <Form.Label>Date</Form.Label>
                      <ReactDatetime
                              
                        dateFormat={'MMM, YYYY'}
                        closeOnSelect
                        onChange={e=>this.dateFilter(e, 'date')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-width'
                        }}
                        timeFormat={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col md={12}>
                    <div>
                      <Button
                        variant="primary"
                        size="sm"
                        style={{ marginTop: "10px", float: "right" }}
                        disabled={saving}
                        onClick={this.onSaveAdministrative}
                      >
                        Save
                      </Button>
                      <Button
                        size="sm"
                        variant="transparent"
                        data-dismiss="modal"
                        type="button"
                        disabled={saving}
                        style={{ marginTop: "10px", float: "right" }}
                        onClick={toggle}
                      >
                        {" "}
                        Close
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Modal>
      </>
    );
  }

}
export default AddQualityControl;
