import React, { Component } from "react";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,

} from "@themesberg/react-bootstrap";
import SpinDiv from "../components/SpinDiv";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { toast } from "react-toastify";
import { getSalary, getCommission, getLoan, getDeduction, getAllowance } from "../../services/salaryService";
import AddSalary from "./AddSalary";
import { formatCurrency, format } from "../../services/formatCurrencyService";
import { AddCommission } from "./AddCommission";
import AddLoan from "./AddLoan";
import AddDeduction from "./AddDeduction";
import AddAllowance from "./AddAllowance";
import { getEmployee, getEmployee2 } from "../../services/employeeService";
import EditAllowance from "./EditAllowance";
import EditCommission from "./EditCommission";
import EditSalary from "./EditSalary";
import EditLoan from "./EditLoan";
import EditDeduction from "./EditDeduction";
import DeleteCommission from "./DeleteCommission";
import DeleteAllowance from "./DeleteAllowance";
import DeleteDeduction from "./DeleteDeduction";


export class SingleSalary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            validation: {},
            show: false,
            edit: false,
            id: props.match.params.id,
            salary: '',
            commissions: [],
            loans: [],
            deductions: [],
            allowances: [],
            employee: ''

        };
    }

    componentDidMount() {
        toast.configure({ hideProgressBar: true, closeButton: false });
        this.getSalary();
        this.getCommissions();
        this.getLoans();
        this.getAllowances();
        this.getEmployee();
        this.getDeductions();
    }


    toggleAddSalary = () => {
        this.setState({ addSalary: !this.state.addSalary });
    };

    toggleAddLoan = () => {
        this.setState({ addLoan: !this.state.addLoan });
    };

    toggleDeleteCommission = (deleteCommission) => {
        this.setState({ deleteCommission });
    }

    toggleAddDeduction = () => {
        this.setState({ addDeduction: !this.state.addDeduction });
    };
    toggleDeleteDeduction = (deleteDeduction) => {
        this.setState({ deleteDeduction });
    }

    toggleAddCommission = () => {
        this.setState({ addCommission: !this.state.addCommission });
    };

    toggleAddAllowance = () => {
        this.setState({ addAllowance: !this.state.addAllowance });
    };

    toggleDeleteAllowance = (deleteAllowance) => {
        this.setState({ deleteAllowance });
    }

    getSalary = () => {
        const { id } = this.state;

        getSalary(id).then(

            (res) => {
                this.setState({
                    loading: false,
                    salary: res.salary,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };


    getEmployee = () => {
        const { id } = this.state;

        getEmployee2(id).then(

            (res) => {
                this.setState({
                    loading: false,
                    employee: res.employee,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    getCommissions = () => {
        const { id } = this.state;

        getCommission(id).then(

            (res) => {
                this.setState({
                    loading: false,
                    commissions: res.commissions,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    getAllowances = () => {
        const { id } = this.state;

        getAllowance(id).then(

            (res) => {
                this.setState({
                    loading: false,
                    allowances: res.allowances,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    formatCurrency(x) {
        if (x !== null && x !== 0 && x !== undefined) {
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `${parts.join(".")}`;
        }
        return '0';
    }

    getLoans = () => {
        const { id } = this.state;

        getLoan(id).then(

            (res) => {
                this.setState({
                    loading: false,
                    loans: res.loans,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    getDeductions = () => {
        const { id } = this.state;

        getDeduction(id).then(

            (res) => {
                this.setState({
                    loading: false,
                    deductions: res.deductions,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };




    toggleEditCommission = (editCommission) => {
        this.setState({ editCommission });
    };

    toggleEditAllowance = (editAllowance) => {
        this.setState({ editAllowance });
    };

    toggleEditLoan = (editLoan) => {
        this.setState({ editLoan });
    };

    toggleEditDeduction = (editDeduction) => {
        this.setState({ editDeduction });
    };

    toggleEditSalary = (editSalary) => {
        this.setState({ editSalary });
    };


    render() {

        const { saving, deleteCommission, deleteDeduction, deleteAllowance, loading, addSalary, addDeduction, editDeduction, employee, addCommission, editCommission, editSalary, editAllowance, editLoan, editDeductions, addAllowance, addLoan, loans, deductions, salary, allowances, commissions } = this.state;
        return (
            <>
                {addSalary && (
                    <AddSalary
                        saved={this.getSalary}
                        addSalary={employee}
                        toggle={() => this.setState({ addSalary: null })}
                    />
                )}



                {addCommission && (
                    <AddCommission
                        saved={this.getCommissions}
                        addCommission={employee}
                        toggle={() => this.setState({ addCommission: null })}
                    />
                )}

                {deleteCommission && (
                    <DeleteCommission
                        saved={this.getCommissions}
                        deleteCommission={deleteCommission}
                        toggle={() => this.setState({ deleteCommission: null })}
                    />
                )}

                {deleteAllowance && (
                    <DeleteAllowance
                        saved={this.getAllowances}
                        deleteAllowance={deleteAllowance}
                        toggle={() => this.setState({ deleteAllowance: null })}
                    />
                )}

                {deleteDeduction && (
                    <DeleteDeduction
                        saved={this.getDeductions}
                        deleteDeduction={deleteDeduction}
                        toggle={() => this.setState({ deleteDeduction: null })}
                    />
                )}


                {addLoan && (
                    <AddLoan
                        saved={this.getLoans}
                        addLoan={employee}
                        toggle={() => this.setState({ addLoan: null })}
                    />
                )}

                {addDeduction && (
                    <AddDeduction
                        saved={this.getDeductions}
                        addDeduction={employee}
                        toggle={() => this.setState({ addDeduction: null })}
                    />
                )}

                {addAllowance && (
                    <AddAllowance
                        saved={this.getAllowances}
                        addAllowance={employee}
                        toggle={() => this.setState({ addAllowance: null })}
                    />
                )}



                {editAllowance && (
                    <EditAllowance
                        saved={this.getAllowances}
                        editAllowance={editAllowance}
                        //roles={roles}
                        toggle={() => this.setState({ editAllowance: null })}
                    />
                )}

                {editLoan && (
                    <EditLoan
                        saved={this.getLoans}
                        editLoan={editLoan}
                        //roles={roles}
                        toggle={() => this.setState({ editLoan: null })}
                    />
                )}

                {editDeduction && (
                    <EditDeduction
                        saved={this.getDeductions}
                        editDeduction={editDeduction}
                        toggle={() => this.setState({ editDeduction: null })}
                    />
                )}

                {editCommission && (
                    <EditCommission
                        saved={this.getCommissions}
                        editCommission={editCommission}
                        //roles={roles}
                        toggle={() => this.setState({ editCommission: null })}
                    />
                )}

                {editSalary && (
                    <EditSalary
                        saved={this.getSalary}
                        editSalary={editSalary}
                        //roles={roles}
                        toggle={() => this.setState({ editSalary: null })}
                    />
                )}

                {loading && <SpinDiv text={"Loading..."} />}

                <Row>
                    <Row style={{}}>
                        <Col lg="12">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                                <div className="d-block mb-4 mb-md-0">
                                    <Breadcrumb
                                        listProps={{
                                            className: " breadcrumb-text-dark text-primary",
                                        }}
                                    >
                                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                        <Breadcrumb.Item href="#users">Salaries</Breadcrumb.Item>
                                    </Breadcrumb>
                                </div>
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <ButtonGroup>

                                        {/* <Button variant="outline-primary" size="sm">
                                        Export
                                    </Button> */}
                                    </ButtonGroup>
                                </div>
                            </div>
                        </Col>
                    </Row>


                    {loading && <SpinDiv text={"Saving..."} />}
                    <Col md={6}>
                        <Row>
                            <Col lg="8">
                                <h5 className="mb-0">Salary Details
                                </h5>
                            </Col>

                        </Row>

                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                                <Row>
                                    <Row>
                                        <Col md={11}>
                                            <h6>Employee Salary</h6>
                                        </Col>
                                        <Col md={1} style={{ marginBottom: 10 }}>
                                            <div className="btn-toolbar mb-2 mb-md-0">
                                                <ButtonGroup>
                                                    <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddSalary()}>
                                                        <span style={{ fontSize: '20px' }}><i className="fa fa-plus" /></span>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                                        <Table
                                            responsive
                                            className="table-centered table-nowrap rounded mb-0"
                                        >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th className="border-0">Name</th>
                                                    <th className="border-0">Payroll type</th>
                                                    <th className="border-0">Basic Salary</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr style={{ fontWeight: "bold" }}>
                                                    <td><span className="mb-0 text-sm" style={{ fontWeight: "bold", textTransform: "capitalize" }}>{employee.name}</span></td>

                                                    <td>{salary !== null ? this.formatCurrency(salary.basic_salary) : ''}</td>

                                                    {/* {salary !== null ? <td>
                                                        <ButtonGroup>
                                                            <Button
                                                                variant="outline-primary"
                                                                size="sm"
                                                                onClick={() => this.toggleEditSalary(salary)}
                                                            >
                                                                View
                                                            </Button>

                                                        </ButtonGroup>
                                                    </td> : ''} */}

                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Row>



                                </Row>

                            </Card.Body>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <Card border="light" className="shadow-sm mb-4">
                                    <Card.Body className="pb-0">
                                        <Row>
                                            <Row>
                                                <Col md={11}>
                                                    <h6 style={{ fontWeight: 'bold' }}>Loan</h6>
                                                </Col>
                                                <Col md={1} >
                                                    <div className="btn-toolbar mb-2 mb-md-0">
                                                        <ButtonGroup>

                                                            <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddLoan()}>
                                                                <span style={{ fontSize: '20px', }}><i className="fa fa-plus" /></span>
                                                            </Button>
                                                        </ButtonGroup>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>

                                                <Table
                                                    responsive
                                                    className="table-centered table-nowrap rounded mb-0"
                                                >
                                                    <thead className="thead-light">
                                                        <tr>

                                                            <th className="border-0">Name</th>
                                                            <th className="border-0">Title</th>
                                                            <th className="border-0">Amount</th>
                                                            <th className="border-0">Start Date</th>
                                                            <th className="border-0">End Date</th>
                                                            <th className="border-0">Status</th>




                                                            <th className="border-0">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <Row>
                                                        <Col md={12}>
                                                            {loans.length < 1 &&
                                                                <div style={{ color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13 }}>
                                                                    <i className="fa fa-ban" style={{ marginRight: 5 }} />
                                                                    No Loans
                                                                </div>}
                                                        </Col>
                                                    </Row>
                                                    <tbody>
                                                        {loans.map((loan, key) => {
                                                            return (
                                                                <tr style={{ fontWeight: "bold" }}>
                                                                    <th scope="row">

                                                                        <td><span className="mb-0 text-sm" style={{ fontWeight: "bold", textTransform: "capitalize" }}>{loan.employee.name}</span></td>

                                                                    </th>
                                                                    <td >{loan.title}</td>

                                                                    <td>{this.formatCurrency(loan.amount)}</td>
                                                                    <td>{moment(loan.start_date).format('MMM DD, YYYY')}</td>
                                                                    <td>{moment(loan.end_date).format('MMM DD, YYYY')}</td>
                                                                    <td> {loan.active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>InActive</span>}</td>
                                                                    <td>
                                                                        <ButtonGroup>
                                                                            <Button
                                                                                variant="outline-primary"

                                                                                size="sm"

                                                                                onClick={() => this.toggleEditLoan(loan)}
                                                                            >
                                                                                View
                                                                            </Button>

                                                                        </ButtonGroup>
                                                                    </td>

                                                                </tr>
                                                            );
                                                        })}


                                                    </tbody>
                                                </Table>
                                            </Row>


                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                                <Row>
                                    <Row>
                                        <Col md={10}>
                                            <h6>Commission</h6>
                                        </Col>
                                        <Col md={2}>
                                            <div className="btn-toolbar mb-2 mb-md-0">
                                                <ButtonGroup>

                                                    <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddCommission()}>
                                                        <span style={{ fontSize: '20px' }}><i className="fa fa-plus" /></span>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                                        <Table
                                            responsive
                                            className="table-centered table-nowrap rounded mb-0"
                                        >
                                            <thead className="thead-light">
                                                <tr>

                                                    <th className="border-0">Name</th>
                                                    <th className="border-0">Title</th>
                                                    <th className="border-0">Amount</th>
                                                    <th className="border-0">Action</th>
                                                </tr>
                                            </thead>
                                            <Row>
                                                <Col md={12}>
                                                    {commissions.length < 1 &&
                                                        <div style={{ color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13 }}>
                                                            <i className="fa fa-ban" style={{ marginRight: 5 }} />
                                                            No Commissions
                                                        </div>}
                                                </Col>
                                            </Row>
                                            <tbody>
                                                {commissions.map((commission, key) => {
                                                    return (
                                                        <tr style={{ fontWeight: "bold" }}>
                                                            <th scope="row">

                                                                <td><span className="mb-0 text-sm" style={{ fontWeight: "bold", textTransform: "capitalize" }}>{commission.employee.name}</span></td>

                                                            </th>
                                                            <td >{commission.title}</td>

                                                            <td>{this.formatCurrency(commission.amount)}</td>

                                                            <td>
                                                                <ButtonGroup>
                                                                    <Button
                                                                        variant="outline-primary"

                                                                        size="sm"
                                                                        onClick={() => this.toggleEditCommission(commission)}

                                                                    >
                                                                        View
                                                                    </Button>
                                                                    <Button
                                                                        variant="outline-danger"

                                                                        size="sm"
                                                                        onClick={() => this.toggleDeleteCommission(commission)}

                                                                    >
                                                                        Delete
                                                                    </Button>

                                                                </ButtonGroup>
                                                            </td>

                                                        </tr>
                                                    );
                                                })}


                                            </tbody>
                                        </Table>
                                    </Row>


                                </Row>

                            </Card.Body>
                        </Card>
                        <Row>
                            <Col md={12}>
                                <Card border="light" className="shadow-sm mb-4">
                                    <Card.Body className="pb-0">
                                        <Row>
                                            <Row>
                                                <Col md={11}>
                                                    <h6 style={{ fontWeight: 'bold' }}>Allowance</h6>
                                                </Col>
                                                <Col md={1} >
                                                    <div className="btn-toolbar mb-2 mb-md-0">
                                                        <ButtonGroup>

                                                            <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddAllowance()}>
                                                                <span style={{ fontSize: '20px', }}><i className="fa fa-plus" /></span>
                                                            </Button>

                                                        </ButtonGroup>
                                                    </div>

                                                </Col>
                                            </Row>
                                            <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                                                <Table
                                                    responsive
                                                    className="table-centered table-nowrap rounded mb-0"
                                                >
                                                    <thead className="thead-light">
                                                        <tr>

                                                            <th className="border-0">Name</th>
                                                            <th className="border-0">Title</th>
                                                            <th className="border-0">Amount</th>





                                                            <th className="border-0">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <Row>
                                                        <Col md={12}>
                                                            {allowances.length < 1 &&
                                                                <div style={{ color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13 }}>
                                                                    <i className="fa fa-ban" style={{ marginRight: 5 }} />
                                                                    No Allowances
                                                                </div>}
                                                        </Col>
                                                    </Row>
                                                    <tbody>
                                                        {allowances.map((allowance, key) => {
                                                            return (
                                                                <tr style={{ fontWeight: "bold" }}>
                                                                    <th scope="row">

                                                                        <td><span className="mb-0 text-sm" style={{ fontWeight: "bold", textTransform: "capitalize" }}>{allowance.employee.name}</span></td>

                                                                    </th>
                                                                    <td >{allowance.title}</td>

                                                                    <td>{this.formatCurrency(allowance.amount)}</td>

                                                                    <td>
                                                                        <ButtonGroup>
                                                                            <Button
                                                                                variant="outline-primary"

                                                                                size="sm"
                                                                                onClick={() => this.toggleEditAllowance(allowance)}

                                                                            >
                                                                                View
                                                                            </Button>
                                                                            <Button
                                                                                variant="outline-danger"

                                                                                size="sm"
                                                                                onClick={() => this.toggleDeleteAllowance(allowance)}

                                                                            >
                                                                                Delete
                                                                            </Button>

                                                                        </ButtonGroup>
                                                                    </td>

                                                                </tr>
                                                            );
                                                        })}


                                                    </tbody>
                                                </Table>
                                            </Row>


                                        </Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row>
                    <Col md={12}>
                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                                <Row>
                                    <Row>
                                        <Col md={11}>
                                            <h6 style={{ fontWeight: 'bold' }}>Deduction</h6>
                                        </Col>
                                        <Col md={1} >
                                            <div className="btn-toolbar mb-2 mb-md-0">
                                                <ButtonGroup>

                                                    <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddDeduction()}>
                                                        <span style={{ fontSize: '20px', }}><i className="fa fa-plus" /></span>
                                                    </Button>
                                                </ButtonGroup>
                                            </div>

                                        </Col>
                                    </Row>
                                    <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                                        <Table
                                            responsive
                                            className="table-centered table-nowrap rounded mb-0"
                                        >
                                            <thead className="thead-light">
                                                <tr>

                                                    <th className="border-0">Name</th>
                                                    <th className="border-0">Title</th>
                                                    <th className="border-0">Amount</th>
                                                    <th className="border-0">Start Date</th>
                                                    <th className="border-0">End Date</th>
                                                    <th className="border-0">Status</th>




                                                    <th className="border-0">Action</th>
                                                </tr>
                                            </thead>
                                            <Row>
                                                    <Col md={12}>
                                                    {deductions.length<1&&
                                                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                                                <i className="fa fa-ban" style={{marginRight: 5}}/>
                                                No Deductions
                                                </div>}
                                                    </Col>
                                                </Row>
                                            <tbody>
                                                {deductions.map((deduction, key) => {
                                                    return (
                                                        <tr style={{ fontWeight: "bold" }}>
                                                            <th scope="row">

                                                                <td><span className="mb-0 text-sm" style={{ fontWeight: "bold", textTransform: "capitalize" }}>{deduction.employee.name}</span></td>

                                                            </th>
                                                            <td >{deduction.title}</td>

                                                            <td>{this.formatCurrency(deduction.amount)}</td>
                                                            <td>{moment(deduction.start_date).format('MMM DD, YYYY')}</td>
                                                            <td>{moment(deduction.end_date).format('MMM DD, YYYY')}</td>
                                                            <td> {deduction.active == 1 ? <span style={{ color: 'green' }}>Active</span> : <span style={{ color: 'red' }}>InActive</span>}</td>
                                                            <td>
                                                                <ButtonGroup>
                                                                    <Button
                                                                        variant="outline-primary"

                                                                        size="sm"

                                                                        onClick={() => this.toggleEditDeduction(deduction)}
                                                                    >
                                                                        View
                                                                    </Button>
                                                                    <Button
                                                                        variant="outline-danger"

                                                                        size="sm"
                                                                        onClick={() => this.toggleDeleteDeduction(deduction)}

                                                                    >
                                                                        Delete
                                                                    </Button>

                                                                </ButtonGroup>
                                                            </td>

                                                        </tr>
                                                    );
                                                })}


                                            </tbody>
                                        </Table>
                                    </Row>


                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </>
        );
    }
}

export default SingleSalary;
