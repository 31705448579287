import React, { Component } from "react";

import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,

} from "@themesberg/react-bootstrap";
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SpinDiv from "../components/SpinDiv";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { toast } from "react-toastify"
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import {getInvoiceId } from "../../services/invoiceService";
import { getProject } from "../../services/projectService";
import { currencies } from "./Currency";
import { InputNumber } from 'antd';
import { AsyncPaginate } from "react-select-async-paginate";

export class AddInvoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: 10,
            loading: false,
            invoice_no: '',
            new_items: [],
            project_id: props.match.params.id,
            project:{},
            amount_paid: 0,
            currency: '',
            purchase_order_no: '',
            description: '',
            validation: {},
            errors: {},
            issue_date: moment().startOf('month'),
            due_date: moment().startOf('month'),
            currencies: currencies,
            invoice_last_id: '',
            submitted: false,
            receipt: {},
            hideNav: false,
            user: JSON.parse(localStorage.getItem('user')),

        };
        this.baseState = this.state;

    }

    resetForm = () => {
        window.location.reload();
    }

    validationRules = field => {
        if (field === "invoice_no") {
            return "Invoice number is required";
        } else if (field === "issue_date") {
            return "Issue date is required";
        } else if (field === "due_date") {
            return "Due date is required";
        }else if (field === 'amount_paid') {
            return "Deposit field is required";
        }else if (field === 'project_id') {
            return "Project is required";
        }
    }

   


    showToast = (msg) => {
        toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
    };
    showToastError = (msg) => {
        toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
    };
    componentDidMount = () => {

        window.addEventListener("resize", this.resize.bind(this));
        this.getProject();
        this.getInvoiceId();

        // toast.dismiss();
        // toast.configure({ hideProgressBar: true, closeButton: false });

    }

    resize() {
        this.setState({ hideNav: window.innerWidth <= 760 });
    }

    getInvoiceId = () => {
        this.setState({loading:true})

        getInvoiceId().then(
            (res) => {
                this.setState({
                    invoice_no: res.invoice ? 'INV-' + (res.invoice.id + 1) : 'INV-1',
                    items: [{ name: '', item_description: '', quantity: 0, rate: 0 }],
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    getProject = () => {
       const {project_id} = this.state;
       this.setState({loading:true})
        getProject(project_id).then(
            (res) => {
                this.setState({
                 project:res.project
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };



    onSaveInvoice = async (e) => {
        e.preventDefault();
        //e.target.reset();
        await toast.dismiss();
        const { validation, invoice_no, project_id,project, amount_paid, cashier_id, due_date, issue_date } = this.state;

        this.setState({ submitted: true });
        let check_payment = amount_paid > project.cost;
        
        console.log(amount_paid)
        await this.setState({
            validation: {
                ...validation,
                invoice_no: invoice_no !== '',
                project_id: project_id !== '',
                due_date: due_date !== '',
                issue_date: issue_date !== '',
                amount_paid: amount_paid !== 0 && amount_paid !== '' && amount_paid !== null,

            }
        })
        if (Object.values(this.state.validation).every(Boolean) && !check_payment ) {
            this.setState({ submitted: false })
            this.saveInvoice();
        } else {
            const errorss = Object.keys(this.state.validation).filter((id) => {
                return !this.state.validation[id];
            });
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            toast(
                <div style={{ padding: "10px 20px" }}>
                    <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
                    {errorss.map((v) => (
                        <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
                            * {this.validationRules(v)}
                        </p>
                    ))}
                </div>
            );
        }
    };

    formatCurrency(x) {
        if (x !== null && x !== '0' && x !== undefined) {
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `${parts.join(".")}`;
        }
        return '0';
    }

    saveInvoice = () => {
        this.setState({ saving: true });

        const { invoice_no, project_id, amount_paid, due_date, issue_date } = this.state;

        let data = new FormData();
        data.set("invoice_no", invoice_no);
        data.set("project_id", project_id);
        data.set("due_date", due_date);
        data.set("issue_date", issue_date);
        data.set("amount_paid", amount_paid);
        

        return axios
            .post(
                `${settings.API_URL}addinvoice`,
                data,
                {
                    headers: authHeader(),
                },
                authService.handleResponse
            )
            .then((res) => {
                this.setState({
                    saving: false,
                    invoice_no: '',
                    amount_paid: 0,
                    project_id: '',
                });
                this.getInvoiceId();

                { console.log(res) }
                this.showToast("Invoice created");

            })
            .catch((err) => {
                if (err) {
                    toast.dismiss();
                    toast.configure({ hideProgressBar: true, closeButton: false });
                    this.showToastError('An Invoice with this invoice No already exist')

                    this.setState({ saving: false });
                }
            });
    };



    onChange = (e, state) => {
        this.setState({ [state]: e });
    };

    render() {

        const { loading, hideNav, project, total_cost, receipt, submitted, invoice_no, amount_paid, issue_date, due_date, saving } = this.state;
        return (
            <>
                
                <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/invoices">Invoices</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col lg="8">
                        <h5 className="mb-0">+ New Invoice </h5>
                    </Col>

                </Row>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="pb-0">
                        <Row>
                            {saving && <SpinDiv text={"Saving..."} />}
                            <Col md={10} className="mb-3">

                                <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '20px 15px', borderRadius: 7 }}>
                                    <Col md={4} style={{ marginBottom:20,}}>

                                        <Form.Group className="mb-2">
                                            <Form.Label > Invoice</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <FontAwesomeIcon icon={faPencilAlt} />
                                                </InputGroup.Text>
                                                <Input

                                                    type="text" placeholder="Enter Invoice No"
                                                    name='invoice_no'
                                                    value={invoice_no}
                                                    onChange={async (e) => {
                                                        await this.onChange(e.target.value, "invoice_no");
                                                    }}
                                                    required


                                                />
                                            </InputGroup>

                                        </Form.Group>

                                    </Col>
                                    
                                    <Col md="4" style={{ marginBottom:20,}}>
                                        <FormGroup className="form-date">

                                            <Form.Label > Date </Form.Label>
                                            <ReactDatetime
                                                value={issue_date}
                                                dateFormat={'MMM D, YYYY'}
                                                closeOnSelect
                                                onChange={e => this.onChange(e, 'issue_date')}
                                                inputProps={{
                                                    required: true,
                                                    className: 'form-control date-width'
                                                }}
                                                timeFormat={false}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '20px 15px', borderRadius: 7 }}>
                                    <Col md={6} style={{ marginBottom:20,}}>
                                        <Form.Label>Project title</Form.Label>
                                        <InputGroup>

                                                <Input

                                                    type="text"
                                                    value={project.title}
                                                    disabled

                                                />
                                        </InputGroup>

                        
                                    </Col>
                                    
                                </Row>
                                <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '20px 15px', borderRadius: 7 }}>
                                    <Col md={4} style={{ marginBottom:20,}}>

                                        <Form.Group className="mb-2">
                                            <Form.Label>Project Cost</Form.Label>
                                            <InputGroup>

                                                <Input

                                                    type="text"
                                                    value={this.formatCurrency(project.cost)}
                                                    disabled

                                                />
                                            </InputGroup>

                                        </Form.Group>

                                    </Col>
                                    <Col md={6} style={{ marginBottom:20,}}>

                                        <Form.Group className="mb-2">
                                            <Form.Label>Amount Received</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    {project.currency}
                                                </InputGroup.Text>
                                                <InputNumber

                                                    style={{ width: 'auto', height: 40, paddingTop: 5, borderRadius: 5, fontSize: 18 }}
                                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onChange={e => this.onChange(e, 'amount_paid')}
                                                />
                                                {submitted && this.state.amount_paid > project.currency && (
                                                    <div style={{ color: "red" }}>Amount received is more than total Cost</div>
                                                )}

                                            </InputGroup>

                                        </Form.Group>

                                    </Col>
                                </Row>

                                <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '20px 15px', borderRadius: 7 }}>

                                    <Col md="3" style={{ marginBottom:20,}}>
                                        <FormGroup className="form-date">
                                            <Form.Label > Due Date</Form.Label>
                                            <ReactDatetime
                                                value={due_date}
                                                dateFormat={'MMM D, YYYY'}
                                                closeOnSelect
                                                onChange={e => this.onChange(e, 'due_date')}
                                                inputProps={{
                                                    required: true,
                                                    className: 'form-control date-width'
                                                }}
                                                timeFormat={false}
                                            />
                                        </FormGroup>
                                    </Col>
                                    


                                </Row>
                                
                                
                            </Col>
                        </Row>
                        <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '15px 15px', borderRadius: 7 }}>
                            
                            <Row>
                                <Col md={8}>
                                </Col>
                                <Col md={4}>
                                    
                                    <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        <Col md={12}>
                                            Total Cost:  <span style={{ fontSize: 15 }}>{project.currency}</span>{this.formatCurrency(project.cost)}
                                        </Col>
                                    </Row>
                                    <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        <Col md={12}>
                                            Amount Received:  <span style={{ fontSize: 15 }}>{project.currency}</span>{this.formatCurrency(amount_paid)}
                                        </Col>
                                    </Row>
                                    <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                                        <Col md={12}>
                                            Balance: <span style={{ fontSize: 15 }}>{project.currency}</span>{this.formatCurrency(project.cost - amount_paid)}
                                        </Col>
                                    </Row>

                                </Col>

                            </Row>

                        </Row>

                        <Row style={{ float: 'right' }}>
                            <Col md={12}>
                                <div className="modal-footer" style={{ padding: '1rem' }}>
                                    <ButtonGroup>
                                        <Button variant="primary" type="button" disabled={saving} size="sm"
                                            onClick={this.onSaveInvoice}
                                        >
                                            Save
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

            </>
        );
    }
}

export default AddInvoice;
