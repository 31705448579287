import React from "react";
import {
  Button,
  Modal
} from "reactstrap";
import { toast } from 'react-toastify';

import { deleteAllowance } from "../../services/salaryService";
import SpinDiv from "../components/SpinDiv";

class DeleteAllowance extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      allowance: props.deleteAllowance,
      loading: false,
      search: '',
      validation: {},
      name: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onDelete = () => {
    this.setState({loading: true});
    const {allowance} = this.state;
    console.log(allowance);
    deleteAllowance(allowance.id)
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('allowance Deleted')
      },
    	error => {
        this.showToast('allowance could not be deleted')
        this.setState({loading: false});
    	});
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  render() {
    const { deleteAllowance, toggle } = this.props
    const {allowance, loading} = this.state;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={deleteAllowance !== null}
          toggle={() => !loading&&toggle}
          style={{maxWidth: 600}}
        >
          {loading&&<SpinDiv text={'Deleting...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h3 className="modal-title" id="exampleModalLabel">
              Delete allowance - {allowance.title}
            </h3>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            Are you sure you want to delete this allowance? <br/><br/>
            This action is irreversible and all data associated with this allowance will be lost permanently!
          </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              variant='outline-secondary'
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button variant="outline-danger" type="button" disabled={loading} size="sm"
              onClick={this.onDelete}
              style={{backgroundColor: '#EC3237', borderColor: 'white', color: '#fff'}}>
              Delete
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default DeleteAllowance;
