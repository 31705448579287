import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  Input } from "reactstrap";
import { Col, Row, Form, ButtonGroup, Card, Image, Spinner, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import {faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Profile1 from "../../assets/img/team/profile-picture-1.jpg";
import Placeholder from  "../../assets/img/team/placeholder.png";
import ProfileCover from "../../assets/img/profile-cover.jpg";
import { toast } from "react-toastify";
import moment from "moment";
import {addProfileImage, getProfile, updateUser} from "../../services/userService"
import ReactDatetime from "react-datetime";
import SpinDiv from "../components/SpinDiv";
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { faDigitalOcean } from "@fortawesome/free-brands-svg-icons";
import Resizer from "react-image-file-resizer";
export class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      photo: '',
      editUser:false,
      user:{},
      employee:{},
      loading:false,
      saving:false,
      savingImage:false,
      profile_picture:''
    };
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  componentDidMount() {
    this.getUser();
  }

  getUser = () => {
   
    this.setState({
      loading: true,
    });
    
    getProfile().then(

      (res) => {
        this.setState({
          loading: false,
          user: res.user,
          employee:res.user.employee !== null ? res.user.employee : {},
          profile_picture:res.user.profile_pic
        });
        localStorage.setItem("profile", JSON.stringify(res.user.profile_pic));
        
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };




  onUpdateUser = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { user, employee, validation } = this.state;
    const {dob, gender, bank_branch, bank_name, account_holder_name, account_no,} = employee;
    const {firstname, lastname, password, email, phone, address } = user;
    await this.setState({
      validation: {
        ...validation,
        email: email !== '',
        firstname: firstname !== '',
        lastname: lastname !== '',
        password: password !== '',
        phone: phone !== '',
        address: address !== '',
        gender: gender !== '',
        dob: dob !== '',
        bank_name: bank_name !== '',
        bank_branch: bank_branch !== '',
        account_holder_name: account_holder_name !== '',
        account_no: account_no !== '',


      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveUser();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      );
    }
  };

  validationRules = (field) => {
    if (field === "firstname") {
      return "firstname is required";
    } else if (field === "lastname") {
      return "Last name is required";
    } else if (field === "address") {
      return "Address is required";
    }else if (field === "gender") {
      return "Gender is required";
    }else if (field === "dob") {
      return "Date of Birth is required";
    }else if (field === "bank_name") {
      return "Bank Name is required";
    } else if (field === "bank_branch") {
      return "Bank Branch Name is required";
    } else if (field === "branch_id") {
    return "Branch Field  is required";
    }else if (field === "account_holder_name") {
      return "Account Holder Name is required";
    } else if (field === "account_no") {
      return "Account Number is required";
    }else if (field === "phone") {
      return "Phone is required";
    }
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
  };

  toggleEditUser = () => {
    this.setState({ editUser: !this.state.editUser });
  };

  onChange = (e, state) => {
    const { user } = this.state;
    this.setState({ user: { ...user, [state]: e } });
  };

  onChange2 = (e, state) => {
    const { employee } = this.state;
    this.setState({ employee: { ...employee, [state]: e } });
  };
  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
      this.setState({
        imageType: event.target.files[0].type,
        imageSize: event.target.files[0].size
      });
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
            //this.setState({imageType:event.target.files[0].type})
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  }


  saveUser = () => {
    this.setState({ saving: true });
    const { user, employee, validation } = this.state;
    const {firstname, lastname, email,phone,address } = user;
    const {gender,dob,bank_branch, bank_name, account_holder_name, account_no,}=employee
    this.setState({ saving: true })
    updateUser({
      firstname: firstname,
      lastname: lastname,
      address: address,
      gender: gender,
      email: email,
      phone: phone,
      dob:dob,
      bank_branch:bank_branch,
      bank_name:bank_name,
      account_no: account_no,
      account_holder_name: account_holder_name,
    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false, saving: false });
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "green" }}>Profile updated</p>

          </div>
        );

        this.getUser();
      },
      (error) => {
        console.log(error);
        this.setState({ saving: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>An error occurred</p>

          </div>
        );
      }
    );
  };



  validationRulesImg = (field) => {
    if (field === "data") {
      return "Image is required";
    } else if (field === "imageType") {
      return "Image Type is not valid";
    } else if (field === "imageSize") {
      return "Image Size is not valid";
    }
  };

  onSaveImage = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { newImage, imageType, imageSize, validation } = this.state;
    console.log(imageType !== 'image/jpeg');
    await this.setState({
      validation: {
        ...validation,
        data: newImage !== undefined || newImage === null,
        imageType: imageType === 'image/jpeg' || imageType === 'image/png',
        imageSize: imageSize / 1024 / 1024 <= 2
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveImage();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRulesImg(v)}
            </p>
          ))}
        </div>
      );
    }
  };


  saveImage = () => {
    this.setState({ savingImage: true });

    const { newImage, id } = this.state;
    console.log(id);
    addProfileImage({
      data: newImage,
      user_id: id
    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false, savingImage: false });
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "green" }}>Image uploaded</p>

          </div>
        );
        this.setState({ newImage: "", uploadImage: false })
        this.getUser();
      },
      (error) => {
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>*{error}</p>

          </div>
        );
        this.setState({ loading: false, savingImage: false });
      }
    );
  };

  render() {
    const {photo, saving, savingImage,profile_picture, employee, loading, user, editUser}=this.state;
  
    return (
        <>    
          
        {loading && <SpinDiv text={"Loading..."} />}
        {saving && <SpinDiv text={"saving..."} />}
          <Row>
            <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
            <Row>
                  <Col className="text-right" md={12}>
                    {user && (
                        <ButtonGroup>
                        
                      <Button
                        variant={editUser ? "secondary" : "primary"}
                        onClick={this.toggleEditUser}
                        size="sm"
                      >
                        {editUser ? "Discard Changes" : "Edit Profile"}
                      </Button>
                        </ButtonGroup>
            
                       
                    )}
                  </Col>
                </Row>
              <Card.Body>
                <h5 className="mb-4">General information</h5>
                <Form>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={user.firstname}
                          disabled={!editUser}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "firstname");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={user.lastname}
                          disabled={!editUser}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "lastname");
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {<Row className="align-items-center">
                    <Col md={6} className="mb-3">
                      <Form.Group id="birthday">
                        <Form.Label>Date of Birth</Form.Label>
                        <ReactDatetime
                          value={employee !== null ? (moment(employee.dob).format('MMM DD, YYYY')) : ''}
                          dateFormat={'MMM D, YYYY'}
                          closeOnSelect
                          onChange={(e) => this.onChange2(e, "dob")}

                          inputProps={{
                            required: true,
                            className: 'form-control date-width',
                            disabled: !editUser
                          }}
                          timeFormat={false}
                        />
                        
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="birthday">
                        <Form.Label>Date Joined</Form.Label>
                        <ReactDatetime
                          value={employee !== null ? (moment(employee.date_joined).format('MMM DD, YYYY')) : ''}
                          dateFormat={'MMM D, YYYY'}
                          closeOnSelect
                          onChange={(e) => this.onChange2(e, "date_joined")}

                          inputProps={{
                            required: true,
                            className: 'form-control date-width',
                            disabled: true
                          }}
                          timeFormat={false}
                        />
                        
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="gender">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select

                          value={employee !== null ? employee.gender:'' }
                          onChange={async (e) => {
                            this.onChange2(e.target.value, "gender");
                          }}
                          disabled={!editUser}

                        >
                          <option value="">Gender</option>
                          <option value="Female">Female</option>
                          <option value="Male">Male</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>}
                  
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="emal">
                      <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={user.email}
                          disabled={!editUser}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "email");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="phone">
                      <Form.Label>Phone</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={user.phone}
                          disabled={!editUser}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "phone");
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <h5 className="my-4">Address</h5>
                  <Row>
                    <Col sm={9} className="mb-3">
                      <Form.Group id="address">
                        <Form.Label>Address</Form.Label>
                      
                        <Form.Control
                          required
                          type="text"
                          value={user.address}
                          disabled={!editUser}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "address");
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {<Row>
                  <Col md={9} style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                      <Form.Label style={{ paddingLeft: 10, fontSize: 18, }}>Bank Information</Form.Label>
                      <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                        <Col md={12} className="mb-3">
                          <Form.Group id="Lastname">
                            <Form.Label>Account Holder Name</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </InputGroup.Text>
                              <Input

                                type="text" placeholder="Enter Account Name"
                                name='account_holder_name'
                                disabled={!editUser}
                                value={employee !== null ? employee.account_holder_name : ''}
                                onChange={async (e) => {
                                  await this.onChange2(e.target.value, "account_holder_name");
                                }}


                              />
                            </InputGroup>

                          </Form.Group>
                        </Col>

                      </Row>
                      <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                        <Col md={12} className="mb-3">
                          <Form.Group id="Lastname">
                            <Form.Label>Bank Name</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faPiggyBank} />
                              </InputGroup.Text>
                              <Input

                                type="text" placeholder="Enter Bank name"
                                name='bank_name'
                                disabled={!editUser}
                                value={employee.bank_name || ''}
                                onChange={async (e) => {
                                  await this.onChange2(e.target.value, "bank_name");
                                }}


                              />
                            </InputGroup>

                          </Form.Group>
                        </Col>
                        <Col md={12} className="mb-3">
                          <Form.Group id="Account">
                            <Form.Label>Account Number</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faDigitalOcean} />
                              </InputGroup.Text>
                              <Input

                                type="text" placeholder="Account Number"
                                name='account_no'
                                disabled={!editUser}
                                value={employee !== null ? employee.account_no : ''}
                                onChange={async (e) => {
                                  await this.onChange2(e.target.value, "account_no");
                                }}


                              />
                            </InputGroup>

                          </Form.Group>
                        </Col>
                      </Row>
                      <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                        <Col md={12} className="mb-3">
                          <Form.Group id="address">
                            <Form.Label>Bank Branch</Form.Label>
                            <InputGroup>
                              <InputGroup.Text>
                                <FontAwesomeIcon icon={faLocationArrow} />
                              </InputGroup.Text>
                              <Input

                                type="text" placeholder="Enter Branch"
                                name='bank_branch'
                                disabled={!editUser}
                                value={employee !== null ? employee.bank_branch : ''}
                                onChange={async (e) => {
                                  await this.onChange2(e.target.value, "bank_branch");
                                }}


                              />
                            </InputGroup>

                          </Form.Group>
                        </Col>

                      </Row>

                    </Col>
                  </Row>
                }
                                    
      
                  {editUser && (
                    <div className="mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={saving}
                        onClick={this.onUpdateUser}>
                        Save
                      </Button>
                    </div>
                  )}
                </Form>
              </Card.Body>
            </Card>
            </Col>
    
            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                <Card border="light" className="text-center p-0 mb-4">
                  <div className="profile-cover rounded-top" />
                  <Card.Body className="pb-5">
                    <Card.Img src={profile_picture !== null ? profile_picture.url : Placeholder} alt={user.name} className="user-avatar large-avatar rounded-circle mx-auto mt-n7 mb-4" />
                    <Card.Title style={{textTransform:'capitalize'}}>{user.name}</Card.Title>
                    <Card.Subtitle className="fw-normal" style={{paddingBottom:10}}>Employee ID:{employee.employeeid}</Card.Subtitle>
                    <Card.Subtitle className="fw-normal">Designation:{user.designation}</Card.Subtitle>
                    <Card.Text className="text-gray mb-4">Department:{user.department}</Card.Text>
                    <Card.Text className="text-gray mb-4">Level: {employee.grade_level+' '+ employee.step_level}</Card.Text>
                    {/* <Card.Text className="text-gray mb-4">{user.address}</Card.Text> */}
                  </Card.Body>
                </Card>
                </Col>
                <Col xs={12}>
                <Card border="light" className="bg-white shadow-sm mb-4">
                  <Card.Body>
                    <div className="d-xl-flex align-items-center">
                      <div className="user-avatar xl-avatar">
                        <Image fluid rounded src={profile_picture !== null ? profile_picture.url : photo} />
                      </div>
                      <div className="file-field">
                        <div className="d-flex justify-content-xl-center ms-xl-3">
                          <div className="d-flex">
                            <span className="icon icon-md">
                              <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                            </span>
                            
                            <input type="file" onChange={this.fileChangedHandler} />
                            <div className="d-md-block text-start">
                              <div className="fw-normal text-dark mb-1">Choose Image</div>
                              <div className="text-gray small">JPG, GIF or PNG. Max size of 800K</div>
                            </div>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                    <Row>
                    <Col md={12}>
                      <img src={this.state.newImage} alt="" />
                        <div className="mt-3">
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={savingImage}
                            onClick={this.onSaveImage}>
                            upload
                          </Button>
                        </div>
                    </Col>
                  </Row>
                  </Card.Body>
                  
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      );
  }
}

export default Profile