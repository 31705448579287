import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { getEmployee } from "../../services/employeeService";
import { editLeave, getLeaveType } from "../../services/leaveService";

export class EditLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: props.employees.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      leave_types: [],
      used_leave:[],
      start_date:moment(props.leave.start_date).format('MMM DD, YYYY'),
      // applied_on: moment().startOf('month'),
      // end_date: moment().endOf('month'),
      show: false,
      edit: false,
      id: props.leave.id,
      leave: props.leave,
      employee_id:props.leave.user_id,
      

    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    //this.getLeaveTypes();
    this.filterLeaveType();
  }


  
  handleChange(e) {
    let obj = JSON.parse(e.target.value) 
    this.setState({leave_type_id: obj})
    //this.filterLeaveDays();
   

  }

  



  onSaveLeave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { leave_type_id, leave_days,leave, remark, start_date, end_date, leave_reason, applied_on, employee_id, validation } = this.state;
    console.log(leave_days)
    await this.setState({
      validation: {
        ...validation,
        employee_id: leave.employee_id !== '' && leave.employee_id !== undefined,
        leave_type_id: leave_type_id !== '' && leave_type_id !== undefined,
        leave_days: leave.leave_days !== '' && leave.leave_days !== undefined,
        leave_reason: leave.leave_reason !== '' && leave.leave_reason !== undefined,
        applied_on: leave.applied_on !== '' && leave.applied_on !== undefined,
        start_date: start_date !== '' && start_date !== undefined,
        //end_date: end_date !== '' && end_date !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveLeave();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field === "employee_id") {
      return "Employee Name  is required";
    } else if (field == 'leave_type_id') {
      return "Leave Type is required"
    } else if (field == 'leave_reason') {
      return "Reason for Leave is required"
    } else if (field == 'start_date') {
      return "Start Date is required"
    } else if (field == 'end_date') {
      return "End Date is required"
    } else if (field == 'applied_on') {
      return "Date applied is required"
    }else if (field == 'leave_days') {
      return "Leave days is required"
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({[state]: e})
    await this.filterLeaveType()
    
  }
  


  saveLeave = () => {
    this.setState({ saving: true });
    const { leave, id, leave_type_id, start_date } = this.state;
    console.log();
    editLeave({
      employee_id: leave.leave,
      id: id,
      leave_type_id:leave_type_id.value,
      year:moment(start_date).format('YYYY'),
      leave_days: leave.leave_days,
      applied_on: leave.applied_on,
      leave_reason: leave.leave_reason,
      remark: leave.remark,
      start_date: start_date,
      end_date:moment(start_date, 'MMM D, YYYY').add(leave_type_id.days, "days"),

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Leave has been created");
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('A user with this title address already exist')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };

  getEmployee = () => {
    const { id } = this.state;

    getEmployee(id).then(

      (res) => {
        this.setState({
          loading: false,
          employee: res.employee,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };



  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { leave } = this.state

    this.setState({ leave: { ...leave, [state]: e } })
  }

  
  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  filterLeaveType = () => {
    this.setState({ loading: true });
    const {employee_id, leave, start_date} = this.state;
    const year = moment(start_date).format('YYYY');
    {console.log(year)}
    getLeaveType({employee_id, year}).then(

      (res) => {
          this.setState({
            loading: false,
              used_leaves: res.used_leaves,
              leave_types: res.leave_types,
              leave_days:'',
              leave_type_id:''
          });
      },
      (error) => {
          this.setState({ filteringLeaveType: false });
      }
  );
  };

  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  leaveType(leave_types, used_leaves){
    const new_used_leaves = [];
    used_leaves.forEach(x => {
      const obj = new_used_leaves.find(o => o.leave_type_id === x.leave_type_id);
      if (obj) {
        obj.total_leave_days = obj.total_leave_days + x.total_leave_days;
      } else {
        new_used_leaves.push(x);
      }
    });
    return (
      <Form.Select
        id="state"
        required
        onChange={this.handleChange}
        style={{
          marginRight: 10,
          width: "100%",
        }}
      >
        
        <option value="">choose Leave Type</option>
        {leave_types.length==0 &&  <option value="">loading</option>}
        
         {new_used_leaves.map((p, index) => (
          p.days-p.total_leave_days > 0 ?
          <option value={JSON.stringify({days:p.days-p.total_leave_days, value:p.leave_type_id})} key={index}>
            {p.title}({p.total_leave_days}/{p.days})
          </option>:''
        ))}
        {leave_types.map((p, index) => (
          <option value={JSON.stringify({days:p.days, value:p.id})} key={index}>
            {p.title}({0}/{p.days})
          </option>
        ))}
      </Form.Select>
    )
  }








  render() {

    const { toggle } = this.props;

    const { saving,filteringLeaveType, start_date, leave_type_id, employee_id, edit, leave,  leave_types, used_leaves, loading } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={leave != null}
          toggle={() => !loading && !saving && toggle}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {(leave && leave.status !== 1)&& (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Leave"}



                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={12} style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                  <Form.Label style={{ paddingLeft: 10, fontSize: 18 }}>Leave update <br/>  {leave.status ==null ? <span><span style={{color:'#FFC300'}}>● </span>Pending</span> : leave.status ==1 ? <span><span style={{color:'green'}}>●  </span>Active</span> :<span><span style={{color:'red'}}>●  </span>Rejected</span>}</Form.Label>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={6} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Last Name"
                            name='lastname'
                            disabled
                            value={leave.name || ''}
                            onChange={async (e) => {
                              this.onChange(e.target.value, "name");
                            }}

                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-date">
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Start Date
                        </label>
                        <ReactDatetime
                          value={start_date}
                          dateFormat={'MMM D, YYYY'}
                          closeOnSelect
                          onChange={(e) => this.dateFilter(e, "start_date")}

                          inputProps={{
                            required: true,
                            className: 'form-control date-width',
                            disabled: !edit
                          }}
                          timeFormat={false}
                        />
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                  <Col md={6} >
                      <Form.Group className="mb-2">
                        <Form.Label>Leave Type</Form.Label>
                        { (leave_types && used_leaves) && this.leaveType(leave_types, used_leaves)}
                      </Form.Group>

                    </Col>
                    {leave_type_id && 
                         <Col md={6}>
                       <Form.Label>Leave Days</Form.Label>
                      <Form.Select
                        type="select"
                        onChange={(e) => this.onChange(e.target.value, "leave_days")}
                        >
                        <option value="">choose Left Days</option>
                        {this.range(1, leave_type_id.days).slice(0).reverse().map((p, index) => (
                          <option value={p} key={p}>
                            {p}
                          </option>
                        ))}
                      </Form.Select>
                      </Col>
                      }


                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={6} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Reason for Leave</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="reason"
                          disabled={!edit}
                          placeholder='Enter Leave Reason'
                          value={leave.leave_reason}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "leave_reason");
                          }}
                          type="textarea"
                        />
                      </Form.Group>

                    </Col>
                    <Col md={6} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Remark</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="reason"
                          disabled={!edit}
                          placeholder='Enter Leave Remark'
                          value={leave.remark}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "remark");
                          }}
                          type="textarea"
                        />
                      </Form.Group>

                    </Col>
                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={6}>

                      <FormGroup className="form-date">
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Applied Date
                        </label>
                        <ReactDatetime
                          value={moment(leave.applied_on).format('MMM DD, YYYY') || ''}
                          dateFormat={'MMM D, YYYY'}
                          closeOnSelect
                          onChange={(e) => this.onChange(e, "applied_on")}
                          disabled={!edit}
                          inputProps={{
                            required: true,
                            className: 'form-control date-width',
                            disabled: !edit
                          }}
                          timeFormat={false}
                        />
                      </FormGroup>
                    </Col>
                  </Row>


                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                {edit && (<div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveLeave}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>)}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditLeave;
