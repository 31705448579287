import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getResignations(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}resignations`, requestOptions).then(authService.handleResponse);
}


export function addResignation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addResignation`, requestOptions).then(authService.handleResponse);
}

export function editResignation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateresignation/${data.id}`, requestOptions).then(authService.handleResponse);
}



export function getResignation(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}resignation/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteResignation(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteresignation/${id}`, requestOptions).then(authService.handleResponse);
}
