import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";

import SpinDiv from "../components/SpinDiv";
import AddNotice from "./AddNotice";
import moment from "moment";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditNotice from "./EditNotice";
import { getNotices } from "../../services/noticeService";
import DeleteNotice from "./DeleteNotice";

export class NoticeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      edit:false,
      notices:[],
      total: 0,
      user:JSON.parse(localStorage.getItem('user')),

    };
    this.searchDebounced = debounce(this.searchNotices, 500);
    this.searchThrottled = throttle(this.searchNotices, 500);
  }

  componentDidMount() {
    this.searchNotices();
  }

  

  searchNotices = () => {
    const { page, rows, search, notices } = this.state;
    this.setState({ loading: true });
    getNotices({ page, rows, search, notices }).then(

      (res) => {
        this.setState({
          notices: res.notices.data,
          page: res.notices.current_page,
          total: res.notices.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.getNotices();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
 

  toggleEditNotice = (editNotice) => {
    this.setState({ editNotice});
    this.searchNotices();
   
  };
  toggleCloseNotice=(editNotice)=>{
    this.setState({ editNotice: !this.state.editNotice });
  }

 

  toggleAddNotice = () => {
    this.setState({ addNotice: !this.state.addNotice });
    this.searchNotices();
  }

  
  toggleDeleteNotice=(deleteNotice)=>{
    this.setState({ deleteNotice });
  }

  toggle = () => {
    this.setState({ deleteNotice: !this.state.deleteNotice });
  }

  render() {
    const { notices, total, user, page, rows, search, loading, addNotice, editNotice, deleteNotice } = this.state;
    
    return (
      <>
       
        
        {addNotice && (
          <AddNotice
            saved={this.searchNotices}
            toggle={this.toggleAddNotice}
            
          />
        )}

        {editNotice && (
          <EditNotice
            saved={this.searchNotices}
            notice={editNotice}
            toggle={this.toggleCloseNotice}
          />
        )}

        
        {deleteNotice && (
          <DeleteNotice
            saved={this.searchNotices}
            notice={deleteNotice}
            toggle={this.toggle}
          />
        )}

      
       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#annoucements">Annoucements</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {user.admin == 1 && <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddNotice()}>
                    Create Annoucement
                  </Button>
                </ButtonGroup>
              </div>}
              
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Annoucements
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Title</th>
                  <th className="border-0">Date</th>
                  <th className="border-0">actions</th>
                </tr>
              </thead>
              <tbody>
                
                {notices.map((notice, key) => {
               
                  return (
                    <tr >
                    
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{notice.title}</span>
                      </td>
                        
                    
                      <td>{moment(notice.created_at).format('MMM D, YYYY')}</td>
      
                    
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" 
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          onClick={() => this.toggleEditNotice(notice)} size="sm">
                            view
                          </Button>
                         {user.admin == 1 &&  <Button
                            variant="outline-danger"
                            onClick={() => {
                              this.toggleDeleteNotice(notice)
                            }}            
                            size="sm"
                          >
                            Delete
                          </Button>  }
                        
                          
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {notices.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Announcement yet
                </div>}
              {notices.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} Announcements`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default NoticeIndex;
