import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";
import { getNotification } from "../../services/settingsService";
import { toast } from "react-toastify";
import AddNotification from "./AddNotification";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
  Form,
} from "@themesberg/react-bootstrap";

import SpinDiv from "../components/SpinDiv";
import EditNotification from "./EditNotification";

export class NotificationIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
     notification:{},
    }
  }

  componentDidMount() {
   this.getNotification();
  }

  

  

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  };
  getNotification = () => {

    const { page, rows, search} = this.state;
    this.setState({ loading: true });
    getNotification({ page, rows, search }).then(
      (res) => {
        this.setState({
          loading: false,
         notification:res.notification ,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  

 

  
  
  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  toggleEdit = (editNotification) => {
    this.setState({ editNotification });
  };

  
  
  
  render() {
    const {
     notification,
      total,
      editNotification,
      addNotification,
      rows,
      search,
      loading,
      filtering
    } = this.state;
    return (
      <>
      
        {addNotification && (
          <AddNotification
            saved={this.getNotification}
            notification={addNotification}
            toggle={() => this.setState({ addNotification: null })}
          />
        )}


      


        {editNotification && (
          <AddNotification
            saved={this.getNotification}
            notification={editNotification}
            toggle={() => this.setState({ editNotification: null })}
          />
        )}

       


        {loading && <SpinDiv text={"Loading..."} />}
        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#EmailToggle"> Email Notification Settings</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {/* <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => this.toggleAddNotification()}
                  >
                    Update Email Notification Toggle
                  </Button>
                 
                  
                  <Button variant="outline-primary" size="sm">
                    Export
                  </Button>
                </ButtonGroup>
              </div> */}
            </div>
          </Col>
        </Row>
       
        
        <Card border="light" className="shadow-sm mb-4">
          
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Email Toggle Status</th>
                </tr>
              </thead>
              <tbody>
                {notification && <>
                  {notification.status ==null ? <span><span style={{color:'#FFC300', fontWeight:'bold', fontSize:20}}>● </span>Pending</span> : notification.status ==1 ? <span style={{ fontWeight:'bold', fontSize:20}}><span style={{color:'green'}}>● </span>Active</span> :<span style={{ fontWeight:'bold', fontSize:20}}><span style={{color:'red'}}>●  </span>In Active</span>}
                   <td>
                     <ButtonGroup>
                     <Button
                              variant="outline-primary"
                              onClick={() => this.toggleEdit(notification)}
                              size="sm"
                            >
                              Change
                            </Button>
                     </ButtonGroup>
                            
                    </td>
                </>}
            
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default NotificationIndex;
