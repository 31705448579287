import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { deleteRole, getRoles } from "../../services/roleService";
import SpinDiv from "../components/SpinDiv";
import AddRole from "./AddRole";
//import { throttle, debounce } from "throttle-debounce";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditRole from "./EditRole";
import DeleteRole from "./DeleteRole";
import styles from './style.module.css'

export class RoleIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      roles:[],
      permissions:[],
      total: 0,
    

    };
    this.searchDebounced = debounce(this.searchRoles, 500);
    this.searchThrottled = throttle(this.searchRoles, 500);
  }

  componentDidMount() {
    this.getRoles();
  }

  getRoles = () => {
    const { page, rows, search, roles } = this.state;
    this.setState({ loading: true });
    getRoles({ page, rows, search, roles }).then(
      (res) => {
        console.log(res.roles.data);
        
        this.setState({
          roles: res.roles.data,
          page: res.roles.current_page,
          roles:res.roles.data,
          total: res.roles.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  searchRoles = () => {
    const { page, rows, search, roles } = this.state;
    this.setState({ loading: false });
    getRoles({ page, rows, search, roles }).then(
      (res) => {
        this.setState({
          roles: res.roles.data,
          page: res.roles.current_page,
          roles:res.roles.data,
          total: res.roles.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  
  

  
  toggleEdit = (editRole) => {
    this.setState({ editRole });
    this.getRoles()
  
  };
 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.getRoles();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  

  toggleAddRole = () => {
    this.setState({ addRole: !this.state.addRole });
    // this.getRoles()
  };

  toggleEditRole = () => {
    this.setState({ editRole: !this.state.editRole });
    // this.getRoles()
  }
  toggle = () => {
    this.setState({ deleteRole: !this.state.deleteRole });
  }


  toggleDeleteRole=(deleteRole)=>{
    this.setState({ deleteRole });
  }

  

  render() {
    const { total, page, rows, search, loading, addRole,editRole,deleteRole, roles } = this.state;
    
    return (
      <>
        {addRole && (
          <AddRole
            saved={this.getRoles}
            addRole={addRole}
            roles={roles}
            toggle={this.toggleAddRole}
            
          />
        )}
        {editRole && (
          <EditRole
            saved={this.getRoles}
            editRole={editRole}
            roles={roles}
            toggle={this.toggleEditRole}
          />
        )}

        {deleteRole && (
          <DeleteRole
            saved={this.getRoles}
            role={deleteRole}
            toggle={this.toggle}
          />
        )}
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#roles">Roles</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddRole()}>
                    Add Roles
                  </Button>
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Roles
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Permissions</th>
                </tr>
              </thead>
              <tbody>
                
                {roles.map((role, key) => {
                  
                  return (
                    <tr style={{fontWeight:"bold"}}>
                      <th scope="row">
                        
                    <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{role.name}</span></td>
                        
                      </th>
                      <td style={{whiteSpace:'inherit !important'}}>{role.permissions.slice(0, 8).map((perm,index)=>{
                    return (
                      <td style={{color:'red',
                      padding:5,
                      }}>{perm.name}</td>
                    )
                  })}</td>
                      <td>
                        <ButtonGroup>
                        <Button
                          variant="outline-primary"
                          onClick={() => this.toggleEdit(role)}
                          size="sm"
                        >
                          View/Edit
                        </Button>
                        <Button
                          variant="outline-danger"
                          onClick={() => {//console.log('111')
                            this.toggleDeleteRole(role)
                          }}            
                          size="sm"
                        >
                          Delete
                        </Button>
                        </ButtonGroup>
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
              <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {roles.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} roles`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
            </Table>
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default RoleIndex;
