
import { faBuilding, faFileInvoice, faReceipt } from "@fortawesome/free-solid-svg-icons";
import PaySalaryIndex from "./pages/payslip/PaySalaryIndex";
import SalaryIndex from "./pages/salary/SalaryIndex";

const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]
export let salaryRoute = [

  ...(permissions.includes('show salary') === true ?  [{ path: "/salaries",component: SalaryIndex, title:"Set salaries",icon:faFileInvoice},] : []),
  
  ...(permissions.includes('show payslip') === true ?  [   { path: "/payslps",component: PaySalaryIndex, title:"Payslips",icon:faReceipt },] : []),
 
    
   
   

  ];
