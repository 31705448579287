import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";
import { getBranches } from "../../services/branchService";
import { toast } from "react-toastify";
import AddTerminationType from "./AddTerminationType";

import EditTerminationType from "./EditTerminationType";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
  Form,
} from "@themesberg/react-bootstrap";

import SpinDiv from "../components/SpinDiv";
import { addTerminationTypes, getTerminationTypes } from "../../services/terminationTypeService";
export class TerminationTypeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      termination_types: [],
      value:"",
      total: 0,
    }
  }

  componentDidMount() {
   this.getTerminationTypes();
  }
  

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  };
  getTerminationTypes = () => {

    const { page, rows, search} = this.state;
    this.setState({ loading: true });
    getTerminationTypes({ page, rows, search }).then(
      (res) => {
        this.setState({
          loading: false,
          termination_types:res.termination_types.data,
          total:res.termination_types.total
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  

  toggleAddTerminationType = () => {
    this.setState({ addTerminationTypes: !this.state.addTerminationTypes });
  };
  
  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  toggleEdit = (editTerminationType) => {
    this.setState({ editTerminationType });
  };

  
  
  render() {
    const {
      termination_types,
      total,
      addTerminationTypes,
      editTerminationType,
      rows,
      search,
      loading,
      filtering
    } = this.state;
    return (
      <>
      
        {addTerminationTypes && (
          <AddTerminationType
            saved={this.getTerminationTypes}
            addTerminationTypes={addTerminationTypes}
            toggle={() => this.setState({ addTerminationTypes: null })}
          />
        )}

        {editTerminationType && (
          <EditTerminationType
            saved={this.getTerminationTypes}
            editTerminationType={editTerminationType}
            toggle={() => this.setState({ editTerminationType: null })}
          />
        )}

       

        {loading && <SpinDiv text={"Loading..."} />}
        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#products">Termination</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => this.toggleAddTerminationType()}
                  >
                    Create Termination Types
                  </Button>
                  <Button variant="outline-primary" size="sm"  
                  onClick={() => {this.props.history.push('/terminations')}}

                  >
                    Termination
                  </Button>
                  
                
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="7">
            <h6>Termination Types({total})</h6>
           
          </Col>
          
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                id="show"
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                value={search}
                onChange={(e) => this.onChange(e.target.value, "search")}
                autoFocus
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    this.getTerminationTypes();
                    this.setState({
                      search: "",
                    });
                  }
                }}
              />
              <Button
                className="btn-icon btn-2"
                color="secondary"
                style={{ maxHeight: 45 }}
                size="sm"
                onClick={this.getTerminationTypes}
              >
                <i className="fa fa-search" />
              </Button>
            </div>
          </Col>
        </Row>
        
        <Card border="light" className="shadow-sm mb-4">
          
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Termination Type</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
            
                {termination_types.map((termination_type, key) => {
                  return (
                    <tr key={key}>
                      <td>{termination_type.name}</td>
                      <td>
                            <Button
                              color="secondary"
                              onClick={() => this.toggleEdit(termination_type)}
                              size="sm"
                            >
                              Edit
                            </Button>
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default TerminationTypeIndex;
