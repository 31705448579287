import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

export function searchEmployees(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}searchusers`, requestOptions).then(authService.handleResponse);
}

export function getEmployees(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employees`, requestOptions).then(authService.handleResponse);
}

export function addEmployee(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addemployee`, requestOptions).then(authService.handleResponse);
}

export function getEmployee(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}employee/${id}`, requestOptions).then(authService.handleResponse);
}

export function getEmployee2(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}employee2/${id}`, requestOptions).then(authService.handleResponse);
}

export function editEmployee(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateemployee/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteEmployee(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteemployee/${id}`, requestOptions).then(authService.handleResponse);
}

