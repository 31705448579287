import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { getRole } from '../../services/roleService'

export class EditRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.editRole,
      selectedPermissions: props.editRole.permissions.map(function (obj) {
        obj['value'] = obj['id']; // Assign new key
        obj['label'] = obj['name'];
        delete obj['name']; // Delete old key 
        delete obj['id'];
        return obj;
      }),
      id: props.editRole.id,
      loading: false,
      search: '',
      validation: {},
      show: false,
      name: '',
      editT: false,
      checkEdit: true,
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.getRole()

    //this.filter()
  }


  onSaveRole = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { role, validation } = this.state;
    const { name } = role;
    await this.setState({
      validation: {
        ...validation,
        name: name !== '',
      }
    })
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveRole();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      await setTimeout(() => toast.error(
        <div style={{ padding: '10px 20px' }}>
          <p style={{ margin: 0, fontWeight: 'bold', color: 'red' }}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: 'red' }}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if (field === "name") {
      return "Name is required";
    }
  }


  saveRole = () => {
    this.setState({ saving: true });

    const { name } = this.state.role;

    let data = new FormData();
    data.set("name", name);
    let op = this.state.selectedPermissions;
    for (var i in op) {

      data.set(`permissions[${i}]`, op[i].label);
    }
    return axios
      .post(
        `${settings.API_URL}updaterole/${this.state.role.id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        this.setState({ saving: false, edit: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Role updated");
      })
      .catch((err) => {
        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err.response.data.name) {
            this.showToastError('A role with this Name already exist')
          }
          this.setState({ saving: false });
        }
      });
  };



  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { role } = this.state
    this.setState({ role: { ...role, [state]: e } })
  }

  roleHandleChange = (selectedPermissions) => {
    this.setState({ selectedPermissions });
    console.log(this.state.selectedPermissions)
  }
  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  getRole = () => {
    const { id } = this.state;

    getRole(id).then(

      (res) => {
        console.log(res)
        this.setState({
          loading: false,
          permissions: res.permissions.map(function (obj) {
            obj['value'] = obj['id']; // Assign new key
            obj['label'] = obj['name'];
            delete obj['name']; // Delete old key 
            delete obj['id'];
            return obj;
          }),
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };



  render() {
    const { editRole, toggle } = this.props;

    const { saving, edit, loading, role, name, modules,
      validation, fields, editT, checkEdit, permissions, selectedPermissions, selectedPermission } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen={editRole != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 850 }}
        >

          {loading && <SpinDiv text={"loading..."} />}
          {loading&&<SpinDiv text={'Saving...'} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {role && (
                  <Button
                    color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Role"}
                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                  <Col md={12} className="mb-3">
                    <Form.Group id="Lastname">
                      <Form.Label>Title</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </InputGroup.Text>
                        <Input

                          type="text" placeholder="Add role title"
                          name='lastname'
                          disabled={!edit}
                          value={role.name || ''} required onChange={async (e) => {
                            await this.onChange(e.target.value, "name");
                          }}

                        />
                      </InputGroup>

                    </Form.Group>
                  </Col>

                </Row>
                <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                  <Col md={12}>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-product_id"
                        style={{ marginBottom: 10 }}
                      >

                        Assign Permission to roles
                      </label>

                      <Select
                        isMulti
                        value={selectedPermissions}
                        defaultValue={selectedPermissions}
                        onChange={this.roleHandleChange}
                        options={permissions}

                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <Col md={12}>
                    {edit && (
                      <div>
                        <Button
                          variant="primary"
                          size="sm"
                          style={{ marginTop: "10px", float: "right" }}
                          disabled={saving}
                          onClick={this.onSaveRole}
                        >
                          Save
                        </Button>
                        <Button
                          size="sm"
                          variant="transparent"
                          data-dismiss="modal"
                          type="button"
                          disabled={saving}
                          style={{ marginTop: "10px", float: "right" }}
                          onClick={toggle}
                        >
                          {" "}
                          Close
                        </Button>
                      </div>
                    )}

                  </Col>
                </Row>

              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditRole;
