import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getTimeLogs(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}timelogs`, requestOptions).then(authService.handleResponse);
}

export function getProjects(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}timelog/projects`, requestOptions).then(authService.handleResponse);
}


export function addTimeLog(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addtimelog`, requestOptions).then(authService.handleResponse);
}

export function deleteTimeLog(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletetimelog/${id}`, requestOptions).then(authService.handleResponse);
}

export function editTimeLog(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatetimelog/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function getTasks(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}projecttasks`, requestOptions).then(authService.handleResponse);
}

export function getMembers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}taskmembers`, requestOptions).then(authService.handleResponse);
}


export function getTimeLog(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}timelog/${id}`, requestOptions).then(authService.handleResponse);
}


