import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import SpinDiv from "../components/SpinDiv";
import { AddPerformance } from "./AddPerformance";
import DeletePerformance from "./DeletePerformance";
import { throttle,debounce } from "../users/debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import {  getPerformances } from "../../services/performanceService";
import { faDashcube } from "@fortawesome/free-brands-svg-icons";
export class PerformanceIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      employees: [],
      performances:[],
      start_year: 2018,
      current_year: new Date().getFullYear(),
      year:'',
      month:'',
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      total: 0,
    };
    this.searchDebounced = debounce(this.searchPerformances, 500);
    this.searchThrottled = throttle(this.searchPerformances, 500);
  }

  componentDidMount() {
    this.searchPerformances();
  }

  searchPerformances = () => {
    const { page, rows, search, performances, year, month } = this.state;
    this.setState({ loading: true });
    getPerformances({ page, rows, search, performances, year, month }).then(
      
      (res) => {
        this.setState({
          performances: res.performances.data,
          employees: res.users,
          page: res.performances.current_page,
          total: res.performances.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

   monthName = (mon)=> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
  }

  
  toggleEdit = (editPerformance) => {
    this.setState({ editPerformance });
   
  };
 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchPerformances();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
  toggleViewPerformance = (singlePerformance) => {
    this.setState({ singlePerformance});
    this.searchPerformances();
   
  };
  toggleClosePerformance=(singlePerformance)=>{
    this.setState({ singlePerformance: !this.state.singlePerformance });
  }

  toggleCloseAddPerformance=(addPerformance)=>{
    this.setState({ addPerformance: !this.state.addPerformance });
  }

  toggleAddPerformance = () => {
    this.setState({ addPerformance: !this.state.addPerformance });
    this.searchPerformances();
  }


  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  toggle = () => {
    this.setState({ deletePerformance: !this.state.deletePerformance });
  }

  

  onFilter = async (e, filter) => {
    await this.setState({ [filter]: e });
    await this.searchPerformances();
  };

  toggleDeletePerformance=(deletePerformance)=>{
    this.setState({ deletePerformance });
  }
  
  

  render() {
    const { employees,performances,singlePerformance, deletePerformance, addPerformance,start_year, year, current_year, month, months,showFilter, total, page, rows, search, loading } = this.state;
   
    return (
      <>
       
       {addPerformance && (
          <AddPerformance
            saved={this.searchPerformances}
            addPerformance={addPerformance}
            employees={employees}
            toggle={this.toggleAddPerformance}
            
          />
        )}

        {deletePerformance && (
          <DeletePerformance
            saved={this.searchPerformances}
            performance={deletePerformance}
            toggle={this.toggle}
          />
        )}



       
        {loading && <SpinDiv text={"Loading..."} />}
        
      

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">users</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddPerformance()}>
                    Create Performance
                  </Button>
                  
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}
                  
        
         
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="2">
          <h5 className="mb-0">Performance
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="6">
            <Row>
            {
              <div
                style={{
                  borderTop: "0.5px solid #e9ecef",
                  padding: "0 0 0 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  overflowX: "auto",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 10, fontSize: 14 }}>
                    Filter By Month:{" "}
                  </span>
                  <Form.Select
                    //value={order}
                    type="select"
                    style={{ marginRight: 10, width: "fit-content" }}
                    onChange={(e) => this.onFilter(e.target.value, "month")}
                  >
                    <option value="">Filter Month</option>
                    {months.map((p, index) => (
                      <option value={index+1} key={p}>
                        {p}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 10, fontSize: 14 }}>
                    Filter By Year:{" "}
                  </span>
                  <Form.Select
                    //value={order}
                    type="select"
                    style={{ marginRight: 10, width: "fit-content" }}
                    onChange={(e) => this.onFilter(e.target.value, "year")}
                  >
                    <option value="">Filter Year</option>
                    {this.range(start_year, current_year).slice(0).reverse().map((p, index) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                
              </div>
            }
          </Row>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Month</th>
                  <th className="border-0">Year</th>
                   <th className="border-0">Action</th>
                  
                 
              
                </tr>
              </thead>
              <tbody>
                
                {performances.map((performance, key) => {
               
                  return (
                    <tr style={{fontWeight:"bold"}}>
                     <th scope="row">
                      <td>
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#p"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                maxHeight: 50,
                                maxWidth: 50,
                                borderRadius: '100%',
                              }}
                              alt="..."
                              src={
                                performance.image ||
                                require("../../assets/img/brand/user.jpg")
                              }
                            />
                           
                          </a>
                          </Media>
                        </td>
                        <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{performance.name}</span>
                      </td>
                        
                      </th>
                   
                      
                      
                      <td>{this.monthName(performance.month)}</td> 
                      <td>{performance.year}</td>
                      <td>
                        <ButtonGroup>
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            this.props.history.push(`/performance/${performance.id}/${performance.year}/${performance.month}`)
                          }}
                          size="sm"
                        >
                          view
                        </Button>
                        <Button
                          variant="outline-danger"
                          onClick={() => {//console.log('111')
                            this.toggleDeletePerformance(performance)
                          }}            
                          size="sm"
                        >
                          Delete
                        </Button>
                       
                      
                        </ButtonGroup>
                      </td>
                      
                      
                    </tr>
                  );
                })}
              </tbody>
              <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {performances.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Performance for this Month and year
                </div>}
              
              {performances.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} performances`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
            </Table>
           
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default PerformanceIndex;
