import AwardIndex from "./pages/award/AwardIndex";
import PromotionIndex from "./pages/promotion/PromotionIndex";
import TerminationIndex from "./pages/termination/TerminationIndex";
import ResignationIndex from "./pages/resignation/ResignationIndex";
import { faAward, faGift, faHandScissors, faUnlock } from "@fortawesome/free-solid-svg-icons";

const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]
export let awardRoute = [
    
  ...(permissions.includes('show award') === true ?  [  { path: "/awards",component: AwardIndex, title:"Award",icon:faAward },] : []),
   
    

  ];