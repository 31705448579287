import React, { Component } from "react";
import {  FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { TimePicker } from 'antd';
import { toast } from "react-toastify";
import Select from 'react-select';
import moment from "moment";
import ReactDatetime from "react-datetime";
import {faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { getEmployees } from "../../services/employeeService";
export class AddAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      selectedUsers:[],
      start_year: 2018,
      current_year: new Date().getFullYear(),
      year:new Date().getFullYear(),
      month: new Date().getMonth(),
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      half_day:false,
      status:false,
      holiday:false,
      

    };
  }

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
    this.getEmployees();
  }

  getEmployees = () => {
    const { page, rows, search, employees } = this.state;
    this.setState({ loading: true });
    getEmployees({ page, rows, search, employees }).then(
      (res) => {
        this.setState({
          employees: res.employees.data.map(function (obj) {
            obj['value'] = obj['id']; // Assign new key
            obj['label'] = obj['name'];
            delete obj['name']; // Delete old key 
            delete obj['id'];
            return obj;
          }),
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };



  onSaveAttendance = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {selectedUsers,date, clock_in, clock_out, working_from, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        employee_id: selectedUsers.length !==0,
        clock_in: clock_in !== '' && clock_in !== undefined,
        clock_out: clock_out !== '' && clock_out !== undefined,
        working_from: working_from !== '' && working_from !== undefined,
        date: date !== '' && date !== undefined,
    
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveAttendance();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };


  validationRules = (field) => {
    if (field === "employee_id") {
      return "Employee Name  is required";
    } else if(field === "clock_in"){
      return "Clock In time  is required";
    }else if(field === "clock_out"){
      return "Clock Out time  is required";
    }else if(field === "working_from"){
      return "Place of work  is required";
    }else if(field === "date"){
      return "Date  is required";
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({[state]: e})
  }

  handleInputChange = (selectedUsers) => {
    this.setState({ selectedUsers });
    console.log(this.state.selectedUsers)
  }

  onClockIn(time, timeString) {
    this.setState({clock_in:timeString})
  }

  onClockOut(time, timeString) {
    this.setState({clock_out:timeString})
  }

  saveAttendance = () => {
    this.setState({ saving: true });

    const { selectedUsers, clock_in, date, holiday, half_day, status, clock_out, working_from } = this.state;
    let data = new FormData();
    let y= moment(date,"MMM D, YYYY").year()
    let m = moment(date,"MMM D, YYYY").month()+1;
    let d = moment(date,"MMM D, YYYY").date();
    data.set("clock_in", clock_in);
    data.set("clock_out", clock_out);
    data.set("working_from", working_from);
    data.set("year", y);
    data.set("month", m);
    // data.set("half_day", half_day);
    data.set("status", status);
    data.set("holiday", holiday === false ? 0 : 1);
    data.set("day", d);
    let op = selectedUsers;
    for (var i in op) {

      data.set(`employee_id[${i}]`, op[i].value);
    }
    return axios
      .post(
        `${settings.API_URL}addAttendance`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Attendance Marked");
        },
        (err) => {
          
          if (err) {
            console.log(err.response)
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err.response.status == 403) {
              this.showToastError('You do not have enough permission to create attendance')
            } else {
              this.showToastError('Cannot create Attendance')
            }
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );
      
  };
  dateFilter = async (e, state) => {
    await this.setState({[state]: e})
  }

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {toggle } = this.props;

    const { saving,loading, current_year, months, holiday, employees, half_day,status } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={employees != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth:800, }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
             <h5>  Mark Attendance</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
              <Col md={6}>
                  
                  <Form.Group>
                    <Form.Label>Employee Name</Form.Label>
                
                      <Select
                        isMulti
                        onChange={this.handleInputChange}
                        options={employees}

                      />
                        
                    </Form.Group>

                </Col>
                <Col md={6} className="mb-3">
                    <Form.Group id="Lastname">
                      <Form.Label>Date</Form.Label>
                      <ReactDatetime
                              
                        dateFormat={'MMM D, YYYY'}
                        closeOnSelect
                        onChange={e=>this.dateFilter(e, 'date')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-width'
                        }}
                        timeFormat={false}
                      />
                    </Form.Group>
                  </Col>
                  
                </Row>
                <Row>
                
                  <Col md={4}>
                  <Form.Group id="Lastname">
                      <Form.Label>Clock In</Form.Label>
                        <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </InputGroup.Text>
                        <TimePicker  use24Hours onChange={(time, clock_in) => this.onChange(clock_in, "clock_in")} 
                        />
                      </InputGroup>

                    </Form.Group>
                    
                  </Col>
                  <Col md={4}>
                    <Form.Group id="Lastname">
                      <Form.Label>Clock Out</Form.Label>
                        <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </InputGroup.Text>
                        <TimePicker  use24Hours onChange={(time, clock_out) => this.onChange(clock_out, "clock_out")}
                        />
                      </InputGroup>

                    </Form.Group>
                    
                  </Col>
                </Row>
                
                <Row style={{paddingTop:10}}>
                  <Col md={6} className="mb-3">
                    <Form.Group id="">
                      <Form.Label>Working From</Form.Label>
                        <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </InputGroup.Text>
                        <Input
                    
                          type="text"
                          style={{ marginRight: 10, width: "fit-content" }}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "working_from");
                          }}
                          placeholder='Working from'
                        
                        />
                         
                      </InputGroup>

                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                
                  
                  <Col md={2} style={{ paddingTop: 30 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Approve Attendance</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="status"
                          checked={status}
                          onChange={async (e) => {
                            await this.onChange(e.target.checked, "status");
                          }}
                          type="checkbox"
                        />
                      </Form.Group>

                    </Col>
                    <Col md={2} style={{ paddingTop: 30 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Holiday</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="holiday"
                          checked={holiday}
                          onChange={async (e) => {
                            await this.onChange(e.target.checked, "holiday");
                          }}
                          type="checkbox"
                        />
                      </Form.Group>

                    </Col>
                   
                </Row>
          
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveAttendance}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddAttendance;
