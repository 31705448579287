import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../../components/SpinDiv";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Progress, Button } from 'antd';
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Editor } from '@tinymce/tinymce-react';
import { currencies } from "./Currency";
export class Editproject extends Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.state = {

      start_date: moment().startOf('month'),
      deadline: moment().endOf('month'),
      id: props.project.id,
      project: props.project,
      progress: props.project.progress,
      departments: [],
      clients: [],
      documents: [],
      summary: props.project.summary,
      employees: [],
      initialEmployees: props.project.members.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      employees_ids: props.project.user_ids,


    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  
  }


  handleEditorChange(summary) {
    this.setState({ summary });
  }

  getDataFromURL = (url) => new Promise((resolve, reject) => {
    setTimeout(() => {
      fetch(url)
        .then(response => response.text())
        .then(data => {
          resolve(data)
        });
    });
  }, 2000);

  fileChangedHandler = (e) => {
    this.setState({ documents: [...this.state.documents, ...e.target.files] })
  }

  
  handleRemoveItem = (idx) => () => {
    this.setState({ documents: this.state.documents.filter((s, sidx) => idx !== sidx) });
  }

  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });


  fileChangedHandler = (e) => {
    this.setState({ documents: [...this.state.documents, ...e.target.files] })
  }


  toggleAddClient = () => {
    this.setState({ addClient: !this.state.addClient });
  };

  

  handleEmployeeChange = (initialEmployees) => {

    let new_employees_id = initialEmployees.map(obj => {
      return obj.value
    })
    this.setState({
      initialEmployees: initialEmployees,
      employees_ids: new_employees_id,
    });

  }



  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })
  }



  toggleDownload = (downloadProject) => {
    this.setState({ downloadProject });
    downloadProject.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = downloadProject.url;
      a.click();
    })
  }






  render() {

    const { toggle } = this.props;

    const { saving, addClient, initialEmployees, employees, edit, summary, project, clients, progress, departments, loading } = this.state;
    return (
      <>

        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={project != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {project && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Project"}



                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                {project.documents && <Table
                  responsive
                  className="table-centered table-nowrap rounded mb-0"
                >
                  <thead className="thead-light">
                    <tr>
                      {/* <th className="border-0">Project</th> */}
                      <th className="border-0">Name</th>
                      <th className="border-0">Download</th>

                    </tr>
                  </thead>
                  <tbody>

                    {project.documents.map((document, key) => {

                      return (
                        <tr >

                          <td>
                            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{document.name}</span>
                          </td>

                          <td>


                            <ButtonGroup>
                              <Button variant="outline-primary"


                                style={{ fontWeight: "bold", textTransform: "capitalize" }}
                                size="sm"><a target="_blank" href={document.url} > Download </a></Button>

                            </ButtonGroup>
                          </td>



                        </tr>
                      );
                    })}
                  </tbody>

                </Table>}
              </Row>
              <Row>
                <Col md={9} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      disabled={!edit}
                      placeholder='Enter Title'
                      value={project.title}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
                

              </Row>
              <Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Currency</Form.Label>
                      <Form.Select
                        id="state"
                        disabled={!edit}
                        required
                        value={project.currency}
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "currency");
                        }}
                        style={{
                          marginRight: 10,
                          width: "100%",
                        }}
                      >

                        <option value="">Select Currency</option>
                        {currencies.length == 0 && <option value="">loading</option>}
                        {currencies.map((p, index) => (
                          <option value={p.abbrev} key={p}>
                            {p.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>


                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Budget</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="title"
                        disabled={!edit}

                        value={project.cost}
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "cost");
                        }}
                        type="text"
                      />
                    </Form.Group>


                  </Col>
                </Row>

              </Row>

              <Row>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                      disabled={!edit}
                      value={moment(project.start_date).format('MMM DD, YYYY') || ''}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                    
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      End Date
                    </label>
                    <ReactDatetime
                      value={moment(project.deadline).format('MMM DD, YYYY') || ''}
                      disabled={!edit}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                     
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>

                <Col md={6} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Project Progress</Form.Label>
                    <Progress type="circle" value={progress || 0} percent={progress} />
                   
                   
                  </Form.Group>

                </Col>



              </Row>
              <Row>
            
                <Editor

                  value={summary}
                 

                  initialValue={project.summary}
                  init={{
                    height: 300,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}

                />

              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                   
                    <Button
                      size="md"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default Editproject;
