import React, { Component } from 'react'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faPowerOff, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/placeholder.png";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { BrowserRouter, useHistory, Route, Switch, Redirect } from "react-router-dom";

export class Navbar2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
    };
  }

  logOut = () =>{ 
    
    localStorage.removeItem("user");
   localStorage.removeItem('token');
   localStorage.removeItem('company');
   localStorage.removeItem("permissions");
   localStorage.clear();
   let path = '/auth/sign-in'; 
   this.props.history.push(path);

 }
getUser = ()=>{
  const user = JSON.parse(localStorage.getItem('user'));
  return user;
}

  render() {
    const {user} = this.state;
    return (
      <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0" style={{marginBottom:0}}>
        <Container fluid className="px-0">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              {/* <Form className="navbar-search">
                <Form.Group id="topbarSearch">
                  <InputGroup className="input-group-merge search-bar">
                    <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                    <Form.Control type="text" placeholder="Search" />
                  </InputGroup>
                </Form.Group>
              </Form> */}
            </div>
            <Nav className="align-items-center">
              {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
                <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faBell} className="bell-shake" />
                    {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                  <ListGroup className="list-group-flush">
                    <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                      Notifications
                    </Nav.Link>
  
                    {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}
  
                    <Dropdown.Item className="text-center text-primary fw-bold py-3">
                      View all
                    </Dropdown.Item>
                  </ListGroup>
                </Dropdown.Menu>
              </Dropdown> */}
  
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image src={user && user.image !== null ? user.image : Profile3} className="user-avatar md-avatar rounded-circle" />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">{user.name}</span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item className="fw-bold">
                    <Link to="/profile">
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
                    </Link>
                 
                  </Dropdown.Item>
                  {/* <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faCog} className="me-2" /> Settings
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faEnvelopeOpen} className="me-2" /> Messages
                  </Dropdown.Item>
                  <Dropdown.Item className="fw-bold">
                    <FontAwesomeIcon icon={faUserShield} className="me-2" /> Support
                  </Dropdown.Item> */}
  
                  <Dropdown.Divider />
  
                  <Dropdown.Item className="fw-bold">
                  <Button onClick={() => this.logOut()} size="sm"><FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2"  />&nbsp;Sign Out</Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default Navbar2