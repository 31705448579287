import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { getEmployees } from "../../services/employeeService";
import SpinDiv from "../components/SpinDiv";
import { AddAttendance } from "./AddAttendance";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import {  getAttendances, getMarkedAttendances } from "../../services/attendanceService";
import { Tooltip } from 'antd';
import SingleAttendance from "./SingleAttendance";
import { faDashcube } from "@fortawesome/free-brands-svg-icons";
import * as XLSX from 'xlsx';
import moment from "moment";
import { toast } from 'react-toastify';


export class AttendanceIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      employees: [],
      attendances:[],
      employee:'',
      roles:[],
      start_year: 2018,
      markedAttendances:[],
      current_year: new Date().getFullYear(),
      year:new Date().getFullYear(),
      month: new Date().getMonth()+1,
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      total: 0,
      fromdate: moment().startOf('month'),
      todate: moment().endOf('month'),
      user:''
    };
    this.searchDebounced = debounce(this.searchAttendances, 500);
    this.searchThrottled = throttle(this.searchAttendances, 500);
  }

  componentDidMount() {
    this.getEmployees();
    this.searchAttendances();
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }
  searchAttendances = () => {
    const { page, rows, search, attendances, user, year, month } = this.state;
    this.setState({ loading: true });
    getAttendances({ page, rows, search, user, attendances, year, month }).then(
      
      (res) => {
        this.setState({
          attendances: res.attendances.data,
          page: res.attendances.current_page,
          total: res.attendances.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  export = async () => {
    const { page, search, user, attendances, year, month, fromdate, todate, total } = this.state;
    const rows=1000;
    if(total<1){console.log(total)
      await toast.dismiss();
      await setTimeout(()=>this.showToast('No Attendance to export.'), 250);
    } else {
      this.setState({ loading: true });
      getAttendances({ page, rows, search, attendances, user, year, month, fromdate, todate })
        .then(response => {
          let exportt = '';
          exportt = response.attendances.data.map(c => (
            {employee: c.name, workDays:c.working_days, present: c.present, absent: c.absent, holiday:c.holidae, hours:c.hours, date: `${month}-${year}`}
          ));

          const theheader = ['employee','workDays','present','absent', 'holiday','hours', 'date'];
          const wch = [30,20,15,20,40,20,20,20,20];
          const cols = wch.map(h => {return ({wch: h});});
          const thedata = exportt.map(item => {
            return theheader.map(item2 => {
              return item[item2]
            })
          });

          const headerTitle = 'your header title here';
         
          const allofit = [theheader].concat(thedata);

          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

          const wb: XLSX.WorkBook = XLSX.utils.book_new(headerTitle);
          ws['!cols'] = cols;
          XLSX.utils.book_append_sheet(wb, ws, `Attendance`);
          XLSX.writeFile(wb, `attendance-${month}-${year}-report.xlsx`);
          this.setState({
            loading: false
          });
        },
        error => {
          this.setState({ loading: false });
        });
    }
  }

   monthName = (mon)=> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
  }

  markedAttendances = (attendance_id) => {
    const {  markedAttendances } = this.state;
    this.setState({ loading: true });
    getMarkedAttendances(attendance_id).then(
      
      (res) => {
        this.setState({
          markedAttendances: res.markedAttendances,
          
          loading: false,
        });
      },
    );
    return 'x'
  };



  getEmployees = () => {
    const { page, rows, search, employees } = this.state;
    this.setState({ loading: true });
    getEmployees({ page, rows, search, employees }).then(
      (res) => {
        console.log(res.employees.data);
        
        this.setState({
          employees: res.employees.data,
          page: res.employees.current_page,
          //total: res.employees.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };
  
  toggleEdit = (editAttendance) => {
    this.setState({ editAttendance });
   
  };
 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchAttendances();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
  toggleViewAttendance = (singleAttendance) => {
    this.setState({ singleAttendance});
    this.searchAttendances();
   
  };
  toggleCloseAttendance=(singleAttendance)=>{
    this.setState({ singleAttendance: !this.state.singleAttendance });
  }

  toggleCloseAddAttendance=(addAttendance)=>{
    this.setState({ addAttendance: !this.state.addAttendance });
  }

  toggleAddAttendance = () => {
    this.setState({ addAttendance: !this.state.addAttendance });
    this.searchAttendances();
  }


  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  getRegAttendance = (result) => {
    return (
      result.map((p, index) => {
        return (
        
          <>
            {  p.status == 1 && p.holiday == 0 &&<Tooltip title={'Full Day ' +p.day } color='green'   onClick={() => this.toggleViewAttendance(p)}size="sm">
          <td> <FontAwesomeIcon icon={faCheck}  color='green'  onClick={() => this.toggleViewAttendance(p)}size="sm" /> </td>
        </Tooltip>}
        {  p.status == 1 && p.holiday == 1 &&<Tooltip title={'Holiday ' +p.day } color='orange'   onClick={() => this.toggleViewAttendance(p)}size="sm">
          <td> <FontAwesomeIcon icon={faHome}  color='orange'  onClick={() => this.toggleViewAttendance(p)}size="sm" /> </td>
        </Tooltip>}
           { p.half_day == 1 &&  <td><Tooltip title={'Half Day ' +p.day } color='cyan'   onClick={() => this.toggleViewAttendance(p)}size="sm"> <FontAwesomeIcon color="cyan"
           icon={faCheck}   
           onClick={() => this.toggleViewAttendance(p)}size="sm" /> </Tooltip> </td>}

          { p.status == 0 && p.half_day == 0 && <td><Tooltip title={'Absent Day' +p.day }  color='red'  onClick={() => this.toggleViewAttendance(p)}size="sm"> <FontAwesomeIcon color="red"
           icon={faTimes}   
           onClick={() => this.toggleViewAttendance(p)}size="sm" /> </Tooltip> </td>}

          </>
      
        )

       
      })
      
    )
    

  }

  getUnmarkedAttendance = (result, month) => {
    let days_of_month = this.range(1, month);
    let marked_days = result.map((p, index)=>{
      return Number(p.day);
    })
    
    
    let unmarked = days_of_month.filter(item => !marked_days.includes(item));
   console.log(unmarked)
    return (
      unmarked.map((p, index)=> {
        return (
          <Tooltip title={'Day ' +p } color='cyan' >
          <td>-</td>
        </Tooltip>
        )
      })
    )
  }

  onFilter = async (e, filter) => {
    await this.setState({ [filter]: e });
    await this.searchAttendances();
  };
  

  render() {
    const { employees,attendances, singleAttendance, addAttendance,start_year, year, current_year, month, months,showFilter, total, page, rows, search, loading } = this.state;
   
    return (
      <>
       
       {addAttendance && (
          <AddAttendance
            saved={this.searchAttendance}
            addAttendance={addAttendance}
            employees={employees}
            toggle={this.toggleAddAttendance}
            
          />
        )}

        {singleAttendance && (
          <SingleAttendance
            saved={this.searchAttendances}
            singleAttendance={singleAttendance}
            toggle={this.toggleCloseAttendance}
          />
        )}  

       
        {loading && <SpinDiv text={"Loading..."} />}
        
      

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">users</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddAttendance()}>
                    Create Attendance
                  </Button>
                  
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}

                  <Button variant="outline-primary" size="sm"  onClick={this.export}>
                    Export Attendance
                  </Button>
                  
        
         
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <div
                style={{
                
                  display: "flex",
               
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 10, fontSize: 14 }}>
                    Filter By Month:{" "}
                  </span>
                  <Form.Select
                    //value={order}
                    type="select"
                    style={{ marginRight: 10, width: "fit-content" }}
                    onChange={(e) => this.onFilter(e.target.value, "month")}
                  >
                    <option value="">Filter Month</option>
                    {months.map((p, index) => (
                      <option value={index+1} key={p}>
                        {p}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 10, fontSize: 14 }}>
                    Filter By Year:{" "}
                  </span>
                  <Form.Select
                    //value={order}
                    type="select"
                    style={{ marginRight: 10, width: "fit-content" }}
                    onChange={(e) => this.onFilter(e.target.value, "year")}
                  >
                    <option value="">Filter Year</option>
                    {this.range(start_year, current_year).slice(0).reverse().map((p, index) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 10, fontSize: 14 }}>
                    Filter By Employee:{" "}
                  </span>
                  <Form.Select
                    //value={order}
                    type="select"
                    style={{ marginRight: 10, width: "fit-content" }}
                    onChange={(e) => this.onFilter(e.target.value, "user")}
                  >
                    <option value="">Filter By All Employee</option>
                    
                    {employees.map((employee, key)=>{
                     return(
                      <option value={employee.id}>{employee.name}</option>
                     )
                    })}
                  </Form.Select>
                </div>
                
              </div>
          </Col>
        </Row>
        <Row>
          <Col lg="2">
          <h5 className="mb-0">Attendance
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="6">
            <Row>
            {
              
            }
          </Row>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Month</th>
                  <th className="border-0">Year</th>
                  <th className="border-0">Attendance</th>
                  <th className="border-0">Score</th>
                 
              
                </tr>
              </thead>
              <tbody>
                
                {attendances.map((attendance, key) => {
               
                  return (
                    <tr style={{fontWeight:"bold"}}>
                     <th scope="row">
                      <td>
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#p"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                maxHeight: 50,
                                maxWidth: 50,
                                borderRadius: '100%',
                              }}
                              alt="..."
                              src={
                                attendance.image ||
                                require("../../assets/img/brand/user.jpg")
                              }
                            />
                           
                          </a>
                          </Media>
                        </td>
                        <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{attendance.name}</span>
                      </td>
                        
                      </th>
                      
                      
                      <td>{this.monthName(month)}</td> 
                      <td>{year}</td>    
                      <td>{this.getRegAttendance(attendance.attendance_marked)}{this.getUnmarkedAttendance(attendance.attendance_marked, new Date(year, month, 0).getDate())} </td>
                       <td>{attendance.attendance_marked.length}/{new Date(year, month, 0).getDate()}</td>                         
                     
                      
                    </tr>
                  );
                })}
              </tbody>
              <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {attendances.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  Filter Attendance By Month and Year
                </div>}
              {attendances.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} attendances`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
            </Table>
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default AttendanceIndex;
