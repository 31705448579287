import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

const authuser=JSON.parse(localStorage.getItem('user'));

export function getPromotions(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin === 1){
        return fetch(`${settings.API_URL}promotions`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/promotions`, requestOptions).then(authService.handleResponse);
    }
}


export function addPromotion(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addpromotion`, requestOptions).then(authService.handleResponse);
}

export function editPromotion(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatepromotion/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getPromotion(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}promotion/${id}`, requestOptions).then(authService.handleResponse);
}

export function deletePromotion(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletepromotion/${id}`, requestOptions).then(authService.handleResponse);
}
