import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

const authuser=JSON.parse(localStorage.getItem('user'));

export function getNotices(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin === 1){
        return fetch(`${settings.API_URL}notices`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/notices`, requestOptions).then(authService.handleResponse);
    }
}


export function addNotice(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addnotice`, requestOptions).then(authService.handleResponse);
}

export function getUserPermissions(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}my_permissions`, requestOptions).then(authService.handleResponse);
}

export function editNotice(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatenotice/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getNotice(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}notice/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteNotice(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletenotice/${id}`, requestOptions).then(authService.handleResponse);
}
