import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getTasks(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employee2/tasks`, requestOptions).then(authService.handleResponse);
}

export function getTimeLogs(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employee/timelogs`, requestOptions).then(authService.handleResponse);
}

export function getProjects(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}timelog/projects`, requestOptions).then(authService.handleResponse);
}


export function addTimeLog(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employee/addtimelog`, requestOptions).then(authService.handleResponse);
}

export function deleteTimeLog(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}employee/deletetimelog/${id}`, requestOptions).then(authService.handleResponse);
}

export function editTimeLog(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employee/updatetimelog/${data.id}`, requestOptions).then(authService.handleResponse);
}