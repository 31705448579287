import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getTasks(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}tasks`, requestOptions).then(authService.handleResponse);
}


export function addTask(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addtask`, requestOptions).then(authService.handleResponse);
}

export function deleteTask(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletetask/${id}`, requestOptions).then(authService.handleResponse);
}

export function editTask(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatetask/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getTask(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}task/${id}`, requestOptions).then(authService.handleResponse);
}
