import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faSignOutAlt, faChartLine, faCloudUploadAlt, faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import { BrowserRouter, useHistory, Route, Switch, Redirect } from "react-router-dom";
import {
  Nav,
  Card,
  Table,
  Form,
  Breadcrumb,
  InputGroup,
} from "@themesberg/react-bootstrap";
import SpinDiv from "../../components/SpinDiv";
import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../../components/Widgets";
import { PageVisitsTable } from "../../../components/Tables";
import { trafficShares, totalOrders } from "../../../data/charts";
import { toast } from "react-toastify";
import {getCurrentAttendance, clockOut} from '../../../services/employeeAttendance';
import ClockIn from './ClockIn';
import axios from 'axios';

export class DashboardOverviewstaff extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      rows: 10,
      loading: false,
      saving:false,
      total: 0,
      attendance:'',
      ip_address:[],
      user_ip_address:{}
    };
  }

  componentDidMount() {
    this.checkAttendance();
    this.getUserIpAddress()
  }

  logOut = () =>{ 
    localStorage.removeItem("user");
    localStorage.removeItem('token');
    localStorage.clear();
    //let path = '/auth/sign-in'; 
    window.location.href = '/auth/sign-in'
  }

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  getUserIpAddress = async () => {
    this.setState({ loading: true });
    return await axios.get('https://ipapi.co/json/').then(
        (res) => {
         
          this.setState({
            user_ip_address: res.data.ip,
            loading:false
          })
          console.log(res.data.ip)
        },
        (err) => {
          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err) {
              this.showToastError('Could not Get your IP Address, please connect to the right network')
            }
            this.setState({ loading: false });
          }
        }
      );
  }

  clockOut = () => {
    this.setState({saving:true})
    const {ip_address, user_ip_address}= this.state;
   
    if(ip_address.includes(user_ip_address)){
      clockOut().then(
        (res) => {
          this.setState({ saving: false });
          this.showToast("Clocked Out");
          this.checkAttendance();
        },
        (err) => {
  
          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err) {
              this.showToastError('An error occured')
            }
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );
    } else {
      alert('Please Connect to the right Network')
      this.setState({ saving: false });
     
     
     
    }
    

  }

  toggleCloseAddAttendance=(addAttendance)=>{
    this.setState({ addAttendance: !this.state.addAttendance });
  }

  checkAttendance = () => {
    this.setState({ loading: true });
    getCurrentAttendance().then(
    
      (res) => {
       
        this.setState({
          attendance: res.attendance,
          ip_address:res.ip_address.map((p, key)=>(p.ip_address)),
          loading:false
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  toggleAddAttendance = () => {
    this.setState({ addAttendance: !this.state.addAttendance });
    this.checkAttendance();
  }

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
   showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };
  showClock = (attendance) => {
    const {user_ip_address, ip_address}= this.state;
    if(!attendance){
     return ( <Button onClick={() => this.toggleAddAttendance()} variant="tertiary" size="sm">
        <FontAwesomeIcon  icon={faSignOutAlt} className="me-2"   />Clock In
      </Button>)
    } else if(attendance.clock_in !== null && attendance.clock_out == null){
      return (
        <Button onClick={() => this.clockOut()} variant="tertiary" size="sm">
        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Clock Out
        </Button>
      )
    } else if(attendance && attendance.clock_in !== null && attendance.clock_out !== null){
      return (
        <Button  disabled variant="primary" size="sm">
          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Clocked
         </Button>
      )
    } else {
      return '';
    }
  }

  render() {
    const {attendance,loading, saving, user_ip_address,addAttendance, ip_address} = this.state;
    return (
      <>
         {addAttendance && (
          <ClockIn
            saved={this.checkAttendance}
            addAttendance={addAttendance}
            ip_address={ip_address}
            toggle={this.toggleAddAttendance}
            
          />
        )}
         {loading && <SpinDiv text={"Loading..."} />}
         {saving && <SpinDiv text={"Clock Out..."} />}
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
           
            { ip_address.includes(user_ip_address) ? this.showClock(attendance):  <Button disabled variant="danger" size="md">
          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Clocking cannot be accessed
        </Button>}
           
        
  
          <ButtonGroup>
            <Button variant="outline-danger"  onClick={() => this.logOut()} size="sm">Sign Out</Button>
            {/* <Button variant="outline-primary" size="sm">Export</Button> */}
          </ButtonGroup>
        </div>
  
        <Row className="justify-content-md-center">
          
          <Col xs={12} sm={6} xl={4} className="mb-4">
            <CounterWidget
              category="Customers"
              title="345k"
              period="Feb 1 - Apr 1"
              percentage={18.2}
              icon={faChartLine}
              iconColor="shape-secondary"
            />
          </Col>
  
          <Col xs={12} sm={6} xl={4} className="mb-4">
            <CounterWidget
              category="Revenue"
              title="$43,594"
              period="Feb 1 - Apr 1"
              percentage={28.4}
              icon={faCashRegister}
              iconColor="shape-tertiary"
            />
          </Col>
  
          <Col xs={12} sm={6} xl={4} className="mb-4">
            <CircleChartWidget
              title="Traffic Share"
              data={trafficShares} />
          </Col>
         
        </Row>
        <Row>
          <Col xs={12} sm={6} xl={4} className="mb-4">
            <Card border="light" className="shadow-sm">
              <Card.Body>
                
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default DashboardOverviewstaff