import React, { Component } from "react";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { addNotice } from "../../services/noticeService";
import AsyncSelect from "react-select/async";
import ReactDatetime from "react-datetime";
import moment from "moment";

export class AddNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      title: '',
      start_date: moment().startOf('month'),
      end_date: moment().endOf('month'),
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
   
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };


  

  



  onSaveNotice = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { title, notice, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        notice: notice !== '' && notice !== undefined,
        title: title !== '' && title !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveNotice();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field === "title") {
      return "Title is required";
    } else if (field == 'notice') {
      return "Announcement Body is required"
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })

  }

  saveNotice = () => {
    this.setState({ saving: true });
    const {  title, notice } = this.state;
    addNotice({
      title: title,
      notice: notice,


    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Annoucement has been created");
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('An error occured')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {toggle } = this.props;

    const { saving,  title, notice,  loading } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5>Create Annoucement</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
              <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}> Annoucement Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      placeholder='Enter Annoucement Title'
                      value={title}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>
              
              <Row>
                <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Annoucement Body</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="reason"
                      style={{height:150}}
                      placeholder='Enter Annoucement Body'
                      value={notice}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "notice");
                      }}
                      type="textarea"
                    />
                  </Form.Group>

                </Col>

              </Row>
              <Row>

              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveNotice}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddNotice;
