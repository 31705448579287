import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getDesignations(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}designations`, requestOptions).then(authService.handleResponse);
}

export function getIpAddress() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${settings.API_URL}ip_address`, requestOptions).then(authService.handleResponse);
}

export function addDesignations(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}adddesignations`, requestOptions).then(authService.handleResponse);
}

export function addIpAddress(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}add_ipaddress`, requestOptions).then(authService.handleResponse);
}

export function editDesignation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatedesignation/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function editIpAddress(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateipaddress/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteIpAddress(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteipaddress/${id}`, requestOptions).then(authService.handleResponse);
}


export function deleteDesignation(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletedesignation/${id}`, requestOptions).then(authService.handleResponse);
}


