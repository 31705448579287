import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    CardHeader,
    Media,
    Input,
    Badge,
} from "reactstrap";
import {
    faAngleDown,
    faAngleUp,
    faCheck,
    faCog,
    faHome,
    faSearch,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
    Col,
    Row,
    Card,
    Table,
    Button,
    ButtonGroup,
    Breadcrumb,
} from "@themesberg/react-bootstrap";
import { throttle, debounce } from "../project/debounce";
import { getDocuments, getProjects } from "../../services/clientService"
import UploadDocument from './UploadDocument';
import moment from "moment";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import { getClient } from '../../services/clientService';
import { Progress } from 'antd';

export class ClientDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
            page: 1,
            rows: 10,
            loading: false,
            documents: [],
            conversation_documents:[],
            projects:[],
            total: 0,
            id: props.match.params.id,
            client: {},
            showDocumentMenu:false,
            showProjectMenu:true,
            showConverseDocumentMenu:false,
            rows: 10,
            page: 1,
            total: 0



        };
        this.searchDebounced = debounce(this.searchDocuments, 500);
        this.searchThrottled = throttle(this.searchDocuments, 500);

        this.searchProjectDebounced = debounce(this.searchProjects, 500);
        this.searchProjectThrottled = throttle(this.searchProjects, 500);
    }

    componentDidMount() {
        this.searchDocuments();
        this.searchProjects();
        this.getClient();
    }


    toggleUploadDocument = () => {
        this.setState({ uploadDocument: !this.state.uploadDocument });
        this.searchDocuments();
    }

    toggleDocumentMenu = () => {
        this.setState({showDocumentMenu: true, showProjectMenu:false, showConverseDocumentMenu:false});
    }

    toggleConversationDocumentMenu = () => {
        this.setState({showConverseDocumentMenu: true, showProjectMenu:false, showDocumentMenu:false});
    }

    

    toggleProjectMenu = () => {
        this.setState({showProjectMenu: true, showDocumentMenu:false, showConverseDocumentMenu:false});
    }


    searchDocuments = () => {
        const { page, rows, id, search, } = this.state;
        this.setState({ loading: true });
        getDocuments({ page, rows, id, search }).then(

            (res) => {
                this.setState({
                    documents: res.documents.data,
                    conversation_documents:res.conversation_documents.data,
                    total: res.total,
                    loading: false,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    searchProjects = () => {
        const { page, rows, id, search, } = this.state;
        this.setState({ loading: true });
        getProjects({ page, rows, id, search }).then(

            (res) => {
                this.setState({
                    projects: res.projects.data,
                    total: res.total,
                    loading: false,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    getClient = () => {
        const { id } = this.state;
        console.log(id)
        this.setState({ loading: true });
        getClient(id).then(
            (res) => {
                this.setState({
                    client: res.client,
                    loading: false,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };



    onChange = (e, state) => {
        this.setState({ [state]: e });
    };

    onPage = async (page, rows) => {
        await this.setState({ page, rows });
        await this.searchDocuments();
    }

    handleSearch = event => {
        this.setState({ search: event.target.value }, () => {
            if (this.state.search < 5) {
                this.searchThrottled(this.state.search);
            } else {
                this.searchDebounced(this.state.search);
            }

        });
    };

    handleSearchProject = event => {
        this.setState({ search: event.target.value }, () => {
            if (this.state.search < 5) {
                this.searchProjectThrottled(this.state.search);
            } else {
                this.searchProjectDebounced(this.state.search);
            }

        });
    };

    formatCurrency(x) {
        if (x !== null && x !== '0' && x !== undefined) {
            const parts = x.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `${parts.join(".")}`;
        }
    
    }

    render() {
        const { project, projects, showConverseDocumentMenu, conversation_documents, showDocumentMenu, showProjectMenu, client, rows, page, total, uploadDocument, id, documents } = this.state;
        return (
            <>
                {uploadDocument && (
                    <UploadDocument
                        saved={this.searchDocuments}
                        client_id={id}
                        toggle={this.toggleUploadDocument}

                    />
                )}
                <Row style={{marginBottom:20}}>
                   <Col md={12}>
                      <ButtonGroup>
                      <Button  disabled variant="outline-primary" style={{ fontSize: 18, fontWeight: 'bold' }} size="sm">
                        <FontAwesomeIcon icon={faUser} />   {client.name}

                        </Button>
                      <Button variant="outline-primary" style={{ fontSize: 18, fontWeight: 'bold' }} size="sm" onClick={() => this.toggleDocumentMenu()}>Show Documents</Button>
                      <Button variant="outline-primary" style={{ fontSize: 18, fontWeight: 'bold' }} 
                        size="sm" onClick={() => this.toggleConversationDocumentMenu()}>
                            Conversation Documents
                        </Button>
                       <Button variant="outline-primary" style={{ fontSize: 18, fontWeight: 'bold' }} size="sm" onClick={() => this.toggleProjectMenu()}>Show Projects</Button>
                      
                       
                      </ButtonGroup>

                    </Col> 
                </Row>
                {showProjectMenu && (<>
                    <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  {/* <th className="border-0">Project</th> */}
                  <th className="border-0">Title</th>
                  <th className="border-0">Client</th>
                  <th className="border-0">Budget</th>
                  <th className="border-0">Progress</th>
                  <th className="border-0">Start Date</th>
                  <th className="border-0">End Date</th>
                  <th className="border-0">actions</th>
                </tr>
              </thead>
              <tbody>
                
                {projects.map((project, key) => {
               
                  return (
                    <tr >
                     
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{project.title}</span>
                      </td>
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{project.client_name}</span>
                      </td>
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>
                          {project.currency !== null ? project.currency :'#'}{this.formatCurrency(project.cost)}</span>
                      </td>
                      <td>
                        <Progress percent={project.progress} status={project.progress < 50 ? 'exception':''} />
                      </td>
                      <td>{moment(project.start_date).format('MMM D, YYYY')}</td>
                      <td>{moment(project.deadline).format('MMM D, YYYY')}</td>
                    
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" 
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          onClick={() => this.toggleEditProject(project)} size="sm">view</Button>
                           
                           {project.invoice == "" ? <Button variant="outline-primary" 
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          onClick={() => this.props.history.push(`/new/invoice/${project.id}`)} size="sm">
                              Create Invoice
                        </Button> : <Button variant="outline-primary" 
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          onClick={() => this.props.history.push(`/invoice/${project.id}`)} size="sm">
                              Invoice
                        </Button>}
                          
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {projects.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `${total} Projects`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
                </>)}

                {showDocumentMenu && (<>
                    <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#users">Documents</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>

                                   
                                </ButtonGroup>
                            </div>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={10}>

                    </Col>
                    <Col md={2} style={{marginBottom:'10px'}}>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <ButtonGroup>

                               
                                <Button variant="outline-primary" size="sm" onClick={() => this.toggleUploadDocument()}>
                                        Upload Documents
                                    </Button>
                            </ButtonGroup>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                            <Row>
                                {documents && <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                >
                                    <thead className="thead-light">
                                        <tr>
                                            {/* <th className="border-0">Project</th> */}
                                            <th className="border-0">Name</th>
                                            <th className="border-0">Date Uploaded</th>
                                            <th className="border-0">Download</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {documents.map((document, key) => {

                                            return (
                                                <tr >

                                                    <td>
                                                        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{document.name}</span>
                                                    </td>
                                                    <td>{moment(document.created_at).format('MMM D, YYYY')}</td>

                                                    <td>
                                                        <ButtonGroup>
                                                            <Button variant="outline-primary"
                                                                style={{ fontWeight: "bold", textTransform: "capitalize" }}
                                                                size="sm"><a style={{ textDecoration: 'none' }} target="_blank" href={document.url} >
                                                                    Download </a></Button>

                                                        </ButtonGroup>
                                                    </td>



                                                </tr>
                                            );
                                        })}
                                    </tbody>

                                </Table>}
                            </Row>
                            <Row>
                                <Col md={12} style={{ fontWeight: "bold", paddingTop: 3 }}>
                                    {documents.length > 0 && <Pagination
                                        total={total}
                                        showTotal={total => `Total ${total} Documents for client`}
                                        onChange={this.onPage}
                                        pageSize={rows}
                                        current={page}
                                    />}
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>

                </Row>
                </>)}

                {showConverseDocumentMenu && (<>
                    <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#users">Conversation Documents</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>

                                   
                                </ButtonGroup>
                            </div>

                        </div>
                    </Col>
                </Row>
               
                <Row>
                    <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                            <Row>
                                {conversation_documents && <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                >
                                    <thead className="thead-light">
                                        <tr>
                                            {/* <th className="border-0">Project</th> */}
                                            <th className="border-0">Name</th>
                                            <th className="border-0">Date Uploaded</th>
                                            <th className="border-0">Download</th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {conversation_documents.map((document, key) => {

                                            return (
                                                <tr >

                                                    <td>
                                                        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{document.name}</span>
                                                    </td>
                                                    <td>{moment(document.created_at).format('MMM D, YYYY')}</td>

                                                    <td>
                                                        <ButtonGroup>
                                                            <Button variant="outline-primary"
                                                                style={{ fontWeight: "bold", textTransform: "capitalize" }}
                                                                size="sm"><a style={{ textDecoration: 'none' }} target="_blank" href={document.url} >
                                                                    Download </a></Button>

                                                        </ButtonGroup>
                                                    </td>



                                                </tr>
                                            );
                                        })}
                                    </tbody>

                                </Table>}
                            </Row>
                            <Row>
                                <Col md={12} style={{ fontWeight: "bold", paddingTop: 3 }}>
                                    {documents.length > 0 && <Pagination
                                        total={total}
                                        showTotal={total => `Total ${total} Conversation Documents for client`}
                                        onChange={this.onPage}
                                        pageSize={rows}
                                        current={page}
                                    />}
                                </Col>
                            </Row>

                        </Card.Body>
                    </Card>

                </Row>
                </>)}
            </>
        )
    }
}

export default ClientDetail