import React, { Component } from "react";
import { FormGroup, CardHeader, Media, Input, Modal, ButtonGroup } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  InputGroup


} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import Select from 'react-select';
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Button } from 'antd';
import { AsyncPaginate } from "react-select-async-paginate";
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'
import {faPencilAlt } from "@fortawesome/free-solid-svg-icons";

import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { getEmployees } from "../../services/employeeService";
import { getProjects } from "../../services/projectService"
import { getTasks, getMembers} from "../../services/timeLogService";

import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimePicker } from 'antd';
export class AddTimeLog extends Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);

    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      title: '',
      employees: [],
      projects: [],
      start_date: moment().startOf('month'),
      end_date: moment().endOf('month'),
      tasks:[],
      members:[],
      status:false,
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.getProjects();
    

  }


  handleEditorChange(description) {
    this.setState({ description });
  }

  getMembers = () => {

    this.setState({ loading: true });
    const {task_id} = this.state;
    console.log(task_id)
    getMembers({task_id}).then(

      (res) => {
        console.log(res)
        this.setState({
          employees: res.members.map((opt) => ({
            label: opt.name,
            value: opt.id,
          })),
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getTasks = async () => {
   
    this.setState({ loading: true });
    const {project_id}= this.state;
    getTasks({project_id}).then(

      (res) => {
        console.log(res.tasks)
        this.setState({
          tasks: res.tasks.map((opt) => ({
            label: opt.title,
            value: opt.id,
          })),
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getProjects = () => {
    this.setState({
      projects: this.props.projects.filter(p => p.progress < 100).map((opt) => ({
        label: opt.title,
        value: opt.id,
      }))
    });
  
  };


  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });


  loadOptions = (data) => async (search, prevOptions) => {
    await this.sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };





  onChange = (e, state) => {
    this.setState({ [state]: e });
    //this.getMembers();
  };
  onChange2 = async (e, state) => {
    await this.setState({ [state]: e });
    await this.getMembers();
  };


  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };


  onSaveTimeLog = async (e) => {
    e.preventDefault();

    await toast.dismiss();
    const { title, project_id, end_time, start_time, task_id,
       employee_id, start_date, end_date, validation } = this.state;
    
    await this.setState({
      validation: {
        ...validation,
        title: title !== '' && title !== undefined,
        project_id: project_id !== '' && project_id !== undefined,
        task_id: task_id !== '' && task_id !== undefined,
        employee_id: employee_id !== '' && employee_id !== undefined,
        start_date: start_date !== '' && start_date !== undefined,
        start_time: start_time !== '' && start_time !== undefined,
        end_time: end_time !== '' && end_time !== undefined,
        end_date: end_date !== '' && end_date !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveTimeLog();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field == 'employee_id') {
      return "Please Select  Employee"
    } else if (field == 'title') {
      return "Title is required"
    } else if (field == 'project_id') {
      return "Project is required"
    } else if (field == 'task_id') {
      return "Task is required"
    } else if (field == 'start_date') {
      return "Start Date is required";
    } else if (field == 'end_date') {
      return "End Date is required";
    }else if (field == 'start_time') {
      return "start time is required";
    }  else if (field == 'end_time') {
      return "End Time is required";
    } 
  };

  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })

  }



  // handleTaskChange = (task) => {
   
  //   this.setState({ task_id: task.value});
  //   this.getMembers(task.value);

  // }

  handleTaskChange = (e, state) => {
    this.setState({ task_id: e });
   
  };

  


  handleMemberChange = (member) => {
    this.setState({ employee_id: member.value});
   
  }

  handleProjectChange = (project) => {
    console.log(this.state.project_id)
    this.setState({employees:[] });
    

    

  }

  saveTimeLog = () => {
    this.setState({ saving: true });
    const { project_id, title, task_id,
      start_date, end_date, start_time, end_time, employee_id, status } = this.state;
      console.log(status)

    let data = new FormData();
    data.set("start_date", start_date);
    data.set("end_date", end_date);
    data.set("title", title);
    data.set("start_time", start_time);
    data.set("end_time", end_time);
    data.set("project_id", project_id);
    data.set("task_id", task_id);
    data.set("employee_id", employee_id);
    data.set('status', status== false ? 0 : 1 )
    return axios
      .post(
        `${settings.API_URL}addtimelog`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Time Log Created");
        },
        (err) => {

          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            this.showToastError('Timelog could not be created')
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );



  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { toggle } = this.props;

    const { saving, title, status, projects, tasks, employees, validation, start_date, end_date, loading } = this.state;
    return (

      <>

        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5>Create Time Log</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}> Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      placeholder='Enter Title'
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>

              <Row>
                <Col md={4}>

                  <Form.Group>
                    <Form.Label>Projects </Form.Label>


                    <AsyncPaginate
                      maxMenuHeight={120}
                      loadOptions={this.loadOptions(projects)}
                      onInputChange={this.handleProjectChange}


                      onChange={async (property, value) => {
                        console.log(property);
                        await this.setState({
                          project_id: property.value,
                          selectedTitle: property.label,
                        });
                        await this.getTasks();
                      }}
                    />
                  </Form.Group>


                </Col>
                <Col md={4}>

                  <Form.Group>
                    <Form.Label>Task </Form.Label>
                    
                    <Form.Select
                      onChange={async (e) => {
                        await this.onChange2(e.target.value, "task_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Task</option>
                      {tasks.length == 0 && ''}
                      {tasks.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>

                    {/* <AsyncPaginate

                      loadOptions={this.loadOptions(tasks)}
                      onChange={this.handleTaskChange}
                    /> */}
                  </Form.Group>


                </Col>
                
                <Col md={4}>

                  <Form.Group>
                    <Form.Label>Members </Form.Label>


                    <Form.Select
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "employee_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Employee</option>
                      {employees.length == 0 && ''}
                      {employees.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>


                </Col>




              </Row>
             

              <Row>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                      value={start_date}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Due Date
                    </label>
                    <ReactDatetime
                      value={end_date}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'end_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                
                  <Col md={6}>
                  <Form.Group id="Lastname">
                      <Form.Label>Start Time</Form.Label>
                        <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </InputGroup.Text>
                        <TimePicker  use12Hours onChange={(time, start_time) => this.onChange(start_time, "start_time")} 
                        />
                      </InputGroup>

                    </Form.Group>
                    
                  </Col>
                  <Col md={6}>
                    <Form.Group id="Lastname">
                      <Form.Label>End Time</Form.Label>
                        <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </InputGroup.Text>
                        <TimePicker  use12Hours onChange={(time, end_time) => this.onChange(end_time, "end_time")}
                        />
                      </InputGroup>

                    </Form.Group>
                    
                  </Col>
                </Row>
                <Row>
                  <Col md={6} style={{ paddingTop: 15 }}>
          
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Approve Timelog<br/>
                      <small>
                        </small></Form.Label>
                      <Input
                        className="custom-control-input"
                        checked={status}
                        onChange={async (e) => {
                          await this.onChange(e.target.checked, "status");
                        }}
                        size='md'
                        type="checkbox"
                      />
                    </Form.Group>

                  </Col>
                </Row>

                            
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveTimeLog}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddTimeLog;
