import React, { Component } from "react";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,

} from "@themesberg/react-bootstrap";
import SpinDiv from "../components/SpinDiv";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { toast } from "react-toastify";
import { getMarkedAttendances } from "../../services/attendanceService";
import { Tooltip } from 'antd';
import {faCheck,} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export class AttendanceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            validation: {},
            show: false,
            edit: false,
            id: props.match.params.id,
            attendances:[],
            profile:{}
        };
    }

    componentDidMount() {
        toast.configure({ hideProgressBar: true, closeButton: false });
        this.getMarkedAttendance();
        
    }





    getMarkedAttendance = () => {
        const { id } = this.state;

        getMarkedAttendances(id).then(

            (res) => {
                this.setState({
                    loading: false,
                    attendances: res.markedAttendances,
                    profile:res.profile
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    monthName = (mon)=> {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
      }

    render() {

        const { saving, profile,attendances, loading, } = this.state;
        return (
            <>


                {loading && <SpinDiv text={"Loading..."} />}
                <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/attendances">Attendance</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Detail</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>

                                    {/* <Button variant="outline-primary" size="sm">
                                        Export
                                    </Button> */}
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                    {loading && <SpinDiv text={"Saving..."} />}
                    <Col md={8}>
                        <Row>
                            <Col lg="12">
                                
                              <Row style={{fontSize:18, fontWeight:'bold', paddingBottom:15, color:'ash'}}>
                                  <Col md ={6}>
                                      Name: {profile.name}
                                  </Col>
                                 
                                  <Col md ={3}>
                                      Date: {this.monthName(profile.month)+ ', '+ profile.year}
                                  </Col>
                              </Row>
                            </Col>

                        </Row>

                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                            <div>
                                <h6>Marked Attendance</h6>
                                {attendances.map((attendance, key) => {
                                
                                return (
                                <td>{attendance.lateness !== 0  ? <Tooltip title={'Day ' +attendance.day } color='cyan'>
                                <td> <FontAwesomeIcon icon={faCheck} /> </td>
                                </Tooltip> :  <td>X</td>} </td>
                                );
                                })}
                            </div>
                        
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </>
        );
    }
}

export default AttendanceDetail;
