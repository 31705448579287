import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";
import { getTrainingTypes } from "../../services/trainingTypeService";
import { toast } from "react-toastify";
import AddTrainingTypes from "./AddTrainingTypes";

import EditTrainingType from "./EditTrainingType";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
  Form,
} from "@themesberg/react-bootstrap";

import SpinDiv from "../components/SpinDiv";
import DeleteTrainingType from "./DeleteTrainingType";

export class TrainingTypeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      training_types: [],
      value:"",
      total: 0,
    }
  }

  componentDidMount() {
   this.getTrainingTypes();
  }
  

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  };
  getTrainingTypes = () => {

    const { page, rows, search} = this.state;
    this.setState({ loading: true });
    getTrainingTypes({ page, rows, search }).then(
      (res) => {
        this.setState({
          loading: false,
          training_types:res.training_types.data,
          total:res.training_types.total
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  

  toggleAddTrainingType = () => {
    this.setState({ addTrainingTypes: !this.state.addTrainingTypes });
  };
  
  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  toggleEdit = (editTrainingType) => {
    this.setState({ editTrainingType });
  };
  toggleDelete = (deleteTrainingType) => {
    this.setState({ deleteTrainingType });
  };

  
  
  render() {
    const {
      training_types,
      total,
      addTrainingTypes,
      editTrainingType,
      deleteTrainingType,
      rows,
      search,
      loading,
      filtering
    } = this.state;
    return (
      <>
      
        {addTrainingTypes && (
          <AddTrainingTypes
            saved={this.getTrainingTypes}
            addTrainingTypes={addTrainingTypes}
            toggle={() => this.setState({ addTrainingTypes: null })}
          />
        )}

        {editTrainingType && (
          <EditTrainingType
            saved={this.getTrainingTypes}
            editTrainingType={editTrainingType}
            toggle={() => this.setState({ editTrainingType: null })}
          />
        )}

{deleteTrainingType && (
          <DeleteTrainingType
            saved={this.getTrainingTypes}
            trainingtype={deleteTrainingType}
            toggle={() => this.setState({ deleteTrainingType: null })}
          />
        )}

       

        {loading && <SpinDiv text={"Loading..."} />}
        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#products">Training Type</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => this.toggleAddTrainingType()}
                  >
                    Create Training types
                  </Button>
                  <Button variant="outline-primary" size="sm"  
                  onClick={() => {this.props.history.push('/trainings')}}

                  >
                  Training 
                  </Button>
                  
                  <Button variant="outline-primary" size="sm">
                    Export
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="7">
            <h6>Training Types({total})</h6>
           
          </Col>
          
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                id="show"
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                value={search}
                onChange={(e) => this.onChange(e.target.value, "search")}
                autoFocus
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    this.getTrainingTypes();
                    this.setState({
                      search: "",
                    });
                  }
                }}
              />
              <Button
                className="btn-icon btn-2"
                color="secondary"
                style={{ maxHeight: 45 }}
                size="sm"
                onClick={this.getTrainingTypes}
              >
                <i className="fa fa-search" />
              </Button>
            </div>
          </Col>
        </Row>
        
        <Card border="light" className="shadow-sm mb-4">
          
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Training Types</th>
                </tr>
              </thead>
              <tbody>
            
                {training_types.map((training_type, key) => {
                  return (
                    <tr key={key}>
                      <td>{training_type.name}</td>
                      <td>{training_type.employees_count}</td>
                      <td>
                           <ButtonGroup>
                           <Button
                              variant="outline-primary"
                              onClick={() => this.toggleEdit(training_type)}
                              size="sm"
                            >
                              Edit
                            </Button>
                            <Button
                              variant="outline-danger"
                              onClick={() => this.toggleDelete(training_type)}
                              size="sm"
                            >
                              Delete
                            </Button>
                           </ButtonGroup>
                      </td>

                      <td>
                           
                      </td>
                      
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default TrainingTypeIndex;
