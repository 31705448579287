import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from "reactstrap";
import { Col, Row, Form, ButtonGroup, Card, Image, Spinner, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Profile1 from "../../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../../assets/img/profile-cover.jpg";
import { toast } from "react-toastify";
import moment from "moment";
import { addEmailSetting, getEmailSetting } from "../../services/companyService"
import ReactDatetime from "react-datetime";
import SpinDiv from "../components/SpinDiv";
import Resizer from "react-image-file-resizer";
export class EmailSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      company: {},
      loading: false,
      saving: false,
      email_setting:''
    };
  }

  componentDidMount() {
    this.getEmail()
  }

  getEmail = () => {

    this.setState({ loading: true });
    getEmailSetting().then(

      (res) => {
        console.log(res)
        this.setState({
          loading: false,
          email_setting: res.email_setting,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };




  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { validation, email_setting } = this.state;
    const { name, email } = email_setting;
    await this.setState({
      validation: {
        ...validation,
        name: name !== '' && name !== undefined,
        email: email !== ''  && email !== undefined,
        

      },
     
    });
    
    if (Object.values(this.state.validation).every(Boolean)) {
      this.save();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      );
    }
  };

  validationRules = (field) => {
    if (field === "name") {
      return "Name is required";
    }else if (field === "email") {
      return "Email is required";
    }
  
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
  };

  toggleEditEmail = () => {
    this.setState({ editEmail: !this.state.editEmail });
  };

  onChange = (e, state) => {
    const { email_setting } = this.state;
    this.setState({ email_setting: { ...email_setting, [state]: e } });
  };

  

  save = () => {
    this.setState({ saving: true });

    const { email_setting, validation } = this.state;
    const { name, email } = email_setting;
    this.setState({ saving: true })
  
    addEmailSetting({
      name: name,
      email: email,
    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false, saving: false });
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "green" }}>Email Settings Updated</p>

          </div>
        );

        this.getEmail();
      },
      (error) => {
        console.log(error);
        this.setState({ saving: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>An error occurred</p>

          </div>
        );
      }
    );
  };




  render() {
    const {saving, loading, email_setting, editEmail } = this.state;

    return (
      <>

        {loading && <SpinDiv text={"Loading..."} />}
        {saving && <SpinDiv text={"saving..."} />}
        <Row>
          <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Row>
                <Col className="text-right" md={12}>
                  {email_setting && (
                    <ButtonGroup>

                      <Button
                        variant={editEmail ? "outline-secondary" : "outline-primary"}
                        onClick={this.toggleEditEmail}
                        size="sm"
                      >
                        {editEmail ? "Discard Changes" : "Edit Company Profile"}
                      </Button>
                    </ButtonGroup>


                  )}
                </Col>
              </Row>
              <Card.Body>
                <h5 className="mb-4">Mail Information</h5>
                <Form>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={email_setting.name || ''}
                          disabled={!editEmail}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "name");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="emal">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={email_setting.email}
                          disabled={!editEmail}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "email");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    

                  </Row>
                  
                  
                  
                
                  {editEmail && (
                    <div className="mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={saving}
                        onClick={this.onSave}>
                        Save
                      </Button>
                    </div>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Col>

          
        </Row>
      </>
    );
  }
}

export default EmailSetting