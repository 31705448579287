import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

const authuser=JSON.parse(localStorage.getItem('user'));

export function getNotification() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${settings.API_URL}notification`, requestOptions).then(authService.handleResponse);
}

export function getLeaveSetting() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}leavesetting`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/leavesetting`, requestOptions).then(authService.handleResponse);
    }
  
}

export function addNotification(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}add_notification`, requestOptions).then(authService.handleResponse);
}