import React, { Component } from "react";
import { CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { addSalary } from "../../services/salaryService";
import { InputNumber } from "antd";

export class AddSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      basic_salary:''
    };
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onSaveSalary = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { basic_salary, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        basic_salary: basic_salary !== '',
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveSalary();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };

  validationRules = (field) => {
    if (field === "basic_salary") {
      return "Basic salary is required";
    }
  };

  saveSalary = () => {
    this.setState({ loading: true });
    const {id,user_id} = this.props.addSalary
    const { basic_salary } = this.state;
    console.log();
    addSalary({
      basic_salary: basic_salary,
      employee_id:id,
      user_id:user_id

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Salary has been created");
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { addSalary, toggle } = this.props;

    const { saving, loading, basic_salary } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={addSalary != null}
          toggle={() => !loading && !saving && toggle}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                <Button variant="outline-primary" size="sm">
                  create Salary
                </Button>
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            <Row style={{ border: '1px #eee solid', padding: '5px 5px 5px', margin: '10px 2px 7px', borderRadius: 7 }}>
                    <Col md={12} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10,  display:'block'}}>Basic Salary</Form.Label>
                        <InputNumber

                          style={{ width: '100%', height: 40, paddingTop: 5, borderRadius: 5, fontSize: 18 }}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          value={basic_salary}
                          onChange={e => this.onChange(e, 'basic_salary')}
                          />
                       
                      </Form.Group>

                    </Col>
                    
                  </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveSalary}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddSalary;
