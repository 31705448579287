import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import {  getTrainers } from "../../services/trainerService";
import SpinDiv from "../components/SpinDiv";
import { AddTrainer } from "./AddTrainer";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditTrainer from "./EditTrainer";
import DeleteTrainer from "./DeleteTrainer";

export class TrainerIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      employees: [],
      trainers:[],
      total: 0,

    

    };
    this.searchDebounced = debounce(this.searchtrainers, 500);
    this.searchThrottled = throttle(this.searchtrainers, 500);
  }

  componentDidMount() {
    this.searchTrainers();
  }

  
  searchTrainers = () => {
    const { page, rows, search, trainers } = this.state;
    this.setState({ loading: false });
    getTrainers({ page, rows, search, trainers }).then(

      (res) => {
        this.setState({
          trainers: res.trainers.data,
          page: res.trainers.current_page,
          total: res.trainers.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.getTrainers();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
 

  toggleEditTrainer = (trainer) => {
    this.setState({ trainer});
    this.searchTrainers();
   
  };
  toggleCloseTrainer=(trainer)=>{
    this.setState({ trainer: !this.state.trainer });
  }

  toggleCloseDelete=(deleteTrainer)=>{
    this.setState({ deleteTrainer: !this.state.deleteTrainer });
  }

  toggleAddTrainer = () => {
    this.setState({ addTrainer: !this.state.addTrainer });
    this.searchTrainers();
  }

  toggleDeleteTrainer=(deleteTrainer)=>{
    this.setState({ deleteTrainer });
    this.searchTrainers();
  }

  render() {
    const {trainers, trainer, deleteTrainer, total, page, rows, search, loading,addTrainer, editTrainer } = this.state;
    
    return (
      <>
       
        
        {addTrainer && (
          <AddTrainer
            saved={this.searchTrainers}
            addTrainer={addTrainer}
            toggle={this.toggleAddTrainer}
            
          />
        )}

        {trainer && (
          <EditTrainer
            saved={this.searchTrainers}
            trainer={trainer}
            toggle={this.toggleCloseTrainer}
          />
        )}

        
        {deleteTrainer && (
          <DeleteTrainer
            saved={this.searchTrainers}
            deleteTrainer={deleteTrainer}
            toggle={this.toggleCloseDelete}
          />
        )}

        

       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#trainers">Trainers</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddTrainer()}>
                    Create Trainer
                  </Button>
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Trainers 
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Branch</th>
                  <th className="border-0">Email</th>
                  <th className="border-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                
                {trainers.map((trainer, key) => {
               
                  return (
                    <tr >
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{trainer.name}</span>
                    
                      </td>
                        
                    
                      <td>{trainer.branch}</td>
                      <td>{trainer.email}</td>
                     
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" style={{fontWeight:"bold",textTransform:"capitalize"}} onClick={() => this.toggleEditTrainer(trainer)} size="sm">view</Button>
                          <Button variant="outline-danger" size="sm" onClick={() => this.toggleDeleteTrainer(trainer)}>Delete</Button>
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {trainers.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Trainers created yet
                </div>}
              {trainers.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} Trainers for all Employees`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default TrainerIndex;
