import React, { Component } from "react";

import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faSleigh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SpinDiv from "../components/SpinDiv";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { toast } from "react-toastify"
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import ReactFormValidation from "react-form-input-validation";
import ReactFormInputValidation from "react-form-input-validation";
import AsyncSelect from "react-select/async";
import {module} from './data'
export class AddRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: '',
      },
      search: "",
      modules:[
        { label: "User", value: "1" },
        { label: "Employee", value: "2" },
        { label: "Attendance", value: "3" },
        { label: "Role", value: "4" },
        { label: "Payslip", value: "5" },
        { label: "Salary", value: "6" },
        { label: "Training", value: "7" },
        { label: "Award", value: "8" },
        { label: "Performance", value: "9" },
        { label: "Leave", value: "10" },
        { label: "Resignation", value: "11" },
        { label: "Termination", value: "12" },
        { label: "Promotion", value: "13" },
        { label: "Work", value: "14" },
        { label: "Project", value: "15" },
        { label: "Client", value: "16" },
        { label: "Invoice", value: "17" },
        { label: "Message", value: "18" },
        { label: "Timelog", value: "19" },
        { label: "Task", value: "20" },
        { label: "Emptools", value: "21" },
        { label: "Payroll", value: "22" },
        { label: "Setting", value: "23" },
        { label: "LeaveType", value: "24" },
        { label: "TrainingType", value: "25" },
  
       
      ],
      page: 1,
      rows: 10,
      loading: false,
      validation: {},
      errors: {},
      edit:false,
      checkEdit:true,
      permissions:[],

    };
    this.handleChange = this.handleChange.bind(this);
    this.form = new ReactFormValidation(this, { locale: "en" });
    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
      name: "required|max:10",
    });
  }

  handleChange(event) {  
    let newArray = [...this.state.permissions, event.target.value];
    if (this.state.permissions.includes(event.target.value)) {
      newArray = newArray.filter(day => day !== event.target.value);
    }
    this.setState({
      permissions: newArray
    });  
        
  }  

  validationRules = (field) => {
    if (field === "name") {
      return "name is required";
    }
  };


  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
  };
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  componentDidMount = () => {
    toast.dismiss();
    toast.configure({ hideProgressBar: true, closeButton: false });
  }


  onSaveRole = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { name, fields, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        name: fields.name !== '' && fields.name !== undefined,

      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveRole();
    } else {
      const errorss = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errorss.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      );
    }
  };

  saveRole = () => {
    this.setState({ saving: true });

    const { fields, permissions } = this.state;

    let data = new FormData();
    data.set("name", fields.name);
    let op = permissions;

      for (var i in op) {

        data.set(`permissions[${i}]`, op[i]);
      }

    return axios
      .post(
        `${settings.API_URL}addrole`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        this.setState({ saving: false, edit: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Role created");
      })
      .catch((err) => {
        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err.response.data.name) {
            this.showToastError('A role with this Name already exist')
          }

          this.setState({ saving: false });
        }
      });
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
    console.log(e)
  };

  filterModule = (inputValue) => {
    const options =
    
    
    [
      { label: "User", value: "1" },
      { label: "Employee", value: "2" },
      { label: "Attendance", value: "3" },
      { label: "Role", value: "4" },
      { label: "Payslip", value: "5" },
      { label: "Salary", value: "6" },
      { label: "Training", value: "7" },
      { label: "Award", value: "8" },
      { label: "Performance", value: "9" },
      { label: "Leave", value: "10" },
      { label: "Resignation", value: "11" },
      { label: "Termination", value: "12" },

     
    ];
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  loadModules = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.filterModule(inputValue));
    }, 1000);
  };



  render() {
    const Required = () => (<span style={{ color: 'red' }}>*</span>)
    const { addRole, toggle } = this.props;
   console.log(this.state.permissions)
    const { loading, saving, name, permissions, modules,
      validation, fields, edit,checkEdit } = this.state;
    return (
      <>

        <Modal
          className="modal-dialog "
          isOpen={addRole != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 850 }}

        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Role with Permissions
              </h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                {saving && <SpinDiv text={"Saving..."} />}
                <Col md={12} className="mb-3">

                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12}>

                      <Form.Group className="mb-2">
                        <Form.Label >  <Required /> Role Title</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Role Title"
                            name='name'
                            onBlur={this.form.handleBlurEvent}
                            value={name}
                            onChange={this.form.handleChangeEvent}
                            required
                            data-attribute-name="Role Title"
                            data-async

                          />
                        </InputGroup>
                        

                      </Form.Group>

                    </Col>





                  </Row>
                  
                  <Row>
                    <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th style={{ paddingLeft: 50 }} className="border-0">Modules</th>
                          <th className="border-0">Permissions</th>
                        </tr>
                      </thead>
                      <tbody>

                        {modules.map((module, key) => {

                          return (
                            <tr style={{ fontWeight: "bold" }}>
                              <th scope="row">
                                <td><span className="mb-0 text-sm" style={{ fontWeight: "bold", textTransform: "capitalize" }}>{module.label}</span></td>


                              </th>
                              <td>
                                
                                {}
                        
                                <Form.Group className="mb-2">
                                <Form.Label style={{ paddingRight: 10 }}>Edit </Form.Label>
                                <Input
                                   className="custom-control-input"
                                   id={`edit${module.label}`}
                                   value={`edit `+module.label}
                                  onChange={this.handleChange}  
                                   type="checkbox"
                              
                                />
                              
                                </Form.Group>  
                              
                                

                              </td>
                              <td>
                                <Form.Group className="mb-2">
                                  <Form.Label style={{ paddingRight: 10 }}>Create </Form.Label>
                                  <Input
                                    className="custom-control-input"
                                    id={`create${module.label}`}
                                    value={`create `+module.label}
                                    onChange={this.handleChange}  
                                    type="checkbox"
                                  />
                                </Form.Group>

                              </td>
                              <td>
                                <Form.Group className="mb-2">
                                  <Form.Label style={{ paddingRight: 10 }}>Delete </Form.Label>
                                  <Input
                                     className="custom-control-input"
                                     id={`delete${module.label}`}
                                     value={`delete `+module.label}
                                    onChange={this.handleChange}  
                                     type="checkbox"
                                  />
                                </Form.Group>

                              </td>
                              <td>
                                <Form.Group className="mb-2">
                                  <Form.Label style={{ paddingRight: 10 }}>Manage </Form.Label>
                                  <Input
                                    className="custom-control-input"

                                    id={`manage${module.label}`}
                                    value={`manage `+module.label}
                                    onChange={this.handleChange}  
                                    type="checkbox"
                                  />
                                </Form.Group>

                              </td>
                              <td>
                                <Form.Group className="mb-2">
                                  <Form.Label style={{ paddingRight: 10 }}>Show </Form.Label>
                                  <Input
                                     className="custom-control-input"
                                     id={`show${module.label}`}
                                     value={`show `+module.label}
                                    onChange={this.handleChange}  
                                     type="checkbox"
                                  />
                                </Form.Group>

                              </td>

                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>

                  </Row>



                  <Row style={{ float: 'right' }}>
                    <div className="modal-footer" style={{ padding: '1rem' }}>
                      <Button
                        size="sm"
                        variant='transparent'
                        data-dismiss="modal"
                        type="button"
                        onClick={toggle}
                      >
                        Close
                      </Button>
                      <Button variant="primary" type="button" disabled={saving} size="sm"
                        onClick={this.onSaveRole}
                      >
                        Save
                      </Button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddRole;
