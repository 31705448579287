import React, { Component } from "react";

import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SpinDiv from "../components/SpinDiv";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { toast } from "react-toastify"
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import AsyncSelect from "react-select/async";
import Select from 'react-select';
import { getSalary, getCommission, getLoan2,getDeduction2, getAllowance } from "../../services/salaryService";
import ReactDatetime from "react-datetime";
import moment from "moment";
 
export class EditPaySlip extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      search: '',
      validation: {},
      allowances:[],
      salary:'',
      show: false,
      edit: false,
      loans:[{}],
      deductions:[{}],
      selectedCommissions:[],
      selectedAllowances:[],
      basic_salary:'',
      salary_month:'',
      employee_id:props.editPaySlip.employee_id,
      employee:props.editPaySlip,
      year:props.editPaySlip.year,
      month:props.editPaySlip.month

    };
  }

  componentDidMount = () => {
    toast.dismiss();
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.filterCommission();
    this.filterLoan();
    this.filterAllowance();
    this.filterDeduction();
    
  }
  
  validationRules = (field) => {
    if(field == 'description'){
      return "Description is required"
    }
  };

  

  onChangeLoan = (index, field, event) => {
    const newLoan = event.target.value;
    
    this.setState(state => {
      const loans = [
        ...state.loans.slice(0, index),
        {
          ...state.loans[index],
          [field]: newLoan,
        },
        ...state.loans.slice(index + 1),
      ];
      return {
        loans,
      };
    });
  };


  onChangeDeduction = (index, field, event) => {
    const newDeduction = event.target.value;
    
    this.setState(state => {
      const deductions = [
        ...state.deductions.slice(0, index),
        {
          ...state.deductions[index],
          [field]: newDeduction,
        },
        ...state.deductions.slice(index + 1),
      ];
      return {
        deductions,
      };
    });
  };

  

  handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    this.setState({ inputValue });
    return inputValue;
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
  };
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };
  

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  filterLoan = async () => {
    this.setState({ filteringLoan: true });
    const {employee_id } = this.state;
    getLoan2(employee_id).then(

      (res) => {
          this.setState({
              filteringLoan: false,
              loans: res.loans,
          });
          console.log(res)
      },
      (error) => {
          this.setState({ filteringLoan: false });
      }
  );
  };

  filterDeduction = async () => {
    this.setState({ filteringDeduction: true });
    const {employee_id, employee,year, month} = this.state;
   
    console.log(employee_id)
    getDeduction2({employee_id,month,year}).then(

      (res) => {
          this.setState({
              filteringDeduction: false,
              deductions: res.deductions,
          });
          console.log(res)
      },
      (error) => {
          this.setState({ filteringDeduction: false });
      }
  );
  };

  filterCommission = async () => {
    this.setState({ filteringCommission: true });
    const {employee_id } = this.state;
    getCommission(employee_id).then(

      (res) => {
          this.setState({
            filteringCommission: false,
              commissions: res.commissions.map(function (obj) {
                obj['value'] = obj['id']; // Assign new key
                obj['label'] = obj['title'];
                delete obj['title']; // Delete old key 
                delete obj['id'];
                return obj;
              }),
          });
      },
      (error) => {
          this.setState({ filteringCommission: false });
      }
  );
    
  };


  filterAllowance = async () => {
    this.setState({ filteringAllowance: true });
    const {employee_id } = this.state;
    getAllowance(employee_id).then(

      (res) => {
          this.setState({
            filteringAllowance: false,
              allowances: res.allowances.map(function (obj) {
                obj['value'] = obj['id']; // Assign new key
                obj['label'] = obj['title'];
                delete obj['title']; // Delete old key 
                delete obj['id'];
                return obj;
              }),
          });
          console.log(res.allowances);
      },
      (error) => {
          this.setState({ filteringAllowance: false });
      }
  );
    
  };

  filterSalary = async () => {
    this.setState({ filteringSalary: true });
    const {employee_id } = this.state;
    getSalary(employee_id).then(

      (res) => {
          this.setState({
            filteringSalary: false,
              salary: res.salary,
          });
      },
      (error) => {
          this.setState({ filteringSalary: false });
      }
  );
    
  };

  commissionHandleChange = (selectedCommissions) => {
    this.setState({ selectedCommissions });
  }

  allowanceHandleChange = (selectedAllowances) => {
    this.setState({ selectedAllowances });
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };



  onSavePaySlip = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {description, validation } = this.state;
    //const {loan_repayment } = newLoan;
    await this.setState({
      validation: {
        ...validation,
        description: description !== '' && description !== undefined,
      }
    })
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveSlip();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      await setTimeout(() => toast.error(
        <div style={{ padding: '10px 20px' }}>
          <p style={{ margin: 0, fontWeight: 'bold', color: 'red' }}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: 'red' }}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }
  saveSlip = () => {
    this.setState({ saving: true });

    const { selectedCommissions, selectedAllowances,employee_id, salary,deductions, loans,description,salary_month,basic_salary, } = this.state;
   
   const {editPaySlip} = this.props;
    let data = new FormData();
   
    for (var i in loans) {
      data.append(`loans[${i}]`, JSON.stringify(loans[i]));
    }
    for (var i in deductions) {
      data.append(`deductions[${i}]`, JSON.stringify(deductions[i]));
    }
    data.set("description", description);
   
    for (var v in selectedCommissions) {
     
      data.append(`commissions[${v}]`, selectedCommissions[v].value);
    }

    for (var u in selectedAllowances) {
      data.set(`allowances[${u}]`, selectedAllowances[u].value);
    }


    return axios
      .post(
        `${settings.API_URL}updatepaysalary/${editPaySlip.id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        this.setState({ saving: false, edit: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Payslip has been updated");
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          this.showToastError('An Employee with the same salary month already exist');
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          this.setState({ saving: false });
        }
      });
  };

  render() {
    const Required = () => (<span style={{ color: 'red' }}>*</span>)
    const { addPaySlip, editPaySlip, toggle } = this.props;
    const { loading, commissions, filteringDeduction, deductions, employee, selectedCommission,selectedAllowance, allowances, loans, filteringSalary, filteringCommission, filteringAllowance, filteringLoan, saving} = this.state;
    return (
      <>
        {console.log(editPaySlip)}
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen={editPaySlip != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}

        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit PaySlip
              </h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                {saving && <SpinDiv text={"Saving..."} />}
                <Col md={12} className="mb-3">

                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                
                      <Form.Group className="mb-2">
                        <Form.Label>Employee</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="basic_salary"
                          disabled
                          value={employee.employee.name}
                          type="text"
                        />
                        
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '5px 5px 5px', margin: '10px 2px 7px', borderRadius: 7 }}>
                    <Col md={12} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Salary</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="basic_salary"
                          disabled
                          value={employee.basic_salary}
                          type="number"
                        />
                      </Form.Group>

                    </Col>
                  </Row>
                  
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '10px 2px', borderRadius: 7 }}>
                    <Form.Label>Loan</Form.Label>
                        {filteringLoan
                          ? "loading..."
                          : loans.map((loan, key)=>{
                            return (
                              <Row>
                                <Col md={4}>
                                  <Form.Label>Title</Form.Label><h5></h5>
                                  <span style={{fontWeight:'bold', display:'inline-table'}}>{loan.title}</span>
                                 
                                </Col>
                                <Col md={4}>
                                < Form.Label>Balance</Form.Label>
                                <h6>{loan.loan_balance}</h6>
                                 
                                </Col>
                                <Col md={4}>
                                
                                  <Form.Group className="mb-2" key={key}>
                                    <Form.Label style={{ paddingRight: 10 }}>Enter Amount to be deducetd</Form.Label>
                                    <Input
                                      className="custom-control-input"
                                      value={loan.amount_to_pay}
                                      onChange={this.onChangeLoan.bind(this, key, 'amount_to_pay')}
                                  
                                      type="number"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            )
                          })}

                          
                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '10px 2px', borderRadius: 7 }}>
                    <Form.Label>Other Deductions</Form.Label>
                        {filteringDeduction
                          ? "loading..."
                          : deductions.map((deduction, key)=>{
                            return (
                              <Row>
                                <Col md={4}>
                                  <Form.Label>Title</Form.Label><h5></h5>
                                  <span style={{fontWeight:'bold', display:'inline-table'}}>{deduction.title}</span>
                                 
                                </Col>
                                <Col md={4}>
                                < Form.Label>Balance</Form.Label>
                                <h6>{deduction.deduction_balance}</h6>
                                 
                                </Col>
                                <Col md={4}>
                                
                                  <Form.Group className="mb-2" key={key}>
                                    <Form.Label style={{ paddingRight: 10 }}>Enter Amount to be deducetd</Form.Label>
                                    <Input
                                      className="custom-control-input"
                                      value={deduction.amount_to_pay}
                                      onChange={this.onChangeDeduction.bind(this, key, 'amount_to_pay')}
                                  
                                      type="number"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            )
                          })}

                          
                  </Row>


                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '10px 2px', borderRadius: 7 }}>
                      <Col md={6}>
                      <Form.Label>Commissions</Form.Label>
                        {filteringCommission
                          ? "loading..."
                          : 
                              <Row>
                              
                                <Col md={12}>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-product_id"
                                      style={{marginBottom:10}}
                                    >

                                      Choose Commission for Payslip
                                    </label>

                                    <Select
                                      isMulti
                                      value={selectedCommission}
                                      onChange={this.commissionHandleChange}
                                      options={commissions}

                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                           }
                      </Col>
                      <Col md={6}>
                      <Form.Label>Allowances</Form.Label>
                        {filteringAllowance
                          ? "loading..."
                          : 
                              <Row>
                              
                                <Col md={12}>
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-product_id"
                                      style={{marginBottom:10}}
                                    >

                                      Choose Allowances for Payslip
                                    </label>

                                    <Select
                                      isMulti
                                      value={selectedAllowance}
                                      onChange={this.allowanceHandleChange}
                                      options={allowances}

                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                           }
                      </Col>
                  </Row>
                  
                  <Row style={{ border: '1px #eee solid', padding: '5px 5px 5px', margin: '10px 2px 7px', borderRadius: 7 }}>
                    <Col md={12} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Narration</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="description"
                          
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "description");
                          }}
                          type="textarea"
                        />
                      </Form.Group>

                    </Col>
                  </Row>
                  
                  
                  <Row style={{ float: 'right' }}>
                    
                    {(!filteringLoan && !filteringCommission && !filteringCommission )&& <div className="modal-footer" style={{ padding: '1rem' }}>
                      <Button
                        size="sm"
                        variant='transparent'
                        data-dismiss="modal"
                        type="button"
                        onClick={toggle}
                      >
                        Close
                      </Button>
                      <Button variant="primary" type="button" disabled={saving} size="sm"
                        onClick={this.onSavePaySlip}
                       >
                        Save
                      </Button>
                    </div> }
                    
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditPaySlip;
