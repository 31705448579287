import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import SpinDiv from "../components/SpinDiv";
import { AddPerformance } from "./AddPerformance";
import { throttle,debounce } from "../users/debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import {getPerformancesDepartments} from "../../services/departmentPerformanceService"
import { faDashcube } from "@fortawesome/free-brands-svg-icons";
export class DepartmentPerformanceIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      departments: [],
     
    };
  }

  componentDidMount() {
    this.searchDepartments();
  }

  searchDepartments = () => {
    this.setState({ loading: true });
    getPerformancesDepartments().then(
      
      (res) => {
        this.setState({
          departments: res.departments,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  
  

 
  

  render() {
    const { departments, loading } = this.state;
   
    return (
      <>
        {loading && <SpinDiv text={"Loading..."} />}
        
        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Performance Departments</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              
            </div>
          </Col>
        </Row>
        
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                   <th className="border-0">Action</th>
                  
                 
              
                </tr>
              </thead>
              <tbody>
                
                {departments.map((department, key) => {
               
                  return (
                    <tr style={{fontWeight:"bold"}}>
                      <th scope="row">
                        <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{department.name}</span></td>
                        
                      </th>
                
                      <td>
                        <ButtonGroup>
                        <Button
                          variant="outline-primary"
                          onClick={() => {
                            this.props.history.push(`/departments/performance/${department.id}`)
                          }}
                          size="sm"
                        >
                          View Performance
                        </Button>
                       
                      
                        </ButtonGroup>
                      </td>
                      
                      
                    </tr>
                  );
                })}
              </tbody>
              
            </Table>
           
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default DepartmentPerformanceIndex;
