import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { getEmployee } from "../../services/employeeService";
import { editLeaveType } from "../../services/leaveService";

export class EditLeaveType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      edit: false,
      leavetype: props.leavetype,
      payment_status: props.leavetype.payment_status,

    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }
  validationRules = (field) => {
    if (field === "title") {
      return "Title is required";
    } else if (field == 'days') {
      return "Days is required"
    }
  };

  
  onChange2 = (e, state) => {
    this.setState({ [state]: e });
  };

  saveLeaveType = () => {
    this.setState({ saving: true });
    const { leavetype, payment_status } = this.state;
    console.log();
    editLeaveType({
      title: leavetype.title,
      days: leavetype.days,
      id: leavetype.id,
      payment_status:payment_status

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Leave Type has been updated");
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('A Leave type with this title address already exist')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };




  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { leavetype } = this.state

    this.setState({ leavetype: { ...leavetype, [state]: e } })
  }

  onSaveLeaveType = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { leavetype, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: leavetype.title !== '' && leavetype.title !== undefined,
        days: leavetype.days !== '' && leavetype.days !== undefined
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveLeaveType();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };






  render() {

    const { toggle } = this.props;

    const { saving, edit, leavetype, loading, payment_status } = this.state;
    {console.log(leavetype)}
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={leavetype != null}
          toggle={() => !loading && !saving && toggle}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {leavetype && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Leave Type"}



                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={6} style={{ paddingTop: 15 }}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Title</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="title"
                        placeholder='Enter Leave Type title'
                        value={leavetype.title}
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "title");
                        }}
                        type="text"
                      />
                    </Form.Group>

                  </Col>
                  <Col md={6} style={{ paddingTop: 15 }}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>No of Days</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="reason"
                        placeholder='Enter Leave Type Days'
                        value={leavetype.days}
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "days");
                        }}
                        type="text"
                      />
                    </Form.Group>

                  </Col>
              </Row>
              <Row>
                  <Col md={3} style={{ paddingTop: 30 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Payment Status</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="admin"
                          disabled={!edit}
                          checked={payment_status == 1 ? true : false}
                          onChange={async (e) => {
                            await this.onChange2(e.target.checked, "payment_status");
                          }}
                          

                          type="checkbox"
                        />
                      </Form.Group>

                  </Col>
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveLeaveType}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditLeaveType;
