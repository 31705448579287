import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import {  FormGroup, Input, Modal } from "reactstrap";

import {  getEmployees } from "../../services/employeeService";
import {  getLeaveTypes } from "../../services/leaveService";
import SpinDiv from "../components/SpinDiv";
import { AddLeave } from "./AddLeave";
import moment from "moment";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditLeaveType from "./EditLeaveType";

export class LeaveTypeIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      saving:false,
      leavetypes:[],
      total: 0,

    

    };
    this.searchDebounced = debounce(this.searchLeaveTypes, 500);
    this.searchThrottled = throttle(this.searchLeaveTypes, 500);
  }

  componentDidMount() {
  
    this.searchLeaveTypes();
  }

  toggleCloseEditLeaveType = (leavetype) => {
    this.setState({ leavetype: !this.state.leavetype });
    
    this.searchLeaveTypes();
   
  };

  toggleEditLeaveType = (leavetype) => {
    this.setState({ leavetype});
    this.searchLeaveTypes();
   
  };

  

  searchLeaveTypes = () => {
    const { page, rows, search, leaves } = this.state;
    this.setState({ loading: false });
    getLeaveTypes({ page, rows, search, leaves }).then(

      (res) => {
        this.setState({
          leavetypes: res.leave_types.data,
          page: res.leave_types.current_page,
          total: res.leave_types.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchLeaveTypes();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
 

  



  render() {
    const {toggle, leaveType} = this.props;
    const {  total, page, rows,leavetype, search, saving, loading, leavetypes} = this.state;
    
    return (
      <>
      {leavetype && (
          <EditLeaveType
            saved={this.searchLeaveTypes}
            leavetype={leavetype}
            toggle={this.toggleCloseEditLeaveType}
          />
        )}
       
        {loading && <SpinDiv text={"Loading..."} />}
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={leaveType != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5> Leave Types  <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
            </div>
            
            <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45,width:400, marginRight:10 }}
                onChange={this.handleSearch}
                
              />

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Title</th>
                  <th className="border-0">Days</th>
                  <th className="border-0">Status</th>
                 
                </tr>
              </thead>
              <tbody>
                
                {leavetypes.map((type, key) => {
               
                  return (
                    <tr >
                      
                      <td >{type.title}</td>
                      <td >{type.days}</td>
                      <td >{type.payment_status == 1 ? 'Paid' : 'Unpaid'}</td>
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" style={{fontWeight:"bold",textTransform:"capitalize"}} onClick={() => this.toggleEditLeaveType(type)} size="sm">edit</Button>
                          
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {leavetypes.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} Leaves for all Employees`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Modal>  
      </>
    );
  }
}

export default LeaveTypeIndex;
