import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  Form,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";

import {  addProject, getProjects } from "../../services/projectService";
import SpinDiv from "../components/SpinDiv";
import { AddProject } from "./AddProject";
import moment from "moment";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditProject from "./EditProject";
import DeleteProject from "./DeleteProject";
import { Progress } from 'antd';

export class ProjectIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      projects:[],
      order:'All',
      departments:[],
      categories:[],
      clients:[],
      members:[],
      client:'',
      member:'',
      category:'',
      showFilter:false,
      total: 0,

    };
    this.searchDebounced = debounce(this.searchProjects, 500);
    this.searchThrottled = throttle(this.searchProjects, 500);
  }

  componentDidMount() {
    this.searchProjects();
  }

  

  searchProjects = () => {
    const { page,order, category, member, client, department, rows, search, projects } = this.state;
    this.setState({ loading: true });
    getProjects({ page,category,member,order, department,client, rows, search, projects }).then(

      (res) => {
        this.setState({
          projects: res.projects.data,
          categories:res.categories,
          members: res.members,
          departments:res.departments,
          clients:res.clients,
          loading: false,
          total:res.projects.total
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchProjects();
  }

  toggleFilter = () => {
    this.setState({showFilter: !this.state.showFilter});
  }

  onFilter = async (e, filter) => {
    console.log(filter);
    await this.setState({[filter]: e});
    await this.searchProjects();
  }

  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
  toggleDeleteProject=(deleteProject)=>{
    this.setState({deleteProject});
  }

  toggleEditProject = (project) => {
    this.setState({ project});
    this.searchProjects();
   
  };
  toggleCloseProject=(project)=>{
    this.setState({ project: !this.state.project });
    this.searchProjects();
  }

 

  toggleAddProject = () => {
    this.setState({ addProject: !this.state.addProject });
    this.searchProjects();
  }

  
  



  formatCurrency(x) {
    if (x !== null && x !== '0' && x !== undefined) {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${parts.join(".")}`;
    }
    return '0';
}




  

  render() {
    const { projects,order,clients, categories, category, members, departments, client, department, member, showFilter, project, deleteProject, total, title, progress, page, rows, search, loading,addProject, editProject } = this.state;
    
    return (
      <>
       
        
        {addProject && (
          <AddProject
            saved={this.searchProjects}
        
            toggle={this.toggleAddProject}
            clients={clients}
            departments={departments}
            categories={categories}
            employees={members}
            
          />
        )}

        {project && (
          <EditProject
            saved={this.searchProjects}
            project={project}
            clients={clients}
            departments={departments}
            categories={categories}
            employees={members}
            toggle={this.toggleCloseProject}
          />
        )}

        
        {deleteProject && (
          <DeleteProject
            saved={this.searchProjects}
            deleteProject={deleteProject}
            toggle={() => this.setState({ deleteProject: null })}
          />
        )}


      
       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Projects</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                     {!showFilter&&<Button
                       variant="outline-primary"
                        onClick={this.toggleFilter}
                        size="mdm"
                      
                      >
                        Filter
                      </Button>}
                  <Button variant="outline-primary" size="mdm" onClick={() => this.toggleAddProject()}>
                    Create Project
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Projects
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          {showFilter&&<div style={{height: 100, borderTop: '0.5px solid #e9ecef', padding: '0 0 0 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflowX: 'auto'}}>
                  <div style={{display: 'flex'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Status </span>
                    <Form.Group className="mb-2">
                   
                    <Form.Select
                      id="state"
                      required
                      value={category}
                      onChange={e=>this.onFilter(e.target.value, 'order')}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >
                     
                     <option value="">Choose Status</option>
                       <option value="All">All</option>
                       <option value="completed">Completed</option>
                       <option value="in_progress">In Progress</option>
                     
                    </Form.Select>
                  </Form.Group>

                 
                    
                  </div>
                  <div style={{display: 'flex'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Category </span>
                    <Form.Group className="mb-2">
                   
                    <Form.Select
                      id="state"
                      required
                      value={category}
                      onChange={e=>this.onFilter(e.target.value, 'category')}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >
                     
                      <option value="">Choose Category</option>
                      {categories.length==0 &&  <option value="#">loading</option>}
                      {categories.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                    
                  </div>
                  {/* <div style={{display: 'flex'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Department: </span>
                    <Form.Group className="mb-2">
                   
                    <Form.Select
                      id="state"
                      required
                      value={department}
                      onChange={e=>this.onFilter(e.target.value, 'department')}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >
                     
                      <option value="">choose Department</option>
                      {departments.length==0 &&  <option value="">loading</option>}
                      {departments.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                    
                  </div> */}
                  <div style={{display: 'flex'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Member </span>
                    <Form.Group className="mb-2">
                   
                    <Form.Select
                      id="state"
                      required
                      value={member}
                      onChange={e=>this.onFilter(e.target.value, 'member')}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >
                     
                      <option value="">Choose Member</option>
                      {members.length==0 &&  <option value="">loading</option>}
                      {members.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  </div>
                  <div style={{display: 'flex'}}>
                    <span style={{marginRight: 10, fontSize: 14}}>Clients </span>
                    <Form.Group className="mb-2">
                   
                    <Form.Select
                      id="state"
                      required
                      value={client}
                      onChange={e=>this.onFilter(e.target.value, 'client')}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >
                     
                      <option value="">Choose Clients</option>
                      {clients.length==0 &&  <option value="">loading</option>}
                      {clients.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                    
                  </div>
                  <Button
                    color="warning"
                    onClick={this.toggleFilter}
                    size="sm"
                    style={{marginRight: 10}}
                  >
                    Hide Filters
                  </Button>
                </div>}
          </Col>
        </Row>


        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  {/* <th className="border-0">Project</th> */}
                  <th className="border-0">Title</th>
                  <th className="border-0">Category</th>
                  <th className="border-0">Client</th>
                  <th className="border-0">Budget</th>
                  <th className="border-0">Progress</th>
                  <th className="border-0">Start Date</th>
                  <th className="border-0">End Date</th>
                  <th className="border-0">Updated Date</th>
                  <th className="border-0">actions</th>
                </tr>
              </thead>
              <tbody>
                
                {projects.map((project, key) => {
               
                  return (
                    <tr >
                     
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{project.title}</span>
                      </td>
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{project.category_name}</span>
                      </td>
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{project.client_name}</span>
                      </td>
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>
                          {project.currency !== null ? project.currency :'#'}{this.formatCurrency(project.cost)}</span>
                      </td>
                      <td>
                        <Progress percent={project.progress} status={project.progress < 50 ? 'exception':''} />
                      </td>
                      <td>{project.start_date !== null ? moment(project.start_date).format('MMM D, YYYY'): 'No Deadline'}</td>
                      <td>{project.deadline !== null ? moment(project.deadline).format('MMM D, YYYY'): 'No Dealine'}</td>
                      <td>{project.updated_at !== null ? moment(project.updated_at).format('MMM D, YYYY'): 'No Date'}</td>

                    
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" 
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          onClick={() => this.toggleEditProject(project)} size="sm">View</Button>
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => this.toggleDeleteProject(project)}

                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {projects.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} projects for all Employees`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default ProjectIndex;
