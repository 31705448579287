
import React, { useState, useEffect } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScaleRight, faBoxOpen, faCalendarCheck, faGifts, faHandScissors, faLayerGroup, faGear, faMinus, faMoneyBill, faParachuteBox,faPen,faSignOutAlt, faTable, faTimes,faUsers, faBrain, faHammer, faReceipt, faRunning, faAward } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, ButtonGroup, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { getUserPermissions } from "../services/noticeService";
import { Routes } from "../routes";
import { routes } from "../authRoute";
import { workRoute } from "../workRoute";
import {settingsRoute} from "../settingsRoute"
import {staffRoutes} from "../staffRoute"
import {salaryRoute} from "../salaryRoute"
import {awardRoute} from "../awardRoute"
import {terminationRoute} from "../terminationRoute"
import {trainingRoute} from "../trainingRoute"
import {hrRoute} from "../hrRoute"
import ThemesbergLogo from "../assets/img/themesberg.svg";
import ReactHero from "../assets/img/technologies/react-hero-logo.svg";
import ProfilePicture from "../assets/img/team/profile-picture-3.jpg";
import { attendanceLeaveRoute } from "../attendanceLeaveRoute";
import { performanceRoute, DepartmentperformanceRoute } from "../performanceRoute";
import { faCalendarTimes } from "@fortawesome/free-regular-svg-icons";
import { profile } from "../services/authService";

// const p = JSON.parse(localStorage.getItem('permissions'));
// const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]


export default (props = {}) => {
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  

  const onCollapse = () => setShow(!show);


  const [permissions, setPermission] = useState("");

    useEffect(() => {
       

        const fetchData = async () => {
            try {
                const response = await getUserPermissions();
                const p = await response.permissions
                const permissions =p.map((permission)=>{
                  return permission.name;
                })
                console.log(permissions)
                setPermission(permissions);
            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, []);



  const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : "";
    // console.log(permissions)

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const createLinks = routes => {
    return routes.map((prop, key) => {
      return (
      
        <>
        <NavItem title={prop.title} link={prop.path} icon={prop.icon} />
        
        </>

      );
    });
  };
  const getUser = ()=>{
    const user = JSON.parse(localStorage.getItem('user'));
    return user.admin;
  }
  const getProfile = ()=>{
    const profile = JSON.parse(localStorage.getItem('profile'));
    return profile;
  }

  const getCompanyProfile = ()=>{
    const company_profile = JSON.parse(localStorage.getItem('company_profile'));
    if(company_profile){
      return (
        <>
           <NavItem title={company_profile.name} image={company_profile.logo_url !== null ? company_profile.logo_url :ReactHero} />
        </>
      )
    } else {
      return 1;
    }
    
  }

  

  

  const logOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem('token');
    localStorage.clear();
    this.props.history.push("/auth/sign-in");
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const classNames = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const navItemClassName = link === pathname ? "active" : "";
    const linkProps = external ? { href: link } : { as: Link, to: link };
    



    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={40} height={40} style={{borderRadius:10}} className="sidebar-icon svg-icon" /> : null}

            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar expand={false} collapseOnSelect variant="light" className="navbar-theme-primary px-4 d-md-none">
        {/* <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image src={ReactHero} className="navbar-brand-light" />
        </Navbar.Brand> */}
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={onCollapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}>
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image src={ProfilePicture} className="card-img-top rounded-circle border-white" />
                </div>
                {/* <div className="d-block">
                  <h6>Hi, Jane</h6>
                  <Button as={Link} variant="secondary" size="xs" to={Routes.Signin.path} className="text-dark">
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> Sign Out
                  </Button>
                </div> */}
              </div>
              <Nav.Link className="collapse-close d-md-none" onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            { getCompanyProfile()}
           
            {getUser() == 1 ? <Nav className="flex-column pt-3 pt-md-0">
           
          
           {createLinks(routes)}
           
           {permissions.includes('show work') === true ?  <CollapsableNavItem eventKey="components/" title="Work" icon={faBrain}>
           { createLinks(workRoute) }
             
           </CollapsableNavItem> : ''  }
           
          
      
           {permissions.includes('show payroll') === true ?  <CollapsableNavItem eventKey="components/" title="PayRoll" icon={faReceipt}>
           { createLinks(salaryRoute) }
             
           </CollapsableNavItem> : ''  }


           { createLinks(attendanceLeaveRoute) }


           {permissions.includes('show training') === true ?  <CollapsableNavItem eventKey="components/" title="Training" icon={faRunning}>
           { createLinks(trainingRoute) }
             
           </CollapsableNavItem> : ''  }

           
           {permissions.includes('show emptools') === true ?  <CollapsableNavItem eventKey="components/" title="Employee Tools" icon={faAward}>
              { createLinks(awardRoute) }
              { createLinks(terminationRoute) }
            
              { createLinks(hrRoute) }
             
           </CollapsableNavItem> : ''  }
          
           {permissions.includes('show performance') === true ?  <CollapsableNavItem eventKey="components/" title="Performances" icon={faBalanceScaleRight}>
           { createLinks(performanceRoute) }
             
           </CollapsableNavItem> : ''  }
            {permissions.includes('show setting') === true ?  <CollapsableNavItem eventKey="components/" title="Settings" icon={faHammer}>
           { createLinks(settingsRoute) }
             
           </CollapsableNavItem> : ''  }
          
          
           <Dropdown.Divider className="my-3 border-indigo" />

           {/*<CollapsableNavItem eventKey="components/" title="Components" icon={faBoxOpen}>
             <NavItem title="Accordion" link={Routes.Accordions.path} />
             <NavItem title="Alerts" link={Routes.Alerts.path} />
             <NavItem title="Badges" link={Routes.Badges.path} />
             <NavItem external title="Widgets" link="https://demo.themesberg.com/volt-pro-react/#/components/widgets" target="_blank" badgeText="Pro" />
             <NavItem title="Breadcrumbs" link={Routes.Breadcrumbs.path} />
             <NavItem title="Buttons" link={Routes.Buttons.path} />
             <NavItem title="Forms" link={Routes.Forms.path} />
             <NavItem title="Modals" link={Routes.Modals.path} />
             <NavItem title="Navbars" link={Routes.Navbars.path} />
             <NavItem title="Navs" link={Routes.Navs.path} />
             <NavItem title="Pagination" link={Routes.Pagination.path} />
             <NavItem title="Popovers" link={Routes.Popovers.path} />
             <NavItem title="Progress" link={Routes.Progress.path} />
             <NavItem title="Tables" link={Routes.Tables.path} />
             <NavItem title="Tabs" link={Routes.Tabs.path} />
             <NavItem title="Toasts" link={Routes.Toasts.path} />
             <NavItem title="Tooltips" link={Routes.Tooltips.path} />
           </CollapsableNavItem>*/}
          

         </Nav>:<Nav className="flex-column pt-3 pt-md-0">
           
           {createLinks(staffRoutes)}
           
           
         </Nav>}
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
