import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

const authuser=JSON.parse(localStorage.getItem('user'));

export function getTrainings(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
   

    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}trainings`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/trainings`, requestOptions).then(authService.handleResponse);
    }
}


export function addTraining(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addTraining`, requestOptions).then(authService.handleResponse);
}

export function deleteTraining(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletetraining/${id}`, requestOptions).then(authService.handleResponse);
}

export function editTraining(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateTraining/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getTraining(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}training/${id}`, requestOptions).then(authService.handleResponse);
}
