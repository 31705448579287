import React, { Component } from "react";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { addTrainer } from "../../services/trainerService";
import { getBranches } from "../../services/branchService";
export class AddTrainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      first_name: '',
      last_name: '',
      email: '',
      address: '',
      branch: '',
      expertise:'',
      branches: []
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.getBranches();
  }

  getBranches = async () => {

    getBranches().then(

      (res) => {
        this.setState({
          branches: res.branches.data,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };






  onSaveTrainer = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { first_name, last_name, expertise, email, address, branch, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        email: email !== '' && email !== undefined,
        address: address !== '' && address !== undefined,
        branch: branch !== '' && branch !== undefined,
        first_name: first_name !== '' && first_name !== undefined,
        last_name: last_name !== '' && last_name !== undefined,
        expertise: expertise !== '' && expertise !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveTrainer();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field === "first_name") {
      return "First Name  is required";
    } else if (field == 'last_name') {
      return "Last Name  is required"
    } else if (field == 'address') {
      return "Address is required"
    } else if (field == 'branch') {
      return "Branch is required"
    } else if (field == 'email') {
      return "Email is required"
    }else if (field == 'expertise') {
      return "Expertise is required"
    }
  };


  saveTrainer = () => {
    this.setState({ saving: true });
    const { first_name, last_name, expertise, email, address, branch } = this.state;
    console.log();
    addTrainer({
      first_name: first_name,
      last_name: last_name,
      email: email,
      address: address,
      branch: branch,
      expertise:expertise

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Trainer has been created");
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('A trainer could not be created')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { toggle } = this.props;

    const { saving, first_name, last_name,expertise, email, address, branch, branches, loading } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                <Button variant="outline-primary" size="sm">
                  Create Trainer
                </Button>
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>First Name</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="reason"
                      placeholder='Enter First Name'
                      value={first_name}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "first_name");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Last Name</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="last_name"
                      placeholder='Enter Last Name'
                      value={last_name}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "last_name");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Email Address</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="reason"
                      placeholder='Enter Email Address'
                      value={email}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "email");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Expertise</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="last_name"
                      placeholder='Enter Expertise'
                      value={expertise}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "expertise");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Branches</Form.Label>

                    <Form.Select
                      id="state"
                      required
                      value={branch}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "branch");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Choose Branch</option>
                      {branches.length == 0 && <option value="">loading</option>}
                      {branches.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </Col>
                <Col md={6} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Address</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="reason"
                      placeholder='Enter Trainers Address'
                      value={address}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "address");
                      }}
                      type="textarea"
                    />
                  </Form.Group>

                </Col>
              </Row>
             
              <Row>
               
                {/* <Col md={6} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Expertise</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="reason"
                      placeholder='Enter Trainers Address'
                      value={expertise}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "expertise");
                      }}
                      type="textarea"
                    />
                  </Form.Group>

                </Col> */}

              </Row>
              <Row>

              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveTrainer}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddTrainer;
