import React from "react";
import {
  Modal
} from "reactstrap";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,
  } from "@themesberg/react-bootstrap";
import { FormGroup, Input } from "reactstrap";
import { toast } from 'react-toastify';
import ReactToPrint from "react-to-print";
import SpinDiv from "../../components/SpinDiv";
import moment from "moment";
import Invoice from "./Invoice"

class PaySlip extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      viewPaySlip: props.viewPaySlip,
      loading: false,
      search: '',
      validation: {},
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }

  monthName = (mon)=> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
  }
   showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };
  
 

  render() {
    const { viewPaySlip, toggle } = this.props
    const {loading} = this.state;
    return (
      <>
        <div style={{ display: "none" }}>
          <Invoice
            payslip={viewPaySlip}
            ref={(el) => (this.componentRef = el)}
          />
        </div>
        <Modal
         className="modal-dialog-full"
          isOpen={viewPaySlip!=null}
          toggle={() => !loading&&toggle}
          style={{maxWidth: 800}}
        >

            <div className="modal-header" style={{ padding: "1rem" }}>
                {viewPaySlip.status &&  <ReactToPrint
                    trigger={() => {
                      return (
                        <Button variant="outline-success" href="#" size="sm">
                          Print Slip
                        </Button>
                      );
                    }}
                    content={() => this.componentRef}
                  />}
                <div className="btn-toolbar mb-2 mb-md-0">
                <h5 className="modal-title" id="exampleModalLabel" style={{textTransform:'capitalize', paddingLeft:30,}}>
                    Payslips Details -  <span style={{fontWeight:'bold',  textTransform:'capitalize', fontSize:18}}>
                   {viewPaySlip.name}
                </span>
                </h5>
                </div>
                <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={toggle}
                ></button>
            </div>
            <div style={{fontSize:15, marginLeft:20, fontWeight:'bold'}}>
               
                <span className="modal-title" id="exampleModalLabel">
                    Basic Salary:&nbsp;&#8358;{viewPaySlip.basic_salary.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
                <span className="modal-title" id="exampleModalLabel" style={{paddingLeft:'30px'}}>
                    Net Salary:&nbsp;&#8358;{viewPaySlip.net_salary .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </span>
                <span className="modal-title" id="exampleModalLabel" style={{paddingLeft:'30px'}}>
                    Status: {viewPaySlip.status == 1 ? 
                    <span style={{color:'green'}}>Paid</span> : 
                    <span style={{color:'red'}}>Pending</span> }
                </span>
            </div>
            <Card border="light" className="shadow-sm mb-4">
                <Card.Body className="pb-0">
                    <Table
                    responsive
                    className="table-centered table-nowrap rounded mb-0"
                    >
                    <thead className="thead-light">
                        <tr>
                            <th>Salary Month</th>
                            <th>Basic Salary</th>
                            <th>Allowance</th>
                            <th>Commission</th>
                            <th>Loan Deduction</th>
                            <th>Other Deduction</th>
                            <th>Net Salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{fontWeight:"bold"}}>
                        
                            <td>{this.monthName(viewPaySlip.month)}, {viewPaySlip.year}</td>
                            <td>&#8358;{viewPaySlip.basic_salary && viewPaySlip.basic_salary.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td>&#8358;{viewPaySlip.allowance && viewPaySlip.allowance.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td>&#8358;{viewPaySlip.commission && viewPaySlip.commission.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                            <td>&#8358;{viewPaySlip.loan_paid && viewPaySlip.loan_paid.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td>&#8358;{viewPaySlip.other_deduction && viewPaySlip.other_deduction.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td>&#8358;{viewPaySlip.net_salary && viewPaySlip.net_salary.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        </tr>
                    </tbody>
                    
                    </Table>
                    <Row>
                      <Col md={8}>
                        <Form.Group className="mb-2">
                          <Form.Label style={{ paddingRight: 10 }}>Narration</Form.Label>
                          <Input
                            className="custom-control-input"
                            id="description"
                            disabled
                            type="textarea"
                            value={viewPaySlip.description && viewPaySlip.description }
                          />
                          </Form.Group>
                      </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Modal>
      </>
    );
  }
}

export default PaySlip;
