import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/async";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Progress, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { getProjects } from "../../services/projectService";
import { getEmployees} from "../../services/employeeService";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Editor } from '@tinymce/tinymce-react';
import { AsyncPaginate } from "react-select-async-paginate";
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { currencies } from "./Currency";
import axios from 'axios'

export class EditTask extends Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.state = {
      
      start_date: moment().startOf('month'),
      deadline: moment().endOf('month'),
      id: props.task.id,
      task: props.task,
      employees:[],
      status: props.task.status,
      initialEmployees:props.task.assignees.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      employees_id:props.task.user_ids,
    
      // project:props.task.initial_project.map((opt) => ({
      //   label: opt.title,
      //   value: opt.id,
      // })),
      project:{label:props.task.project_name, value:props.task.project_id},
      project_id:props.task.project_id,
      projects:[],
      description: props.task.description,

    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.getProjects();
    this.getEmployees();

  }


  handleEditorChange(description) {
    this.setState({ description });
  }

  setStatus(event) {
    console.log(event.target.value);
    this.setState({status:event.target.value})
  }

 


  getProjects = () => {
    this.setState({
      projects: this.props.projects.filter(p => p.progress < 100).map((opt) => ({
        label: opt.title,
        value: opt.id,
      }))
    });
  
  };
 

  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });



  loadOptions = (data) => async (search, prevOptions) => {
    await this.sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };


  
  

  getEmployees = () => {
    this.setState({ loading: true });
    const {task} = this.state;
    getEmployees().then(
     
      (res) => {
        this.setState({
          employees: res.employees.data.map((opt) => ({
            label: opt.name,
            value: opt.id,
          })),
        
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  handleEmployeeChange =  (initialEmployees) => {

    let new_employees_id = initialEmployees.map(obj => {
      return obj.value
   })
    this.setState({
      initialEmployees:initialEmployees,
      employees_id: new_employees_id,
    });
 
  }

  handleProjectChange = (project) => {
    
    this.setState({ project:project, project_id:project.value });

  }



 
  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })
  }

  onSaveTask = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {title, status,
        start_date, deadline } = this.state.task;
        console.log(status)
    const {  validation, description, employees_id, project_id } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== '' && title !== undefined,
        description: description !== '' && description !== undefined,
        start_date: start_date !== '' && start_date !== undefined,
        deadline: deadline !== '' && deadline !== undefined,
        project_id: project_id !== '' && project_id !== undefined,
      
        employees_id: employees_id.length > 0,
        
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveTask();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field === "project_id") {
      return "Project is required";
    } else if (field == 'employees_id') {
      return "Assignees is required"
    } else if (field == 'title') {
      return "Title is required"
    } else if (field == 'description') {
      return "Description is required"
    } else if (field == 'staert_date') {
      return "Starting Date is required"
    } else if (field == 'deadline') {
      return "Deadline is required";
    }
  };




  saveTask = () => {

    this.setState({ saving: true });
    const {title,
       start_date, deadline } = this.state.task;
      const {description, status, project_id,employees_id,  id}=this.state;
     
    let data = new FormData();
    data.set("start_date", start_date);
    data.set("deadline", deadline);
    data.set("project_id", project_id);
    data.set("title", title);
    data.set("status", status);
    data.set("description", description);
    let op = employees_id;
    for (var i in op) {
      data.append(`employees[${i}]`, op[i])
    }
    
   
    return axios
      .post(
        `${settings.API_URL}updatetask/${id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Task Updated");
        },
        (err) => {

          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            this.showToastError('Task could not be updated')
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );


  };

  

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { task } = this.state

    this.setState({ task: { ...task, [state]: e } })
  }

  onChange2 = (e, state) => {
    this.setState({ [state]: e });
  };

  

  

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  






  render() {

    const { toggle } = this.props;

    const { saving,edit,initialEmployees, status, project,project_id, initialProject, description, task, employees, projects, loading } = this.state;
    return (
      <>
      {console.log(project)}
     
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={task != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {task && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Task"}



                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            
            <Row>
              <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      disabled={!edit}
                      placeholder='Enter Title'
                      value={task.title}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>
            <Row>
              <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Projects</Form.Label>
                    <Form.Select
                      onChange={async (e) => {
                        await this.onChange2(e.target.value, "project_id");
                      }}
                      value={project_id}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Project</option>
                      {projects.length == 0 && ''}
                      {projects.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>

                  </Form.Group>

              </Col>
              
            <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Assignees</Form.Label>
                    <AsyncPaginate
                      maxMenuHeight={120}
                      value={initialEmployees}
                      isMulti
                      loadOptions={this.loadOptions(employees)}
                      onChange={this.handleEmployeeChange}
                    />

                   
                  </Form.Group>
                  

                </Col>
               

              </Row>
              <Row>
                <Col md={6}>
                <div onChange={this.setStatus.bind(this)}>
                <Form.Label style={{ paddingRight: 10 }}>Completed </Form.Label>
                  <input type="radio" value="1" checked={status == 1 ? true :false} name="status"/> 
                  <Form.Label style={{ paddingRight: 10 }}> &nbsp; &nbsp;Inprogess </Form.Label>
                  <input type="radio" value="0"  checked={status == 0 ? true :false} name="status"/>
                </div>
                 

                 

                </Col>
                


              </Row>
              
              <Row>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                        disabled={!edit}
                      value={moment(task.start_date).format('MMM DD, YYYY') || ''}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      End Date
                    </label>
                    <ReactDatetime
                      value={moment(task.deadline).format('MMM DD, YYYY') || ''}
                      disabled={!edit}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'deadline')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>

              
              <Row>
                        
                          <Editor
                          
                            value={description}
                            onEditorChange={this.handleEditorChange}

                            initialValue={task.description}
                            init={{
                              height: 300,
                              menubar: true,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}

                          />

                        </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="md"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveTask}
                    >
                      Save
                    </Button>
                    <Button
                      size="md"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditTask;
