
import DashboardOverview from "./pages/dashboard/DashboardOverview";
import Profile from "./pages/profile/Profile"
import {faUser, faRunning, faUnlock, faAward} from "@fortawesome/free-solid-svg-icons";
import TrainingTypeIndex from "./pages/trainingtype/TrainingTypeIndex";
import TerminationTypeIndex from "./pages/terminationtype/TerminationTypeIndex";
import AwardTypeIndex from "./pages/awardtype/AwardTypeIndex";
import ClientProfile from "./pages/customer/Profile";




export let noSideBarRoute = [
    
    { path: "/profile",component: Profile, title:"Users",icon:faUser },
    { path: "/customer/profile",component: ClientProfile, title:"Profile",icon:faUser },
    { path: "/training_types",component: TrainingTypeIndex, title:"Training Types",icon:faRunning},
    { path: "/terminationtypes",component: TerminationTypeIndex, title:"Termination types",icon:faUnlock}, 
    { path: "/awardtypes",component: AwardTypeIndex, title:"Award Types",icon:faAward} 
  ];
