import React, { Component } from "react";
import { CardHeader, Media, Input, Modal,  } from "reactstrap";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { addLoan } from "../../services/salaryService";
import { InputNumber } from "antd";
export class AddLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      title:'',
      amount:'',
      start_date: moment().startOf('month'),
      end_date: moment().endOf('month'),
      reason:''
    };
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };


  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })

  }


  onSaveLoan = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { title, amount, start_date, end_date,reason, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== '',
        amount: amount !== '',
        start_date:start_date !== '',
        end_date:end_date !== '',
        reason:reason !== ''
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveLoan();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };

  validationRules = (field) => {
    if (field === "title") {
      return "Loan title is required";
    } else if(field == 'amount'){
      return "Loan Amount is required"
    } else if(field == 'reason'){
      return "Reason is required"
    }else if(field == 'end_date'){
      return "Loan End date is required"
    }else if(field == 'start_date'){
      return "Loan start date is required"
    }
  };

  saveLoan = () => {
    this.setState({ loading: true });
    const {id,user_id} = this.props.addLoan
    const { title, amount,start_date,end_date,reason} = this.state;
    console.log();
    addLoan({
      title: title,
      amount: amount,
      employee_id:id,
      user_id:user_id,
      start_date:start_date,
      end_date:end_date,
      reason:reason

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Loan has been created");
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { addLoan, toggle } = this.props;

    const { saving, loading, title, amount, reason, start_date, end_date } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={addLoan != null}
          toggle={() => !loading && !saving && toggle}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                <Button variant="outline-primary" size="sm">
                  Add Loan
                </Button>
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            <Row style={{ border: '1px #eee solid', padding: '5px 5px 5px', margin: '10px 2px 7px', borderRadius: 7 }}>
                    <Col md={12} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Loan Title</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="title"
                          placeholder='Enter Basic Loan Title'
                          disabled={!addLoan}
                          value={title}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "title");
                          }}
                          type="text"
                        />
                      </Form.Group>

                    </Col>
                    <Col md={12} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10,  display:'block'}}>Loan Amount</Form.Label>
                        <InputNumber

                        style={{ width: '100%', height: 40, paddingTop: 5, borderRadius: 5, fontSize: 18 }}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        value={amount}
                        onChange={e => this.onChange(e, 'amount')}
                        />
                      </Form.Group>

                    </Col>
                    <Col md={12}>
                    <Form.Label style={{ paddingRight: 10 }}>Start Date</Form.Label>
                    <ReactDatetime
                          value={start_date}
                          dateFormat={"MMM DD, YYYY"}
                          onChange={e=>this.dateFilter(e, 'start_date')}
                          closeOnSelect
                          //onChange={(e) => this.onChange(e, "start_date")}
                          inputProps={{
                            required: true,
                            className: "form-control date-width",
                          }}
                          timeFormat={false}
                        />
                    </Col>

                    <Col md={12}>
                    <Form.Label style={{ paddingRight: 10 }}>End Date</Form.Label>
                      <ReactDatetime
                          value={end_date}
                          dateFormat={"MMM DD, YYYY"}
                         
                          closeOnSelect
                          onChange={(e) => this.onChange(e, "end_date")}
                          inputProps={{
                            required: true,
                            className: "form-control date-width",
                          }}
                          timeFormat={false}
                        />
                    </Col>
                    <Col md={12} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Reason for Loan</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="reason"
                          placeholder='Enter Loan reason'
                          value={reason}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "reason");
                          }}
                          type="textarea"
                        />
                      </Form.Group>

                    </Col>
                    
                  </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveLoan}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddLoan;
