import React, { Component } from "react";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,

} from "@themesberg/react-bootstrap";
import SpinDiv from "../../components/SpinDiv";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { toast } from "react-toastify";
import { getMarkedDepartmentPerformances } from "../../../services/departmentPerformanceService";
import { Tooltip } from 'antd';
import {faCheck, faBoxOpen,faUsers,  faStepForward, faEnvelope, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from 'antd';
import Chart from "react-apexcharts";
import { Popover} from 'antd';

const attendanceOverview = (
  <div>
    <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
            <p>Weekly Attendance is calcuted as follows</p>
            <p>Working Hours for a week = No of Days of week x 9hrs</p>
            <p> Employee working Hours for a week = Attendance (total No of hours worked) X No of working Days</p>
            <h6> Attendance Percentage =   (Employee working Hours for a week /  Employee working Hours for a week) x 20</h6>

        </Card.Body>
    </Card>
  </div>
);
const performanceOverview = (
    <div>
      <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
              <h5>Performance</h5>
              <p>(Sum total Rating / No of weeks (No of time it was rated) x 5) x 100</p>
              <p>This arithmetic operation works for content_upload, Policy and Compliance, management_support, Training & Development</p>
             
  
          </Card.Body>
      </Card>
    </div>
  );



export class ViewOperationManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            validation: {},
            show: false,
            edit: false,
            id: props.match.params.id,
            year:props.match.params.year,
            month:props.match.params.month,
            performances:[],
            overall_management_support:0,
            overall_quality_of_service:0,
            overall_content_upload:0,
            overall_safety_management:0,
    
        };
    }

    componentDidMount() {
        toast.configure({ hideProgressBar: true, closeButton: false });
        this.getMarkedPerformances();
        
    }





    getMarkedPerformances = () => {
        const { id } = this.state;
        this.setState({  loading: true})
        getMarkedDepartmentPerformances(id).then(
            
            (res) => {
                this.setState({
                    performances: res.marked_performances,
                    loading: false
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };
    sumArray(arr){
        const sum = arr.reduce((partialSum, a) => partialSum + a, 0);
        return sum;
    }


    getTeamWorkPercentage(performances){
        let teamwork =[];
        let total_teamwork  = performances.length*5;
        performances.map((p, index)=>{
            return teamwork .push( p.teamwork );
        })
       
         let percentage = (this.sumArray(teamwork )/total_teamwork )*100;
         return Math.round(percentage);
        

    }
     getEmployeeSatisfactionPercentage(performances){
        let employee_satisfaction =[];
        let total_employee_satisfaction  = performances.length*5;
        performances.map((p, index)=>{
            return employee_satisfaction .push( p.employee_satisfaction );
        })
       
         let percentage = (this.sumArray(employee_satisfaction )/total_employee_satisfaction )*100;
         return Math.round(percentage);
        

    }

     getEmployeeProductivityPercentage(performances){
        let employee_productivity=[];
        let total_employee_productivity = performances.length*5;
        performances.map((p, index)=>{
            return employee_productivity.push( p.employee_productivity);
        })
       
         let percentage = (this.sumArray(employee_productivity)/total_employee_productivity)*100;
       
         return Math.round(percentage);
         
    }

    getQualityWorkPercentage(performances){
        let quality_of_work=[];
        let total_quality_of_work = performances.length*5;
        performances.map((p, index)=>{
            return quality_of_work.push( p.quality_of_work);
        })
       
         let percentage = (this.sumArray(quality_of_work)/total_quality_of_work)*100;
       
         return Math.round(percentage);
         
    }
    

    getPolicyCompliancePercentage(performances){
        let policy_compliance=[];
        let total_policy_compliance = performances.length*5;
        performances.map((p, index)=>{
            return policy_compliance.push( p.policy_compliance);
        })
       
         let percentage = (this.sumArray(policy_compliance)/total_policy_compliance)*100;
         return Math.round(percentage);
        

    }

    getSafetyManagementPercentage(performances){
        let safety_management=[];
        let total_safety_management = performances.length*5;
        performances.map((p, index)=>{
            return safety_management.push( p.safety_management);
        })
       
         let percentage = (this.sumArray(safety_management)/total_safety_management)*100;
         return Math.round(percentage);
        

    }

    getRiskManagementPercentage(performances){
        let risk_management=[];
        let total_risk_management = performances.length*5;
        performances.map((p, index)=>{
            return risk_management.push( p.risk_management);
        })
       
         let percentage = (this.sumArray(risk_management)/total_risk_management)*100;
         return Math.round(percentage);
        

    }

    getSingleWeekPolicyCompliancePercentage(performances){
        let policy_compliance=[];
        let total_policy_compliance = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return policy_compliance.push( p.policy_compliance);
        })
       
         let percentage = (policy_compliance[0]/total_policy_compliance)*20;
         return Math.round(percentage);
        

    }

    getSingleWeekTeamworkPercentage(performances){
        let teamwork=[];
        let total_teamwork = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return teamwork.push( p.teamwork);
        })
       
         let percentage = (teamwork[0]/total_teamwork)*20;
         return Math.round(percentage);
        

    }

    getSingleWeekEmployeeProductivityPercentage(performances){
        let employee_productivity=[];
        let total_employee_productivity = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return employee_productivity.push( p.employee_productivity);
        })
       
         let percentage = (employee_productivity[0]/total_employee_productivity)*20;
         return Math.round(percentage);
        

    }

    getSingleWeekQualityWorkPercentage(performances){
        let quality_of_work=[];
        let total_quality_of_work = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return quality_of_work.push( p.quality_of_work);
        })
       
         let percentage = (quality_of_work[0]/total_quality_of_work)*20;
         return Math.round(percentage);
        

    }

    getSingleWeekRiskManagementPercentage(performances){
        let risk_management=[];
        let total_risk_management = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return risk_management.push( p.risk_management);
        })
       
         let percentage = (risk_management[0]/total_risk_management)*10;
         return Math.round(percentage);
        

    }

    getSingleWeekSafetyManagementPercentage(performances){
        let safety_management=[];
        let total_safety_management = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return safety_management.push( p.safety_management);
        })
       
         let percentage = (safety_management[0]/total_safety_management)*10;
         return Math.round(percentage);
        

    }


    
   

    monthName = (mon)=> {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }

    getName(performances){
        var name = performances.map(function(p) {
           return p.department_name;
       });
       return name[0];
   }

    weeklyPerformanceChat(performances){
        
        const week_one_performances = performances.filter(p => p.week == 1);
        const week_two_performances = performances.filter(p => p.week == 2);
        const week_three_performances = performances.filter(p => p.week == 3);
        const week_four_performances = performances.filter(p => p.week == 4);
    
       
    
        let teamwork_one= week_one_performances.length !== 0 ? (this.getTeamWorkPercentage(week_one_performances)/100)*20 : 0;
        let teamwork_two= week_two_performances.length !== 0 ? (this.getTeamWorkPercentage(week_two_performances)/100)*20 : 0;
        let teamwork_three= week_three_performances.length !== 0 ? (this.getTeamWorkPercentage(week_three_performances)/100)*20 : 0;
        let teamwork_four= week_four_performances.length !== 0 ? (this.getTeamWorkPercentage(week_four_performances)/100)*20 : 0;
        
        let policy_compliance_one= week_one_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_one_performances)/100)*20 : 0;
        let policy_compliance_two= week_two_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_two_performances)/100)*20 : 0;
        let policy_compliance_three= week_three_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_three_performances)/100)*20 : 0;
        let policy_compliance_four= week_four_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_four_performances)/100)*20 : 0;

        let employee_productivity_one= week_one_performances.length !== 0 ? (this.getEmployeeProductivityPercentage(week_one_performances)/100)*20 : 0;
        let employee_productivity= week_two_performances.length !== 0 ? (this.getEmployeeProductivityPercentage(week_two_performances)/100)*20 : 0;
        let employee_productivity_three= week_three_performances.length !== 0 ? (this.getEmployeeProductivityPercentage(week_three_performances)/100)*20 : 0;
        let employee_productivity_four= week_four_performances.length !== 0 ? (this.getEmployeeProductivityPercentage(week_four_performances)/100)*20 : 0;

        let quality_of_work_one= week_one_performances.length !== 0 ? (this.getQualityWorkPercentage(week_one_performances)/100)*20 : 0;
        let quality_of_work_two= week_two_performances.length !== 0 ? (this.getQualityWorkPercentage(week_two_performances)/100)*20 : 0;
        let quality_of_work_three= week_three_performances.length !== 0 ? (this.getQualityWorkPercentage(week_three_performances)/100)*20 : 0;
        let quality_of_work_four= week_four_performances.length !== 0 ? (this.getQualityWorkPercentage(week_four_performances)/100)*20 : 0;

        let risk_management_one= week_one_performances.length !== 0 ? (this.getRiskManagementPercentage(week_one_performances)/100)*10 : 0;
        let risk_management_two= week_two_performances.length !== 0 ? (this.getRiskManagementPercentage(week_two_performances)/100)*10 : 0;
        let risk_management_three= week_three_performances.length !== 0 ? (this.getRiskManagementPercentage(week_three_performances)/100)*10 : 0;
        let risk_management_four= week_four_performances.length !== 0 ? (this.getRiskManagementPercentage(week_four_performances)/100)*10 : 0;

        let safety_management_one= week_one_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_one_performances)/100)*10 : 0;
        let safety_management_two= week_two_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_two_performances)/100)*10 : 0;
        let safety_management_three= week_three_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_three_performances)/100)*10 : 0;
        let safety_management_four= week_four_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_four_performances)/100)*10 : 0;

        let overall_week_one_performance = quality_of_work_one+teamwork_one+risk_management_one+employee_productivity_one+policy_compliance_one+safety_management_one;
        let overall_week_two_performance = quality_of_work_two+teamwork_two+risk_management_two+employee_productivity+policy_compliance_two+safety_management_two;
        let overall_week_three_performance = quality_of_work_three+teamwork_three+risk_management_three+employee_productivity_three+policy_compliance_three+safety_management_three;
        let overall_week_four_performance = quality_of_work_four+teamwork_four+risk_management_four+employee_productivity_four+policy_compliance_four+safety_management_four;

       
        const data  = {
            options: {
              chart: {
                id: "basic-bar"
              },
              xaxis: {
                categories: ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4']
              }
            },
            series: [
              {
                name: "Weekly overall-performance",
                data: [overall_week_one_performance,overall_week_two_performance,overall_week_three_performance,overall_week_four_performance,100]
              }
            ]
          };
          return (
              <Chart
              options={data.options}
              series={data.series}
              type="line"
              width="750"
              height="250"
            />
          )
    }

    performanceChart = (performances) => {
        let teamwork= this.getTeamWorkPercentage(performances);
        let quality_of_work= this.getQualityWorkPercentage(performances);
        let employee_productivity= this.getEmployeeProductivityPercentage(performances);
        let policy_compliance = this.getPolicyCompliancePercentage(performances);
        let safety_management = this.getSafetyManagementPercentage(performances);
        let risk_management = this.getRiskManagementPercentage(performances);
        
        const data  = {
          options: {
            chart: {
              id: "basic-bar"
            },
            xaxis: {
              categories: ['','TEAM WORK', 'SAFETY MANAGEMENT', 'RISK MANAGEMENT', 'EMPLOYEE PRODUCTIVITY', 'QUALITY OF WORK', 'POLICY COMPLIANCE','']
            }
          },
          series: [
            {
              name: "overall-performance",
              data: [0, teamwork,safety_management,risk_management,employee_productivity,quality_of_work,policy_compliance, 100 ]
            }
          ]
        };
        return (
            <Chart
            options={data.options}
            series={data.series}
            type="line"
            width="750"
            height="250"
          />
        )
    }

    render() {

        const { saving, month, year, loading, performances } = this.state;
        return (
            <>


                {loading && <SpinDiv text={"Loading..."} />}
                <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/performances">Performance</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Detail</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>

                                    {/* <Button variant="outline-primary" size="sm">
                                        Export
                                    </Button> */}
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                    <Row>
                        <Col md={12}>
                            <Card border="light" className="shadow-sm mb-4">
                                <Card.Body className="pb-0">
                                    <Row>
                                        <Col md={8}></Col>
                                        <Col md={4} style={{marginBottom:10}}>
                                            
                                                
                                                <ButtonGroup>
                                                    <Button variant="outline-primary" size="sm">
                                                        <Popover content={attendanceOverview} title="Attendance Overview">
                                                            Attendance overview
                                                        </Popover>
                                                    </Button>
                                                    <Button variant="outline-primary" size="sm">
                                                        <Popover content={performanceOverview} title="Performance Overview">
                                                            Performance OverView
                                                        </Popover>
                                                    </Button>
                                                </ButtonGroup>
                                           
                                        </Col>
                                    </Row>
                                    <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                        style={{marginBottom:10, fontSize:30}}
                                    >
                                    <thead className="thead-light">
                                        <tr>
                                        <th className="border-0" style={{fontSize:15}}>Department: {this.getName(performances)} </th>
                                        <th className="border-0" style={{fontSize:15}}>Date: {this.monthName(month)}, {year}</th>
                                        
                                        
                                        </tr>
                                    </thead>
                                    
                                    
                                    </Table>
                                
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Col md={12}>
                       

                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                               <Row style={{marginBottom:10}}>
                                    
                                   <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                    Team Work
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faEnvelope}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getTeamWorkPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                    Policy Compliance
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faStepForward}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getPolicyCompliancePercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Safety Management
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faAngleUp}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{performances.length !== 0 ?this.getSafetyManagementPercentage(performances)+'%': '0%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Risk Management
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faAngleUp}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{performances.length !== 0 ?this.getRiskManagementPercentage(performances)+'%': '0%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Employee Productivity
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faBoxOpen}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getEmployeeProductivityPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Quality of Work
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faBoxOpen}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getQualityWorkPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                   
                                    
                               </Row>
                              

                        
                            </Card.Body>
                            <Row style={{margin:10}}>
                                <Col md={12}>
                                    <Card border="light" className="shadow-sm mb-4">
                                        <Card.Body className="pb-0">
                                            <Table
                                            responsive
                                            className="table-centered table-nowrap rounded mb-0"
                                            >
                                            <thead className="thead-light">
                                                <tr>
                                                <th className="border-0">No</th>
                                                <th className="border-0">Week</th>
                                                <th className="border-0">Team Work</th>
                                                <th className="border-0">Policy Compliance</th>
                                                <th className="border-0">Risk Management</th>
                                                <th className="border-0">Safety Management</th>
                                                <th className="border-0">Employee Productivity</th>
                                                <th className="border-0">Quality of Work</th>
                                               
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                {performances.map((performance, key) => {
                                            
                                                return (
                                                    <tr style={{fontWeight:"bold"}}>
                                                    <th scope="row">
                                                        <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{key+1}</span></td>
                                                        
                                                    </th>
                                                
                                                    <td>{performance.week}</td>
                                                    <td><Rate style={{color:'green'}} value={performance.teamwork } /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.policy_compliance } /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.risk_management } /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.safety_management } /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.employee_productivity} /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.quality_of_work} /></td>
                                                    
                                                   
                                                    
                                                    
                                                    </tr>
                                                );
                                                })}
                                            </tbody>
                                            
                                            </Table>
                                            
                                        </Card.Body>
                                        </Card>
                                </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>

                               <Col md={10}>
                                    <h5>Weekly Overall Performance</h5>
                                    {this.weeklyPerformanceChat(performances)}
                               </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>
                               <Col md={10} style={{marginBottom:20}}>
                                <h5>Performance BreakDown Graph</h5>
                                {this.performanceChart(performances)}
                               </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>

            </>
        );
    }
}

export default ViewOperationManager;
