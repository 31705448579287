import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getCurrentAttendance() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
    };
    return fetch(`${settings.API_URL}employee/attendances`, requestOptions).then(authService.handleResponse);
}

export function getMarkedAttendances(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    return fetch(`${settings.API_URL}employeemarkedAttendance/${id}`, requestOptions).then(authService.handleResponse);
}

export function clockIn(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employee/clock_in`, requestOptions).then(authService.handleResponse);
}

export function clockOut(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employee/clock_out`, requestOptions).then(authService.handleResponse);
}

export function getAttendances(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employee/my_attendances`, requestOptions).then(authService.handleResponse);
}


