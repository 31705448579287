import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getAwardTypes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}award_types`, requestOptions).then(authService.handleResponse);
}

export function addAwardTypes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addaward_types`, requestOptions).then(authService.handleResponse);
}

export function editAwardType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateaward_type/${data.id}`, requestOptions).then(authService.handleResponse);
}


