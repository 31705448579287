import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank, faStepForward } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { getEmployee } from "../../services/employeeService";
import { faDigitalOcean } from "@fortawesome/free-brands-svg-icons";
import { getBranches } from "../../services/branchService";
import { getDesignations } from "../../services/designationService";
import { getDepartments } from "../../services/departmentService";

export class EditEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.editEmployee,
      id: props.editEmployee.id,
      employee: props.employee,
      loading: false,
      search: '',
      validation: {},
      branches:[],
      designations:[],
      departments:[],
      show: false,
      edit: false,
      fromdate: moment().startOf('month'),
      todate: moment().endOf('day'),

    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    this.getEmployee();
    this.getBranches();
    this.getDesignations();
    this.getDepartments();
  }

  getBranches = () => {
    getBranches().then(
      (res) => {
        this.setState({
          branches:res.branches.data,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getDesignations = () => {
    getDesignations().then(
      (res) => {
        this.setState({
          designations:res.designations.data,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getDepartments = () => {
    getDepartments().then(
      (res) => {
        this.setState({
          departments:res.departments.data,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  onSaveUser = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { employee, validation } = this.state;
    const { bank_branch,step_level, grade_level, address, department_id, bank_name, branch_id, designation_id, account_holder_name, account_no, dob } = employee;
    await this.setState({
      validation: {
        ...validation,
        bank_name: bank_name !== '' && bank_name !== undefined,
        bank_branch: bank_branch !== ''  && bank_branch !== undefined,
        branch_id: branch_id !== ''  && branch_id !== undefined,
        designation_id: designation_id !== ''  && designation_id !== undefined,
        department_id: department_id !== ''  && department_id !== undefined,
        account_holder_name: account_holder_name !== '' && account_holder_name !== undefined,
        account_no: account_no !== '' && account_no !== undefined,
        dob: dob !== '' && dob !== undefined,
        address: address !== '' && address !== undefined,
        grade_level: grade_level !== '' && grade_level !== undefined,
        step_level: step_level !== '' && step_level !== undefined,
      }
    })
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveUser();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      await setTimeout(() => toast.error(
        <div style={{ padding: '10px 20px' }}>
          <p style={{ margin: 0, fontWeight: 'bold', color: 'red' }}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: 'red' }}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }

  validationRules = field => {
    if (field === "bank_name") {
      return "Bank Name is required";
    } else if (field === "bank_branch") {
      return "Bank Branch Name is required";
    } else if (field === "branch_id") {
    return "Branch Field  is required";
    } else if (field === "designation_id") {
      return "Designation Field  is required";
    }else if (field === "account_holder_name") {
      return "Account Holder Name is required";
    } else if (field === "account_no") {
      return "Account Number is required";
    } else if (field === "dob") {
      return "Date of Birth is required";
    }else if (field === "address") {
      return "Address is required";
    }else if (field === "department_id") {
      return "Department Field  is required";
    }else if (field === "grade_level") {
      return "Grade Level Field  is required";
    }else if (field === "step_level") {
      return "Step Level Field  is required";
    }
  }


  saveUser = () => {
    this.setState({ saving: true });
    const { id } = this.state

    const { bank_branch,employeeid, step_level,date_joined, grade_level, address, department_id, bank_name, account_holder_name,designation_id,branch_id, account_no, dob } = this.state.employee;
    console.log(this.state.employee.branch_id);

    let data = new FormData();
    data.set("bank_name", bank_name);
    data.set("employee_id", id);
    data.set("address", address);
    data.set("bank_branch", bank_branch);
    data.set("account_no", account_no);
    data.set("dob", dob);
    data.set("branch_id", branch_id);
    data.set("designation_id", designation_id);
    data.set("department_id", department_id);
    data.set("account_holder_name", account_holder_name);
    data.set("employeeid", employeeid);
    data.set("grade_level", grade_level);
    data.set("step_level", step_level);
    data.set("date_joined", date_joined)

    return axios
      .post(
        `${settings.API_URL}saveemployee`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        this.setState({ saving: false, edit: false });
      
        this.showToast("Employee updated");
      })
      .catch((err) => {
        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          // if (err.response.data.email) {
          //   this.showToastError('A u with this Info already exist')
          // }
          // if (err.response.data.phone) {
          //   this.showToastError('A user with this Phone number already exist')
          // }
          this.setState({ saving: false });
        }
      });
  };

  getEmployee = () => {
    const { id } = this.state;

    getEmployee(id).then(

      (res) => {
        this.setState({
          loading: false,
          employee: res.employee,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };



  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { employee } = this.state

    this.setState({ employee: { ...employee, [state]: e } })
    console.log(e)
  }

  roleHandleChange = (selectedRoles) => {
    this.setState({ selectedRoles });
  }
  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };



  render() {

    const { editEmployee, toggle } = this.props;

    const { saving, edit,loading, user, branches, departments, designations, show, employee, todate, fromdate, id } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen={editEmployee != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {user && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Employee"}



                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={6} style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                  <Form.Label style={{ paddingLeft: 10, fontSize: 18 }}>Personal Information</Form.Label>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Last Name"
                            name='lastname'
                            disabled
                            value={user.name || ''}

                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>

                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Email"
                            name='email'
                            disabled
                            value={user.email || ''}

                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>

                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="phone">
                        <Form.Label>Phone</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPhone} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Phone Digit"
                            name='phone'
                            disabled
                            value={user.phone || ''}

                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="phone">
                        <Form.Label>Employee ID</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPhone} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Employee ID"
                            name='employeeid'
                            disabled={!edit}
                            value={employee.employeeid || ''}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "employeeid");
                            }}

                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    
                    <Col md={6}>
                      <Form.Group id="firstName">
                        <Form.Label>Date of Birth</Form.Label>

                        <ReactDatetime
                          value={moment(employee.dob).format('MMM DD, YYYY')|| ""}
                          dateFormat={"MMM DD, YYYY"}
                          disabled={!edit}
                          closeOnSelect
                          onChange={(e) => this.onChange(e, "dob")}
                          inputProps={{
                            required: true,
                            className: "form-control date-width",
                          }}
                          timeFormat={false}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group id="firstName">
                        <Form.Label>Joining Date</Form.Label>

                        <ReactDatetime
                          value={moment(employee.date_joined).format('MMM DD, YYYY')|| ""}
                          dateFormat={"MMM DD, YYYY"}
                          disabled={!edit}
                          closeOnSelect
                          onChange={(e) => this.onChange(e, "date_joined")}
                          inputProps={{
                            required: true,
                            className: "form-control date-width",
                          }}
                          timeFormat={false}
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <Row>
                    <Col md={12}>
                    <Form.Group id="address">
                        <Form.Label>Address</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faLocationArrow} />
                          </InputGroup.Text>
                          <Input

                            type="textarea" placeholder="Enter Address"
                            name='address'
                            disabled={true}
                            value={user.address || ''}
                            
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "address");
                            }}

                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>
                  </Row>

                </Col>
                <Col md={5} style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                  <Form.Label style={{ paddingLeft: 10, fontSize: 18, }}>Bank Information</Form.Label>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Account Holder Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Account Name"
                            name='account_holder_name'
                            disabled={!edit}
                            value={employee.account_holder_name || ''}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "account_holder_name");
                            }}


                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>

                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Bank Name</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPiggyBank} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Bank name"
                            name='bank_name'
                            disabled={!edit}
                            value={employee.bank_name || ''}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "bank_name");
                            }}


                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-3">
                      <Form.Group id="Account">
                        <Form.Label>Account Number</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faDigitalOcean} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Account Number"
                            name='account_no'
                            disabled={!edit}
                            value={employee.account_no || ''}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "account_no");
                            }}


                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>
                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={12} className="mb-3">
                      <Form.Group id="address">
                        <Form.Label>Bank Branch</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faLocationArrow} />
                          </InputGroup.Text>
                          <Input

                            type="text" placeholder="Enter Branch"
                            name='bank_branch'
                            disabled={!edit}
                            value={employee.bank_branch || ''}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "bank_branch");
                            }}


                          />
                        </InputGroup>

                      </Form.Group>
                    </Col>

                  </Row>

                </Col>

              </Row>
              <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                <Col md={12} style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                  <Form.Label style={{ paddingLeft: 10, fontSize: 18, }}>Company Detail</Form.Label>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={6} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Branch</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </InputGroup.Text>
                          <Input

                            type="select" placeholder="Enter Branch"
                            name='branch_id'
                            disabled={!edit}
                            value={employee.branch_id || ''}
                           
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "branch_id");
                            }}
                           
                          >

                            <option value="">choose Branch</option>
                            {branches.length == 0 && <option value="">loading</option>}
                            {branches.map((p, index) => (
                              <option value={p.id} key={index}>
                                {p.name}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>

                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Departments</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPiggyBank} />
                          </InputGroup.Text>
                          <Input
                            type="select"
                            value={employee.department_id || ''}
                            disabled={!edit}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "department_id");
                            }}
                          >

                            <option value="">choose Department</option>
                            {departments.length == 0 && <option value="">loading</option>}
                            {departments.map((p, index) => (
                              <option value={p.id} key={p}>
                                {p.name}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>

                      </Form.Group>
                    </Col>

                  </Row>
                  <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '0 2px', borderRadius: 7 }}>
                    <Col md={6} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Designation</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faPiggyBank} />
                          </InputGroup.Text>
                          <Input
                            type="select"
                            value={employee.designation_id || ''}
                            disabled={!edit}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "designation_id");
                            }}
                          >

                            <option value="">choose Designation</option>
                            {designations.length == 0 && <option value="">loading</option>}
                            {designations.map((p, index) => (
                              <option value={p.id} key={p}>
                                {p.name}
                              </option>
                            ))}
                          </Input>
                        </InputGroup>

                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Grade Level</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faStepForward} />
                          </InputGroup.Text>
                          <Input
                            type="select"
                            value={employee.grade_level || ''}
                            disabled={!edit}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "grade_level");
                            }}
                          >
                            
                            <option value="">Choose Grade Level</option>
                            <option value="Grade level 5">Grade level 5</option>
                            <option value="Grade level 6">Grade level 6</option>
                            <option value="Grade level 7">Grade level 7</option>
                            <option value="Grade level 8">Grade level 8</option>
                            <option value="Grade level 9">Grade level 9</option>
                            <option value="Grade level 10">Grade level 10</option>
                            <option value="Grade level 11">Grade level 11</option>
                            <option value="Grade level 12">Grade level 12</option>

                            
                          </Input>
                        </InputGroup>

                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="Lastname">
                        <Form.Label>Step Level</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faStepForward} />
                          </InputGroup.Text>
                          <Input
                            type="select"
                            value={employee.step_level || ''}
                            disabled={!edit}
                            onChange={async (e) => {
                              await this.onChange(e.target.value, "step_level");
                            }}
                          >
                            
                            <option value="">Choose Steps</option>
                            <option value="Step 1">Step 1</option>
                            <option value="Step 2">Step 2</option>
                            <option value="Step 3">Step 3</option>
                            <option value="Step 4">Step 4</option>
                            <option value="Step 5">Step 5</option>
                            <option value="Step 6">Step 6</option>
                          </Input>
                        </InputGroup>

                      </Form.Group>
                    </Col>
                    
                  </Row>

                </Col>
              </Row>

              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  {edit && (
                    <div>
                      <Button
                        variant="primary"
                        size="sm"
                        style={{ marginTop: "10px", marginBottom: 20, float: "right" }}
                        disabled={saving}
                        onClick={this.onSaveUser}
                      >
                        Save Employee
                      </Button>
                      <Button
                        size="sm"
                        variant="transparent"
                        data-dismiss="modal"
                        type="button"
                        disabled={saving}
                        style={{ marginTop: "10px", float: "right" }}
                        onClick={toggle}
                      >
                        {" "}
                        Close
                      </Button>
                    </div>
                  )}

                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditEmployee;
