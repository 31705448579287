import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getTerminationTypes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}termination_types`, requestOptions).then(authService.handleResponse);
}

export function addTerminationTypes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addtermination_types`, requestOptions).then(authService.handleResponse);
}

export function editTerminationType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatetermination_type/${data.id}`, requestOptions).then(authService.handleResponse);
}


