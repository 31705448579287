import React, { Component } from "react";
import {  FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { addLeaveType, getLeaveTypes } from "../../services/leaveService";
import AsyncSelect from "react-select/async";
import ReactDatetime from "react-datetime";
import moment from "moment";
export class AddLeaveType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      title:'',
      amount:'',
      payment_status:false,
  
    };
  }

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };


  
  onSaveLeaveType = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { title, days, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== '' && title !== undefined,
        days: days !== '' &&  days !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveLeaveType();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };


  validationRules = (field) => {
    if (field === "title") {
      return "Leave type title is required";
    } else if(field == 'days'){
      return "Leave Type Days is required"
    } 
  };

  saveLeaveType = () => {
    this.setState({ saving: true });
    const {title,days, payment_status} = this.state;
    addLeaveType({
      title:title,
      days:days,
      payment_status:payment_status
    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Leave Type has been created");
      },
      (err) => {
        
        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('A Leave type with this title address already exist')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { addLeaveType, toggle } = this.props;

    const { saving,loading, payment_status } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={addLeaveType != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5> Create Leave Type</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={6} style={{ paddingTop: 15 }}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Title</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="title"
                        placeholder='Enter Leave Type title'
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "title");
                        }}
                        type="text"
                      />
                    </Form.Group>

                  </Col>
                  <Col md={6} style={{ paddingTop: 15 }}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>No of Days</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="reason"
                        placeholder='Enter Leave Type Days'
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "days");
                        }}
                        type="text"
                      />
                    </Form.Group>

                  </Col>
              </Row>
              <Row>
                  <Col md={12} style={{ paddingTop: 30 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Payment Type Status (Paid/Unpaid)</Form.Label>
                        <Input
                          className="custom-control-input"
                          id="status"
                          checked={payment_status}
                          onChange={async (e) => {
                            await this.onChange(e.target.checked, "payment_status");
                          }}
                          type="checkbox"
                        />
                      </Form.Group>

                  </Col>
                
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveLeaveType}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddLeaveType;
