import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";

import {  addTimeLog, getTimeLogs } from "../../services/timeLogService";
import SpinDiv from "../components/SpinDiv";
import { AddTimeLog } from "./AddTimeLog";
import moment from "moment";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditTimeLog from "./EditTimeLog";
import DeleteTimeLog from "./DeleteTimeLog";
import ReactDatetime from "react-datetime";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';


export class TimeLogIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      timelogs:[],
      total: 0,
      projects:[],
      members:[],
      project:'',
      member:'',
      fromdate: '',
      todate: '',

    

    };
    this.searchDebounced = debounce(this.searchTimeLogs, 500);
    this.searchThrottled = throttle(this.searchTimeLogs, 500);
  }

  componentDidMount() {
    this.searchTimeLogs();
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }
  

  searchTimeLogs = () => {
    const { page, project, member, rows, search, timelogs, fromdate, todate } = this.state;
    this.setState({ loading: true });
    getTimeLogs({ page, rows, project, member, search, timelogs, fromdate, todate }).then(

      (res) => {
        this.setState({
          timelogs: res.time_logs.data,
          total:res.time_logs.total,
          projects:res.projects,
          members:res.members,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  export = async () => {
    const { page, project, member, search, timelogs, total, fromdate, todate } = this.state;
    const rows=1000;
    if(total<1){console.log(total)
      await toast.dismiss();
      await setTimeout(()=>this.showToast('No timelog report to export.'), 250);
    } else {
      this.setState({ loading: true });
      getTimeLogs({ page, rows, search, project, member, timelogs, fromdate, todate })
        .then(response => {
          let exportt = '';
          exportt = response.time_logs.data.map(c => (
            {employee: c.employee_name, project: c.project_name, task: c.task_name,
              start:moment(c.start_date).format('MMM D, YYYY')+' '+c.start_time, end:moment(c.end_date).format('MMM D, YYYY')+' '+c.end_time,duration:c.duration }
          ));
          

          const theheader = ['employee','project','task','start','end','duration'];
          const wch = [30,20,15,20,40,20,20,20,20];
          const cols = wch.map(h => {return ({wch: h});});
          const thedata = exportt.map(item => {
            return theheader.map(item2 => {
              return item[item2]
            })
          });

          const headerTitle = 'your header title here';
         
          const allofit = [theheader].concat(thedata);

          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

          const wb: XLSX.WorkBook = XLSX.utils.book_new(headerTitle);
          ws['!cols'] = cols;
          XLSX.utils.book_append_sheet(wb, ws, `Timelogs`);
          XLSX.writeFile(wb, `timelog-${fromdate}-${todate}-report.xlsx`);
          this.setState({
            loading: false
          });
        },
        error => {
          this.setState({ loading: false });
        });
    }
  }


 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchTimeLogs();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
  toggleDeleteTimeLog=(deleteTimeLog)=>{
    this.setState({deleteTimeLog});
  }

  toggleEditTimeLog = (timelog) => {
    this.setState({ timelog});
    this.searchTimeLogs();
   
  };
  toggleCloseTimeLog=(timelog)=>{
    this.setState({ timelog: !this.state.timelog });
    this.searchTimeLogs();
  }

 

  toggleAddTimeLog = () => {
    this.setState({ addTimeLog: !this.state.addTimeLog });
    this.searchTimeLogs();
  }

  
  



  formatCurrency(x) {
    if (x !== null && x !== '0' && x !== undefined) {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${parts.join(".")}`;
    }
    return '0';
  }

  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  }

  onFilter = async (e, filter) => {
    console.log(filter);
    await this.setState({ [filter]: e });
    await this.searchTimeLogs();
  }




  

  render() {
    const { fromdate, todate, timelogs, showFilter, projects, members, member, project, timelog, deleteTimeLog, total, page, rows, search, loading,addTimeLog, editTimeLog } = this.state;
    
    return (
      <>
       
        
        {addTimeLog && (
          <AddTimeLog
            saved={this.searchTimeLogs}
            projects={projects}
            toggle={this.toggleAddTimeLog}
            
          />
        )}

        {timelog && (
          <EditTimeLog
            saved={this.searchTimeLogs}
            timelog={timelog}
            projects={projects}
            toggle={this.toggleCloseTimeLog}
          />
        )}

        
        {deleteTimeLog && (
          <DeleteTimeLog
            saved={this.searchTimeLogs}
            deleteTimeLog={deleteTimeLog}
            toggle={() => this.setState({ deleteTimeLog: null })}
          />
        )}


      
       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Time Logs</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div style={{padding: '0 15px', alignItems: 'center',  display: 'flex'}}>
                      
                <ReactDatetime
                  value={fromdate}
                  dateFormat={'MMM D, YYYY'}
                  closeOnSelect
                  onChange={e=>this.onFilter(e, 'fromdate')}
                  inputProps={{
                    required: true,
                    className: 'form-control date-filter'
                  }}
                  isValidDate={(current)=>{return (current.isBefore(todate)||current.isSame(todate))&&current.isBefore(moment());}}
                  timeFormat={false}
                />
                <span className="hide-mobile" style={{padding: '0 10px'}}>-</span>
                <ReactDatetime
                  value={todate}
                  dateFormat={'MMM D, YYYY'}
                  closeOnSelect
                  onChange={e=>this.onFilter(e, 'todate')}
                  inputProps={{
                    required: true,
                    className: 'form-control date-filter'
                  }}
                  isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                  timeFormat={false}
                />
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                {!showFilter && <Button
                    variant="outline-primary"
                    onClick={this.toggleFilter}
                    size="mdm"

                  >
                    Filter
                  </Button>}
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddTimeLog()}>
                    Create Time Log
                  </Button>
                  <Button variant="outline-primary" size="sm"  onClick={this.export}>
                    Export TimeLogs
                  </Button>
                </ButtonGroup>
              </div>
              
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Timelog
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>
        <Row>

          {showFilter && <Col md={12}>

            <Row>
              <Col md={3}>
                <span style={{ marginRight: 10, fontSize: 14 }}>Project </span>
                <Form.Group className="mb-2">

                  <Form.Select
                    id="state"
                    required
                    value={project}
                    onChange={e => this.onFilter(e.target.value, 'project')}
                    style={{
                      marginRight: 10,
                      width: "100%",
                    }}
                  >

                    <option value="">Choose Project</option>
                    {projects.length == 0 && <option value="#">loading</option>}
                    {projects.map((p, index) => (
                      <option value={p.id} key={p}>
                        {p.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

              </Col>
              <Col md={3}>
                <span style={{ marginRight: 10, fontSize: 14 }}>Member </span>
                <Form.Group className="mb-2">

                  <Form.Select
                    id="state"
                    required
                    value={member}
                    onChange={e => this.onFilter(e.target.value, 'member')}
                    style={{
                      marginRight: 10,
                      width: "100%",
                    }}
                  >

                    <option value="">Choose Member</option>
                    {members.length == 0 && <option value="">loading</option>}
                    {members.map((p, index) => (
                      <option value={p.id} key={p}>
                        {p.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              
            </Row>
            <Row>
              <Col md={10}>

              </Col>
              <Col md={2}>
              <Button
                color="warning"
                onClick={this.toggleFilter}
                size="sm"
                style={{ marginRight: 10 }}
              >
                Hide Filters
              </Button>
              </Col>
            </Row>
          </Col>}

        </Row>
        

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                <th className="border-0">Employee</th>
                  <th className="border-0">Project</th>
                  <th className="border-0">Task</th>
                  <th className="border-0">Start Date</th>
                  <th className="border-0">End Date</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">Duration</th>
                 
                  <th className="border-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                
                {timelogs.map((timelog, key) => {
               
                  return (
                    <tr >
                      <th scope="row">
                      <td>
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#p"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                maxHeight: 50,
                                maxWidth: 50,
                                borderRadius: '100%',
                              }}
                              alt="..."
                              src={
                                timelog.image ||
                                require("../../assets/img/brand/user.jpg")
                              }
                            />
                           
                          </a>
                          </Media>
                        </td>
                        <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{timelog.employee_name}</span>
                      </td>
                        
                      </th>
                    
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{timelog.project_name}</span>
                      </td>
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{timelog.task_name}</span>
                      </td>
                    
                      <td style={{fontWeight:"bold",textTransform:"capitalize"}}>{moment(timelog.start_date).format('MMM D, YYYY')}{' '+timelog.start_time}</td>
                      <td style={{fontWeight:"bold",textTransform:"capitalize"}}>{moment(timelog.end_date).format('MMM D, YYYY')}{' '+timelog.end_time}</td>
                      
                      <td style={{fontWeight:"bold",textTransform:"capitalize"}}>
                        {timelog.status == 1 ? <><span style={{color:'green'}}>●</span><span>Approved</span> </> : <><span style={{color:'orange'}}>●</span><span>Pending</span> </>}
                      </td>
                      <td style={{fontWeight:"bold",textTransform:"capitalize"}}>{timelog.duration}Hrs</td>
                    
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" 
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          onClick={() => this.toggleEditTimeLog(timelog)} size="sm">view</Button>
                          <Button
                            variant="outline-danger"

                            size="sm"
                            onClick={() => this.toggleDeleteTimeLog(timelog)}

                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {timelogs.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Timelogs Created
                </div>}
              {timelogs.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} timelogs`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default TimeLogIndex;
