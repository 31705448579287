import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import ReactDatetime from "react-datetime";
import { toast } from 'react-toastify';
import { addTask, getTasks } from "../../services/taskService";
import SpinDiv from "../components/SpinDiv";
import { AddTask } from "./AddTask";
import moment from "moment";
import { throttle, debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditTask from "./EditTask";
import DeleteTask from "./DeleteTask";
import * as XLSX from 'xlsx';

export class TaskIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      tasks: [],
      members: [],
      projects: [],
      project: '',
      member: '',
      total: 0,
      showFilter: false,
      start_date: '',
      end_date:'',
      order:'',
      fromdate: '',
      todate: '',



    };
    this.searchDebounced = debounce(this.searchTasks, 500);
    this.searchThrottled = throttle(this.searchTasks, 500);
  }

  componentDidMount() {
    this.searchTasks();
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }



  searchTasks = () => {
    const { page, order, member, fromdate, todate, start_date, end_date, project, rows, search, tasks } = this.state;
    this.setState({ loading: true });
    getTasks({ page, order, member,start_date, end_date, project, rows, search, tasks,  fromdate, todate, }).then(

      (res) => {
        console.log(res)
        this.setState({
          tasks: res.tasks.data,
          total: res.tasks.total,
          projects:res.projects,
          members:res.members,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  export = async () => {
    const { page, order, member, total, start_date, end_date, todate, fromdate, project, search, tasks } = this.state;
    const rows=1000;
    if(total<1){console.log(total)
      await toast.dismiss();
      await setTimeout(()=>this.showToast('No tasks to export.'), 250);
    } else {
      this.setState({ loading: true });
      getTasks({page, order, member,start_date, end_date,todate, fromdate, project, rows, search, tasks})
        .then(response => {
          let exportt = '';
          exportt = response.tasks.data.map(c => (
            {project: c.project_name, task: c.title, status: c.status == 1 ? 'Completed' : 'In Progress', startdate: c.start_date, deadline: c.deadline }
          ));

          const theheader = ['project','task','status','startdate','deadline'];
          const wch = [30,20,15,20,40,20,20,20,20];
          const cols = wch.map(h => {return ({wch: h});});
          const thedata = exportt.map(item => {
            return theheader.map(item2 => {
              return item[item2]
            })
          });

          const headerTitle = 'your header title here';
         
          const allofit = [theheader].concat(thedata);

          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

          const wb: XLSX.WorkBook = XLSX.utils.book_new(headerTitle);
          ws['!cols'] = cols;
          XLSX.utils.book_append_sheet(wb, ws, `Tasks`);
          XLSX.writeFile(wb, `task-${fromdate}-${todate}-report.xlsx`);
          this.setState({
            loading: false
          });
        },
        error => {
          this.setState({ loading: false });
        });
    }
  }

  toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  }

  onFilter = async (e, filter) => {
    console.log(filter);
    await this.setState({ [filter]: e });
    await this.searchTasks();
  }




  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page, rows) => {
    await this.setState({ page, rows });
    await this.searchTasks();
  }

  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if (this.state.search < 5) {
        this.searchThrottled(this.state.search);
      } else {
        this.searchDebounced(this.state.search);
      }

    });
  };

  toggleDeleteTask = (deleteTask) => {
    this.setState({ deleteTask });
  }

  toggleEditTask = (task) => {
    this.setState({ task });
    this.searchTasks();

  };
  toggleCloseTask = (task) => {
    this.setState({ task: !this.state.task });
    this.searchTasks();
  }



  toggleAddTask = () => {
    this.setState({ addTask: !this.state.addTask });
    this.searchTasks();
  }






  formatCurrency(x) {
    if (x !== null && x !== '0' && x !== undefined) {
      const parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${parts.join(".")}`;
    }
    return '0';
  }






  render() {
    const { tasks, showFilter,order, end_date, todate, fromdate,  start_date, projects, members, project, member, task, deleteTask, total, page, rows, search, loading, addTask, editTask } = this.state;

    return (
      <>


        {addTask && (
          <AddTask
            saved={this.searchTasks}
            projects={projects}
            toggle={this.toggleAddTask}

          />
        )}

        {task && (
          <EditTask
            saved={this.searchTasks}
            task={task}
            projects={projects}
            toggle={this.toggleCloseTask}
          />
        )}


        {deleteTask && (
          <DeleteTask
            saved={this.searchTasks}
            deleteTask={deleteTask}
            toggle={() => this.setState({ deleteTask: null })}
          />
        )}




        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Tasks</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div style={{padding: '0 15px', alignItems: 'center',  display: 'flex'}}>
                      
                      <ReactDatetime
                        value={fromdate}
                        dateFormat={'MMM D, YYYY'}
                        closeOnSelect
                        onChange={e=>this.onFilter(e, 'fromdate')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-filter'
                        }}
                        isValidDate={(current)=>{return (current.isBefore(todate)||current.isSame(todate))&&current.isBefore(moment());}}
                        timeFormat={false}
                      />
                      <span className="hide-mobile" style={{padding: '0 10px'}}>-</span>
                      <ReactDatetime
                        value={todate}
                        dateFormat={'MMM D, YYYY'}
                        closeOnSelect
                        onChange={e=>this.onFilter(e, 'todate')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-filter'
                        }}
                        isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                        timeFormat={false}
                      />
                    </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  {!showFilter && <Button
                    variant="outline-primary"
                    onClick={this.toggleFilter}
                    size="mdm"

                  >
                    Filter
                  </Button>}
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddTask()}>
                    Create Task
                  </Button>
                  <Button variant="outline-primary" size="sm"  onClick={this.export}>
                    Export Task 
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
            <h5 className="mb-0">Tasks
              <span style={{ color: '#aaa', fontSize: 14, fontWeight: 'normal' }}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}

              />
            </div>
          </Col>
        </Row>
        <Row>

          {showFilter && <Col md={12}>
          <Row>
              <Col md={3}>
                <span style={{ marginRight: 10, fontSize: 14 }}>Project </span>
                <Form.Group className="mb-2">

                  <Form.Select
                    id="state"
                    required
                    value={project}
                    onChange={e => this.onFilter(e.target.value, 'project')}
                    style={{
                      marginRight: 10,
                      width: "100%",
                    }}
                  >

                    <option value="">Choose Project</option>
                    {projects.length == 0 && <option value="#">loading</option>}
                    {projects.map((p, index) => (
                      <option value={p.id} key={p}>
                        {p.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

              </Col>
              <Col md={3}>
                <span style={{ marginRight: 10, fontSize: 14 }}>Member </span>
                <Form.Group className="mb-2">

                  <Form.Select
                    id="state"
                    required
                    value={member}
                    onChange={e => this.onFilter(e.target.value, 'member')}
                    style={{
                      marginRight: 10,
                      width: "100%",
                    }}
                  >

                    <option value="">Choose Member</option>
                    {members.length == 0 && <option value="">loading</option>}
                    {members.map((p, index) => (
                      <option value={p.id} key={p}>
                        {p.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              
            </Row>
            
            <Row>
              <Col md={10}>

              </Col>
              <Col md={2}>
              <Button
                color="warning"
                onClick={this.toggleFilter}
                size="sm"
                style={{ marginRight: 10 }}
              >
                Hide Filters
              </Button>
              </Col>
            </Row>
          </Col>}

        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>

                  <th className="border-0">Title</th>
                  <th className="border-0">Project</th>

                  <th className="border-0">Start Date</th>
                  <th className="border-0">End Date</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">actions</th>
                </tr>
              </thead>
              <tbody>

                {tasks.map((task, key) => {

                  return (
                    <tr >

                      <td>
                        <span style={{ fontWeight: "bold" }}>{task.title}</span>
                      </td>
                      <td>
                        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{task.project_name}</span>
                      </td>

                      <td>{moment(task.start_date).format('MMM D, YYYY')}</td>
                      <td>{moment(task.deadline).format('MMM D, YYYY')}</td>
                      <td>{task.status == 1 ? 'Completed' : 'In Progress'}</td>

                      <td>

                        <ButtonGroup>
                          <Button variant="outline-primary"
                            style={{ fontWeight: "bold", textTransform: "capitalize" }}
                            onClick={() => this.toggleEditTask(task)} size="sm">view</Button>
                          <Button
                            variant="outline-danger"

                            size="sm"
                            onClick={() => this.toggleDeleteTask(task)}

                          >
                            Delete
                          </Button>
                        </ButtonGroup>
                      </td>



                    </tr>
                  );
                })}
              </tbody>

            </Table>
            <Row>
            {tasks.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Tasks Assigned yet
                </div>}
              <Col md={12} style={{ fontWeight: "bold", paddingTop: 3 }}>
                {tasks.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} Tasks`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default TaskIndex;
