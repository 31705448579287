
import DashboardOverview from "./pages/dashboard/DashboardOverview";
import Index from "./pages/users/Index";
import EmployeeIndex from "./pages/employee/Index";
import AwardIndex from "./pages/award/AwardIndex";
import { faBook, faBoxOpen, faChartPie, faShoppingBag,faCog, faFileAlt, faHandHoldingUsd, faIcons, faSignOutAlt, faTable, faTimes, faUsers, faTruck, faTruckLoading, faTruckMoving, faThermometer, faRuler, faCalculator, faStepForward, faCalendar, faAward, faHome } from "@fortawesome/free-solid-svg-icons";
import { faFacebookMessenger, faHackerrank, faSpeakap, faSpeakerDeck, faViacoin } from "@fortawesome/free-brands-svg-icons";
import MessagesIndex from "./pages/message/MessagesIndex";
import NoticeIndex from "./pages/notice/NoticeIndex";
const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]



export let routes = [
    
    { path: "/", component: DashboardOverview, title:"Dashboard",icon: faHome,},

  ...(permissions.includes('show user') === true ?  [ { path: "/admin/users",component: Index, title:"Users",icon:faUsers }] : []),
    { path: "/admin/notice",component: NoticeIndex, title:"Announcements",icon:faViacoin },
    ...(permissions.includes('show employee') === true ?  [  { path: "/admin/employees",component: EmployeeIndex, title:"Employees",icon:faUsers }] : []),
   
    // { path: "/awards",component: AwardIndex, title:"Award",icon:faAward },
   
    

  ];
