import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { addTraining} from "../../services/trainingService";
import { getTrainers } from "../../services/trainerService";
import AsyncSelect from "react-select/async";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Progress, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { getBranches } from "../../services/branchService";
import {getTrainingTypes} from "../../services/trainingTypeService"
import { editTraining } from "../../services/trainingService";

export class EditTraining extends Component {
  constructor(props) {
    super(props);
    this.state = {
    
      employees: props.employees,
      branches: props.branches,
      trainers:props.trainers,
      training_types:props.training_types,
      start_date: moment().startOf('month'),
      end_date: moment().endOf('month'),
      id: props.training.id,
      training: props.training,
      progress:props.training.progress,
      user:JSON.parse(localStorage.getItem('user')),


    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
   
  }

 
  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })
  }

  onSaveTraining = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {branch_id, milestone, trainer_id, trainer_option, training_type_id,
     title, start_date, end_date, employee_id}=this.state.training;
    const {  validation, progress } = this.state;
    await this.setState({
      validation: {
        ...validation,
        employee_id: employee_id !== '' && employee_id !== undefined,
        training_type_id: training_type_id !== '' && training_type_id !== undefined,
        trainer_id: trainer_id !== '' && trainer_id !== undefined,
        branch_id: branch_id !== '' && branch_id !== undefined,
        start_date: start_date !== '' && start_date !== undefined,
        end_date: end_date !== '' && end_date !== undefined,
        milestone: milestone !== '' && milestone !== undefined,
        progress: progress !== '' && progress !== undefined && progress !== 0,
        trainer_option: trainer_option !== '' && trainer_option !== undefined,
         title: title !== '' && title !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveTraining();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field === "employee_id") {
      return "Employee Name  is required";
    } else if (field == 'training_type_id') {
      return "Training type is required"
    } else if (field == 'training_option') {
      return "Training option is required"
    } else if (field == 'start_date') {
      return "Start Date is required"
    } else if (field == 'end_date') {
      return "End Date is required"
    } else if (field == 'milestone') {
      return "milestone is required"
    } else if (field == 'trainer_id') {
      return "Trainer is required"
    }else if (field == 'trainer_option') {
      return "Trainer option is required"
    } else if (field == 'title') {
      return "Title is required"
    }else if (field == 'branch_id') {
      return "Branch is required"
    }else if (field == 'progress') {
      return "Progress is required"
    }
  };




  saveTraining = () => {
    this.setState({ saving: true });
    const { training, progress,id } = this.state;
    console.log();
    editTraining({
      employee_id: training.employee_id,
      trainer_id: training.trainer_id,
      training_type_id: training.training_type_id,
      branch_id: training.branch_id,
      milestone: training.milestone,
      progress: progress,
      training_option: training.training_option,
      title: training.title,
      id: id,
      start_date: training.start_date,
      end_date: training.end_date,

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Training has been updated");
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('An error occured')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };

  

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { training } = this.state

    this.setState({ training: { ...training, [state]: e } })
  }

  onChange2 = (e, state) => {
    this.setState({ [state]: e });
  };

  increase = () => {
   
    let progress = this.state.progress + 10;
    if (progress > 100) {
      progress = 100;
    }
    this.setState({ progress });
  };

  decline = () => {
    
    let progress = this.state.progress - 10;
    if (progress < 0) {
      progress = 0;
    }
    this.setState({ progress });
  };

  

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };






  render() {

    const { toggle } = this.props;

    const { saving, edit, user, training, branches, progress, employees,training_types, trainers, loading } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={training != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            {user.admin == 1 && <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {training && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit training"}



                  </Button>
                )}
              </ButtonGroup>
            </div>}

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            <Row>
              <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      disabled={!edit}
                      placeholder='Enter Title'
                      value={training.title}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>
            <Row>
            <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Employee</Form.Label>

                    <Form.Select
                      id="state"
                      disabled={!edit}
                      required
                      value={training.employee_id}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "employee_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Employee</option>
                      {employees.length == 0 && <option value="">loading</option>}
                      {employees.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </Col>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Training Type</Form.Label>

                    <Form.Select
                      id="state"
                      required
                      disabled={!edit}
                      value={training.training_type_id}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "training_type_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Choose Training Type</option>
                      {training_types.length == 0 && <option value="">loading</option>}
                      {training_types.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </Col>

              </Row>
              <Row>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Trainer</Form.Label>

                    <Form.Select
                      id="state"
                      required
                      disabled={!edit}
                      value={training.trainer_id}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "trainer_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Choose Trainer</option>
                      {trainers.length == 0 && <option value="">loading</option>}
                      {trainers.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </Col>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Branch</Form.Label>

                    <Form.Select
                      id="state"
                      required
                      disabled={!edit}
                      value={training.branch_id}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "branch_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Choose Branch</option>
                      {branches.length == 0 && <option value="">loading</option>}
                      {branches.map((p, index) => (
                        <option value={p.id} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Training Option</Form.Label>

                    <Form.Select
                      id="state"
                      required
                      disabled={!edit}
                      value={training.trainer_option}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "training_option");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Choose Branch</option>
                      <option value="Internal">Internal</option>
                      <option value="External">External</option>

                    </Form.Select>
                  </Form.Group>

                </Col>
                
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                        disabled={!edit}
                      value={moment(training.start_date).format('MMM DD, YYYY') || ''}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      End Date
                    </label>
                    <ReactDatetime
                      value={moment(training.start_date).format('MMM DD, YYYY') || ''}
                      disabled={!edit}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'end_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>milestone</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="reason"
                      disabled={!edit}
                      placeholder='Enter milestone'
                      value={training.milestone}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "milestone");
                      }}
                      type="textarea"
                    />
                  </Form.Group>

                </Col>
                <Col md={6} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Training Progress</Form.Label>
                    <Progress type="circle"  onChange2={e=>this.onChange(e, 'progress')} value={progress || 0} percent={progress} />
                    <Button.Group>
                      <Button   disabled={!edit} onClick={this.decline} icon={<MinusOutlined />} />
                      <Button   disabled={!edit} onClick={this.increase} icon={<PlusOutlined />} />
                    </Button.Group>
                  </Form.Group>

                </Col>


              </Row>
              <Row>

              </Row>
              <Row style={{ marginTop: "10px" }}>
                {user.admin == 1 && <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="md"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveTraining}
                    >
                      Save
                    </Button>
                    <Button
                      size="md"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>}
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditTraining;
