import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import { getInvoices, getClients, getEmployees } from "../../services/invoiceService";
import SpinDiv from "../components/SpinDiv";
import AddInvoice from "./AddInvoice";
import { throttle, debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditInvoice from "./EditInvoice";
import moment from "moment";
import ReactDatetime from "react-datetime";
import DeleteInvoice from "./DeleteInvoice";
import { AsyncPaginate } from "react-select-async-paginate";
import { currencies } from "./Currency";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

export class InvoiceIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      user: JSON.parse(localStorage.getItem('user')),
      setFiltering: false,
      invoices: [],
      clients: [],
      order: '',
      employee_id: '',
      employees: [],
      total_balance: '',
      total_sales: '',
      total: 0,
      fromdate: moment().startOf('month'),
      todate: moment().endOf('day'),
      currencies: currencies,
      currency:''


    };
    this.searchDebounced = debounce(this.searchInvoices, 500);
    this.searchThrottled = throttle(this.searchInvoices, 500);
  }

  componentDidMount() {
    const {user} = this.state;
    this.getInvoices();
    user.admin == 1 && (this.getClients() )
    user.admin == 1 && (this.getEmployees())
  }

  getClients = (page, search) => {
    const { rows } = this.state;
    getClients({ rows, page, search }).then(
      (res) => {
        this.setState({
          clients: res.clients.data.map((opt) => ({
            label: opt.name,
            value: opt.id,
          })),

        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getEmployees = (rows, page, search) => {

    getEmployees({ rows, page, search }).then(
      (res) => {
        console.log(res)
        this.setState({
          employees: res.employees.data.map((opt) => ({
            label: opt.name,
            value: opt.id,
          })),
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

    


  loadClients = (data) => async (search, loadedOptions, { page }) => {
    await this.sleep(1000);
    const { rows } = this.state;
    await this.getClients(page, search)
    console.log(data)
    //const new_data = {data}
    let new_clients = [{ label: "All Clients", value: "" }, ...data]
    return {
      options: new_clients,
      hasMore: data.length >= 10,
      additional: {
        page: search ? 2 : page + 1,
      },
    };


  };


  loadEmployees = (data) => async (search, prevOptions, { page }) => {
    await this.sleep(1000);
    const { rows } = this.state;
    await this.getEmployees(rows, page, search)
    let new_employees = [{ label: "All employees", value: "" }, ...data]
    return {
      options: new_employees,
      hasMore: data.length >= 1,
      additional: {
        page: search ? 2 : page + 1,
      },
    };



  };


  getInvoices = () => {
    const { page, rows, user, search, invoices, currency, order, employee_id, fromdate, todate } = this.state;
    console.log(employee_id)
    this.setState({ loading: true });
    getInvoices({ page, rows, search, invoices, currency, order, employee_id, fromdate, todate }).then(
      (res) => {
        this.setState({
          invoices: res.invoices.data,
          page: res.invoices.current_page,
          total: res.invoices.total,
          total_sales: res.total_sales,
          total_balance: res.total_balance,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  searchInvoices = () => {
    const { page, rows, search, employee_id, invoices } = this.state;
    this.setState({ loading: false });
    getInvoices({ page, rows, employee_id, search, invoices }).then(
      (res) => {
        this.setState({
          invoices: res.invoices.data,
          page: res.invoices.current_page,
          total: res.invoices.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  export = async () => {
    const { page, search, total, invoices, currency, order, employee_id, fromdate, todate } = this.state;
    const rows=1000;
    if(total<1){console.log(total)
      await toast.dismiss();
      await setTimeout(()=>this.showToast('No income history to export.'), 250);
    } else {
      this.setState({ loading: true });
     
      getInvoices({  page, rows, search, invoices, currency, order, employee_id, fromdate, todate })
        .then(response => {
          let exportt = '';
          exportt = response.invoices.data.map(c => (
            {client: c.client_name, project: c.project_title, currency: c.currency, 
              amount:this.formatCurrency(c.amount), balance:c.total_balance,
               issuedDate:moment(c.issued_date).format('MMM DD YYYY'),
               dueDate:moment(c.due_date).format('MMM DD YYYY')}
          ));

          const theheader = ['client','project','currency', 'amount','balance', 'issuedDate','dueDate'];
          const wch = [30,20,15,20,40,20,20,20,20];
          const cols = wch.map(h => {return ({wch: h});});
          const thedata = exportt.map(item => {
            return theheader.map(item2 => {
              return item[item2]
            })
          });

          const headerTitle = 'your header title here';
         
          const allofit = [theheader].concat(thedata);

          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

          const wb: XLSX.WorkBook = XLSX.utils.book_new(headerTitle);
          ws['!cols'] = cols;
          XLSX.utils.book_append_sheet(wb, ws, `Invoice`);
          XLSX.writeFile(wb, `income-history-${fromdate}-${todate}-.xlsx`);
          this.setState({
            loading: false
          });
        },
        error => {
          this.setState({ loading: false });
        });
    }
  }

  onFilter = async (e, filter) => {

    await this.setState({ [filter]: e });
    await this.getInvoices();
  };

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }





  toggleEdit = (editInvoice) => {
    this.setState({ editInvoice });
  };


  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onChange2 = async (e, state) => {
    await this.setState({ [state]: e });
    await this.getInvoices()
  };

  onPage = async (page, rows) => {
    await this.setState({ page, rows });
    await this.getInvoices();
  }

  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if (this.state.search < 5) {
        this.searchThrottled(this.state.search);
      } else {
        this.searchDebounced(this.state.search);
      }

    });
  };


  toggleAddInvoice = () => {
    this.setState({ addInvoice: !this.state.addInvoice });
    this.getInvoices()
  };

  toggleEditInvoice = () => {
    this.setState({ editInvoice: !this.state.editInvoice });
    this.getInvoices()
  }
  toggle = () => {
    this.setState({ deleteInvoice: !this.state.deleteInvoice });
  }

  formatCurrency(x) {
    if (x !== null && x !== 0 && x !== undefined) {
      const parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${parts.join(".")}`;
    }
    return '0';
  }



  toggleDeleteInvoice = (deleteInvoice) => {
    this.setState({ deleteInvoice });
  }

  handleEmployeeChange = async (cashier) => {
    await this.setState({ employee_id: cashier.value });
    await this.getInvoices();

  }

  handleClientChange = async (client) => {
    await this.setState({ order: client.value });
    await this.getInvoices();

  }



  render() {
    const { todate, fromdate, user, currencies, setFiltering, company, total_sales, total_balance, order, currency, employees, clients, invoices, total, page, rows, search, loading, addInvoice, editInvoice, deleteInvoice, roles } = this.state;
    console.log(setFiltering)
    return (
      <>
        {addInvoice && (
          <AddInvoice
            saved={this.getInvoices}
            addInvoice={addInvoice}
            toggle={this.toggleAddInvoice}

          />
        )}
        {deleteInvoice && (
          <DeleteInvoice
            saved={this.getInvoices}
            invoice={deleteInvoice}
            toggle={this.toggle}

          />
        )}
        {/* {editInvoice && (
          <EditInvoice
            saved={this.getInvoices}
            paymentss={editInvoice}
            toggle={this.toggleEditInvoice}
          />
        )} */}
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#Invoices">Invoices</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                {user.admin == 1 &&   <Button variant="outline-primary" size="sm"  onClick={this.export}>
                    Export Invoice
                  </Button>}
                
         
                </ButtonGroup>
              </div>
              
            </div>
          </Col>
         

        </Row>
        <Row>
          <Col md="2">
            <h5 className="mb-0">Invoices
              <span style={{ color: '#aaa', fontSize: 14, fontWeight: 'normal' }}> ({total})</span></h5>
          </Col>
          <Col md={3}>
            <ReactDatetime
              value={setFiltering === false ? fromdate : todate}
              dateFormat={'MMM D, YYYY'}
              closeOnSelect

              onChange={e => this.onFilter(e, 'fromdate')}
              inputProps={{
                disabled: setFiltering,
                className: 'form-control date-filter'
              }}
              isValidDate={(current) => { return (current.isBefore(todate) || current.isSame(todate)) && current.isBefore(moment()); }}
              timeFormat={false}
            />


          </Col>

          <Col md={3}>

            <ReactDatetime
              value={todate}
              dateFormat={'MMM D, YYYY'}
              closeOnSelect
              onChange={e => this.onFilter(e, 'todate')}
              inputProps={{

                required: true,
                className: 'form-control date-filter'
              }}
              isValidDate={(current) => { return (current.isAfter(fromdate) || current.isSame(fromdate)) && current.isBefore(moment()); }}
              timeFormat={false}
            />-
          </Col>

          <Col md="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}

              />

            </div>
          </Col>
        </Row>
        {user.admin == 1 && (<>
          <Row>
        <Col md={4} style={{ marginBottom: 20, }}>
            <Form.Group className="mb-2">
            <span style={{ fontSize: 14 }}>Filter By Currency</span>

              <Form.Select
                onChange={async (e) => {
                  await this.onChange2(e.target.value, "currency");
                }}
                style={{
                  marginRight: 10,
                  width: "100%",
                }}
              >

                <option value="">Select Currency</option>
                {currencies.length == 0 && ''}
                {currencies.map((p, index) => (
                  <option value={p.abbrev} key={p}>
                    {p.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          {user.admin === 1 && <Col md={4}>
            <span style={{ fontSize: 14 }}>Filter By Employee</span>
            <AsyncPaginate
              onChange={this.handleEmployeeChange}
              loadOptions={this.loadEmployees(employees)}
              additional={{
                page: 1,
                type: 'employee'
              }}

            />
          </Col>}
          <Col md={4}>
            <span style={{ fontSize: 14 }}>
              Filter By Clients:{" "}
            </span>
            <AsyncPaginate
              onChange={this.handleClientChange}
              loadOptions={this.loadClients(clients)}
              additional={{
                page: 1,
              }}

            />

          </Col>
        </Row>
        <Row>
          
          {currency &&<>
            <Col md={4}>
            <h5 style={{ fontWeight: 'bold' }}>Total Sales: {currency}{this.formatCurrency(total_sales)}</h5>
          </Col>
          <Col md={4}>
            <h5 style={{ fontWeight: 'bold' }}>Total Balance: {currency}{this.formatCurrency(total_balance)}</h5>
          </Col>
          </>}
        </Row>
        </>)}
        

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Invoice No</th>
                  {user.admin == 1 && <th className="border-0">Client Name</th>}
                  {user.admin !== 1 && <th className="border-0">Project Title</th>}

                  <th className="border-0">Total Purchase</th>
                  <th className="border-0">Balance</th>
                  <th className="border-0">Issue Date</th>
                  <th className="border-0">Due Date</th>
                </tr>
              </thead>
              <tbody>

                {invoices.map((invoice, key) => {

                  return (
                    <tr style={{ fontWeight: "bold" }}>

                      <td >{invoice.invoice_no}</td>
                      {user.admin == 1 &&  <td >{invoice.client_name}</td>}
                      {user.admin !== 1 &&  <td >{invoice.project_title}</td>}
                      <td >{invoice.currency}{this.formatCurrency(invoice.amount)}</td>
                      <td >{invoice.currency}{this.formatCurrency(invoice.total_balance)}</td>
                      <td>{moment(invoice.issued_date).format('MMM DD YYYY')}</td>

                      <td>{moment(invoice.due_date).format('MMM DD YYYY')}</td>

                      <td>
                        <ButtonGroup>
                          <Button
                            variant="outline-primary"
                            //onClick={() => this.toggleEdit(invoice)}
                            onClick={() => {//console.log('111')
                              this.props.history.push('/invoice/' + invoice.project_id)
                            }}
                            size="sm"
                          >
                            View
                          </Button>
                         {user.admin == 1 &&  <Button
                            variant="outline-danger"
                            //onClick={() => this.toggleEdit(invoice)}
                            onClick={() => {//console.log('111')
                              this.toggleDeleteInvoice(invoice)
                            }}
                            size="sm"
                          >
                            Delete
                          </Button>}
                        </ButtonGroup>
                      </td>

                    </tr>
                  );
                })}
              </tbody>

            </Table>
            <Row>
              <Col md={12} style={{ fontWeight: "bold", paddingTop: 3 }}>
              {invoices.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Invoices yet
                </div>}
                {invoices.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} Invoices`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>

          </Card.Body>
        </Card>
      </>
    );
  }
}

export default InvoiceIndex;
