import React, { Component } from 'react'
import "./style.css";
import axios from 'axios'

import settings from "../../../services/settings";
import { authHeader } from "../../../services/authHeader";
import { authService } from "../../../services/authService";
import SpinDiv from "../../components/SpinDiv";
import { toast } from "react-toastify";
import { debounce, throttle } from '../../users/debounce';
import { getAdmins, getMessages } from '../../../services/clientMessageService';
import { Pagination } from 'antd';
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
} from "@themesberg/react-bootstrap";
import { Input } from 'reactstrap'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Placeholder from  "../../../assets/img/team/placeholder.png";


export class MessagesIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
            page: 1,
            rows: 20,
            userid:'',
            messages: [],
            admins: [],
            search:'',
            search_message:'',
            total: 0,
            client_name: '',
            admin_image:'',
            documents: [],
            total_admins: 0,
            loading: false,
            saving:false,
            message: '',
            user: JSON.parse(localStorage.getItem('user')),

        };
        this.searchDebouncedMessage = debounce(this.searchMessages, 500);
        this.searchThrottledMessage = throttle(this.searchMessages, 500);

        this.searchDebouncedClient = debounce(this.searchAdmins, 500);
        this.searchThrottledClient = throttle(this.searchAdmins, 500);
    }

    handleSearchAdmin = event => {
        this.setState({ search: event.target.value }, () => {
            if (this.state.search < 5) {
                this.searchThrottledClient(this.state.search);
            } else {
                this.searchDebouncedClient(this.state.search);
            }

        });
    };

    handleSearchMessage = event => {
        this.setState({ search_message: event.target.value }, () => {
            if (this.state.search < 5) {
                this.searchThrottledMessage(this.state.search_message,);
            } else {
                this.searchDebouncedMessage(this.state.search_message);
            }

        });
    };

    

    handleRemoveItem = (idx) => () => {
        this.setState({ documents: this.state.documents.filter((s, sidx) => idx !== sidx) });
      }

    onChange = (e, state) => {
        this.setState({ [state]: e });
    };

    componentDidMount() {
        this.searchAdmins();
        this.searchMessages();

    }
    async toggleAdminMessage(admin_id, admin_name,admin_image) {
        await this.setState({ userid: admin_id, admin_name: admin_name, admin_image:admin_image })
       await  this.searchMessages();
        this.searchAdmins();
    }

    fileChangedHandler = (e) => {
        this.setState({ documents: [...this.state.documents, ...e.target.files] })
    }

    searchMessages = async () => {
       
        const { page, rows, search_message, userid} = this.state;
         
        this.setState({ loading: true });
        await getMessages({ page, rows, search_message, userid }).then(
            (res) => {
                this.setState({
                    messages: res.messages.data.reverse(),
                    page: res.messages.current_page,
                    total: res.messages.total,
                    loading: false,
                });
                setTimeout(() => this.scrollToBottom(), 100);
            },

            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }

    sendMessage = () => {
        this.setState({ saving: true });
        const { userid, message, documents } = this.state;
        let data = new FormData();
        data.set("message", message);
        data.set("receiver_id", userid);
        console.log(documents);

        if (documents.length > 0) {
            let op = documents;
            for (var i in op) {
                data.append(`documents[${i}]`, op[i])
            }
        }
        return axios
            .post(
                `${settings.API_URL}client/send/message`,
                data,
                {
                    headers: authHeader(),
                },
                authService.handleResponse
            ).then(
                (res) => {

                    setTimeout(() => this.scrollToBottom(), 100);
                    this.setState({ message: '', saving: false, documents:[] });
                    this.searchMessages(userid)
                    //   this.showToast("Message Sent");
                },
                (err) => {

                    if (err) {
                        toast.dismiss();
                        toast.configure({ hideProgressBar: true, closeButton: false });
                        if (err.errors) {
                            this.showToastError('Wrong File Type')
                        }
                        this.setState({ saving: false });
                    }
                    this.setState({ loading: false });
                }
            );



    };

    validationRules = (field) => {
        if (field === "userid") {
            return "admin ID is required";
        } else if (field == 'message') {
            return "Message is required"
        }
    };
    onSendMessage = async (e) => {
        e.preventDefault();

        await toast.dismiss();
        const { message, userid, document, validation } = this.state;

        await this.setState({
            validation: {
                ...validation,
                userid: userid !== '' && userid !== undefined,
                message: message !== '' && message !== undefined,
            },
        });
        if (Object.values(this.state.validation).every(Boolean)) {
            this.sendMessage();
        } else {
            const errors = Object.keys(this.state.validation).filter((id) => {
                return !this.state.validation[id];
            });
            //await toast.configure({hideProgressBar: true, closeButton: false});
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });

            toast(
                <div style={{ padding: "10px 20px" }}>
                    <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
                    {errors.map((v) => (
                        <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
                            * {this.validationRules(v)}
                        </p>
                    ))}
                </div>
            )
        }
    };


    searchAdmins = () => {
        const { page, rows, search } = this.state;
        this.setState({ loading: true });
        getAdmins({ page, rows, search }).then(
            (res) => {
                this.setState({
                    admins: res.admins.data,
                    page: res.admins.current_page,
                    total_admins: res.admins.total,
                    loading: false,
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };

    onPage = async (page, rows) => {
        await this.setState({ page, rows });
        await this.searchAdmins();
    }



    render() {
        const { admins, rows, total_admins, user, search_message, loading, saving, total, admin_name, admin_image, message, userid, search, page, messages } = this.state;
        { console.log(messages) }

        return (
            <div className="container">
                <div className="row clearfix">
                    <div className="col-lg-12">
                        <div className="card chat-app">
                            
                        <div id="plist" className="people-list">
                                <div className="input-group">
                                    <Input
                                        placeholder="Search..."
                                        autoFocus
                                        id="show"
                                        value={search}
                                        style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                                        onChange={this.handleSearchAdmin}

                                    />
                                    {/* <input type="text" className="form-control" placeholder="Search..." /> */}
                                </div>
                                <ul className="list-unstyled chat-list mt-2 mb-0" style={{ height: '400px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                    {admins.map((admin, key) => {
                                        return (
                                            <li className="clearfix" onClick={async () => await this.toggleAdminMessage(admin.id, admin.name, admin.image)}>

                                                <img src={admin.image !== null ? admin.image : Placeholder} alt="avatar" />
                                                <div className="about">
                                                    <span size="sm" style={{ borderStyle: 'none' }}


                                                    >
                                                        <div className="name">{admin.name}</div>
                                                        <p style={{margin: 0, fontSize: 11, lineHeight: 1}}>{admin.lastm?moment(admin.lastm).calendar(null, {sameDay: 'HH:mm', lastDay: '[Yesterday]', lastWeek: 'dddd', sameElse: 'MMM D, YYYY'}):'N/A'}</p>

                                                    </span>

                                                    <div className="status"> <i className="fa fa-circle online"></i></div>
                                                </div>
                                            </li>
                                        )
                                    })}



                                </ul>
                                {admins.length > 0 && <Pagination
                                    total={total_admins}
                                    showTotal={total_admins => `${total_admins} admins`}
                                    onChange={this.onPage}
                                    pageSize={rows}
                                    current={page}
                                />}
                            </div>
                            <div className="chat" >
                                <div className="chat-header clearfix">
                                    {userid && <div className="row">
                                        <div className="col-lg-6">
                                            <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                                                <img src={admin_image !== null ? admin_image : Placeholder} alt="avatar" />
                                            </a>
                                            <div className="chat-about">
                                                <h6 className="m-b-0">Admin</h6>
                                                <small>Message {admin_name}</small>
                                            </div>

                                        </div>
                                        <div className="col-lg-6">
                                            <div className='input-group'>
                                            <Input
                                                placeholder="Search..."
                                                autoFocus
                                                id="show"
                                                value={search_message}
                                                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                                                onChange={this.handleSearchMessage}

                                            />

                                            </div>
                                            
                                            {/* <input type="text" className="form-control" placeholder="Search..." /> */}
                                        </div>

                                    </div>}
                                </div>
                                <div className="chat-history"  style={{ height: 500, overflowY: 'scroll', overflowX: 'hidden' }}>
                                    {loading && <SpinDiv text={"Loading..."} />}
                                    {saving && <SpinDiv text={"sending..."} />}
                                    <ul className="m-b-0">
                                        {messages.map((message, key) => {
                                            return (
                                                <li className="clearfix">
                                                   
                                                  
                                                    <div className={user.id == message.sender_id ? `message text-right` : `message-data`} style={{display:'block', marginTop:3}}>
                                                    <div className={user.id == message.sender_id ? `message-data text-right` : `message-data`}>
                                                        <span className="message-data-time">{moment(message.sent_at).format('MMM D, YYYY hh:mm A')}</span>
                                                      
                                                    </div>
                                                    {message.documents !== null && message.documents.map((document, key) => {
                                                            return (
                                                               <div style={{display:'block'}}>
                                                               
                                                                <ButtonGroup>
                                                                  <Button variant="outline-secondary" 
                                                                 
                                                                  
                                                                  style={{fontWeight:"bold",textTransform:"capitalize"}} 
                                                                  size="sm"><a target="_blank" href={document.url} >{ document.name} </a></Button>
                                                                  
                                                                </ButtonGroup>
                                                               </div>
                                            
                                                            )
                                                        })}
                                                    </div>
                                                    {console.log(user.id)}
                                                    {console.log(message.sender_id)}
                                                    <div style={{textAlign:'left'}}className={user.id == message.sender_id ? `message other-message float-right` : `message my-message`}> 
                                                        {message.message} 
                                                        
                                                    </div>
                                                </li>
                                            )
                                        })}




                                    </ul>
                                    {userid && <div className="chat-message clearfix">
                                        <div className="input-group mb-0">
                                            {/* <div className="input-group-prepend">
                                            <span className="input-group-text"><button  onClick={this.onSendMessage} className="fa fa-send"></button></span>
                                        </div>
                                        <input type="text" value={message} onChange={async (e) => {
                                                await this.onChange(e.target.value, "message");
                                            }} className="form-control" placeholder="Enter text here..." /> */}


                                            <Input
                                                placeholder="Type your message..."
                                                autoFocus
                                                id="show"
                                                type='textarea'
                                                value={message}
                                                onChange={async (e) => {
                                                    await this.onChange(e.target.value, "message");
                                                }}
                                                style={{ height:200, marginRight: 5, marginBottom: 10 }}


                                            />
                                            <div className="file-field">
                                                <div className="d-flex justify-content-xl-center ms-xl-3">
                                                    <div className="d-flex">


                                                        <input onChange={this.fileChangedHandler} type="file" multiple />
                                                        <div className="d-md-block text-start">
                                                            <span className="icon icon-md">
                                                                <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                                                            </span>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="company-avatar xl-avatar">

  {this.state.documents.map((file, key) => (
    <Button style={{ paddingBottom: 10 }} variant="outline-primary" key={file.name} onClick={this.handleRemoveItem(key)}>
      {file.name}&nbsp;<span style={{ color: 'red', fontWeight: 'bold', fontSize: 15 }}>x</span>
    </Button>
  ))}

</div>
                                          
                                            <Button
                                                onClick={this.onSendMessage}
                                                className="btn-icon btn-2"
                                                color="secondary"
                                                style={{ maxHeight: 45 }}
                                                size="sm"

                                            >
                                                <i className="fa fa-send" />
                                            </Button>
                                        </div>

                                    </div>}
                                    <div style={{ float: "left", clear: "both" }} ref={(el) => { this.messagesEnd = el; }} />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}

export default MessagesIndex