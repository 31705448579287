
import {faBook, faClock, faHammer, faNetworkWired, faReceipt, faRunning, faScrewdriver, faUsers} from "@fortawesome/free-solid-svg-icons";

import ProjectIndex from "./pages/project/ProjectIndex";
import TaskIndex from "./pages/tasks/TaskIndex";
import TimeLogIndex from "./pages/timelogs/TimeLogIndex";
import ClientIndex from "./pages/clients/ClientIndex"
import MessagesIndex from "./pages/message/MessagesIndex";
import InvoiceIndex from "./pages/invoice/InvoiceIndex";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";

const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]

export let workRoute = [
  ...(permissions.includes('show client') === true ?  [ { path: "/clients",component: ClientIndex, title:"Clients",icon:faUsers }] : []),
  ...(permissions.includes('show project') === true ?  [{ path: "/projects",component: ProjectIndex, title:"Projects", icon:faBook }] : []),
  ...(permissions.includes('show invoice') === true ?  [  { path: "/invoices",component: InvoiceIndex, title:"Invoices",icon:faReceipt },] : []),
  ...(permissions.includes('show task') === true ?  [  { path: "/tasks",component: TaskIndex, title:"Tasks",icon: faScrewdriver}] : []),
  ...(permissions.includes('show timelog') === true ?  [  { path: "/time-logs",component: TimeLogIndex, title:"Time Log",icon:faClock }] : []),
  ...(permissions.includes('show message') === true ?  [  { path: "/admin/messages",component:MessagesIndex, title:"Messages",icon:faFacebookMessenger }] : []),
   
    
  
   
   
   
    
  ];