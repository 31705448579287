import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";

import { getTasks } from "../../../services/employeeWorkService";
import SpinDiv from "../../components/SpinDiv";
import moment from "moment";
import { throttle,debounce } from "../debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditTask from "./EditTask";

export class TaskIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      tasks:[],
      total: 0,

    

    };
    this.searchDebounced = debounce(this.searchTasks, 500);
    this.searchThrottled = throttle(this.searchTasks, 500);
  }

  componentDidMount() {
    this.searchTasks();
  }

  

  searchTasks = () => {
    const { page, rows, search, tasks } = this.state;
    this.setState({ loading: true });
    getTasks({ page, rows, search, tasks }).then(

      (res) => {
        this.setState({
          tasks: res.tasks.data,
          total:res.tasks.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchTasks();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
  toggleDeleteTask=(deleteTask)=>{
    this.setState({deleteTask});
  }

  toggleEditTask = (task) => {
    this.setState({ task});
    this.searchTasks();
   
  };
  toggleCloseTask=(task)=>{
    this.setState({ task: !this.state.task });
    this.searchTasks();
  }

 

  toggleAddTask = () => {
    this.setState({ addTask: !this.state.addTask });
    this.searchTasks();
  }

  
  



  formatCurrency(x) {
    if (x !== null && x !== '0' && x !== undefined) {
        const parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${parts.join(".")}`;
    }
    return '0';
}




  

  render() {
    const { tasks,  task, deleteTask, total, page, rows, search, loading,addTask, editTask } = this.state;
    
    return (
      <>
       
        
      

        {task && (
          <EditTask
            saved={this.searchTasks}
            task={task}
            toggle={this.toggleCloseTask}
          />
        )}

        
      
       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Tasks</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Tasks
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
              
                  <th className="border-0">Title</th>
                  <th className="border-0">Project</th>
                 
                  <th className="border-0">Start Date</th>
                  <th className="border-0">End Date</th>
                  <th className="border-0">Status</th>
                  <th className="border-0">actions</th>
                </tr>
              </thead>
              <tbody>
                
                {tasks.map((task, key) => {
               
                  return (
                    <tr >
                     
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{task.title}</span>
                      </td>
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{task.project_name}</span>
                      </td>
                      
                      <td>{moment(task.start_date).format('MMM D, YYYY')}</td>
                      <td>{moment(task.deadline).format('MMM D, YYYY')}</td>
                      <td>{task.status == 1 ? 'Completed' :'In Progress'}</td>
                    
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" 
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          onClick={() => this.toggleEditTask(task)} size="sm">view</Button>
                          {/* <Button
                            variant="outline-danger"

                            size="sm"
                            onClick={() => this.toggleDeleteTask(task)}

                          >
                            Delete
                          </Button> */}
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {tasks.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Tasks Assigned yet
                </div>}
              {tasks.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} Tasks`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default TaskIndex;
