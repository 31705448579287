import React, { Component } from "react";

import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import {  FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import { Avatar, Image } from 'antd';
import {faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { editAttendance} from "../../services/attendanceService";
import { TimePicker } from 'antd';

export class EditAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
      show: false,
      edit: false,
      id: props.attendance.id,
      attendance: props.attendance,
      loading:false,
      saving:false

    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    
  }

  
  onSaveAttendance = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {clock_in, clock_out, half_day, working_from, validation } = this.state.attendance;
    console.log(half_day)
    await this.setState({
      validation: {
        ...validation,
        clock_in: clock_in !== '' && clock_in !== undefined,
        clock_out: clock_out !== '' && clock_out !== undefined,
        working_from: working_from !== '' && working_from !== undefined,
    
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveAttendance();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };


  validationRules = (field) => {
     if(field === "clock_in"){
      return "Clock In time  is required";
    }else if(field === "clock_out"){
      return "Clock Out time  is required";
    }else if(field === "working_from"){
      return "Place of work  is required";
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })
  }
  hold(){
    return setTimeout(function(){  window.location.href = '/attendance' }, 3000);
  }

  saveAttendance = () => {
    this.setState({ saving: true });
    const { attendance, id} = this.state;
    editAttendance({
      clock_in: attendance.clock_in,
      clock_out: attendance.clock_out,
      working_from: attendance.working_from,
      holiday: attendance.holiday,
      status: attendance.status,
      id:id
    }).then(
      (res) => {
        console.log(res);
        this.setState({ saving: false });
        this.hold()

        this.showToast("Attendance has been updated");
        this.hold()
       
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('Unable to update Attendance')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };

  



  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { attendance } = this.state

    this.setState({ attendance: { ...attendance, [state]: e } })
  }

  

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };






  render() {

    const { toggle } = this.props;

    const { saving, loading, edit, attendance, id } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog-top"
          isOpen={attendance!=null}
          toggle={() => !saving&&toggle}
          style={{maxWidth: 800}}
        >
          <div className="modal-header" style={{padding: '1rem', fontSize:20, fontWeight:'bold'}}>
            Edit Attendance
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
            {saving && <SpinDiv text={"updating..."} />}
            <Row>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="pb-0">
                        <Row style={{marginBottom:30}}>
                            <Col md={1}>
                            <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#p"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                maxHeight: 50,
                                maxWidth: 50,
                                borderRadius: '100%',
                              }}
                              alt="..."
                              src={
                                attendance.image ||
                                require("../../assets/img/brand/user.jpg")
                              }
                            />
                           
                          </a>
                          </Media>
                            </Col>
                            <Col md={6} style={{fontWeight:'bold', paddingBottom:5, textTransform:'capitalize'}}>
                                {attendance.name}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
          
            </Row>
            <Row>
                <Col md={12}>
                <Card border="light" className="shadow-sm mb-4">
                    <Card.Body className="pb-0">
                        <Row style={{marginBottom:30}}>
                            <Col md={12} style={{fontWeight:'bold', fontSize:20}}>
                             Date: {attendance.day}-{attendance.month}-{attendance.year}
                            </Col>

                        </Row>
                        <Row>
                
                          <Col md={4}>
                            <Form.Group id="Lastname">
                              <Form.Label>Clock In</Form.Label>
                                <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </InputGroup.Text>
                                <TimePicker defaultValue={moment(attendance.clock_in, 'HH:mm:ss A')}  
                                  use24Hours 
                                  onChange={(time, clock_in) => this.onChange(clock_in, "clock_in")} 
                                />
                              </InputGroup>

                            </Form.Group>
                            
                          </Col>
                          <Col md={4}>
                            <Form.Group id="Lastname">
                              <Form.Label>Clock Out</Form.Label>
                                <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </InputGroup.Text>
                                <TimePicker  defaultValue={moment(attendance.clock_out, 'HH:mm:ss A')} use24Hours onChange={(time, clock_out) => this.onChange(clock_out, "clock_out")}
                                />
                              </InputGroup>

                            </Form.Group>
                            
                          </Col>
                          
                        </Row>
                        
                        <Row style={{paddingTop:10}}>
                          <Col md={6} className="mb-3">
                            <Form.Group id="">
                              <Form.Label>Working From</Form.Label>
                                <InputGroup>
                                <InputGroup.Text>
                                  <FontAwesomeIcon icon={faPencilAlt} />
                                </InputGroup.Text>
                                <Input
                                  value={attendance.working_from}
                                  type="text"
                                  style={{ marginRight: 10, width: "fit-content" }}
                                  onChange={async (e) => {
                                    await this.onChange(e.target.value, "working_from");
                                  }}
                                  
                                  placeholder='Working from'
                                
                                />
                                  
                              </InputGroup>

                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                        
                          
                          <Col md={2} style={{ paddingTop: 30 }}>
                              <Form.Group className="mb-2">
                                <Form.Label style={{ paddingRight: 10 }}>Status</Form.Label>
                                <Input
                                  className="custom-control-input"
                                  id="status"
                                  checked={attendance.status == 1 ? true:false}
                                  onChange={async (e) => {
                                    await this.onChange(e.target.checked, "status");
                                  }}
                                  type="checkbox"
                                />
                              </Form.Group>

                            </Col>
                            <Col md={2} style={{ paddingTop: 30 }}>
                              <Form.Group className="mb-2">
                                <Form.Label style={{ paddingRight: 10 }}>Holiday</Form.Label>
                                <Input
                                  className="custom-control-input"
                                  id="status"
                                  checked={attendance.holiday == 1 ? true:false}
                                  onChange={async (e) => {
                                    await this.onChange(e.target.checked, "holiday");
                                  }}
                                  type="checkbox"
                                />
                              </Form.Group>

                            </Col>
                            {/* <Col md={2} style={{ paddingTop:30 }}>
                              <Form.Group className="mb-2">
                                <Form.Label style={{ paddingRight: 10 }}>Half Day?</Form.Label>
                                <Input
                                  className="custom-control-input"
                                  id="half_day"
                                  checked={attendance.half_day ==1 ? true : false}
                                  onChange={async (e) => {
                                    await this.onChange(e.target.checked, "half_day");
                                  }}
                                  type="checkbox"
                                />
                              </Form.Group>

                            </Col> */}
                        </Row>
                    </Card.Body>
                </Card>
          
                </Col>
                <Col md={6}>

                    

                </Col>
            </Row>
             
          </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
           
            <Button
              size="sm"
              variant='outline-secondary'
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              size="sm"
              variant='outline-primary'
              data-dismiss="modal"
              type="button"
              disabled={saving}
              onClick={this.onSaveAttendance}
            >
              Save
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default EditAttendance;
