import React, { Component } from "react";
import {  FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import "antd/dist/antd.css";
import { toast } from "react-toastify";
import Select from 'react-select';
import moment from "moment";
import ReactDatetime from "react-datetime";
import {faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { Rate } from 'antd';


const desc = ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];



export class AddPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      appearance:3,
      teamwork:3,
      customer_conversion_rate:3,
      response_time:3,
      service_knowledge:3,
      customer_service_satisfaction:3,
      management_support:3,
      quality_of_service:3,
      quality_of_work:3,
      safety_management:3,
      risk_management:3,
      quality_of_content:3,
      average_time_spent_on_work:3,
      employee_satisfaction:3,
      employee_productivity:3,
      employee_training_development:3,
      policy_compliance:3,
      training_development:3,
      department:props.department,
      department_id:props.department.id,
      start_year: 2018,
      current_year: new Date().getFullYear(),
      year:new Date().getFullYear(),
      month: new Date().getMonth(),
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
     
      

    };
  }

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  

  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };



  onSavePerformance = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {date, inputValues, training_development,policy_compliance, appearance, teamwork, validation } = this.state;
    console.log(inputValues);
    await this.setState({
      validation: {
        ...validation,
        policy_compliance: policy_compliance !== '' && policy_compliance !== undefined,
        training_development: training_development !== '' && training_development !== undefined,
        teamwork: teamwork !== '' && teamwork !== undefined,
        appearance: appearance !== '' && appearance !== undefined,
        date: date !== '' && date !== undefined,
    
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      //this.savePerformance();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };


  validationRules = (field) => {
    if(field === "policy_compliance"){
      return "Policy compliance Rating is required";
    }else if(field === "teamwork"){
      return "Team work is required";
    }else if(field === "training_development"){
      return "Training Development rating  is required";
    }else if(field === "date"){
      return "Date  is required";
    }else if(field === "appearance"){
      return "Appearance rating  is required";
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({[state]: e})
  }

  


  savePerformance = () => {
    this.setState({ saving: true });

    const { selectedUsers,week, appearance, date, policy_compliance, training_development, inputValues, teamwork } = this.state;
    let data = new FormData();
   
    let y= moment(date,"MMM, YYYY").year()
    let m = moment(date,"MMM, YYYY").month()+1;
    data.set("week", week);
    data.set("appearance", appearance);
    data.set("teamwork", teamwork);
    data.set("year", y);
    data.set("month", m);
    data.set("policy_compliance", policy_compliance);
    data.set("training_development", training_development);
    let op = selectedUsers;
    for (var i in op) {

      data.set(`user_id[${i}]`, op[i].value);
    }
    return axios
      .post(
        `${settings.API_URL}addPerformance`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Performance Registered");
        },
        (err) => {
          
          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err) {
              this.showToastError('An error occur')
            }
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );
      
  };
  dateFilter = async (e, state) => {
    await this.setState({[state]: e})
  }

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {toggle } = this.props;

    const { saving,loading,department, department_id, teamwork,
      customer_conversion_rate,
      response_time,
      service_knowledge,
      customer_service_satisfaction,
      management_support,
      quality_of_service,
      quality_of_work,
      policy_compliance,
      safety_management,
      risk_management,
      quality_of_content,
      average_time_spent_on_work,
      employee_satisfaction,
      employee_productivity,
      employee_training_development} = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={department_id != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth:800, }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5> Create {department.name} Performance </h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
              
                <Col md={6} className="mb-3">
                    <Form.Group id="date">
                      <Form.Label>Date</Form.Label>
                      <ReactDatetime
                              
                        dateFormat={'MMM, YYYY'}
                        closeOnSelect
                        onChange={e=>this.dateFilter(e, 'date')}
                        inputProps={{
                          required: true,
                          className: 'form-control date-width'
                        }}
                        timeFormat={false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} >
                    <Form.Group className="mb-2">
                      <Form.Label>Week</Form.Label>

                      <Form.Select
                        id="state"
                        required
                      
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "week");
                        }}
                        style={{
                          marginRight: 10,
                          width: "100%",
                        }}
                      >

                        <option value="">Choose Week</option>
                        <option value="1">Week 1</option>
                        <option value="2">Week 2</option>
                        <option value="3">Week 3</option>
                        <option value="4">Week 4</option>
                        
                      </Form.Select>
                    </Form.Group>

                  </Col>
                  
                </Row>
                
            
                
                
                <Row style={{ marginTop: "10px" }}>
                  <Col md={12}>
                    <div>
                      <Button
                        variant="primary"
                        size="sm"
                        style={{ marginTop: "10px", float: "right" }}
                        disabled={saving}
                        onClick={this.onSavePerformance}
                      >
                        Save
                      </Button>
                      <Button
                        size="sm"
                        variant="transparent"
                        data-dismiss="modal"
                        type="button"
                        disabled={saving}
                        style={{ marginTop: "10px", float: "right" }}
                        onClick={toggle}
                      >
                        {" "}
                        Close
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Modal>
      </>
    );
  }
}

export default AddPerformance;
