import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  Form,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";
import {  getEmployees } from "../../services/employeeService";
import {  getLeaves } from "../../services/leaveService";
import SpinDiv from "../components/SpinDiv";
import { AddLeave } from "./AddLeave";
import moment from "moment";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditLeave from "./EditLeave";
import DeleteLeave from "./DeleteLeave";
import RejectLeave from "./RejectLeave";
import ConfirmLeave from "./ConfirmLeave";
import AddLeaveType from "./AddLeaveType";
import LeaveTypeIndex from "./LeaveTypeIndex";
import ReactDatetime from "react-datetime";
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
const p = JSON.parse(localStorage.getItem('permissions'));
export class LeaveIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      employees: [],
      leaves:[],
      total: 0,
      fromdate: moment().startOf('month'),
      todate: moment().endOf('month'),
      permission: p !== null ?  p.map((p, index)=>{ return p.name}).includes('show setting') :[]

    

    };
    this.searchDebounced = debounce(this.searchLeaves, 500);
    this.searchThrottled = throttle(this.searchLeaves, 500);
  }

  componentDidMount() {
    this.getEmployees();
    this.searchLeaves();
  }

  onFilter = async (e, filter) => {
    await this.setState({ [filter]: e });
    await this.searchLeaves();
  };

  getEmployees = () => {
    const { page, rows, search, employees, } = this.state;
    this.setState({ loading: true });
    getEmployees({ page, rows, search, employees }).then(
      (res) => {
        console.log(res.employees.data);
        
        this.setState({
          employees: res.employees.data,
          page: res.employees.current_page,
          //roles:res.roles,
          total: res.employees.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  searchLeaves = () => {
    const { page, rows, search, leaves, employee_id, fromdate, todate } = this.state;
    this.setState({ loading: true });
    getLeaves({ page, rows, search, leaves, employee_id, fromdate, todate }).then(

      (res) => {
        this.setState({
          leaves: res.leaves.data,
          page: res.leaves.current_page,
          total: res.leaves.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  export = async () => {
    const { page, search, leaves, total, employee_id,fromdate,todate } = this.state;
    const rows=1000;
    if(total<1){console.log(total)
      await toast.dismiss();
      await setTimeout(()=>this.showToast('No Leave Report to export.'), 250);
    } else {
      this.setState({ loading: true });
      getLeaves({ page, rows, search, leaves, employee_id, fromdate, todate })
        .then(response => {
          let exportt = '';
          exportt = response.leaves.data.map(c => (
            {employee: c.name, pending: c.status == null ? 1 : 0, approved: c.status == 1 ? 1 :0 , rejected: c.status == 0 ? 1 :0 ,
               applied:moment(c.applied_on).format('MMM D, YYYY'), start:moment(c.start_date).format('MMM D, YYYY'), end:moment(c.end_date).format('MMM D, YYYY')  }
          ));
          

          const theheader = ['employee','pending','approved','rejected','applied','start','end'];
          const wch = [30,20,15,20,40,20,20,20,20];
          const cols = wch.map(h => {return ({wch: h});});
          const thedata = exportt.map(item => {
            return theheader.map(item2 => {
              return item[item2]
            })
          });

          const headerTitle = 'your header title here';
         
          const allofit = [theheader].concat(thedata);

          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(allofit);

          const wb: XLSX.WorkBook = XLSX.utils.book_new(headerTitle);
          ws['!cols'] = cols;
          XLSX.utils.book_append_sheet(wb, ws, `Leaves`);
          XLSX.writeFile(wb, `leave-${fromdate}-${todate}-report.xlsx`);
          this.setState({
            loading: false
          });
        },
        error => {
          this.setState({ loading: false });
        });
    }
  }

  showToast = msg => {
    toast.error(<div style={{padding:20}}>{msg}</div>);
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.searchLeaves();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
 

  toggleEditLeave = (leave) => {
    this.setState({ leave});
    this.searchLeaves();
   
  };
  toggleCloseLeave=(leave)=>{
    this.setState({ leave: !this.state.leave });
  }

  toggleCloseReject=(rejectLeave)=>{
    this.setState({ rejectLeave: !this.state.rejectLeave });
  }
  toggleCloseConfirm=(confirmLeave)=>{
    this.setState({ confirmLeave: !this.state.confirmLeave });
  }

  toggleCloseDelete=(deleteLeave)=>{
    this.setState({ deleteLeave: !this.state.deleteLeave });
  }

  toggleAddLeave = () => {
    this.setState({ addLeave: !this.state.addLeave });
    this.searchLeaves();
  }

  toggleAddLeaveType = () => {
    this.setState({ addLeaveType: !this.state.addLeaveType });
    this.searchLeaves();
  }

  toggleViewLeaveType = (leaveType) => {
    this.setState({ leaveType: !this.state.leaveType });
    
    this.searchLeaves();
   
  };

  toggleDeleteLeave=(deleteLeave)=>{
    this.setState({ deleteLeave });
    this.searchLeaves();
  }

  toggleConfirmLeave=(confirmLeave)=>{
    this.setState({ confirmLeave });
    this.searchLeaves();
  }

  toggleRejectLeave=(rejectLeave)=>{
    this.setState({ rejectLeave });
    this.searchLeaves();
  }

  


  




  

  render() {
    const { employees, leaves,leaveType, permission, fromdate, todate,addLeaveType, rejectLeave, confirmLeave, deleteLeave, leave, total, page, rows, search, loading,addLeave, editLeave } = this.state;
    
    return (
      <>
       
        
        {addLeave && (
          <AddLeave
            saved={this.searchLeaves}
            addLeave={addLeave}
            employees={employees}
        
            toggle={this.toggleAddLeave}
            
          />
        )}

        {addLeaveType && (
          <AddLeaveType
            saved={this.searchLeaves}
            addLeaveType={addLeaveType}
            toggle={this.toggleAddLeaveType}
            
          />
        )}

        {leaveType && (
          <LeaveTypeIndex
          saved={this.searchLeaves}
            leaveType={leaveType}
            toggle={this.toggleViewLeaveType}
            
          />
        )}


        {leave && (
          <EditLeave
            saved={this.searchLeaves}
            leave={leave}
            employees={employees}
            toggle={this.toggleCloseLeave}
          />
        )}

        
        {deleteLeave && (
          <DeleteLeave
            saved={this.searchLeaves}
            deleteLeave={deleteLeave}
            toggle={this.toggleCloseDelete}
          />
        )}

        {rejectLeave && (
          <RejectLeave
            saved={this.searchLeaves}
            rejectLeave={rejectLeave}
            toggle={this.toggleCloseReject}
          />
        )}

        {confirmLeave && (
          <ConfirmLeave
            saved={this.searchLeaves}
            confirmLeave={confirmLeave}
            toggle={this.toggleCloseConfirm}
          />
        )}  

       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Leaves</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div style={{padding: '0 15px', alignItems: 'center',  display: 'flex'}}>
                      
                <ReactDatetime
                  value={fromdate}
                  dateFormat={'MMM D, YYYY'}
                  closeOnSelect
                  onChange={e=>this.onFilter(e, 'fromdate')}
                  inputProps={{
                    required: true,
                    className: 'form-control date-filter'
                  }}
                  isValidDate={(current)=>{return (current.isBefore(todate)||current.isSame(todate))&&current.isBefore(moment());}}
                  timeFormat={false}
                />
                <span className="hide-mobile" style={{padding: '0 10px'}}>-</span>
                <ReactDatetime
                  value={todate}
                  dateFormat={'MMM D, YYYY'}
                  closeOnSelect
                  onChange={e=>this.onFilter(e, 'todate')}
                  inputProps={{
                    required: true,
                    className: 'form-control date-filter'
                  }}
                  isValidDate={(current)=>{return (current.isAfter(fromdate)||current.isSame(fromdate))&&current.isBefore(moment());}}
                  timeFormat={false}
                />
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddLeave()}>
                    Create Leave
                  </Button>
                  <Button variant="outline-primary" size="sm"  onClick={this.export}>
                    Export Leave
                  </Button> 
                  {permission &&   <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddLeaveType()}>
                    Create Leave Types
                  </Button>} 
                  {permission &&  <Button variant="outline-primary" size="sm" onClick={() => this.toggleViewLeaveType()}>
                    Leave Types
                  </Button>} 
                 
                </ButtonGroup>
                
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <h5 className="mb-0">Leaves 
            <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col md={4}>
  
            <Form.Select
        
              type="select"
              style={{ marginRight: 10, width: "fit-content" }}
              onChange={(e) => this.onFilter(e.target.value, "employee_id")}
            >
              <option value="">Filter By All Employee</option>
              {employees.map((p, index) => (
                <option value={p.id} key={p}>
                  {p.name}
                </option>
              ))}
                
            </Form.Select>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Employees</th>
                  <th className="border-0">Leave type</th>
                  <th className="border-0">Applied on</th>
                  <th className="border-0">Start Date</th>
                  <th className="border-0">End Date</th>
                  <th className="border-0">Total Days</th>
                  <th className="border-0">status</th>
                  <th className="border-0">Payment Type</th>
                  <th className="border-0">Actions</th>
                </tr>
              </thead>
              <tbody>
                
                {leaves.map((leave, key) => {
               
                  return (
                    <tr >
                     <th scope="row">
                      <td>
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#p"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                maxHeight: 50,
                                maxWidth: 50,
                                borderRadius: '100%',
                              }}
                              alt="..."
                              src={
                                leave.image ||
                                require("../../assets/img/brand/user.jpg")
                              }
                            />
                           
                          </a>
                          </Media>
                        </td>
                        <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{leave.name}</span>
                      </td>
                        
                      </th>
                        
                      <td >{leave.leavetype}</td>
                      <td>{moment(leave.applied_on).format('MMM D, YYYY')}</td>
                      <td>{moment(leave.start_date).format('MMM D, YYYY')}</td>
                      <td>{moment(leave.end_date).format('MMM D, YYYY')}</td>
                      <td>{leave.total_leave_days}</td>
                      <td>
                      
                       {leave.status ==null ? <span><span style={{color:'#FFC300'}}>● </span>Pending</span> : leave.status ==1 ? <span><span style={{color:'green'}}>● </span>Approved</span> :<span><span style={{color:'red'}}>●  </span>Rejected</span>}
                      </td>
                      <td>
                      
                      {leave.payment ==null ? <span><span style={{color:'#FFC300'}}>● </span>Unpaid</span> : leave.payment ==1 ? <span><span style={{color:'green'}}>● </span>Paid</span> :<span><span style={{color:'red'}}>●  </span>Unpaid</span>}
                     </td>
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" style={{fontWeight:"bold",textTransform:"capitalize"}} onClick={() => this.toggleEditLeave(leave)} size="sm">view</Button>
                          {leave.status == 0 ? '' : <Button variant="outline-warning" style={{fontWeight:"bold",textTransform:"capitalize"}} onClick={() => this.toggleRejectLeave(leave)} size="sm">Reject</Button>}
                          {leave.status == 1 ? '' :  <Button variant="outline-success" style={{fontWeight:"bold",textTransform:"capitalize"}} onClick={() => this.toggleConfirmLeave(leave)}size="sm">confirm</Button>}
                          <Button variant="outline-danger" size="sm" onClick={() => this.toggleDeleteLeave(leave)}>Delete</Button>
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {leaves.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Information for this employee at the moment
                </div>}
              {leaves.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} Leaves for all Employees`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default LeaveIndex;
