import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getTrainingTypes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}trainingtypes`, requestOptions).then(authService.handleResponse);
}

export function addTrainingTypes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addtrainingtype`, requestOptions).then(authService.handleResponse);
}

export function editTrainingType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatetrainingtype/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteTrainingType(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletetrainingtype/${id}`, requestOptions).then(authService.handleResponse);
}



