

import PerformanceIndex from "./pages/performance/PerformanceIndex";
import DepartmentPerformanceIndex from "./pages/dept-performance/DepartmentPerformanceIndex";
import { faNetworkWired } from "@fortawesome/free-solid-svg-icons";
import { faObjectGroup } from "@fortawesome/free-regular-svg-icons";
import { getPerformancesDepartments } from "./services/departmentPerformanceService";
 
export let getDepts = () => {
 
  return getPerformancesDepartments().then(
    
    (res) => {
     return res.departments;
    },
  );
};
const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]
  export let performanceRoute = [
    
    ...(permissions.includes('show performance') === true ?  [{ path: "/performance", component: PerformanceIndex, title:"Employee",icon: faNetworkWired,},] : []),
    ...(permissions.includes('show performance') === true ?  [  { path: "/departments/performance", component: DepartmentPerformanceIndex, title:"Departments",icon: faObjectGroup,}
  
  ] : []),
   
   
   
 
   
  ];



  export let DepartmentperformanceRoute = [
    
    

  ];

  // export let DepartmentperformanceRoute=()=>{
  //   const depts = this.getDepts() !== null ? this.getDepts() : [];
  //   return [depts.map((p,index)=>{
  //     return {path: `'\'+${p.name}+'\'+${p.id}`, component: DepartmentPerformanceIndex, title:`${p.name}`,icon: faObjectGroup}
  //   })]
  // }