import React, { Component } from "react";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,

} from "@themesberg/react-bootstrap";
import SpinDiv from "../components/SpinDiv";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { toast } from "react-toastify";
import { getMarkedPerformances } from "../../services/performanceService";
import { Tooltip } from 'antd';
import {faCheck, faBoxOpen,faUsers,  faStepForward, faEnvelope, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from 'antd';
import Chart from "react-apexcharts";
import { Popover} from 'antd';

const attendanceOverview = (
  <div>
    <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
            <p>Weekly Attendance is calcuted as follows</p>
            <p>Working Hours for a week = No of Days of week x 9hrs</p>
            <p> Employee working Hours for a week = Attendance (total No of hours worked) X No of working Days</p>
            <h6> Attendance Percentage =   (Employee working Hours for a week /  Employee working Hours for a week) x 20</h6>

        </Card.Body>
    </Card>
  </div>
);
const performanceOverview = (
    <div>
      <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
              <h5>Performance</h5>
              <p>(Sum total Rating / No of weeks (No of time it was rated) x 5) x 100</p>
              <p>This arithmetic operation works for Appearance, Policy and Compliance, Teamwork, Training & Development</p>
             
  
          </Card.Body>
      </Card>
    </div>
  );



export class PerformanceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            validation: {},
            show: false,
            edit: false,
            id: props.match.params.id,
            year:props.match.params.year,
            month:props.match.params.month,
            performances:[],
            attendances:[],
            timelogs:[],
            tasks:[],
            overall_compliance:0,
            overall_teamwork:0,
            overall_attendance:0,
            overall_appearance:0,
            overall_training:0,
            profile:{}
        };
    }

    componentDidMount() {
        toast.configure({ hideProgressBar: true, closeButton: false });
        this.getMarkedPerformances();
        
    }





    getMarkedPerformances = () => {
        const { id } = this.state;
        this.setState({  loading: true})
        getMarkedPerformances(id).then(
            
            (res) => {
                this.setState({
                  
                    performances: res.marked_performances,
                    attendances: res.attendances,
                    profile:res.profile,
                    tasks:res.tasks,
                    timelogs:res.timelogs,
                    loading: false
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };
    sumArray(arr){
        const sum = arr.reduce((partialSum, a) => partialSum + a, 0);
        return sum;
    }
    getAttendancePercentage(attendances){
        console.log(attendances)
        var working_days = attendances.map(function(p) {
            return p.working_days;
        });
        var duration = attendances.map(function(p) {
            return p.duration;
        });
        let total_duration =this.sumArray(duration);

        // you are suppose to work 9hrs daily * total supposed working
        let suppose_duration= 9*working_days[0];
        let percentage = (total_duration/suppose_duration)*100
      
       return Math.round(percentage);
        

    }

    getWeeklyAttendancePercentage(attendances){
        var working_days = attendances.map(function(p) {
            return p.working_days/4;
        });
        var duration = attendances.map(function(p) {
            return p.duration;
        });
        let total_duration =this.sumArray(duration);
        let suppose_duration= 9*working_days[0];
        let percentage = (total_duration/suppose_duration)*100
      
       return Math.round(percentage);
        

    }
    getTeamWorkPercentage(performances){
        console.log(performances)
        let teamwork=[];
        let total_teamwork = performances.length*5;
        performances.map((p, index)=>{
            return teamwork.push( p.teamwork);
        })
       
         let percentage = (this.sumArray(teamwork)/total_teamwork)*100;
         return Math.round(percentage);
        

    }

    getWorkDuration(){
        const {timelogs} = this.state;
        console.log(timelogs)
        const sum_durations = timelogs.map(timelog => timelog.duration).reduce((prev, curr) => prev + curr, 0);
        console.log(sum_durations);
        return sum_durations;
    }

    getCompletedTasks(){
        const {tasks} = this.state;

        var completed_tasks = tasks.filter(task => task.status === 1);
        return completed_tasks.length;

    }

     getTrainingDevelopmentPercentage(performances){
        let training_development=[];
        let total_training_development = performances.length*5;
        performances.map((p, index)=>{
            return training_development.push( p.training_development);
        })
       
         let percentage = (this.sumArray(training_development)/total_training_development)*100;
         return Math.round(percentage);
        

    }

     getPolicyCompliancePercentage(performances){
        let policy_compliance=[];
        let total_policy_compliance = performances.length*5;
        performances.map((p, index)=>{
            return policy_compliance.push( p.policy_compliance);
        })
       
         let percentage = (this.sumArray(policy_compliance)/total_policy_compliance)*100;
         let overall_compliance_performance= (Math.round(percentage)/100)*20;
         //this.setState({overall_compliance:overall_compliance_performance});
         return Math.round(percentage);
         
    }

    getAppearancePercentage(performances){
        let appearance=[];
        let total_appearance = performances.length*5;
        performances.map((p, index)=>{
            return appearance.push( p.appearance);
        })
       
         let percentage = (this.sumArray(appearance)/total_appearance)*100;
         let overall_appearance_performance= (Math.round(percentage)/100)*20;
         //this.setState({overall_appearance:overall_appearance_performance});
         return Math.round(percentage);
        

    }
    getName(attendances){
         var name = attendances.map(function(p) {
            return p.name;
        });
        return name[0];
    }

    monthName = (mon)=> {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }

    weeklyPerformanceChat(attendances, performances){
        
        const week_one_performances = performances.filter(p => p.week == 1);
        const week_two_performances = performances.filter(p => p.week == 2);
        const week_three_performances = performances.filter(p => p.week == 3);
        const week_four_performances = performances.filter(p => p.week == 4);
        console.log(week_four_performances);

        const week_one_attendances = attendances.filter(p => p.week == 1);
        const week_two_attendances = attendances.filter(p => p.week == 2);
        const week_three_attendances = attendances.filter(p => p.week == 3);
        const week_four_attendances = attendances.filter(p => p.week == 4);



        
       
    
        let appearance_one= week_one_performances.length !== 0 ? (this.getAppearancePercentage(week_one_performances)/100)*20 : 0;
        let appearance_two= week_two_performances.length !== 0 ? (this.getAppearancePercentage(week_two_performances)/100)*20 : 0;
        let appearance_three= week_three_performances.length !== 0 ? (this.getAppearancePercentage(week_three_performances)/100)*20 : 0;
        let appearance_four= week_four_performances.length !== 0 ? (this.getAppearancePercentage(week_four_performances)/100)*20 : 0;
        
        let teamwork_one= week_one_performances.length !== 0 ? (this.getTeamWorkPercentage(week_one_performances)/100)*20 : 0;
        let teamwork_two= week_two_performances.length !== 0 ? (this.getTeamWorkPercentage(week_two_performances)/100)*20 : 0;
        let teamwork_three= week_three_performances.length !== 0 ? (this.getTeamWorkPercentage(week_three_performances)/100)*20 : 0;
        let teamwork_four= week_four_performances.length !== 0 ? (this.getTeamWorkPercentage(week_four_performances)/100)*20 : 0;

        let policy_compliance_one= week_one_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_one_performances)/100)*20 : 0;
        let policy_compliance_two= week_two_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_two_performances)/100)*20 : 0;
        let policy_compliance_three= week_three_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_three_performances)/100)*20 : 0;
        let policy_compliance_four= week_four_performances.length !== 0 ? (this.getPolicyCompliancePercentage(week_four_performances)/100)*20 : 0;

        let training_development_one= week_one_performances.length !== 0 ? (this.getTrainingDevelopmentPercentage(week_one_performances)/100)*20 : 0;
        let training_development_two= week_two_performances.length !== 0 ? (this.getTrainingDevelopmentPercentage(week_two_performances)/100)*20 : 0;
        let training_development_three= week_three_performances.length !== 0 ? (this.getTrainingDevelopmentPercentage(week_three_performances)/100)*20 : 0;
        let training_development_four= week_four_performances.length !== 0 ? (this.getTrainingDevelopmentPercentage(week_four_performances)/100)*20 : 0;

        let attendance_one= week_one_attendances.length !== 0 ? (this.getWeeklyAttendancePercentage(week_one_attendances)/100)*20 : 0;
        let attendance_two= week_two_attendances.length !== 0 ? (this.getWeeklyAttendancePercentage(week_two_attendances)/100)*20 : 0;
        let attendance_three= week_three_attendances.length !== 0 ? (this.getWeeklyAttendancePercentage(week_three_attendances)/100)*20 : 0;
        let attendance_four= week_four_attendances.length !== 0 ? (this.getWeeklyAttendancePercentage(week_four_attendances)/100)*20 : 0;

        let overall_week_one_performance = appearance_one+training_development_one+policy_compliance_one+attendance_one+teamwork_one;
        let overall_week_two_performance = appearance_two+training_development_two+policy_compliance_two+attendance_two+teamwork_two;
        let overall_week_three_performance = appearance_three+training_development_three+policy_compliance_three+attendance_three+teamwork_three;
        let overall_week_four_performance = appearance_four+training_development_four+policy_compliance_four+attendance_four+teamwork_four;
        console.log(attendance_four)

       
        const data  = {
            options: {
              chart: {
                id: "basic-bar",
                
              },
              xaxis: {
                categories: ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4'],
                
               
              },
              
            },
            series: [
              {
                name: "Weekly overall-performance",
               
                data: [overall_week_one_performance,overall_week_two_performance,overall_week_three_performance,overall_week_four_performance,100]
              }
            ]
          };
          return (
              <Chart
              options={data.options}
              series={data.series}
              type="line"
              width="750"
              height="250"
            />
          )
    }

    performanceChart = (attendances,performances) => {
        let appearance= this.getAppearancePercentage(performances);
        let teamwork= this.getTeamWorkPercentage(performances);
        let policy_compliance= this.getPolicyCompliancePercentage(performances);
        let training_development= this.getTrainingDevelopmentPercentage(performances);
        let attendance= attendances.length == 0 ? 0 :this.getAttendancePercentage(attendances);
        
        const data  = {
          options: {
            chart: {
              id: "basic-bar"
            },
            xaxis: {
              categories: ['ATTENDANCE', 'APPEARANCE', 'TRAINING', 'POLICY', 'TEAMWORK']
            },
            yaxis: {
                show: true,
                min: 0,
                max: 100,
            }
          },
          series: [
            {
              name: "overall-performance",
              data: [attendance,appearance,training_development,policy_compliance,teamwork ]
            }
          ]
        };
        return (
            <Chart
            options={data.options}
            series={data.series}
            type="line"
            width="750"
            height="250"
          />
        )
    }

    render() {

        const { saving, profile,tasks, timelogs, attendances, month, year, loading, performances } = this.state;
        return (
            <>


                {loading && <SpinDiv text={"Loading..."} />}
                <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/performance">Performance</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Detail</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>

                                    {/* <Button variant="outline-primary" size="sm">
                                        Export
                                    </Button> */}
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                    <Row>
                        <Col md={12}>
                            <Card border="light" className="shadow-sm mb-4">
                                <Card.Body className="pb-0">
                                    <Row>
                                        <Col md={8}></Col>
                                        <Col md={4} style={{marginBottom:10}}>
                                            
                                                
                                                <ButtonGroup>
                                                    <Button variant="outline-primary" size="sm">
                                                        <Popover content={attendanceOverview} title="Attendance Overview">
                                                            Attendance overview
                                                        </Popover>
                                                    </Button>
                                                    <Button variant="outline-primary" size="sm">
                                                        <Popover content={performanceOverview} title="Performance Overview">
                                                            Performance OverView
                                                        </Popover>
                                                    </Button>
                                                </ButtonGroup>
                                           
                                        </Col>
                                    </Row>
                                    <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                        style={{marginBottom:10, fontSize:30}}
                                    >
                                    <thead className="thead-light">
                                        <tr>
                                        <th className="border-0" style={{fontSize:15}}>Name: {this.getName(performances)} </th>
                                        <th className="border-0" style={{fontSize:15}}>Date: {this.monthName(month)}, {year}</th>
                                        
                                        
                                        </tr>
                                    </thead>
                                    
                                    
                                    </Table>
                                
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Col md={12}>
                       

                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                               <Row style={{marginBottom:10}}>
                                    
                                   <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                    Policy & Compliance
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faEnvelope}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getPolicyCompliancePercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Training & Dev
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faStepForward}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getTrainingDevelopmentPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Attendance
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faAngleUp}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{attendances.length !== 0 ?this.getAttendancePercentage(attendances)+'%': '0%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Appearance
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faBoxOpen}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getAppearancePercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Team Work
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faUsers}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>
                                                    {this.getTeamWorkPercentage(performances)+'%'}
                                                </h6>
                                            </Row>       
                                        </Card>
                                    </Col>

                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Completed Tasks and project
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faUsers}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>
                                                   Task Assigned : {tasks.length}
                                                 
                                                </h6>
                                                <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>
                                                   
                                                   Duration : {this.getWorkDuration()}Hrs
                                                  
                                                </h6>
                                                {/* <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>
                                                  
                                                   Completed : {this.getCompletedTasks}
                                                </h6> */}
                                            </Row>       
                                        </Card>
                                    </Col>
                                    
                                    
                               </Row>
                              

                        
                            </Card.Body>
                            <Row style={{margin:10}}>
                                <Col md={12}>
                                    <Card border="light" className="shadow-sm mb-4">
                                        <Card.Body className="pb-0">
                                            <Table
                                            responsive
                                            className="table-centered table-nowrap rounded mb-0"
                                            >
                                            <thead className="thead-light">
                                                <tr>
                                                <th className="border-0">Name</th>
                                                <th className="border-0">Week</th>
                                                <th className="border-0">Training</th>
                                                <th className="border-0">Policy Compliance</th>
                                                <th className="border-0">Team Work</th>
                                                {/* <th className="border-0">Attendance</th> */}
                                                <th className="border-0">Appearance</th>
                                               
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                {performances.map((performance, key) => {
                                            
                                                return (
                                                    <tr style={{fontWeight:"bold"}}>
                                                    <th scope="row">
                                                        <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{performance.name}</span></td>
                                                        
                                                    </th>
                                                
                                                    <td>{performance.week}</td>
                                                    <td><Rate style={{color:'green'}} value={performance.training_development} /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.policy_compliance} /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.teamwork} /></td>
                                                    {/* <td><Rate style={{color:'green'}} value={performance.attendance} /></td> */}
                                                    <td><Rate style={{color:'green'}} value={performance.appearance} /></td>
                                                    
                                                   
                                                    
                                                    
                                                    </tr>
                                                );
                                                })}
                                            </tbody>
                                            
                                            </Table>
                                            
                                        </Card.Body>
                                        </Card>
                                </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>

                               <Col md={10}>
                                    <h5>Weekly Overall Performance</h5>
                                    {this.weeklyPerformanceChat(attendances, performances)}
                               </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>
                               <Col md={10} style={{marginBottom:20}}>
                                <h5>Performance BreakDown Graph</h5>
                                {this.performanceChart(attendances, performances)}
                               </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>

            </>
        );
    }
}

export default PerformanceDetail;
