import React, { Component }from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister, faSignOutAlt, faBed, faCalendar, faMoneyBill, faNetworkWired, faPlus, faPlusSquare, faPowerOff, faReceipt, faRemoveFormat, faTasks, faUsers, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Table, Form, Card,Dropdown, Spinner, Button, ButtonGroup, Container, InputGroup } from '@themesberg/react-bootstrap';
import { BrowserRouter, useHistory, Route, Switch, Redirect } from "react-router-dom";

import { CounterWidget, CircleChartWidget, BarChartWidget, TeamMembersWidget, ProgressTrackWidget, RankingWidget, SalesValueWidget, SalesValueWidgetPhone, AcquisitionWidget } from "../../components/Widgets";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Link } from 'react-router-dom';
import { getDashboard } from "../../services/userService";
import SpinDiv from "../components/SpinDiv";
import { faStackExchange, faFacebookMessenger, } from "@fortawesome/free-brands-svg-icons";
import { toast } from "react-toastify";
import {getCurrentAttendance, clockOut} from '../../services/employeeAttendance';
import ClockIn from './ClockIn';
import axios from 'axios';
import moment from "moment";


class DashboardOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      authuser: JSON.parse(localStorage.getItem('user')),
      clients:0,
      projects:0,
      employees:0,
      tasks:[],
      attendance:'',
      attendance_count:0,
      ip_address:'',
      user_ip_address:'',
      saving:false,
      loading:false,
      salaries:0,
      leaves:0,
      terminations:0,
      resignations:0,
      invoices:0,
      message:0,
      month:"",
      trainings:0,
      attendance_count:'',
      ip_address:[],
      upcoming_birthdays:[],
      attendance_setting:'',
      user_ip_address:{}
    };
  }

   async componentDidMount() {
    await  this.getDashboards();
    await this.checkAttendance();
    await this.getUserIpAddress()
    var refresh = window.localStorage.getItem('refresh');
      console.log(refresh);
      if (refresh===null){
          window.location.reload();
          window.localStorage.setItem('refresh', "1");
      }
  
  }

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  getUserIpAddress = async () => {
    this.setState({ loading: true });
    return await axios.get('https://ipapi.co/json/').then(
        (res) => {
         
          this.setState({
            user_ip_address: res.data.ip,
            loading:false
          })
          console.log(res.data.ip)
        },
        (err) => {
          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err) {
              this.showToastError('Could not Get your IP Address, please connect to the right network')
            }
            this.setState({ loading: false });
          }
        }
      );
  }

  clockOut = () => {
    this.setState({saving:true})
    const {ip_address, user_ip_address, attendance_setting}= this.state;
   
    if(ip_address.includes(user_ip_address) || attendance_setting.clockin_without_ip == 1 ){
      clockOut().then(
        (res) => {
          this.setState({ saving: false });
          this.showToast("Clocked Out");
          this.checkAttendance();
        },
        (err) => {
  
          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err) {
              this.showToastError('An error occured')
            }
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );
    } else {
      alert('Please Connect to the right Network')
      this.setState({ saving: false });
     
     
     
    }
    

  }

  toggleCloseAddAttendance=(addAttendance)=>{
    this.setState({ addAttendance: !this.state.addAttendance });
  }

  checkAttendance = () => {
    this.setState({ loading: true });
    const {authuser} = this.state
    if(authuser !== null && authuser.client== '1'){
      return '';
    } else {
      getCurrentAttendance().then(
    
        (res) => {
         
          this.setState({
            attendance: res.attendance,
            ip_address:res.ip_address.map((p, key)=>(p.ip_address)),
            loading:false
          });
        },
        (error) => {
          this.setState({ loading: false });
        }
      );
    }
    
  };

  toggleAddAttendance = () => {
    this.setState({ addAttendance: !this.state.addAttendance });
    this.checkAttendance();
  }

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
   showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };
  showClock = (attendance) => {
    console.log(attendance)
    const {user_ip_address, ip_address}= this.state;
    if(!attendance){
     return ( <Button onClick={() => this.toggleAddAttendance()} variant="tertiary" size="sm">
        <FontAwesomeIcon  icon={faSignOutAlt} className="me-2"   />Clock In
      </Button>)
    } else if(attendance.clock_in !== null && attendance.clock_out == null){
      return (
        <Button onClick={() => this.clockOut()} variant="tertiary" size="sm">
        <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Clock Out
        </Button>
      )
    } else if(attendance && attendance.clock_in !== null && attendance.clock_out !== null){
      return (
        <Button  disabled variant="primary" size="sm">
          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Clocked
         </Button>
      )
    } else {
      return '';
    }
  }


  
  
   getDashboards =  () => {
    this.setState({loading:true})
     getDashboard().then(
      (res) => {
        
        this.setState({
          clients: res.clients,
          attendance_count:res.attendance,
          trainings:res.trainings,
          employees:res.employees,
          projects:res.projects,
          month:res.month,
          tasks:res.tasks,
          upcoming_birthdays:res.upcoming_birthdays,
          resignations:res.resignations,
          message:res.message,
          terminations:res.terminations,
          salaries:res.pending_salaries,
          leaves:res.pending_leaves,
          invoices:res.invoices,
          attendance_setting:res.attendance_setting,

          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  }
  logOut = () =>{ 
     localStorage.removeItem("user");
    localStorage.removeItem('token');
    localStorage.removeItem('company');
    localStorage.clear();
    let path = '/auth/sign-in'; 
    this.props.history.push(path);

  }
 
  
  AdminDashboard = () => {
    const {clients, upcoming_birthdays, attendance_count,resignations,trainings, invoices, terminations, salaries, leaves, employees,tasks, projects} = this.state;
    return (
      <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
       
      <h5 style={{fontWeight:'bold'}}>Dashboard Overview</h5>
    
       <ButtonGroup>
       <Card.Link as={Link} to='/profile' >
         {/* <Button variant="outline-primary" style={{fontWeight: 'bold'}}  size="sm">
         <FontAwesomeIcon icon={faUser} /> Profile
         
         </Button> */}
        </Card.Link>
         {/* <Button variant="outline-danger" style={{fontWeight: 'bold'}}  onClick={() => this.logOut()} size="sm"><FontAwesomeIcon icon={faPowerOff} />&nbsp;Sign Out</Button> */}
         {/* <Button variant="outline-primary" size="sm">Export</Button> */}
       </ButtonGroup>
     </div>

     <Row className="justify-content-md-center">
      
      
       <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Total Clients"
           title={clients}
           icon={faUsers}
           iconColor="white"
           link="/clients"
           textColor='white'
           bgColor='#CD5C5C'
         />
       </Col>
       <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Total Employees"
           title={employees}
           icon={faUsers}
           textColor='white'
           bgColor='#6495ED'
           iconColor="shape-secondary"
           link="/employees"
         />

       </Col>

       <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Total Projects"
           title={projects}
           icon={faStackExchange}
           iconColor="shape-tertiary"
           link="/projects"
           textColor='white'
           bgColor='#40E0D0'
         />
       </Col>

      
       <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Pending Tasks"
           title={tasks.length}
           icon= {faTasks}
           link="/tasks"
           textColor='white'
           bgColor='#DE3163'
         
           />
       </Col>
      
     </Row>
     <Row className="justify-content-md-center">
      
      
     <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
          category="Today's Attendance"
           title={attendance_count}
           icon= {faCalendar}
           link="/attendance"
           textColor='white'
           bgColor='#CCCCFF'
          />
       </Col>
       <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Pending Leaves"
           title={leaves}
           icon={faBed}
           link="/leaves"
           iconColor="white"
           textColor='white'
           bgColor='#6495ED'
         />

       </Col>

       <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Pending Salary "
           title={salaries}
           icon={faMoneyBill}
           iconColor="shape-tertiary"
           link="/salaries"
           textColor='white'
           bgColor='#9FE2BF'
         />
       </Col>
      

       <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Resignations"
           title={resignations}
           icon= {faRemoveFormat}
           link="/resignations"
           textColor='white'
           bgColor='#40E0D0'
           />
       </Col>
      
       
     </Row>
     <Row>
     <Col xs={12} sm={6} xl={3} md={3} className="mb-4">
         <CounterWidget
           category="Pending Invoices"
           title={invoices}
           icon={faReceipt}
           iconColor="shape-tertiary"
           link="/invoices"
           textColor='white'
           bgColor='#FFBF00'
         />
       </Col>
       <Col xs={12} sm={12} xl={8} md={8} className="mb-4">
          <Card border="light" className="shadow-sm mb-4">
            
            <Card.Body className="pb-0">
            <h5>Upcoming Birthdays of Employees </h5>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>

                  <th className="border-0">Employee</th>
                  <th className="border-0">Date</th>
                </tr>
              </thead>
              <tbody>
              

                {upcoming_birthdays.map((employee, key) => {

                  return (
                    <tr >

                      <td>
                        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{employee.name}</span>
                      </td>
                      <td>{moment(employee.dob).format('MMM D, YYYY')}</td>
                     

                    </tr>
                  );
                })}
              </tbody>

            </Table>
            <Row>
              <Col md={12}>
              {upcoming_birthdays.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Upcoming Birthdays
                </div>}
              </Col>
            </Row>
 

            </Card.Body>
          </Card>
        </Col>
     </Row>
   
     
           </>
    )
  }

  EmployeeDashboard = () => {
    const {tasks, upcoming_birthdays, attendance_setting, leaves,salaries,addAttendance, invoices,  trainings, month, attendance_count, attendance,loading, saving, user_ip_address, ip_address} = this.state;
    return (
      <>
      {addAttendance && (
          <ClockIn
            saved={this.checkAttendance}
            addAttendance={addAttendance}
            ip_address={ip_address}
            toggle={this.toggleAddAttendance}
            
          />
        )}
         {loading && <SpinDiv text={"Loading..."} />}
         {saving && <SpinDiv text={"Clock Out..."} />}
      
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
       
       <h5 style={{fontWeight:'bold'}}>Dashboard Overview</h5>
       
      
        <ButtonGroup>
        {/* <Card.Link as={Link} to='/customer/profile' >
        
          <Button variant="outline-primary" style={{fontWeight: 'bold'}}  size="sm">
            <FontAwesomeIcon icon={faUser} /> Profile
          </Button>
         </Card.Link>
          <Button variant="outline-danger" style={{fontWeight: 'bold'}}  onClick={() => this.logOut()} size="sm"><FontAwesomeIcon icon={faPowerOff} />&nbsp;Sign Out</Button>
          <Button variant="outline-primary" size="sm">Export</Button> */}
        </ButtonGroup>
      </div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
      { ip_address.includes(user_ip_address) || attendance_setting.clockin_without_ip == 1 ? this.showClock(attendance):  <Button disabled variant="danger" size="md">
          <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />Clocking cannot be accessed
        </Button>}
      </div>
        
 

     <Row >
       
       <Col xs={12} sm={6} xl={4} md={4} className="mb-4">
         <CounterWidget
           category={`${month} Attendance`}
           title={attendance_count}
           link="/employee-attendance"
           icon={faCalendar}
           iconColor="shape-secondary"
           textColor='white'
           bgColor='#FFBF00'
         />
       </Col>
       <Col xs={12} sm={6} xl={4} md={4} className="mb-4">
         <CounterWidget
           category={`${month} Salary`}
           title={`${salaries} `}
           icon={faMoneyBill}
           iconColor="shape-tertiary"
           link="/employee/payslip"
           textColor='white'
           bgColor='#CCCCFF'
         />
       </Col>
       <Col xs={12} sm={6} xl={4} md={4} className="mb-4">
         <CounterWidget
           category={`Pending Leaves`}
           title={`${leaves} `}
           icon={faBed}
           iconColor="shape-tertiary"
           link="/employee-leaves"
           textColor='white'
           bgColor='#40E0D0'
         />
       </Col>
     </Row>
     <Row>
     <Col xs={12} sm={6} xl={4} md={4} className="mb-4">
         <CounterWidget
           category="Pending Trainings"
           title={trainings}
           icon={faPlusSquare}
           iconColor="shape-secondary"
           link="/employee/trainings"
           textColor='white'
           bgColor='#DE3163'

         />

       </Col>

        <Col xs={12} sm={12} xl={8} md={8} className="mb-4">
          <Card border="light" className="shadow-sm mb-4">
            
            <Card.Body className="pb-0">
            <h5>Pending, Completed, Ongoing Tasks</h5>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>

                  <th className="border-0">Task</th>
                  <th className="border-0">Project Name</th>
                  <th className="border-0">Status</th>
                </tr>
              </thead>
              <tbody>
              

                {tasks.map((task, key) => {

                  return (
                    <tr >

                      <td>
                        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{task.title}</span>
                      </td>
                      <td>
                        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{task.project_name}</span>
                      </td>
                      <td  style={{ fontWeight: "bold", textTransform: "capitalize" }}>{task.status == 1 ? 'Completed' : 'In Progress'}</td>

                      



                    </tr>
                  );
                })}
              </tbody>

            </Table>
            <Row>
              <Col md={12}>
              {tasks.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Tasks
                </div>}
              </Col>
            </Row>
 

            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={12} xl={8} md={8} className="mb-4">
          <Card border="light" className="shadow-sm mb-4">
            
            <Card.Body className="pb-0">
            <h5>Upcoming Birthdays of Employees </h5>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>

                  <th className="border-0">Employee</th>
                  <th className="border-0">Date</th>
                </tr>
              </thead>
              <tbody>
              

                {upcoming_birthdays.map((employee, key) => {

                  return (
                    <tr >

                      <td>
                        <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>{employee.name}</span>
                      </td>
                      <td>{moment(employee.dob).format('MMM D, YYYY')}</td>
                     

                    </tr>
                  );
                })}
              </tbody>

            </Table>
            <Row>
              <Col md={12}>
              {upcoming_birthdays.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Upcoming Birthdays
                </div>}
              </Col>
            </Row>
 

            </Card.Body>
          </Card>
        </Col>
     </Row>

     
      </>
    )
  }

  
  // Client Dashboard Component 


  ClientDashboard = () => {
    const {projects, invoices, message} = this.state;
    return (
      <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
       
       <h5 style={{fontWeight:'bold'}}>Dashboard Overview</h5>
     
        <ButtonGroup>
        <Card.Link as={Link} to='/customer/profile' >
         
         </Card.Link>
          {/* <Button variant="outline-danger" style={{fontWeight: 'bold'}}  onClick={() => this.logOut()} size="sm"><FontAwesomeIcon icon={faPowerOff} />&nbsp;Sign Out</Button> */}
          {/* <Button variant="outline-primary" size="sm">Export</Button> */}
        </ButtonGroup>
      </div>
 

     <Row className="justify-content-md-center">
       
       <Col xs={12} sm={6} xl={4} md={4} className="mb-4">
         <CounterWidget
           category="Total Projects"
           title={projects}
           icon={faNetworkWired}
           iconColor="shape-tertiary"
           link="/clients/projects"
           textColor='white'
           bgColor='#FFBF00'
         />
       </Col>
       <Col xs={12} sm={6} xl={4} md={4} className="mb-4">
         <CounterWidget
           category="Invoices"
           title={invoices}
           icon={faReceipt}
           iconColor="shape-tertiary"
           link="/clients/invoices"
           bgColor='#40E0D0'
           textColor='white'
         />
       </Col>
       <Col xs={12} sm={6} xl={4} md={4} className="mb-4">
         <CounterWidget
           category="Today's Messages"
           title={`${message}`}
           icon={faFacebookMessenger}
           iconColor="shape-tertiary"
           link="/clients/messages"
           textColor='white'
           bgColor='#DE3163'
         />
       </Col>
     </Row>

     
      </>
    )
  }
 __renderDashboards = () => {
  const {authuser} = this.state;
  if(authuser !== null && authuser.admin == 1){
    return (
     this.AdminDashboard()
    )
    } else if (authuser !== null && authuser.admin !== 1 && authuser.client == 0){
      return (
       this.EmployeeDashboard()
      )
    } else if(authuser !== null && authuser.client == 1){
        return this.ClientDashboard();
    }

 }

  render() {
    const {loading} = this.state;
    return (
       <>
       {loading ?  <SpinDiv text={"Loading..."} /> : this.__renderDashboards()}
       </>
    );
  }
}

export default DashboardOverview;
