import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from "reactstrap";
import { Col, Row, Form, ButtonGroup, Card, Image, Spinner, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Profile1 from "../../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../../assets/img/profile-cover.jpg";
import { toast } from "react-toastify";
import moment from "moment";
import { addAttendanceSetting, getAttendanceSetting } from "../../services/companyService"
import ReactDatetime from "react-datetime";
import SpinDiv from "../components/SpinDiv";
import Resizer from "react-image-file-resizer";
export class AttendanceSetting extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      company: {},
      loading: false,
      saving: false,
      attendance_setting:'',
      leave_setting:'',
      timelog_setting:''
    };
  }

  componentDidMount() {
    this.getAttendance()
  }

  getAttendance = () => {

    this.setState({ loading: true });
    getAttendanceSetting().then(

      (res) => {
        console.log(res)
        this.setState({
          loading: false,
          attendance_setting: res.attendance_setting,
          leave_setting:res.leave_setting,
          timelog_setting:res.timelog_setting
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };




  
  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
  };

  toggleEditAttendance = () => {
    this.setState({ editAttendance: !this.state.editAttendance });
  };

  onChange = (e, state) => {
    const { attendance_setting } = this.state;
    this.setState({ attendance_setting: { ...attendance_setting, [state]: e } });
  };

  onChange2 = (e, state) => {
    const { leave_setting } = this.state;
    this.setState({ leave_setting: { ...leave_setting, [state]: e } });
  };

  onChange3 = (e, state) => {
    const { timelog_setting } = this.state;
    this.setState({ timelog_setting: { ...timelog_setting, [state]: e } });
  };

  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { validation, attendance_setting } = this.state;
    const { disable_clockin, clockin_without_ip } = attendance_setting;

    await this.setState({
      validation: {
        ...validation,
        disable_clockin: disable_clockin !== '' && disable_clockin !== undefined,
        clockin_without_ip: clockin_without_ip !== ''  && clockin_without_ip !== undefined,
        

      },
     
    });
    
    if (Object.values(this.state.validation).every(Boolean)) {
      this.save();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      );
    }
  };

  validationRules = (field) => {
    if (field === "clockin_without_ip") {
      return "Clockin IP status is required";
    }else if (field === "disable_clockin") {
      return "Clockin Status is required";
    }
  
  };


  

  save = () => {
    this.setState({ saving: true });

    const { attendance_setting, leave_setting, timelog_setting, validation } = this.state;
    const { clockin_without_ip, disable_clockin } = attendance_setting;
    const {leave_restriction} = leave_setting;
    const {approval} = timelog_setting;
    this.setState({ saving: true })
  
    addAttendanceSetting({
      clockin_without_ip: clockin_without_ip,
      disable_clockin: disable_clockin,
      leave_restriction:leave_restriction,
      approval:approval
    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false, saving: false });
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "green" }}>Attendance/Leave/Timelog Settings Updated</p>

          </div>
        );

        this.getAttendance();
      },
      (error) => {
        console.log(error);
        this.setState({ saving: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>An error occurred</p>

          </div>
        );
      }
    );
  };




  render() {
    const {saving, loading, attendance_setting, leave_setting, timelog_setting, editAttendance } = this.state;

    return (
      <>

        {loading && <SpinDiv text={"Loading..."} />}
        {saving && <SpinDiv text={"saving..."} />}
        <Row>
          <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Row>
                <Col className="text-right" md={12}>
                  {attendance_setting && (
                    <ButtonGroup>

                      <Button
                        variant={editAttendance ? "outline-secondary" : "outline-primary"}
                        onClick={this.toggleEditAttendance}
                        size="sm"
                      >
                        {editAttendance ? "Discard Changes" : "Edit Setting "}
                      </Button>
                    </ButtonGroup>


                  )}
                </Col>
              </Row>
              <Card.Body>
                <h5 className="mb-4">Attendance, Timelog, Leave Setting</h5>
                <Form>
                  <Row>
                  <Col md={6} style={{ paddingTop: 15 }}>
          
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Disable Employee Clock In<br/>
                        <small>
                          </small></Form.Label>
                        <Input
                          className="custom-control-input"
                          checked={attendance_setting.disable_clockin == 1 ? true : false}
                          onChange={async (e) => {
                            await this.onChange(e.target.checked, "disable_clockin");
                          }}
                          size='md'
                          type="checkbox"
                        />
                      </Form.Group>

                    </Col>
                    <Col md={6} style={{ paddingTop: 15 }}>
          
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Clockin without IP Address<br/>
                        <small>
                          </small></Form.Label>
                        <Input
                          className="custom-control-input"
                          checked={attendance_setting.clockin_without_ip == 1 ? true : false}
                          onChange={async (e) => {
                            await this.onChange(e.target.checked, "clockin_without_ip");
                          }}
                          size='md'
                          type="checkbox"
                        />
                      </Form.Group>

                    </Col>

                    <Col md={6} style={{ paddingTop: 15 }}>
          
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Leave Restriction<br/>
                        <small>
                          </small></Form.Label>
                        <Input
                          className="custom-control-input"
                          checked={leave_setting.leave_restriction == 1 ? true : false}
                          onChange={async (e) => {
                            await this.onChange2(e.target.checked, "leave_restriction");
                          }}
                          size='md'
                          type="checkbox"
                        />
                      </Form.Group>

                    </Col>

                    <Col md={6} style={{ paddingTop: 15 }}>
          
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Allow Timelog to be Approved<br/>
                        <small>
                          </small></Form.Label>
                        <Input
                          className="custom-control-input"
                          checked={timelog_setting.approval == 1 ? true : false}
                          onChange={async (e) => {
                            await this.onChange3(e.target.checked, "approval");
                          }}
                          size='md'
                          type="checkbox"
                        />
                      </Form.Group>

                    </Col>
                    

                  </Row>
                  
                  
                  
                
                  {editAttendance && (
                    <div className="mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={saving}
                        onClick={this.onSave}>
                        Save
                      </Button>
                    </div>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Col>

          
        </Row>
      </>
    );
  }
}

export default AttendanceSetting