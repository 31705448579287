import React, { Component } from "react";
import {  FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../../components/SpinDiv";
import { TimePicker } from 'antd';
import { toast } from "react-toastify";
import Select from 'react-select';
import moment from "moment";
import {faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {getCurrentAttendance, clockIn} from '../../../services/employeeAttendance';
export class ClockIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
    };
  }

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };



  onSaveClock = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {working_from, validation} = this.state;
    await this.setState({
      validation: {
        ...validation,
        working_from: working_from !== '' && working_from !== undefined,
      
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.clockIn();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };

  clockIn = () => {
    this.setState({ saving: true });
    const {working_from} = this.state;
   
    clockIn({
      working_from: working_from,
    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Clocked In");
      },
      (err) => {

        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err) {
            this.showToastError('An error occured')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };



  validationRules = (field) => {
     if(field === "working_from"){
      return "Place of work  is required";
    }
  };

  
  
  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const {toggle, addAttendance } = this.props;

    const { saving,loading, current_year, months, employees, half_day,lateness } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth:500, }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5 style={{fontWeight:'bold'}}>  Clock In</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
                
                <Row style={{paddingTop:10}}>
                  <Col md={12} className="mb-3">
                    <Form.Group id="">
                      <Form.Label>Working From</Form.Label>
                        <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </InputGroup.Text>
                        <Input
                    
                          type="text"
                          style={{ marginRight: 10, width: "fit-content" }}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "working_from");
                          }}
                          placeholder='Working from'
                        
                        />
                         
                      </InputGroup>

                    </Form.Group>
                  </Col>
                </Row>
                
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveClock}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default ClockIn;
