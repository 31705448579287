import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';
const authuser=JSON.parse(localStorage.getItem('user'));

export function getLeaves(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    // return fetch(`${settings.API_URL}leaves`, requestOptions).then(authService.handleResponse);
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}leaves`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/leaves`, requestOptions).then(authService.handleResponse);
    }
}

export function getLeaveTypes(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
  
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}leave_types`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/leave_types`, requestOptions).then(authService.handleResponse);
    }
}

export function addLeave(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}addLeave`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/addLeave`, requestOptions).then(authService.handleResponse);
    }
}



export function editLeave(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}updateleave/${data.id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/updateleave/${data.id}`, requestOptions).then(authService.handleResponse);
    }
}

export function addLeaveType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}leave_type`, requestOptions).then(authService.handleResponse);

    
}

export function editLeaveType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateleavetype/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function getLeave(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}leave/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/leave/${id}`, requestOptions).then(authService.handleResponse);
    }
}

export function getLeaveType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}employee_leave_type`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/employee_leave_type`, requestOptions).then(authService.handleResponse);
    }
}

export function getSingleLeaveType(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
  
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}single_employee_leave_type`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/single_employee_leave_type`, requestOptions).then(authService.handleResponse);
    }
}



export function confirmLeave(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}confirmleave/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteLeave(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
   
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}deleteleave/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/deleteleave/${id}`, requestOptions).then(authService.handleResponse);
    }
}



export function rejectLeave(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}rejectleave/${data.id}`, requestOptions).then(authService.handleResponse);
}