import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Button} from 'antd'
import SpinDiv from "../../components/SpinDiv";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Editor } from '@tinymce/tinymce-react';

export class EditTask extends Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.state = {

      start_date: moment().startOf('month'),
      deadline: moment().endOf('month'),
      id: props.task.id,
      task: props.task,
      employees: [],
      initialEmployees: props.task.assignees.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      employees_id: props.task.user_ids,

      // project:props.task.initial_project.map((opt) => ({
      //   label: opt.title,
      //   value: opt.id,
      // })),
      project: { label: props.task.project_name, value: props.task.project_id },
      project_id: props.task.project_id,
      projects: [],
      description: props.task.description,

    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });

  }


  handleEditorChange(description) {
    this.setState({ description });
  }







  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })
  }




  render() {

    const { toggle } = this.props;

    const { saving, edit, initialEmployees, project, initialProject, description, task, employees, projects, loading } = this.state;
    return (
      <>
        {console.log(project)}

        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={task != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            {/* <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {task && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Task"}



                  </Button>
                )}
              </ButtonGroup>
            </div> */}

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">

              <Row>
                <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      disabled={!edit}
                      placeholder='Enter Title'
                      value={task.title}

                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>
              <Row>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Projects</Form.Label>
                    <Select
                       disabled={!edit}
                      value={project}

                    />

                  </Form.Group>

                </Col>

                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Assignees</Form.Label>
                    <Select
                       disabled={!edit}
                      value={initialEmployees}

                    />


                  </Form.Group>


                </Col>


              </Row>
              <Row>
                <Col md={6}>
                  <Form.Label style={{ paddingRight: 10 }}>Status</Form.Label>
                  {console.log(task.status)}
                  <Input
                    className="custom-control-input"
                    id="status"
                    checked={task.status == 1 ? 'Completed' : 'In progress'}
                    disabled
                    type="text"
                  />

                </Col>


              </Row>

              <Row>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                      disabled={!edit}
                      value={moment(task.start_date).format('MMM DD, YYYY') || ''}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect

                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      End Date
                    </label>
                    <ReactDatetime
                      value={moment(task.deadline).format('MMM DD, YYYY') || ''}
                      disabled={!edit}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>


              <Row>

                <Editor

                  value={description}
                  onEditorChange={this.handleEditorChange}

                  initialValue={task.description}
                  init={{
                    height: 300,
                    menubar: true,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | ' +
                      'bold italic backcolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}

                />

              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                  
                    <Button
                      size="md"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditTask;
