
import { faBuilding, faReceipt, faRunning } from "@fortawesome/free-solid-svg-icons";
import TrainerIndex from "./pages/trainers/TrainerIndex";
import TrainingIndex from "./pages/training/TrainingIndex";

const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]

export let trainingRoute = [
  ...(permissions.includes('show training') === true ?  [  { path: "/trainings",component: TrainingIndex, title:"Trainings",icon:faBuilding}] : []),
  ...(permissions.includes('show training') === true ?  [  { path: "/trainer",component: TrainerIndex, title:"Trainer",icon:faBuilding}] : []),
   
   
   

  ];
