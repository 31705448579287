import React, { Component } from "react";

import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import SpinDiv from "../components/SpinDiv";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { toast } from "react-toastify"
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import { getPaySlip2 } from "../../services/paysalaryService";


export class BulkPaySlip extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      saving: false,
      search: '',
      payslips:[],
      month:'',
      year:'',
      validation: {},
      salary:'',
      show: false,
      edit: false,
      start_year: 2018,
      current_year: new Date().getFullYear(),
      current_year: new Date().getFullYear(),
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      

    };
  }

  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  

  monthName = (mon)=> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
  }

  componentDidMount = () => {
    toast.dismiss();
    toast.configure({ hideProgressBar: true, closeButton: false });
    
  }

  filterPaySlip = async () => {
    this.setState({ filteringPaySlip: true });
    const {month, year } = this.state;
    getPaySlip2({month, year}).then(

      (res) => {
          this.setState({
              filteringPaySlip: false,
              payslips: res.payslips,
          });
      },
      (error) => {
          this.setState({ filteringPaySlip: false });
      }
  );
  };

  
  validationRules = (field) => {
    if (field === "month") {
      return "Month is required";
    } else if(field == 'year'){
      return "Year is required"
    } else if (field == 'payslip'){
        return "PaySlip cannot be nulled";
    }
  };


  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
  };
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };
  
  
  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onSavePaySlip = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { month,year,payslips, validation } = this.state;
    //const {loan_repayment } = newLoan;
    await this.setState({
      validation: {
        ...validation,
        month: month !== '' && month !== undefined,
        year: year !== ''  && year !== undefined,  
        payslip:payslips.length >0
      }
    })
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveSlip();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id]
      })
      await setTimeout(() => toast.error(
        <div style={{ padding: '10px 20px' }}>
          <p style={{ margin: 0, fontWeight: 'bold', color: 'red' }}>Errors:</p>
          {errors.map(v => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: 'red' }}>* {this.validationRules(v)}</p>
          ))}
        </div>
      ), 250);
    }
  }
  saveSlip = () => {
    this.setState({ saving: true });
    const {month, year} = this.state;
    let data = new FormData();
    data.set("year", year);
    data.set("month", month);
    return axios
      .post(
        `${settings.API_URL}bulk_confirm`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        this.setState({ saving: false, edit: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Payment confirmed");
      })
      .catch((err) => {
        if (err) {
          this.showToastError('Could not confirm payment');
          toast.configure({ hideProgressBar: true, closeButton: false });
          this.setState({ saving: false });
        }
      });
  };

  onFilter = async (e, filter) => {
    await this.setState({ [filter]: e });
    await this.filterPaySlip();
  };

  render() {
    const Required = () => (<span style={{ color: 'red' }}>*</span>)
    const { bulkPaySlip, toggle } = this.props;
    const { loading, payslips, filteringPaySlip, month, year, saving, months, years, start_year, current_year} = this.state;
    return (
      <>
      
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={bulkPaySlip != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}

        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5 className="modal-title" id="exampleModalLabel">
                Bulk Payment Confirmation
              </h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                {saving && <SpinDiv text={"Saving..."} />}
                <Col md={12} className="mb-3">
                  <Row style={{ border: '1px #eee solid', padding: '5px 5px 5px', margin: '10px 2px 7px', borderRadius: 7 }}>
                    <Col md={6}>
                    <Form.Label style={{ paddingRight: 10 }}>Salary Month</Form.Label>
                      <Form.Select
                        //value={order}
                        type="select"
                        onChange={(e) => this.onFilter(e.target.value, "month")}
                      >
                        <option value="">Select Month</option>
                        {months.map((p, index) => (
                          <option value={index+1} key={p}>
                            {p}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col md={6}>
                    <Form.Label style={{ paddingRight: 10 }}>Salary Year</Form.Label>
                    <Form.Select
                
                    type="select"
                    onChange={(e) => this.onFilter(e.target.value, "year")}
                  >
                    <option value="">Filter Year</option>
                    {this.range(start_year, current_year).slice(0).reverse().map((p, index) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                
                    {filteringPaySlip
                        ? "loading..."
                        : <Col md={6}>
                            
                            {month !== '' && year !== '' ? <h5 style={{fontWeight:"bold", fontSize:18}}>
                                Total Unpaid Employee {payslips.length}
                            </h5> : <h5 style={{fontWeight:"bold", fontSize:18}}>
                                Filter By Month and Year
                            </h5>}
                           
                         
                        </Col>}
                </Row>
                  <Row style={{ float: 'right' }}>
                  { (month !== '' && year !== '' && payslips.length > 0) &&<div className="modal-footer" style={{ padding: '1rem' }}>
                      <Button
                        size="sm"
                        variant='transparent'
                        data-dismiss="modal"
                        type="button"
                        onClick={toggle}
                      >
                        Close
                      </Button>
                      <Button variant="primary" type="button" disabled={saving} size="sm"
                        onClick={this.onSavePaySlip}
                       >
                        Save
                      </Button>
                    </div> }
                   
                  </Row>                  
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default BulkPaySlip;
