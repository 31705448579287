import { faFacebookMessenger, faRProject } from "@fortawesome/free-brands-svg-icons";
import { faBook, faReceipt } from "@fortawesome/free-solid-svg-icons";
import MessagesIndex from "./pages/customer/message/MessagesIndex";
import ProjectIndex from "./pages/customer/project/ProjectIndex";
import InvoiceIndex from "./pages/invoice/InvoiceIndex";


export let clientRoute = [
    { path: "/clients/projects",component: ProjectIndex, title:"Projects",icon:faBook },
    { path: "/clients/invoices",component: InvoiceIndex, title:"Invoices",icon:faReceipt },
    { path: "/clients/messages",component: MessagesIndex, title:"Messages",icon:faFacebookMessenger },
    
  
  ];