
export const Routes = {
    // pages
    Payroll: { path: "/salaries/:id" },
    DashboardOverview: { path: "/" },
    AttendanceDetail: { path: "/attendance/:id" },
    PerformanceDetail: { path: "/performance/:id/:year/:month" },
    ClientDetail: { path: "/client/:id" },
    AddInvoice: { path: "/new/invoice/:id" },
    EditInvoice: { path: "/invoice/:id" },
    AwardTypeIndex:{path:'/awardtypes'},
    TerminationTypeIndex:{path:'/awardtypes'},
    ViewOfficePerformance: { path: "/office-assistance/:id/:year/:month" },
    ViewAdministrativePerformance:{ path: "/administrative/:id/:year/:month" },
    ViewWritingPerformance:{ path: "/writing/:id/:year/:month" },
    ViewItSupportPerformance:{ path: "/it-support/:id/:year/:month" },
    ViewQualityControl:{ path: "/quality-control-manager/:id/:year/:month" },
    ViewHumanResourcePerformance:{ path: "/human-resources-management/:id/:year/:month" },
    ViewOperationManager:{ path: "/operations-manager/:id/:year/:month" },
    DeptPerformanceDetail:{path: "/departments/performance/:id"},
    EmployeeDeptPerformanceDetail:{path: "/employee/departments/performance/:id"},
    Signin: { path: "/auth/sign-in" },
    Signup: { path: "/auth/sign-up" },
    ForgotPassword: { path: "/auth/forgot-password" },
    ResetPassword: { path: "/auth/reset-password/:id" },
    Lock: { path: "/examples/lock" },
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },

   
};