import React, { Component } from "react";
import {  FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  ButtonGroup,

} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { addLeave, getLeaveType } from "../../services/leaveService";
import AsyncSelect from "react-select/async";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { getLeaveSetting } from "../../services/settingsService";

export class AddLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      title:'',
      amount:'',
      employees: props.employees.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      leave_types:[],
      year:'',
      employee_id:'',
      start_date: moment().startOf('month'),
      applied_on: moment().startOf('month'),
      end_date: moment().endOf('month'),
      leave_setting: '',
      user:'',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
    this.filterLeaveType();
    this.getLeaveSetting();
  }


  getLeaveSetting = () => {

    getLeaveSetting().then(
      (res) => {
        this.setState({
         leave_setting :res.leave_setting,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };


  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  handleChange(e) {
    let obj = JSON.parse(e.target.value) 
    this.setState({leave_type_id: obj})
    this.filterLeaveDays();
   

  }
  

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };


  getEmployees = (inputValue) => {
  
    return this.state.employees.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  loadEmployees = (inputValue, callback) => {
    setTimeout(() => {
      callback(this.getEmployees(inputValue));
    }, 1000);
  };

  filterLeaveType = async () => {
    this.setState({ filteringLeaveType: true });
    const {employee_id, start_date} = this.state;
    const year = moment(start_date,"'MMM D, YYYY'").year();
    {console.log(year)}
    getLeaveType({employee_id, year}).then(

      (res) => {
          this.setState({
            filteringLeaveType: false,
              used_leaves: res.used_leaves,
              user: res.user,
              leave_types: res.leave_types,
              leave_days:'',
              leave_type_id:''
          });
      },
      (error) => {
          this.setState({ filteringLeaveType: false });
      }
  );
  };

  filterLeaveDays = async () => {
    const {leave_type_id} = this.state;
  
   
  };

 

  


  onSaveLeave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { leave_type_id,leave_days, remark,start_date,end_date,leave_reason, applied_on, employee_id, validation } = this.state;
    console.log(leave_reason)
    await this.setState({
      validation: {
        ...validation,
        employee_id: employee_id !== '' && employee_id !== undefined,
        leave_type_id: leave_type_id !== '' &&  leave_type_id !== undefined,
        leave_days: leave_days !== '' &&  leave_days !== undefined,
        leave_reason: leave_reason !== '' && leave_reason !== undefined,
        applied_on: applied_on !== '' && applied_on !== undefined,
        start_date: start_date !== '' && start_date !== undefined,
        end_date: end_date !== '' && end_date !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveLeave();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };


  validationRules = (field) => {
    if (field === "employee_id") {
      return "Employee Name  is required";
    } else if(field == 'leave_type_id'){
      return "Leave Type is required"
    } else if(field == 'leave_reason'){
      return "Reason for Leave is required"
    } else if(field == 'start_date'){
      return "Start Date is required"
    } else if(field == 'end_date'){
      return "End Date is required"
    } else if(field == 'applied_on'){
      return "Date applied is required"
    } else if(field == 'leave_days'){
      return "Leave Days is required"
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({[state]: e})
    await this.filterLeaveType()
    
  }

  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  leaveType(leave_types, used_leaves, user){
    const {leave_setting} = this.state;
    console.log(leave_setting)
    const new_used_leaves = [];
    used_leaves.forEach(x => {
      const obj = new_used_leaves.find(o => o.leave_type_id === x.leave_type_id);
      if (obj) {
        obj.total_leave_days = obj.total_leave_days + x.total_leave_days;
      } else {
        new_used_leaves.push(x);
      }
    });
    return (
      <Form.Select
        id="state"
        required
        onChange={this.handleChange}
        style={{
          marginRight: 10,
          width: "100%",
        }}
      >
        
        <option value="">Choose Leave Type</option>
        {leave_types.length==0 &&  <option value="">loading</option>}
        
         {new_used_leaves.map((p, index) => (
          p.days-p.total_leave_days > 0 ?
          <option value={JSON.stringify({days:p.days-p.total_leave_days, value:p.leave_type_id})} key={index}
           disabled={p.title=='Annual' && leave_setting.leave_restriction ==1 && user.duration < 365 ? true : false}>
            {p.title}({p.total_leave_days}/{p.days})
          </option>:''
        ))}
        
        {leave_types.map((p, index) => (
          <option value={JSON.stringify({days:p.days, value:p.id})} key={index} 
          disabled={p.title=='Annual' && leave_setting.leave_restriction ==1 && user.duration < 365 ? true : false}>
            {p.title}({0}/{p.days})
          </option>
        ))}
      </Form.Select>
    )
  }

  saveLeave = () => {
    this.setState({ saving: true });
    const {leave_type_id,leave_days, remark,start_date,leave_reason, applied_on, employee_id} = this.state;
    console.log(leave_type_id.days);
    
    addLeave({
      employee_id:employee_id,
      leave_type_id:leave_type_id.value,
      leave_days:leave_days,
      year:moment(start_date,'MMM D, YYYY').year(),
      applied_on:applied_on,
      leave_reason:leave_reason,
      remark:remark,
      start_date:start_date, 
      end_date:moment(start_date, 'MMM D, YYYY').add(leave_type_id.days, "days"),

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Leave has been created");
      },
      (err) => {
        
        if (err) {
         
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          if (err.response.status == 403) {
            this.showToastError('You do not have enough permission to create Leave')
          } else {
            this.showToastError('Cannot create Leave')
          }
          this.setState({ saving: false });
        }
        this.setState({ loading: false });
      }
    );
  };


  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { employees, toggle } = this.props;

    const { filteringLeaveType, employee_id, used_leaves, user, saving,leave_type_id,applied_on,start_date, end_date, leave_types, remark, leave_reason,loading } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen={employees != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 700 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                <Button variant="outline-primary" size="sm">
                  Create Leave
                </Button>
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={6}>
                
                <Form.Group>
                  <Form.Label>Select Employee Name</Form.Label>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={this.loadEmployees}
                      onChange={async (property, value) => {
                        console.log(property);
                        await this.setState({
                          employee_id: property.value,
                          selectedEmployee: property.label,
                        });
                        await this.filterLeaveType();
                      }}
                    />
                  </Form.Group>

                </Col>
                
                <Col md="6">
                <FormGroup className="form-date">
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                  >
                   Start Date
                  </label>
                  <ReactDatetime
                      value={start_date}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e=>this.dateFilter(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                </FormGroup>
              </Col>
                  </Row>
                  <Row>
             <Col md="12">
                <FormGroup className="form-date">
                  <label
                    className="form-control-label"
                    htmlFor="input-address"
                  >
                   Applied Date
                  </label>
                  <ReactDatetime
                      value={applied_on}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e=>this.dateFilter(e, 'applied_on')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{width: '100%'}}
                      timeFormat={false}
                    />
                </FormGroup>
              </Col>
            </Row>
                  <Row>
                  <Col md={6} >
                      
                      <Form.Label>Leave Type</Form.Label>
                      {console.log(employee_id)}
                      {filteringLeaveType
                            ? "loading..."
                            : employee_id == '' ? 'loading' :
                            (leave_types && used_leaves && user) && this.leaveType(leave_types, used_leaves, user)
                            }

                    </Col>
                   
                      {leave_type_id && 
                         <Col md={6}>
                       <Form.Label>Leave Days</Form.Label>
                      <Form.Select
                        type="select"
                        onChange={(e) => this.onChange(e.target.value, "leave_days")}
                        >
                        <option value="">Choose Left Days</option>
                        {this.range(1, leave_type_id.days).slice(0).reverse().map((p, index) => (
                          <option value={p} key={p}>
                            {p}
                          </option>
                        ))}
                      </Form.Select>
                      </Col>
                      }
                   
              
             </Row>
             
             <Row>
                <Col md={6} style={{ paddingTop: 15 }}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Reason for Leave</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="reason"
                        placeholder='Enter Leave reason'
                        value={leave_reason}
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "leave_reason");
                        }}
                        type="textarea"
                      />
                    </Form.Group>

                  </Col>
                  <Col md={6} style={{ paddingTop: 15 }}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Remark</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="reason"
                        placeholder='Enter Leave remark'
                        value={remark}
                        onChange={async (e) => {
                          await this.onChange(e.target.value, "remark");
                        }}
                        type="textarea"
                      />
                    </Form.Group>

                  </Col>
              </Row>
              <Row>
                
              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveLeave}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddLeave;
