import React, { Component } from "react";
import { CardHeader, Media, Input, Modal } from "reactstrap";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { editSalary, addSalary } from "../../services/salaryService";
import { InputNumber } from "antd";
export class EditSalary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      salary:props.editSalary,
      title:'',
      amount:''
    };
  }

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onSaveSalary = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { title, amount, validation } = this.state.salary;
    await this.setState({
      validation: {
        ...validation,
        title: title !== '',
        amount: amount !== '',
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveSalary();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };

  validationRules = (field) => {
    if(field == 'amount'){
      return "Salary Amount is required"
    }
  };


  onChange = (e, state) => {
    const {salary} = this.state
    this.setState({salary: {...salary, [state]: e}})
  }


  saveSalary = () => {
    this.setState({ loading: true });
    const { salary} = this.state;
    console.log();
    addSalary({
      amount: salary.basic_salary,
      id:salary.id,
      //employee_id:salary.employee_id,
      user_id:salary.user_id

    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false });
        this.props.saved();
        this.props.toggle();
        this.showToast("Salary has been updated");
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false });
      }
    );
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { editSalary, toggle } = this.props;

    const { saving, loading, title, amount, salary } = this.state;
    return (
      <>
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={editSalary != null}
          toggle={() => !loading && !saving && toggle}
        >
          {loading && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                <Button variant="outline-primary" size="sm">
                  Edit Salary
                </Button>
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            <Row style={{ border: '1px #eee solid', padding: '5px 5px 5px', margin: '10px 2px 7px', borderRadius: 7 }}>
                    
                    <Col md={12} style={{ paddingTop: 15 }}>
                      <Form.Group className="mb-2">
                        <Form.Label style={{ paddingRight: 10 }}>Salary Amount</Form.Label>
                        
                        <Input
                          className="custom-control-input"
                          id="basic_salary"
                          placeholder='Enter Basic Salary Amount'
                         
                          value={salary.basic_salary}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "basic_salary");
                          }}
                          type="number"
                        />
                      </Form.Group>

                    </Col>
                    
                  </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveSalary}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default EditSalary;
