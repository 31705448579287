import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getProjects(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}projects`, requestOptions).then(authService.handleResponse);
}

export function getProjectMembers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}projectmembers`, requestOptions).then(authService.handleResponse);
}


export function addProject(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addproject`, requestOptions).then(authService.handleResponse);
}

export function deleteProject(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteproject/${id}`, requestOptions).then(authService.handleResponse);
}

export function editProject(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateproject/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getProject(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}project/${id}`, requestOptions).then(authService.handleResponse);
}
