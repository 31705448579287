import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
} from "@themesberg/react-bootstrap";

import SpinDiv from "../components/SpinDiv";
import AddResignation from "./AddResignation";
import moment from "moment";
import { throttle,debounce } from "./debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import EditResignation from "./EditResignation";
import { getResignations } from "../../services/resignationService";

export class ResignationIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "", 
      page: 1,
      rows: 10,
      loading: false,
      employees: [],
      resignations:[],
      employees2:[],
      total: 0,

    

    };
    this.searchDebounced = debounce(this.searchResignations, 500);
    this.searchThrottled = throttle(this.searchResignations, 500);
  }

  componentDidMount() {
    this.searchResignations();
  }

  

  searchResignations = () => {
    const { page, rows, search, resignations } = this.state;
    this.setState({ loading: true });
    getResignations({ page, rows, search, resignations }).then(

      (res) => {
        this.setState({
          resignations: res.resignations.data,
          employees: res.employees,
          employees2:res.employees2,
          page: res.resignations.current_page,
          total: res.resignations.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

 

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.getResignations();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };
  
  toggleAddResignation = () => {
    this.setState({ addResignation: !this.state.addResignation });
    this.searchResignations();
  }

  toggleEditResignation = (editResignation) => {
    this.setState({ editResignation});
    this.searchResignations();
   
  };
  toggleCloseResignation=(editResignation)=>{
    this.setState({ editResignation: !this.state.editResignation });
  }

 

  toggleResignation = () => {
    this.setState({ Resignation: !this.state.Resignation });
    this.searchResignations();
  }



  
  



  




  

  render() {
    const { employees, employees2, resignations, total, page, rows, search, loading,addResignation, editResignation } = this.state;
    
    return (
      <>
       
        
        {addResignation && (
          <AddResignation
            saved={this.searchResignations}
            employees={employees}
            toggle={this.toggleAddResignation}
            
          />
        )}

        {editResignation && (
          <EditResignation
            saved={this.searchResignations}
            employees={employees2}
            resignation={editResignation}
            toggle={this.toggleCloseResignation}
          />
        )}

        
        {/* {deleteResignation && (
          <DeleteResignation
            saved={this.searchResignations}
            deleteResignation={deleteResignation}
            toggle={this.toggleCloseDelete}
          />
        )} */}

      
       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Resignations</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddResignation()}>
                    Create Resignation
                  </Button>
              
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="8">
          <h5 className="mb-0">Resignations
          <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Employees</th>
                  <th className="border-0">Notice Date</th>
                  <th className="border-0">Resignation Date</th>
                  <th className="border-0">actions</th>
                </tr>
              </thead>
              <tbody>
                
                {resignations.map((resignation, key) => {
               
                  return (
                    <tr >
                      <th scope="row">
                      <td>
                        <Media className="align-items-center">
                          <a
                            className="avatar rounded-circle mr-3"
                            href="#p"
                            onClick={(e) => e.preventDefault()}
                          >
                            <img
                              style={{
                                maxHeight: 50,
                                maxWidth: 50,
                                borderRadius: '100%',
                              }}
                              alt="..."
                              src={
                                resignation.image ||
                                require("../../assets/img/brand/user.jpg")
                              }
                            />
                           
                          </a>
                          </Media>
                        </td>
                        <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{resignation.name}</span>
                      </td>
                        
                      </th>
                      <td>{moment(resignation.notice_date).format('MMM D, YYYY')}</td>
                        
                    
                      <td>{moment(resignation.resignation_date).format('MMM D, YYYY')}</td>
      
                    
                      <td>
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" style={{fontWeight:"bold",textTransform:"capitalize"}} onClick={() => this.toggleEditResignation(resignation)} size="sm">view</Button>
                          
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>
            <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {resignations.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No Resignation Recorded yet
                </div>}
              {resignations.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} resignations of Employees`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default ResignationIndex;
