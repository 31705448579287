import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getDepartments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}departments`, requestOptions).then(authService.handleResponse);
}

export function addDepartments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}add_departments`, requestOptions).then(authService.handleResponse);
}

export function editDepartment(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatedepartment/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteDepartment(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletedepartment/${id}`, requestOptions).then(authService.handleResponse);
}