import React, { Component } from "react";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,

} from "@themesberg/react-bootstrap";
import SpinDiv from "../../components/SpinDiv";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { toast } from "react-toastify";
import { getMarkedDepartmentPerformances } from "../../../services/departmentPerformanceService";
import { Tooltip } from 'antd';
import {faCheck, faBoxOpen,faUsers,  faStepForward, faEnvelope, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from 'antd';
import Chart from "react-apexcharts";
import { Popover} from 'antd';

const attendanceOverview = (
  <div>
    <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
            <p>Weekly Attendance is calcuted as follows</p>
            <p>Working Hours for a week = No of Days of week x 9hrs</p>
            <p> Employee working Hours for a week = Attendance (total No of hours worked) X No of working Days</p>
            <h6> Attendance Percentage =   (Employee working Hours for a week /  Employee working Hours for a week) x 20</h6>

        </Card.Body>
    </Card>
  </div>
);
const performanceOverview = (
    <div>
      <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
              <h5>Performance</h5>
              <p>(Sum total Rating / No of weeks (No of time it was rated) x 5) x 100</p>
              <p>This arithmetic operation works for content_upload, Policy and Compliance, management_support, Training & Development</p>
             
  
          </Card.Body>
      </Card>
    </div>
  );



export class ViewOfficePerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            validation: {},
            show: false,
            edit: false,
            id: props.match.params.id,
            year:props.match.params.year,
            month:props.match.params.month,
            performances:[],
            overall_management_support:0,
            overall_quality_of_service:0,
            overall_content_upload:0,
            overall_safety_management:0,
    
        };
    }

    componentDidMount() {
        toast.configure({ hideProgressBar: true, closeButton: false });
        this.getMarkedPerformances();
        
    }





    getMarkedPerformances = () => {
        const { id } = this.state;
        this.setState({  loading: true})
        getMarkedDepartmentPerformances(id).then(
            
            (res) => {
                this.setState({
                    performances: res.marked_performances,
                    loading: false
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };
    sumArray(arr){
        const sum = arr.reduce((partialSum, a) => partialSum + a, 0);
        return sum;
    }


    getManagementSupportPercentage(performances){
        let management_support=[];
        let total_management_support = performances.length*5;
        performances.map((p, index)=>{
            return management_support.push( p.management_support);
        })
       
         let percentage = (this.sumArray(management_support)/total_management_support)*100;
         return Math.round(percentage);
        

    }

     getSafetyManagementPercentage(performances){
        let safety_management =[];
        let total_safety_management  = performances.length*5;
        performances.map((p, index)=>{
            return safety_management .push( p.safety_management );
        })
       
         let percentage = (this.sumArray(safety_management )/total_safety_management )*100;
         return Math.round(percentage);
        

    }

     getQualityServicePercentage(performances){
        let quality_of_service=[];
        let total_quality_of_service = performances.length*5;
        performances.map((p, index)=>{
            return quality_of_service.push( p.quality_of_service);
        })
       
         let percentage = (this.sumArray(quality_of_service)/total_quality_of_service)*100;
       
         return Math.round(percentage);
         
    }
    

    getContentUploadPercentage(performances){
        let content_upload=[];
        let total_content_upload = performances.length*5;
        performances.map((p, index)=>{
            return content_upload.push( p.content_upload);
        })
       
         let percentage = (this.sumArray(content_upload)/total_content_upload)*100;
         return Math.round(percentage);
        

    }

    getSingleWeekContentUploadPercentage(performances){
        let content_upload=[];
        let total_content_upload = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return content_upload.push( p.content_upload);
        })
       
         let percentage = (content_upload[0]/total_content_upload)*40;
         return Math.round(percentage);
        

    }

    getSingleWeekManagementSupportPercentage(performances){
        let management_support=[];
        let total_management_support = 5;
        performances.filter(p => p.week == 1).map((p, index)=>{
            return management_support.push( p.management_support);
        })
       
         let percentage = (management_support[0]/total_management_support)*20;
         return Math.round(percentage);
        

    }

    
   

    monthName = (mon)=> {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }

    getName(performances){
        var name = performances.map(function(p) {
           return p.department_name;
       });
       return name[0];
   }

    weeklyPerformanceChat(performances){
        
        const week_one_performances = performances.filter(p => p.week == 1);
        const week_two_performances = performances.filter(p => p.week == 2);
        const week_three_performances = performances.filter(p => p.week == 3);
        const week_four_performances = performances.filter(p => p.week == 4);
    
       
    
        let content_upload_one= week_one_performances.length !== 0 ? (this.getContentUploadPercentage(week_one_performances)/100)*40 : 0;
        let content_upload_two= week_two_performances.length !== 0 ? (this.getContentUploadPercentage(week_two_performances)/100)*40 : 0;
        let content_upload_three= week_three_performances.length !== 0 ? (this.getContentUploadPercentage(week_three_performances)/100)*40 : 0;
        let content_upload_four= week_four_performances.length !== 0 ? (this.getContentUploadPercentage(week_four_performances)/100)*40 : 0;
        
        let management_support_one= week_one_performances.length !== 0 ? (this.getManagementSupportPercentage(week_one_performances)/100)*20 : 0;
        let management_support_two= week_two_performances.length !== 0 ? (this.getManagementSupportPercentage(week_two_performances)/100)*20 : 0;
        let management_support_three= week_three_performances.length !== 0 ? (this.getManagementSupportPercentage(week_three_performances)/100)*20 : 0;
        let management_support_four= week_four_performances.length !== 0 ? (this.getManagementSupportPercentage(week_four_performances)/100)*20 : 0;

        let quality_of_service_one= week_one_performances.length !== 0 ? (this.getQualityServicePercentage(week_one_performances)/100)*20 : 0;
        let quality_of_service= week_two_performances.length !== 0 ? (this.getQualityServicePercentage(week_two_performances)/100)*20 : 0;
        let quality_of_service_three= week_three_performances.length !== 0 ? (this.getQualityServicePercentage(week_three_performances)/100)*20 : 0;
        let quality_of_service_four= week_four_performances.length !== 0 ? (this.getQualityServicePercentage(week_four_performances)/100)*20 : 0;

        let safety_management_one= week_one_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_one_performances)/100)*20 : 0;
        let safety_management_two= week_two_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_two_performances)/100)*20 : 0;
        let safety_management_three= week_three_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_three_performances)/100)*20 : 0;
        let safety_management_four= week_four_performances.length !== 0 ? (this.getSafetyManagementPercentage(week_four_performances)/100)*20 : 0;

    
        let overall_week_one_performance = content_upload_one+safety_management_one+quality_of_service_one+management_support_one;
        let overall_week_two_performance = content_upload_two+safety_management_two+quality_of_service+management_support_two;
        let overall_week_three_performance = content_upload_three+safety_management_three+quality_of_service_three+management_support_three;
        let overall_week_four_performance = content_upload_four+safety_management_four+quality_of_service_four+management_support_four;

       
        const data  = {
            options: {
              chart: {
                id: "basic-bar"
              },
              xaxis: {
                categories: ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4']
              }
            },
            series: [
              {
                name: "Weekly overall-performance",
                data: [overall_week_one_performance,overall_week_two_performance,overall_week_three_performance,overall_week_four_performance,100]
              }
            ]
          };
          return (
              <Chart
              options={data.options}
              series={data.series}
              type="line"
              width="750"
              height="250"
            />
          )
    }

    performanceChart = (performances) => {
        let content_upload= this.getContentUploadPercentage(performances);
        let management_support= this.getManagementSupportPercentage(performances);
        let quality_of_service= this.getQualityServicePercentage(performances);
        let safety_management = this.getSafetyManagementPercentage(performances);
        
        const data  = {
          options: {
            chart: {
              id: "basic-bar"
            },
            xaxis: {
              categories: ['','CONTENT UPLOAD', 'SAFETY MANAGEMENT', 'QUALITY OF SERVICE', 'MANAGEMENT SUPPORT','']
            }
          },
          series: [
            {
              name: "overall-performance",
              data: [0, content_upload,safety_management ,quality_of_service,management_support,100 ]
            }
          ]
        };
        return (
            <Chart
            options={data.options}
            series={data.series}
            type="line"
            width="750"
            height="250"
          />
        )
    }

    render() {

        const { saving, month, year, loading, performances } = this.state;
        return (
            <>


                {loading && <SpinDiv text={"Loading..."} />}
                <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/performances">Performance</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Detail</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>

                                    {/* <Button variant="outline-primary" size="sm">
                                        Export
                                    </Button> */}
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                    <Row>
                        <Col md={12}>
                            <Card border="light" className="shadow-sm mb-4">
                                <Card.Body className="pb-0">
                                    <Row>
                                        <Col md={8}></Col>
                                        <Col md={4} style={{marginBottom:10}}>
                                            
                                                
                                                <ButtonGroup>
                                                    {/* <Button variant="outline-primary" size="sm">
                                                        <Popover content={attendanceOverview} title="Attendance Overview">
                                                            Attendance overview
                                                        </Popover>
                                                    </Button> */}
                                                    <Button variant="outline-primary" size="sm">
                                                        <Popover content={performanceOverview} title="Performance Overview">
                                                            Performance OverView
                                                        </Popover>
                                                    </Button>
                                                </ButtonGroup>
                                           
                                        </Col>
                                    </Row>
                                    <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                        style={{marginBottom:10, fontSize:30}}
                                    >
                                    <thead className="thead-light">
                                        <tr>
                                        <th className="border-0" style={{fontSize:15}}>Department: {this.getName(performances)} </th>
                                        <th className="border-0" style={{fontSize:15}}>Date: {this.monthName(month)}, {year}</th>
                                        
                                        
                                        </tr>
                                    </thead>
                                    
                                    
                                    </Table>
                                
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Col md={12}>
                       

                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                               <Row style={{marginBottom:10}}>
                                    
                                   <Col md={4}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                    Content Upload
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faEnvelope}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getContentUploadPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Safety Management
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faStepForward}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getSafetyManagementPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    {/* <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Attendance
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faAngleUp}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{performances.length !== 0 ?this.getQualityServicePercentage(performances)+'%': '0%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col> */}
                                    <Col md={4}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Management Support
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faBoxOpen}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getManagementSupportPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                   
                                    
                               </Row>
                              

                        
                            </Card.Body>
                            <Row style={{margin:10}}>
                                <Col md={12}>
                                    <Card border="light" className="shadow-sm mb-4">
                                        <Card.Body className="pb-0">
                                            <Table
                                            responsive
                                            className="table-centered table-nowrap rounded mb-0"
                                            >
                                            <thead className="thead-light">
                                                <tr>
                                                <th className="border-0">No</th>
                                                <th className="border-0">Week</th>
                                                <th className="border-0">Safety Management</th>
                                                <th className="border-0">Quality Of Service</th>
                                                <th className="border-0">Management Support</th>
                                                <th className="border-0">Content Upload</th>
                                               
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                {performances.map((performance, key) => {
                                            
                                                return (
                                                    <tr style={{fontWeight:"bold"}}>
                                                    <th scope="row">
                                                        <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{key+1}</span></td>
                                                        
                                                    </th>
                                                
                                                    <td>{performance.week}</td>
                                                    <td><Rate style={{color:'green'}} value={performance.safety_management } /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.quality_of_service} /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.management_support} /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.content_upload} /></td>
                                                    
                                                   
                                                    
                                                    
                                                    </tr>
                                                );
                                                })}
                                            </tbody>
                                            
                                            </Table>
                                            
                                        </Card.Body>
                                        </Card>
                                </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>

                               <Col md={10}>
                                    <h5>Weekly Overall Performance</h5>
                                    {this.weeklyPerformanceChat(performances)}
                               </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>
                               <Col md={10} style={{marginBottom:20}}>
                                <h5>Performance BreakDown Graph</h5>
                                {this.performanceChart(performances)}
                               </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>

            </>
        );
    }
}

export default ViewOfficePerformance;
