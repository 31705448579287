import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";
import { getIpAddress } from "../../services/designationService";
import { toast } from "react-toastify";
import AddIpAddress from "./AddIpAddress";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  ButtonGroup,
  Breadcrumb,
  Form,
} from "@themesberg/react-bootstrap";

import SpinDiv from "../components/SpinDiv";
import EditIpAddress from "./EditIpAddress";
import DeleteIpAddress from "./DeleteIpAddress";

export class IpAddressIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      ips:[],
    }
  }

  componentDidMount() {
   this.getIpAddress();
  }

  

  

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  };
  getIpAddress = () => {

    const { page, rows, search} = this.state;
    this.setState({ loading: true });
    getIpAddress({ page, rows, search }).then(
      (res) => {
        this.setState({
          loading: false,
          ips:res.ips,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  

  toggleAddIpAddress = () => {
    this.setState({ addIpAddress: !this.state.addIpAddress });
  };

  
  
  onChange = (e, state) => {
    this.setState({ [state]: e });
  };
  toggleEdit = (editIpAddress) => {
    this.setState({ editIpAddress });
  };

  toggleDeleteIpAddress=(deleteIpAddress)=>{
    this.setState({ deleteIpAddress });
  }
  
  
  render() {
    const {
      ips,
      total,
      editIpAddress,
      addIpAddress,
      deleteIpAddress,
      rows,
      search,
      loading,
      filtering
    } = this.state;
    return (
      <>
      
        {addIpAddress && (
          <AddIpAddress
            saved={this.getIpAddress}
            addIpAddress={addIpAddress}
            toggle={() => this.setState({ addIpAddress: null })}
          />
        )}


        {deleteIpAddress && (
          <DeleteIpAddress
            saved={this.getIpAddress}
            deleteIpAddress={deleteIpAddress}
            toggle={() => this.setState({ deleteIpAddress: null })}
          />
        )}


        {editIpAddress && (
          <EditIpAddress
            saved={this.getIpAddress}
            editIpAddress={editIpAddress}
            toggle={() => this.setState({ editIpAddress: null })}
          />
        )}

       


        {loading && <SpinDiv text={"Loading..."} />}
        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#IPs">IP Address</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => this.toggleAddIpAddress()}
                  >
                    Create IP Address
                  </Button>
                 
                  
                  {/* <Button variant="outline-primary" size="sm">
                    Export
                  </Button> */}
                </ButtonGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="7">
            <h6>IP Address</h6>
           
          </Col>
          
          
        </Row>
        
        <Card border="light" className="shadow-sm mb-4">
          
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">IPs</th>
                </tr>
              </thead>
              <tbody>
                {ips.map((ip, index)=>(
                   <tr>
                   <td>{ip.ip_address}</td>
                   <td>
                     <ButtonGroup>
                     <Button
                              variant="outline-primary"
                              onClick={() => this.toggleEdit(ip)}
                              size="sm"
                            >
                              Edit
                            </Button>
                            <Button
                          variant="outline-danger"
                          onClick={() => {//console.log('111')
                            this.toggleDeleteIpAddress(ip)
                          }}            
                          size="sm"
                        >
                          Delete
                        </Button>
                     </ButtonGroup>
                            
                    </td>
                      
                   </tr>
                ))}
               
              
            
                
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default IpAddressIndex;
