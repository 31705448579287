
import {faCalendarMinus, faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import LeaveIndex from "./pages/leaves/LeaveIndex";
import AttendanceIndex from "./pages/attendance/AttendanceIndex";

const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]


export let attendanceLeaveRoute = [
  ...(permissions.includes('show leave') === true ?  [ { path: "/leaves",component: LeaveIndex, title:"Leaves",icon:faCalendarMinus},] : []),
  ...(permissions.includes('show attendance') === true ?  [  { path: "/attendance",component: AttendanceIndex, title:"Attendance",icon:faCalendarPlus},] : []),
    
  
   

  ];
