import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';
const authuser=JSON.parse(localStorage.getItem('user'));

export function getMessages(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.client == 1){
        return fetch(`${settings.API_URL}client/messages`, requestOptions).then(authService.handleResponse);
    }
  
}

export function getAdmins(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    
    return fetch(`${settings.API_URL}client/message/admin`, requestOptions).then(authService.handleResponse);
}

export function addMessage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}client/send/message`, requestOptions).then(authService.handleResponse);
}



