import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow, faPiggyBank } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Progress, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { getClients } from "../../services/clientService";
import { getEmployees} from "../../services/employeeService";
import { getDepartments } from "../../services/departmentService";
import { getCategories } from "../../services/categoryService";
import AddClient from "../clients/AddClient"
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { Editor } from '@tinymce/tinymce-react';
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { currencies } from "./Currency";
import AddCategories from "../categories/AddCategories";
import axios from 'axios'
import DownloadLink from "react-download-link";
export class Editproject extends Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.state = {
      
      start_date: moment().startOf('month'),
      deadline: moment().endOf('month'),
      id: props.project.id,
      project: props.project,
      progress:props.project.progress,
      departments:props.departments.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      clients:props.clients.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      categories:props.categories.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      documents:[],
      summary: props.project.summary,
      employees:props.employees.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      initialEmployees:props.project.members.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
      is_deadline: props.project.deadline !== null  ? true : false,
      employees_ids:props.project.user_ids,


    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });


  }


  handleEditorChange(summary) {
    this.setState({ summary });
  }

  getDataFromURL = (url) => new Promise((resolve, reject) => {
    setTimeout(() => {
        fetch(url)
            .then(response => response.text())
            .then(data => {
                resolve(data)
            });
    });
}, 2000);

  fileChangedHandler = (e) => {
    this.setState({ documents: [...this.state.documents, ...e.target.files] })
  }

 
 

  handleRemoveItem = (idx) => () => {
    this.setState({ documents: this.state.documents.filter((s, sidx) => idx !== sidx) });
  }

  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });


  fileChangedHandler = (e) => {
    this.setState({ documents: [...this.state.documents, ...e.target.files] })
  }

  loadOptions = (data) => async (search, prevOptions) => {
    await this.sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };


  toggleAddClient = () => {
    this.setState({ addClient: !this.state.addClient });
  };

  toggleAddCategory = () => {
    this.setState({ addCategory: !this.state.addCategory });
  };

  // getEmployees = () => {
  //   this.setState({
     
  //   });
  // };




  handleEmployeeChange =  (initialEmployees) => {

    let new_employees_id = initialEmployees.map(obj => {
      return obj.value
   })
    this.setState({
      initialEmployees:initialEmployees,
      employees_ids: new_employees_id,
    });
 
  }


 
  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })
  }

  onSaveProject = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {title, currency, cost, client_id,
       department_id, start_date, deadline } = this.state.project;
       console.log(start_date)
    const {  validation, progress, summary, employees_ids } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== '' && title !== undefined,
        summary: summary !== '' && summary !== undefined,
        start_date: start_date !== null && start_date !== undefined,
        deadline: deadline !== '' && deadline !== undefined,
        department_id: department_id !== '' && department_id !== undefined,
        client_id: client_id !== '' && client_id !== undefined,
        currency: currency !== '' && currency !== undefined,
        cost: cost !== '' && cost !== undefined,
        progress: progress !== '' && progress !== 0,
        employees_ids: employees_ids.length > 0,
        
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveProject();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field === "department_id") {
      return "Department is required";
    } else if (field == 'client_id') {
      return "Client is required"
    } else if (field == 'category_id') {
      return "Category is required"
    } else if (field == 'title') {
      return "Title is required"
    } else if (field == 'summary') {
      return "Summary is required"
    } else if (field == 'start_date') {
      return "Starting Date is required"
    } else if (field == 'deadline') {
      return "Deadline is required";
    }else if (field == 'cost') {
      return "Budget is required";
    }else if (field == 'currency') {
      return "Currency is required";
    }else if (field == 'employees_ids') {
      return "Project Members are required"
    } 
  };




  saveProject = () => {

    this.setState({ saving: true });
    const {title, currency, cost, client_id,
      department_id, start_date, deadline, category_id } = this.state.project;
      const {progress, employees_ids, is_deadline, documents, summary, id}=this.state;
      console.log(start_date)
    let data = new FormData();
    if(is_deadline){
      data.set("deadline", deadline);
    }
    data.set("start_date", start_date);
   
    data.set("client_id", client_id);
    data.set("category_id", category_id);
    data.set("department_id", department_id);
    data.set("title", title);
    data.set("progress", progress);
    data.set("cost", cost);
    data.set("summary", summary);
    data.set("currency", currency);
    let op = employees_ids;
    for (var i in op) {
      data.append(`employees[${i}]`, op[i])
    }
    
   if(documents.length > 0){
    let op = documents;
    for (var i in op) {
      data.append(`documents[${i}]`, op[i])
    }
   }
    return axios
      .post(
        `${settings.API_URL}updateproject/${id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Project Created");
        },
        (err) => {

          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err.errors) {
              this.showToastError('Wrong File Type')
            }
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );


  };

  

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    const { project } = this.state

    this.setState({ project: { ...project, [state]: e } })
  }

  onChange2 = (e, state) => {
    this.setState({ [state]: e });
  };

  increase = () => {
   
    let progress = this.state.progress + 10;
    if (progress > 100) {
      progress = 100;
    }
    this.setState({ progress });
  };

  decline = () => {
    
    let progress = this.state.progress - 10;
    if (progress < 0) {
      progress = 0;
    }
    this.setState({ progress });
  };

  

  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  toggleDownload= (downloadProject) => {
    this.setState({ downloadProject });
    downloadProject.blob().then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = downloadProject.url;
      a.click();
    })
  }






  render() {

    const { toggle } = this.props;

    const { saving,addClient,addCategory,is_deadline, categories, initialEmployees, employees, edit, summary, project, clients, progress, departments, loading } = this.state;
    return (
      <>
      {console.log(clients)}
      {addClient && (
                <AddClient
                    saved={this.getClients}
                    addClient={addClient}
                    toggle={() => this.setState({ addClient: null })}
                />
            )}

              {addCategory && (
                <AddCategories
                    saved={this.getCategories}
                    addCategories={addCategory}
                    toggle={() => this.setState({ addCategory: null })}
                />
            )}
        <Modal
          className="modal-dialog modal-dialog-top"
          isOpen={project != null}
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {saving && <SpinDiv text={"Saving..."} />}
          {loading && <SpinDiv text={"loading..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                {project && (

                  <Button color={edit ? "secondary" : "success"}
                    onClick={this.toggleEdit}
                    size="sm" variant="outline-primary">
                    {edit ? "Discard Changes" : "Edit Project"}



                  </Button>
                )}
              </ButtonGroup>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
            <Row>
              {project.documents && <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  {/* <th className="border-0">Project</th> */}
                  <th className="border-0">Name</th>
                  <th className="border-0">Download</th>
                 
                </tr>
              </thead>
              <tbody>
                
                {project.documents.map((document, key) => {
               
                  return (
                    <tr >
                     
                      <td>
                        <span style={{fontWeight:"bold",textTransform:"capitalize"}}>{document.name}</span>
                      </td>
                      
                      <td>
                        
                        
                        <ButtonGroup>
                          <Button variant="outline-primary" 
                         
                          
                          style={{fontWeight:"bold",textTransform:"capitalize"}} 
                          size="sm"><a target="_blank" href={document.url} > Download </a></Button>
                          
                        </ButtonGroup>
                      </td>

                      
                      
                    </tr>
                  );
                })}
              </tbody>
             
            </Table>}
            </Row>
            <Row>
              <Col md={7} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      disabled={!edit}
                      placeholder='Enter Title'
                      value={project.title}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
                <Col md={5} style={{marginTop:15}}>
                  <Form.Group className="mb-2">
                    <Form.Label>Members</Form.Label>
                    <AsyncPaginate
                      maxMenuHeight={120}
                      value={initialEmployees}
                      isMulti
                      loadOptions={this.loadOptions(employees)}
                      onChange={this.handleEmployeeChange}
                    />

                   
                  </Form.Group>
                  

                </Col>
            
              </Row>
            <Row>
              <Row>
                <Col md={6}>
                <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Currency</Form.Label>
                    <Form.Select
                      id="state"
                      disabled={!edit}
                      required
                      value={project.currency}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "currency");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Currency</option>
                      {currencies.length == 0 && <option value="">loading</option>}
                      {currencies.map((p, index) => (
                        <option value={p.abbrev} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  
                </Col>
                <Col md={6}>
                <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Budget</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      disabled={!edit}
                    
                      value={project.cost}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "cost");
                      }}
                      type="text"
                    />
                  </Form.Group>

                  
                </Col>
              </Row>
            <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Client</Form.Label>

                    <Form.Select
                      id="state"
                      disabled={!edit}
                      required
                      value={project.client_id}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "client_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Clients</option>
                      {clients.length == 0 && <option value="">loading</option>}
                      {clients.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <   ButtonGroup style={{display:'inline-table'}}>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => this.toggleAddClient()}
                        >
                            + New Client
                        </Button>


                    </ButtonGroup>

                </Col>
                <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Department</Form.Label>

                    <Form.Select
                      id="state"
                      required
                      disabled={!edit}
                      value={project.department_id}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "department_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">choose Departments</option>
                      {departments.length == 0 && <option value="">loading</option>}
                      {departments.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                </Col>

              </Row>
              <Row style={{marginTop:20}}>
              <Col md="5">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                        disabled={!edit}
                      value={moment(project.start_date).format('MMM DD, YYYY') || ''}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.onChange(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                {is_deadline && (
                  <>
                  
                <Col md="5">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      End Date
                    </label>
                    <ReactDatetime
                      value={moment(project.deadline).format('MMM DD, YYYY') || ''}
                      disabled={!edit}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.onChange(e, 'deadline')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                  </>
                )}
              {/* <Col md={2}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Allow Deadline</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="is_deadline"
  
                        checked={is_deadline}
                        onChange={async (e) => {
                          await this.onChange(e.target.checked, "is_deadline");
                        }}
                        

                        type="checkbox"
                      />
                    </Form.Group>
                </Col> */}
                
              </Row>
              <Row>
              <Col md={6} >
                  <Form.Group className="mb-2">
                    <Form.Label>Category</Form.Label>

                    <Form.Select
                      id="state"
                      disabled={!edit}
                      required
                      value={project.category_id}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "category_id");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Categories</option>
                      {categories.length == 0 && <option value="">loading</option>}
                      {categories.map((p, index) => (
                        <option value={p.value} key={p}>
                          {p.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                  <   ButtonGroup style={{display:'inline-table'}}>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => this.toggleAddCategory()}
                        >
                            + New Category
                        </Button>


                    </ButtonGroup>

                </Col>
                <Col md={6} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Project Progress</Form.Label>
                    <Progress type="circle"  onChange2={e=>this.onChange(e, 'progress')} value={progress || 0} percent={progress} />
                    <Button.Group>
                      <Button   disabled={!edit} onClick={this.decline} icon={<MinusOutlined />} />
                      <Button   disabled={!edit} onClick={this.increase} icon={<PlusOutlined />} />
                    </Button.Group>
                  </Form.Group>

                </Col>
                
              </Row>
              <Row>
              <Col md={6}>
                  <div className="d-xl-flex align-items-center">

                    <div className="company-avatar xl-avatar">

                      {this.state.documents.map((file, key) => (
                        <Button style={{ paddingBottom: 10 }} variant="outline-primary" key={file.name} onClick={this.handleRemoveItem(key)}>
                          {file.name}&nbsp;<span style={{ color: 'red', fontWeight: 'bold', fontSize: 15 }}>x</span>
                        </Button>
                      ))}

                    </div>
                    <div className="file-field">
                      <div className="d-flex justify-content-xl-center ms-xl-3">
                        <div className="d-flex">


                          <input onChange={this.fileChangedHandler} type="file" multiple />
                          <div className="d-md-block text-start">
                            <span className="icon icon-md">
                              <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                            </span>
                            <div className="fw-normal text-dark mb-1">Choose Documents</div>
                            <div className="text-gray small">Pdf, doc, docx, ppt, txt, JPG, GIF or PNG. Max size of 2MB</div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </Col>


              </Row>
              <Row>
                          
                          <Editor
                          
                            value={summary}
                            onEditorChange={this.handleEditorChange}

                            initialValue={project.summary}
                            init={{
                              height: 300,
                              menubar: true,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent | ' +
                                'removeformat | help',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}

                          />

                        </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="md"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveProject}
                    >
                      Save
                    </Button>
                    <Button
                      size="md"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default Editproject;
