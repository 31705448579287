import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getClients(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}clients`, requestOptions).then(authService.handleResponse);
}

export function getDocuments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}client/documents`, requestOptions).then(authService.handleResponse);
}

export function getProjects(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}getclient_projects`, requestOptions).then(authService.handleResponse);
}




export function addClient(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addclient`, requestOptions).then(authService.handleResponse);
}

export function deleteClient(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteuser/${id}`, requestOptions).then(authService.handleResponse);
}

export function editClient(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateclient/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getClient(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}client/${id}`, requestOptions).then(authService.handleResponse);
}

export function getEmployees(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}clients/employees`, requestOptions).then(authService.handleResponse);
}


