import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import {getPaySalary } from "../../../services/paysalaryService";
import SpinDiv from "../../components/SpinDiv";
import { throttle,debounce } from "../leaves/debounce";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import PaySlip from "./PaySlip";


 
export class PaySalaryIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      payslips: [],
      commissions:[],
      allowances:[],
      loans:[],
      salary:'',
      start_year: 2018,
      current_year: new Date().getFullYear(),
      year:new Date().getFullYear(),
      month: new Date().getMonth()+1,
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      total: 0,
    

    };
    this.searchDebounced = debounce(this.searchPaySlips, 500);
    this.searchThrottled = throttle(this.searchPaySlips, 500);
  }

  componentDidMount() {
    this.searchPaySlips();
    //this.getEmployees();
   
  }

  monthName = (mon)=> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
  }

  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

 
 

  
  searchPaySlips = () => {
    const { page, rows, search,payslips, year, month  } = this.state;
    this.setState({ loading: true });
    getPaySalary({ page, rows, search, payslips,year, month  }).then(
      (res) => {
        this.setState({
          payslips: res.payslips.data,
          page: res.payslips.current_page,
          total: res.payslips.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };
  onFilter = async (e, filter) => {
    await this.setState({ [filter]: e });
    await this.searchPaySlips();
  };

  

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onPage = async (page,rows) => {
    await this.setState({page,rows});
    await this.getPaySlips();
  }
  
  handleSearch = event => {
    this.setState({ search: event.target.value }, () => {
      if(this.state.search < 5){
        this.searchThrottled(this.state.search);
      }else{
        this.searchDebounced(this.state.search);
      }
    
    });
  };

  toggleAddPaySlip = () => {
    this.setState({ addPaySlip: !this.state.addPaySlip });
  };

  toggleBulkPaySlip = () => {
    this.setState({ bulkPaySlip: !this.state.bulkPaySlip });
  };

  
  toggleEdit = (editPaySlip) => {
    this.setState({ editPaySlip });
  };
  toggleConfirm = (confirmPaySlip) => {
    this.setState({ confirmPaySlip });
   
  };

  toggleView = (viewPaySlip) => {
    this.setState({ viewPaySlip });
   
  };

  

  toggleEditPaySlip = () => {
    this.setState({ editPaySlip: !this.state.editPaySlip });
  };

  toggleConfirmPaySlip = () => {
    this.setState({ confirmPaySlip: !this.state.confirmPaySlip });
  };
  
  

  render() {
    const { payslips,bulkPaySlip,   start_year, month, year, current_year, current_month, total, page,viewPaySlip, rows, search, loading,addPaySlip, editPaySlip, confirmPaySlip,months } = this.state;
    
    return (
      <>

      

        
        
        {viewPaySlip && (
          <PaySlip
            viewPaySlip={viewPaySlip}
            toggle={() => this.setState({ viewPaySlip: null })}
          />
        )}


        

       
        {loading && <SpinDiv text={"Loading..."} />}

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">payslips</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              {/* <div className="btn-toolbar mb-2 mb-md-0">
                <ButtonGroup>

                <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddPaySlip()}>
                    Add PaySlip
                  </Button>
                  <Button variant="outline-primary" size="sm" onClick={() => this.toggleBulkPaySlip()}>
                    Bulk payment confirmation
                  </Button>
                  
                   <Button variant="outline-primary" size="sm">
                    Export
                  </Button> 

                 
                 </ButtonGroup> 
              </div> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="2">
            <h5 className="mb-0">Payslips
            <span style={{color: '#aaa', fontSize: 14, fontWeight: 'normal'}}> ({total})</span></h5>
          </Col>
         
          <Col md={3}>
            <Form.Select
              value={month}
              type="select"
              onChange={(e) => this.onFilter(e.target.value, "month")}
            >
              <option value="">Filter Month</option>
              {months.map((p, index) => (
                <option value={index+1} key={p}>
                  {p}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={3}>
            <Form.Select
              value={year}
              type="select"
              
              onChange={(e) => this.onFilter(e.target.value, "year")}
            >
              <option value="">Filter Year</option>
              {this.range(start_year, current_year).slice(0).reverse().map((p, index) => (
                <option value={p} key={p}>
                  {p}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col lg="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Salary Date</th>
                  <th className="border-0">Salary</th>
                  <th className="border-0">Net Salary</th>
                  <th className="border-0">status</th>
                  <th className="border-0">Action</th>
                </tr>
              </thead>
              <tbody>
                
                {payslips.map((payslip, key) => {
               
                  return (
                    <tr style={{fontWeight:"bold"}}>
                      <th scope="row">
                        
                          <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{payslip.name}</span></td>
                        
                      </th>
                      <td>{this.monthName(payslip.month)},{payslip.year}</td>
                      <td>{payslip.basic_salary}</td>
                      <td>{payslip.net_salary}</td>
                      <td>{payslip.status == 1 ? <span style={{color:'green'}}>Approved</span> : <span style={{color:'red'}}>Pending</span>}</td>
                      
                      <td>
                        <ButtonGroup>
                          {/* {payslip.status !==1 &&
                            <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => this.toggleEdit(payslip)}
                           
                          >
                           Edit
                          </Button>
                          } */}
                        
                        {/* <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => this.toggleConfirm(payslip)}
                          disabled={payslip.status==1}
                        >
                         {payslip.status==1 ? 'confirmed' : 'confirm'}
                        </Button> */}
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => this.toggleView(payslip)}
                        >
                         view
                        </Button>
                       
                      
                        </ButtonGroup>
                      </td>

                      
                    </tr>
                  );
                })}
              </tbody>
              <Row>
              {payslips.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  No PaySlip for this Month
                </div>}
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {payslips.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} employees`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
              </Col>
            </Row>
            </Table>
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default PaySalaryIndex;
