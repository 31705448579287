import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';
const authuser=JSON.parse(localStorage.getItem('user'));


export function getEmployees(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}employees2`, requestOptions).then(authService.handleResponse);
}

export function getPaySalary(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}payslip`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/payslip`, requestOptions).then(authService.handleResponse);
    }
}

export function getPaySlip2(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
  
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}pay_slips`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/pay_slips`, requestOptions).then(authService.handleResponse);
    }
}

export function deletePaySlip(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletepay_slip/${id}`, requestOptions).then(authService.handleResponse);
}

export function paySalary(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}paysalary`, requestOptions).then(authService.handleResponse);
}

export function confirmSalaryPayment(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}confirm_payment/${id}`, requestOptions).then(authService.handleResponse);
}





export function getSalary(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}salary/${id}`, requestOptions).then(authService.handleResponse);
}
