import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

const authuser=JSON.parse(localStorage.getItem('user'));

export function getAwards(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}awards`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/awards`, requestOptions).then(authService.handleResponse);
    }
    
}


export function addAward(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addAward`, requestOptions).then(authService.handleResponse);
}

export function editAward(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateAward/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getAward(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}award/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteAward(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteaward/${id}`, requestOptions).then(authService.handleResponse);
}
