
import DashboardOverviewstaff from "./pages/staff/dashboard/DashboardOverviewstaff";
import EmployeeAttendance from "./pages/staff/attendance/EmployeeAttendance";
import AwardIndex from "./pages/staff/award/AwardIndex";
import { faBook, faBoxOpen, faChartPie, faShoppingBag,faCog, faFileAlt, faHandHoldingUsd, faIcons, faSignOutAlt, faTable, faTimes, faUsers, faTruck, faTruckLoading, faTruckMoving, faThermometer, faRuler, faCalculator, faStepForward, faCalendar, faAward, faRestroom, faMoneyCheck, faBalanceScale, faTrophy, faRunning, faUser, faScrewdriver, faClock, faCalendarPlus, faCalendarMinus, faReceipt, faGift } from "@fortawesome/free-solid-svg-icons";
import LeaveIndex from "./pages/staff/leaves/LeaveIndex";
import PaySalaryIndex from "./pages/staff/payslip/PaySalaryIndex";
import SingleSalary from "./pages/staff/payslip/SingleSalary";
import PerformanceIndex from "./pages/staff/performance/PerformanceIndex";
import PromotionIndex from "./pages/promotion/PromotionIndex";
import TrainingIndex from "./pages/training/TrainingIndex";
import Profile from "./pages/profile/Profile";
import TaskIndex from "./pages/staff/tasks/TaskIndex";
import TimeLogIndex from "./pages/staff/timelogs/TimeLogIndex";
import { faViacoin } from "@fortawesome/free-brands-svg-icons";
import NoticeIndex from "./pages/notice/NoticeIndex";

const getUser = ()=>{
  const user = JSON.parse(localStorage.getItem('user'));
  if(user){
    return user;
  } else {
    return ''
  }
 
}

export let staffRoutes = [
    
  
    { path: "/employee/profile",component: Profile, title:"Profile",icon:faUser },
    { path: "/employee/announcement",component: NoticeIndex, title:"Announcements",icon:faViacoin },
    { path: "/employee/tasks",component:TaskIndex, title:"Tasks",icon:faScrewdriver },
    { path: "/employee/timelogs",component:TimeLogIndex, title:"Timelogs",icon:faClock },
    { path: "/employee-attendance",component:EmployeeAttendance, title:"Attendance",icon:faCalendarPlus },
    { path: "/employee-leaves",component:LeaveIndex, title:"Leaves",icon:faCalendarMinus },
    { path: "/employee/payslip",component:PaySalaryIndex, title:"Payroll",icon:faReceipt },
    { path: "/employee/entitlement",component:SingleSalary, title:"Entitlements",icon:faMoneyCheck },
    { path: "/employee/performances",component:PerformanceIndex, title:"Individual Performance",icon:faBalanceScale },
    { path: `/employee/departments/performance/${getUser() !== null ? getUser().department_id:''}`, title:"Departmental Performance",icon:faBalanceScale },
    { path: "/employee/trainings",component:TrainingIndex, title:"Trainings",icon:faRunning },
    { path: "/employee/awards",component:AwardIndex, title:"Awards",icon:faAward },

    { path: "/employee/promotions",component:PromotionIndex, title:"Promotions",icon:faGift },
    
    

  ];
