
import { faAward, faBuilding, faClock, faEnvelopeOpen, faHandScissors, faMicrophone, faObjectGroup, faParachuteBox, faRunning, faScrewdriver, faStepForward } from "@fortawesome/free-solid-svg-icons";
import BranchIndex from "./pages/branches/BranchIndex";
import DepartmentIndex from "./pages/departments/DepartmentIndex";
import DesignationIndex from "./pages/designations/DesignationIndex";
import IpAddressIndex from "./pages/ip/IpAddressIndex";
import NotificationIndex from "./pages/notification/NotificationIndex";
import TerminationTypeIndex from "./pages/terminationtype/TerminationTypeIndex";
import RoleIndex from "./pages/roles/Index";
import { faHackerrank } from "@fortawesome/free-brands-svg-icons";
import Profile from "./pages/company/Profile";
import EmailSetting from "./pages/company/Email";
import AttendanceSetting from "./pages/company/Attendance";
import CategoryIndex from "./pages/categories/CategoryIndex";


const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]

export let settingsRoute = [

    { path: "/admin/roles",component: RoleIndex, title:"Roles",icon:faHackerrank },
    { path: "/branches",component: BranchIndex, title:"Branches",icon:faBuilding},
    { path: "/designations",component: DesignationIndex, title:"Designations",icon:faParachuteBox},
    { path: "/ip-restriction",component: IpAddressIndex, title:"IP Restrictions",icon:faParachuteBox},
    { path: "/company-settings",component: Profile, title:"Company Setting",icon:faScrewdriver},
    { path: "/email-settings",component:EmailSetting, title:"Email Setting",icon:faEnvelopeOpen},
    { path: "/attendance-settings",component:AttendanceSetting, title:"Attendance/Leave/Timelog",icon:faClock},
    { path: "/departments",component: DepartmentIndex, title:"Departments",icon:faStepForward},
    { path: "/categories",component: CategoryIndex, title:"Categories",icon:faObjectGroup},
    { path: "/notification-settings",component:NotificationIndex, title:"Email Notification",icon:faMicrophone},
    // { path: "/terminationtypes",component: TerminationTypeIndex, title:"Termination Types",icon:faHandScissors} 
   
  ];
