import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

const authuser=JSON.parse(localStorage.getItem('user'));

export function getPerformances(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    

    if(authuser && authuser.admin === 1){
        return fetch(`${settings.API_URL}performances`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/performances`, requestOptions).then(authService.handleResponse);
    }
}


export function addResignation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addResignation`, requestOptions).then(authService.handleResponse);
}

export function editResignation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateresignation/${data.id}`, requestOptions).then(authService.handleResponse);
}



export function getUserPerformances(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}userPerformances/${id}`, requestOptions).then(authService.handleResponse);
}

export function getMarkedPerformances(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    
    if(authuser && authuser.admin === 1){
        return fetch(`${settings.API_URL}marked_performance/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/marked_performance/${id}`, requestOptions).then(authService.handleResponse);
    }
}




export function deleteResignation(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteresignation/${id}`, requestOptions).then(authService.handleResponse);
}

export function deletePerformance(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteperformance/${id}`, requestOptions).then(authService.handleResponse);
}
