import React, { Component } from "react";
import { FormGroup, CardHeader, Media, Input, Modal, ButtonGroup } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,
  Button,


} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import axios from 'axios'

import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export class UploadDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      documents: [],
      client_id:props.client_id,
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
  }


  

  handleRemoveItem = (idx) => () => {
    this.setState({ documents: this.state.documents.filter((s, sidx) => idx !== sidx) });
  }

  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });


  fileChangedHandler = (e) => {
    this.setState({ documents: [...this.state.documents, ...e.target.files] })
  }

  

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };


  

  

  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })

  }


  saveDocument = () => {
    this.setState({ saving: true });
    const { client_id, documents } = this.state;
    let data = new FormData();
    
    data.set("client_id", client_id);
    
    if(documents.length > 0){
      let op = documents;
      for (var i in op) {
        data.append(`documents[${i}]`, op[i])
      }

      return axios
      .post(
        `${settings.API_URL}client/adddocuments`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Document Uploaded");
        },
        (err) => {

          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err.errors) {
              this.showToastError('Wrong File Type')
            }
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );

    } else {
     
      this.showToastError('File Upload Cannot be Empty')
      this.setState({ loading: false, saving:false });
    }
    


  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { toggle, client_id } = this.props;

    const { saving, loading } = this.state;
    return (
      
      <>
            
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen
          toggle={() => !loading && !saving && toggle && client_id}
          style={{ maxWidth: 800 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5>Upload Document</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              
              <Row>
                
                <Col md={6}>
                  <div className="d-xl-flex align-items-center">

                    <div className="company-avatar xl-avatar">

                      {this.state.documents.map((file, key) => (
                        <Button style={{ paddingBottom: 10 }} variant="outline-primary" key={file.name} onClick={this.handleRemoveItem(key)}>
                          {file.name}&nbsp;<span style={{ color: 'red', fontWeight: 'bold', fontSize: 15 }}>x</span>
                        </Button>
                      ))}

                    </div>
                    <div className="file-field">
                      <div className="d-flex justify-content-xl-center ms-xl-3">
                        <div className="d-flex">


                          <input onChange={this.fileChangedHandler} type="file" multiple />
                          <div className="d-md-block text-start">
                            <span className="icon icon-md">
                              <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                            </span>
                            <div className="fw-normal text-dark mb-1">Choose Documents</div>
                            <div className="text-gray small">Pdf, doc, docx, ppt, txt, JPG, GIF or PNG. Max size of 2MB</div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </Col>

              </Row>
              
              
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.saveDocument}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default UploadDocument;
