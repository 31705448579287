import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';

const authuser=JSON.parse(localStorage.getItem('user'));

export function searchUsers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}searchusers`, requestOptions).then(authService.handleResponse);
}

export function getUsers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}users`, requestOptions).then(authService.handleResponse);
}

export function addUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}adduser`, requestOptions).then(authService.handleResponse);
}

export function addUserImage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}adduserimage`, requestOptions).then(authService.handleResponse);
}



export function getUser(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}user/${id}`, requestOptions).then(authService.handleResponse);
}

export function recoverPassword(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}recoverpassword/${id}`, requestOptions).then(authService.handleResponse);
}

export function getProfile() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}profile`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/profile`, requestOptions).then(authService.handleResponse);
    }
  
}

export function getDashboard() {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
     if(authuser !== null && authuser.client == "1"){
        
        return fetch(`${settings.API_URL}client/dashboard`, requestOptions).then(authService.handleResponse);

    } else if (authuser !== null && authuser.admin !== 1 && authuser.client == 0){  
        return fetch(`${settings.API_URL}employee/dashboard`, requestOptions).then(authService.handleResponse);
    }
    return fetch(`${settings.API_URL}dashboard`, requestOptions).then(authService.handleResponse);
  
  
  
}

export function editUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    
    return fetch(`${settings.API_URL}updateuser/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function updateUser(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}updateprofile`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/updateprofile`, requestOptions).then(authService.handleResponse);
    }
    
}

export function resetPassword(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}resetpassword`, requestOptions).then(authService.handleResponse);
}

export function addProfileImage(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin == 1){
        return fetch(`${settings.API_URL}uploadProfilePic`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/uploadProfilePic`, requestOptions).then(authService.handleResponse);
    }
}

export function deleteUser(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteuser/${id}`, requestOptions).then(authService.handleResponse);
}

