

import TerminationIndex from "./pages/termination/TerminationIndex";
import { faAward, faGift, faHandScissors, faUnlock } from "@fortawesome/free-solid-svg-icons";

const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]

export let terminationRoute = [
    
  ...(permissions.includes('show termination') === true ?  [   { path: "/terminations",component: TerminationIndex, title:"Terminations",icon:faHandScissors },] : []),
  
    

  ];