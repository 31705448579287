import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Signin from "./auth/Signin";
import SingleSalary from "./salary/SingleSalary";
import Signup from "./auth/Signup";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";



// components
import Sidebar from "../components/Sidebar";
import Navbar2 from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

import { routes } from '../authRoute';
import { workRoute } from '../workRoute';
import {settingsRoute} from '../settingsRoute'
import {salaryRoute} from '../salaryRoute'
import {awardRoute} from '../awardRoute'
import {terminationRoute} from '../terminationRoute'
import {trainingRoute} from '../trainingRoute'
import {hrRoute} from '../hrRoute'
import {performanceRoute, DepartmentperformanceRoute} from '../performanceRoute'
import { attendanceLeaveRoute } from '../attendanceLeaveRoute';
import AttendanceDetail from './attendance/AttendanceDetail';
import PerformanceDetail from './performance/PerformanceDetail';
import ClientDetail from './clients/ClientDetail';
import ViewOfficePerformance from './dept-performance/ViewPerformance/ViewOfficePerformance';
import ViewAdministrativePerformance from './dept-performance/ViewPerformance/ViewAdministrativePerformance';
import ViewItSupportPerformance from './dept-performance/ViewPerformance/ViewItSupportPerformance';
import ViewOperationManager from './dept-performance/ViewPerformance/ViewOperationManager';
import ViewQualityControl from './dept-performance/ViewPerformance/ViewQualityControl';
import ViewHumanResourcePerformance from './dept-performance/ViewPerformance/ViewHumanResourcePerformance';

import ViewWritingPerformance from './dept-performance/ViewPerformance/ViewWritingPerformance';
//import RouteWithSidebar from "../services/RouteWithSidebar"

import DeptPerformanceDetail from './dept-performance/DeptPerformanceDetail';
import SecureStaff from '../services/SecureStaff';
import secureRoute from '../services/secureRoute';
import { staffRoutes } from '../staffRoute';
import {noSideBarRoute} from '../nosideBarRoute';
import Sidebar2 from '../components/Sidebar2';
import { clientRoute } from '../clientRoute';
import Sidebar3 from '../components/Sidebar3';
import AddInvoice from './invoice/AddInvoice';
import EditInvoice from './invoice/EditInvoice';
import EmployeeDeptPerformanceDetail from './staff/performance/EmployeeDeptPerformanceDetail';
import Navbar3 from '../components/NavBar3';

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const [authuser,setAuthUser]=useState( JSON.parse(localStorage.getItem('user')));
  const [client, setClient] = useState(JSON.parse(localStorage.getItem('client')));
  
  

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }
  if(authuser !== null && authuser.admin == 1){
      return (
        <Route {...rest} render={props => (
      
          <>
    
             
            <Preloader show={loaded ? false : true} />
            <main className="content">
            
            <Sidebar/>
            <Navbar2 {...props}/>
            
              <Component {...props} />
              <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
            </main>
             
            
              {/* { <Redirect to={{ pathname: '/auth/sign-in', state: { from: props.location.pathname } }} /> } */}
            
           
            
          </>
        )}
        />
        
      )
  } else if (authuser !== null && authuser.admin !== 1 && authuser.client == "0"){
   

    return (
      <Route {...rest} render={props => (
      
        <>
  
           
          <Preloader show={loaded ? false : true} />
          <main className="content">
          <Sidebar2 /> 
          <Navbar2 {...props}/>
          
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
           
          
            {/* {  } */}
          
         
          
        </>
      )}
      />
    )
  } else if (authuser !== null && authuser.client == "1"){
    return (
      <Route {...rest} render={props => (
      
        <>
  
           
          <Preloader show={loaded ? false : true} />
          <main className="content">
          <Sidebar3 /> 
          <Navbar3 {...props}/>
          
            <Component {...props} />
            <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
          </main>
           
          
            {/* {  } */}
          
         
          
        </>
      )}
      />
    )
  } else {
    return (
      <Route {...rest} render={props => (
      
        <>
  
           
          <Redirect to={{ pathname: '/auth/sign-in', state: { from: props.location.pathname } }} />
           
          
            {/* {  } */}
          
         
          
        </>
      )}
      />
     
    )
  }
};


const getRoutes = routes => {
 
  return routes.map((prop, key) => {
    return (
      <RouteWithSidebar
        key={key}
        exact path={prop.path}
        component={prop.component}
        
      />
    );
   
  });
};

const getNoSideBarRoutes = routes => {
 
  return routes.map((prop, key) => {
    return (
      <RouteWithSidebar
        key={key}
        exact path={prop.path}
        component={prop.component}
        
      />
    );
   
  });
};

// const getStaffRoutes = routes => {
 
//   return routes.map((prop, key) => {
//     return (
//       <SecureStaff
//         key={key}
//         exact path={prop.path}
//         component={prop.component}
        
//       />
//     );
   
//   });
// };

export default () => (
  <Switch>
    {/* <Route exact path={Routes.Presentation.path} component={Presentation} />
    <Route exact path={Routes.Signup.path} component={Signup} />
    
    <Route exact path={Routes.Lock.path} component={Lock} />
    <Route exact path={Routes.NotFound.path} component={NotFoundPage} />
    <Route exact path={Routes.ServerError.path} component={ServerError} /> */}

    {/* pages */}
    <Route exact path={Routes.Signin.path} component={Signin} />
    <noSideBar exact path={Routes.Signin.path} component={Signin} />
    <Route exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <Route exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithSidebar exact path={Routes.Payroll.path} component={SingleSalary} />
    <RouteWithSidebar exact path={Routes.AttendanceDetail.path} component={AttendanceDetail} />
    <RouteWithSidebar exact path={Routes.PerformanceDetail.path} component={PerformanceDetail} />
    <RouteWithSidebar exact path={Routes.ClientDetail.path} component={ClientDetail} />
    <RouteWithSidebar exact path={Routes.AddInvoice.path} component={AddInvoice} />
    <RouteWithSidebar exact path={Routes.EditInvoice.path} component={EditInvoice} />
    <RouteWithSidebar exact path={Routes.DeptPerformanceDetail.path} component={DeptPerformanceDetail} />
    <RouteWithSidebar exact path={Routes.EmployeeDeptPerformanceDetail.path} component={EmployeeDeptPerformanceDetail} />
    <RouteWithSidebar exact path={Routes.ViewOfficePerformance.path} component={ViewOfficePerformance} />
    <RouteWithSidebar exact path={Routes.ViewAdministrativePerformance.path} component={ViewAdministrativePerformance} />
    <RouteWithSidebar exact path={Routes.ViewWritingPerformance.path} component={ViewWritingPerformance} />
    <RouteWithSidebar exact path={Routes.ViewItSupportPerformance.path} component={ViewItSupportPerformance} />
    <RouteWithSidebar exact path={Routes.ViewHumanResourcePerformance.path} component={ViewHumanResourcePerformance} />
    <RouteWithSidebar exact path={Routes.ViewOperationManager.path} component={ViewOperationManager} />
    <RouteWithSidebar exact path={Routes.ViewQualityControl.path} component={ViewQualityControl} />

    
    
    
    {getRoutes(routes)}
    {getRoutes(clientRoute)}
    {getRoutes(workRoute)}
    {getRoutes(settingsRoute)}
    {getRoutes(salaryRoute)}
    {getRoutes(trainingRoute)}
    {getRoutes(awardRoute)}
    {getRoutes(terminationRoute)}
    {getRoutes(hrRoute)}
    {getRoutes(performanceRoute)}
    {getRoutes(DepartmentperformanceRoute)}
    {getRoutes(attendanceLeaveRoute)}
    {getRoutes(staffRoutes)}
    {getNoSideBarRoutes(noSideBarRoute)}
    {/* {getRoutes(settingsRoute)} */}
    


    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
