import React from "react";
import {
  Button,
  Modal
} from "reactstrap";
import { toast } from 'react-toastify';

import { confirmSalaryPayment } from "../../services/paysalaryService";
import SpinDiv from "../components/SpinDiv";

class ConfirmPaySlip extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      confirmPaySlip: props.confirmPaySlip,
      loading: false,
      search: '',
      validation: {},
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
   showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };
  onConfirm = () => {
    this.setState({loading: true});
    const {confirmPaySlip} = this.props;
    confirmSalaryPayment(confirmPaySlip.id)
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('Salary Payment has been confirmed')
      },
    	error => {
        this.showToastError('Salary payment could not be completed')
        this.setState({loading: false});
    	});
  }


  

 

  render() {
    const { confirmPaySlip, toggle } = this.props
    const {loading} = this.state;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={confirmPaySlip!=null}
          toggle={() => !loading&&toggle}
          style={{maxWidth: 600}}
        >
          {loading&&<SpinDiv text={'confirming...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h5 className="modal-title" id="exampleModalLabel">
              Confirm salary for  employee - {confirmPaySlip.name}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee'}}>
           You are about to confirm the salary payment <br/><br/>
           Please click confirm to proceed.
          </div>
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button color="success" type="button" disabled={loading} size="sm"
              onClick={this.onConfirm}>
              confirm
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default ConfirmPaySlip;
