import React, { Component } from "react";
import {
    Col,
    Row,
    Nav,
    Card,
    Table,
    Form,
    Button,
    ButtonGroup,
    Breadcrumb,
    InputGroup,
    Dropdown,

} from "@themesberg/react-bootstrap";
import SpinDiv from "../../components/SpinDiv";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { toast } from "react-toastify";
import { getMarkedDepartmentPerformances } from "../../../services/departmentPerformanceService";
import { Tooltip } from 'antd';
import {faCheck, faBoxOpen,faUsers,  faStepForward, faEnvelope, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Rate } from 'antd';
import Chart from "react-apexcharts";
import { Popover} from 'antd';

const attendanceOverview = (
  <div>
    <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
            <p>Weekly Attendance is calcuted as follows</p>
            <p>Working Hours for a week = No of Days of week x 9hrs</p>
            <p> Employee working Hours for a week = Attendance (total No of hours worked) X No of working Days</p>
            <h6> Attendance Percentage =   (Employee working Hours for a week /  Employee working Hours for a week) x 20</h6>

        </Card.Body>
    </Card>
  </div>
);
const performanceOverview = (
    <div>
      <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
              <h5>Performance</h5>
              <p>(Sum total Rating / No of weeks (No of time it was rated) x 5) x 100</p>
              <p>This arithmetic operation works for content_upload, Policy and Compliance, management_support, Training & Development</p>
             
  
          </Card.Body>
      </Card>
    </div>
  );



export class ViewWritingPerformance extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            search: '',
            validation: {},
            show: false,
            edit: false,
            id: props.match.params.id,
            year:props.match.params.year,
            month:props.match.params.month,
            performances:[],
            overall_average_time_spent_on_work:0,
            overall_quality_of_content:0,
            overall_teamwork:0,
    
        };
    }

    componentDidMount() {
        toast.configure({ hideProgressBar: true, closeButton: false });
        this.getMarkedPerformances();
        
    }





    getMarkedPerformances = () => {
        const { id } = this.state;
        this.setState({  loading: true})
        getMarkedDepartmentPerformances(id).then(
            
            (res) => {
                this.setState({
                    performances: res.marked_performances,
                    loading: false
                });
            },
            (error) => {
                this.setState({ loading: false });
            }
        );
    };
    sumArray(arr){
        const sum = arr.reduce((partialSum, a) => partialSum + a, 0);
        return sum;
    }


    getAverageTimePercentage(performances){
        let average_time_spent_on_work=[];
        let total_average_time_spent_on_work = performances.length*5;
        performances.map((p, index)=>{
            return average_time_spent_on_work.push( p.average_time_spent_on_work);
        })
       
         let percentage = (this.sumArray(average_time_spent_on_work)/total_average_time_spent_on_work)*100;
         return Math.round(percentage);
        

    }

     getQualityContentPercentage(performances){
        let quality_of_content =[];
        let total_quality_of_content  = performances.length*5;
        performances.map((p, index)=>{
            return quality_of_content .push( p.quality_of_content );
        })
       
         let percentage = (this.sumArray(quality_of_content )/total_quality_of_content )*100;
         return Math.round(percentage);
        

    }

     getTeamWorkPercentage(performances){
        let teamwork=[];
        let total_teamwork = performances.length*5;
        performances.map((p, index)=>{
            return teamwork.push( p.teamwork);
        })
       
         let percentage = (this.sumArray(teamwork)/total_teamwork)*100;
       
         return Math.round(percentage);
         
    }
    

    
    monthName = (mon)=> {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }

    getName(performances){
        var name = performances.map(function(p) {
           return p.department_name;
       });
       return name[0];
   }

    weeklyPerformanceChat(performances){
        
        const week_one_performances = performances.filter(p => p.week == 1);
        const week_two_performances = performances.filter(p => p.week == 2);
        const week_three_performances = performances.filter(p => p.week == 3);
        const week_four_performances = performances.filter(p => p.week == 4);
    
       
    
        let teamwork_one= week_one_performances.length !== 0 ? (this.getTeamWorkPercentage(week_one_performances)/100)*20 : 0;
        let teamwork_two= week_two_performances.length !== 0 ? (this.getTeamWorkPercentage(week_two_performances)/100)*20 : 0;
        let teamwork_three= week_three_performances.length !== 0 ? (this.getTeamWorkPercentage(week_three_performances)/100)*20 : 0;
        let teamwork_four= week_four_performances.length !== 0 ? (this.getTeamWorkPercentage(week_four_performances)/100)*40 : 0;
        
        let quality_of_content_one= week_one_performances.length !== 0 ? (this.getQualityContentPercentage(week_one_performances)/100)*60 : 0;
        let quality_of_content_two= week_two_performances.length !== 0 ? (this.getQualityContentPercentage(week_two_performances)/100)*60 : 0;
        let quality_of_content_three= week_three_performances.length !== 0 ? (this.getQualityContentPercentage(week_three_performances)/100)*60 : 0;
        let quality_of_content_four= week_four_performances.length !== 0 ? (this.getQualityContentPercentage(week_four_performances)/100)*60 : 0;

        let average_time_spent_on_work_one= week_one_performances.length !== 0 ? (this.getAverageTimePercentage(week_one_performances)/100)*20 : 0;
        let average_time_spent_on_work_two= week_two_performances.length !== 0 ? (this.getAverageTimePercentage(week_two_performances)/100)*20 : 0;
        let average_time_spent_on_work_three= week_three_performances.length !== 0 ? (this.getAverageTimePercentage(week_three_performances)/100)*20 : 0;
        let average_time_spent_on_work_four= week_four_performances.length !== 0 ? (this.getAverageTimePercentage(week_four_performances)/100)*20 : 0;

        
        let overall_week_one_performance = average_time_spent_on_work_one+quality_of_content_one+teamwork_one;
        let overall_week_two_performance = average_time_spent_on_work_two+quality_of_content_two+teamwork_two;
        let overall_week_three_performance = average_time_spent_on_work_three+quality_of_content_three+teamwork_three;
        let overall_week_four_performance = average_time_spent_on_work_four+quality_of_content_four+teamwork_four;

       
        const data  = {
            options: {
              chart: {
                id: "basic-bar"
              },
              xaxis: {
                categories: ['WEEK 1', 'WEEK 2', 'WEEK 3', 'WEEK 4']
              }
            },
            series: [
              {
                name: "Weekly overall-performance",
                data: [overall_week_one_performance,overall_week_two_performance,overall_week_three_performance,overall_week_four_performance,100]
              }
            ]
          };
          return (
              <Chart
              options={data.options}
              series={data.series}
              type="line"
              width="750"
              height="250"
            />
          )
    }

    performanceChart = (performances) => {
        let average_time_spent_on_work= this.getAverageTimePercentage(performances);
        let quality_of_content= this.getQualityContentPercentage(performances);
        let teamwork= this.getTeamWorkPercentage(performances);
   
        const data  = {
          options: {
            chart: {
              id: "basic-bar"
            },
            xaxis: {
              categories: ['','AVERAGE TIME', 'QUALITY OF CONTENT', 'TEAM WORK','']
            }
          },
          series: [
            {
              name: "overall-performance",
              data: [0, average_time_spent_on_work,quality_of_content ,teamwork,100 ]
            }
          ]
        };
        return (
            <Chart
            options={data.options}
            series={data.series}
            type="line"
            width="750"
            height="250"
          />
        )
    }

    render() {

        const { saving, month, year, loading, performances } = this.state;
        return (
            <>


                {loading && <SpinDiv text={"Loading..."} />}
                <Row style={{}}>
                    <Col lg="12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <Breadcrumb
                                    listProps={{
                                        className: " breadcrumb-text-dark text-primary",
                                    }}
                                >
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/performances">Performance</Breadcrumb.Item>
                                    <Breadcrumb.Item href="#">Detail</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <ButtonGroup>
{/* 
                                    <Button variant="outline-primary" size="sm">
                                        Export
                                    </Button> */}
                                </ButtonGroup>
                            </div>
                        </div>
                    </Col>
                    <Row>
                        <Col md={12}>
                            <Card border="light" className="shadow-sm mb-4">
                                <Card.Body className="pb-0">
                                    <Row>
                                        <Col md={8}></Col>
                                        <Col md={4} style={{marginBottom:10}}>
                                            
                                                
                                                <ButtonGroup>
                                                    <Button variant="outline-primary" size="sm">
                                                        <Popover content={attendanceOverview} title="Attendance Overview">
                                                            Attendance overview
                                                        </Popover>
                                                    </Button>
                                                    <Button variant="outline-primary" size="sm">
                                                        <Popover content={performanceOverview} title="Performance Overview">
                                                            Performance OverView
                                                        </Popover>
                                                    </Button>
                                                </ButtonGroup>
                                           
                                        </Col>
                                    </Row>
                                    <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                        style={{marginBottom:10, fontSize:30}}
                                    >
                                    <thead className="thead-light">
                                        <tr>
                                        <th className="border-0" style={{fontSize:15}}>Department: {this.getName(performances)} </th>
                                        <th className="border-0" style={{fontSize:15}}>Date: {this.monthName(month)}, {year}</th>
                                        
                                        
                                        </tr>
                                    </thead>
                                    
                                    
                                    </Table>
                                
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Col md={12}>
                       

                        <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                               <Row style={{marginBottom:10}}>
                                    
                                   <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                   Quality of Content
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faEnvelope}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getQualityContentPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Average Time Spent
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faStepForward}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getAverageTimePercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                    
                                    <Col md={2}>
                                        <Card border="light" className="shadow-sm mb-4" style={{ color:'grey', fontWeight:'bold'}} >
                                            <Row style={{textAlign:'center', padding:10,}}>
                                               <Col md={12} style={{fontSize:15}}>
                                                     Team Work
                                               </Col>
                                               <Col md={12}>
                                                 <FontAwesomeIcon color='grey' style={{fontSize:20, paddingTop:5}} icon={faBoxOpen}  />
                                                </Col>
                                                 <h6 style={{ color:'blue', padding:10,  fontWeight:'bold'}}>{this.getTeamWorkPercentage(performances)+'%'}</h6>
                                            </Row>       
                                        </Card>
                                    </Col>
                                   
                                    
                               </Row>
                              

                        
                            </Card.Body>
                            <Row style={{margin:10}}>
                                <Col md={12}>
                                    <Card border="light" className="shadow-sm mb-4">
                                        <Card.Body className="pb-0">
                                            <Table
                                            responsive
                                            className="table-centered table-nowrap rounded mb-0"
                                            >
                                            <thead className="thead-light">
                                                <tr>
                                                <th className="border-0">No</th>
                                                <th className="border-0">Week</th>
                                                <th className="border-0">Quality Of Content</th>
                                                <th className="border-0">Average Time Spent</th>
                                                <th className="border-0">Team Work</th>
                                               
                                               
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                {performances.map((performance, key) => {
                                            
                                                return (
                                                    <tr style={{fontWeight:"bold"}}>
                                                    <th scope="row">
                                                        <td><span className="mb-0 text-sm" style={{fontWeight:"bold",textTransform:"capitalize"}}>{key+1}</span></td>
                                                        
                                                    </th>
                                                
                                                    <td>{performance.week}</td>
                                                    <td><Rate style={{color:'green'}} value={performance.quality_of_content} /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.average_time_spent_on_work} /></td>
                                                    <td><Rate style={{color:'green'}} value={performance.teamwork} /></td>
                                                    
                                                   
                                                    
                                                    
                                                    </tr>
                                                );
                                                })}
                                            </tbody>
                                            
                                            </Table>
                                            
                                        </Card.Body>
                                        </Card>
                                </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>

                               <Col md={10}>
                                    <h5>Weekly Overall Performance</h5>
                                    {this.weeklyPerformanceChat(performances)}
                               </Col>
                            </Row>
                            <Row>
                               <Col md={2}></Col>
                               <Col md={10} style={{marginBottom:20}}>
                                <h5>Performance BreakDown Graph</h5>
                                {this.performanceChart(performances)}
                               </Col>
                            </Row>
                        </Card>
                    </Col>

                </Row>

            </>
        );
    }
}

export default ViewWritingPerformance;
