import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';
const authuser=JSON.parse(localStorage.getItem('user'));

export function getPerformancesDepartments(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}performance/departments`, requestOptions).then(authService.handleResponse);
}

export function getDepartmentalPerformance(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    if(authuser && authuser.admin === 1){
        return fetch(`${settings.API_URL}departmentPerformances`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/departmentPerformances`, requestOptions).then(authService.handleResponse);
    }
    
  
}

export function getDepartment(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}department/${id}`, requestOptions).then(authService.handleResponse);
}


export function addDepartmentPerformance(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addDeptPerformance`, requestOptions).then(authService.handleResponse);
}

export function editResignation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updateresignation/${data.id}`, requestOptions).then(authService.handleResponse);
}


export function getMarkedDepartmentPerformances(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    if(authuser && authuser.admin === 1){
        return fetch(`${settings.API_URL}marked_deptperformance/${id}`, requestOptions).then(authService.handleResponse);
    }else{
        return fetch(`${settings.API_URL}employee/marked_deptperformance/${id}`, requestOptions).then(authService.handleResponse);
    }
  
}

export function deleteDeptPerformance(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}delete_department_performance/${id}`, requestOptions).then(authService.handleResponse);
}

export function deleteResignation(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deleteresignation/${id}`, requestOptions).then(authService.handleResponse);
}
