import React, { Component } from 'react'
import { Col, Divider, Row, Table } from 'antd';
import 'antd/dist/antd.css';


export class Invoice extends Component {
    constructor(props) {
		super(props);
        this.state = {
        viewPaySlip: props.viewPaySlip,
        loading: false,
        search: '',
        validation: {},
        payslip:props.payslip
        };
	}
    monthName = (mon)=> {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
    }

    totalEarning = () => {
        const {payslip} = this.state;
        const {commission, allowance, basic_salary}= payslip;
        let total= Number(commission)+Number(allowance)+Number(basic_salary);
        return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    
  render() {
      const {payslip}= this.state;
    return (
        <div class="container mt-5 mb-5">
    <div class="row">
        <div class="col-md-12">
            <div class="row" style={{marginBottom:5}}>
                <div class="col-md-6">
                    <img height='80' width='300' src={require("../../assets/img/brand/logo2.png")}/>
                </div>
            </div>
           
            <div class="row">
                <div class="col-md-10">
                    <div class="row">
                        <div class="col-md-6">
                            <div> <span class="fw-bolder">Km 2, Idiroko Road , Ota, <br/> Suburb Lagos-23439</span>  </div>
                        </div>
                        <div class="col-md-6">
                            <div> <span class="fw-bolder">Name</span> <small class="ms-3" style={{textTransform:'capitalize'}}>{payslip.employee.name}</small> </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div> <span class="fw-bolder">Branch</span> <small class="ms-3">{payslip.employee.branch_name}</small> </div>
                        </div>
                        <div class="col-md-6">
                            <div> <span class="fw-bolder">Account No</span> <small class="ms-3">{payslip.employee.account_no}</small> </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                           
                            <div> <span class="fw-bolder">Designation</span> <small class="ms-3">{payslip.employee.designation_name}</small> </div>
                        </div>
                        <div class="col-md-6">
                            <div> <span class="fw-bolder">Bank</span> <small class="ms-3">{payslip.employee.bank_name}</small> </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                        <div> <span class="fw-bolder">Paid By.</span> <small class="ms-3">Coursepedia Global Resources Limited</small> </div>
                        </div>
                        <div class="col-md-6">
                            <div> <span class="fw-bolder">Salary Date</span> <small class="ms-3">{this.monthName(payslip.month)},{payslip.year}</small> </div>
                        </div>
                    </div>
                </div>
                <table class="mt-4 table table-bordered">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th scope="col">Earnings</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Basic Salary</th>
                            <td>{payslip.basic_salary !== null ? '#'+payslip.basic_salary && payslip.basic_salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'Nil'}</td>
                            
                        </tr>
                        <tr>
                            <th scope="row">Allowance</th>
                            <td>{payslip.allowance !== null ? '#'+payslip.allowance && payslip.allowance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'Nil'}</td>
                            
                        </tr>
                        <tr>
                            <th scope="row">Commission</th>
                            <td>{payslip.commission !== null ? '#'+payslip.commission && payslip.commission.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 'Nil'}</td>
                            
                        </tr>
                        
                        <tr class="border-top">
                            <th scope="row">Total Earning</th>
                            <td>#{this.totalEarning()}</td>
                    
                        </tr>
                    </tbody>
                </table>
                <table class="mt-4 table table-bordered">
                    <thead class="bg-dark text-white">
                        <tr>
                            <th scope="col">Deductions</th>
                            <th scope="col">Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Loan</th>
                            <td>{payslip.loan_paid !== null ? '#'+payslip.loan_paid : 'Nil'}</td>
                            
                        </tr>
                        <tr>
                            <th scope="row">Other Deductions</th>
                            <td>{payslip.other_deduction !== null ? '#'+payslip.other_deduction : 'Nil'}</td>
                            
                        </tr>
                        
                        
                        <tr class="border-top">
                            
                            <td>Total Deductions</td>
                            <td>#{Number(payslip.loan_paid+payslip.other_deduction)}</td>
                        </tr>
                        <tr class="border-top">
                            
                            <td>Narration</td>
                            <td>{payslip.description}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row" style={{}}>
                <div class="col-md-4"> <br/> <span class="fw-bold" style={{fontSize:20}}>Net Salary : #{payslip.net_salary}</span> </div>
                
            </div>
           
        </div>
    </div>
</div>
        
      );
    
  }
}

export default Invoice