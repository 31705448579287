import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardHeader,
  Media,
  Input,
  Badge,
} from "reactstrap";
import {
  faAngleDown,
  faAngleUp,
  faCheck,
  faCog,
  faHome,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,
} from "@themesberg/react-bootstrap";
import SpinDiv from "../../components/SpinDiv";
import 'antd/dist/antd.css';
import { Pagination } from 'antd';
import { Tooltip } from 'antd';
import { faDashcube } from "@fortawesome/free-brands-svg-icons";
import SingleAttendance from  './SingleAttendance';
import {getAttendances, getMarkedAttendances} from '../../../services/employeeAttendance';
import {debounce, throttle} from '../debounce'


export class EmployeeAttendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      attendances:[],
      start_year: 2018,
      markedAttendances:[],
      user:JSON.parse(localStorage.getItem('user')),
      current_year: new Date().getFullYear(),
      year:new Date().getFullYear(),
      month: new Date().getMonth()+1,
      months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      total: 0,
    };
    this.searchDebounced = debounce(this.searchAttendances, 500);
    this.searchThrottled = throttle(this.searchAttendances, 500);
  }

  componentDidMount() {

    this.searchAttendances();
  }

  
  monthName = (mon)=> {
    return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][mon - 1];
  }

  range = (start, end) =>{
    var years = [];
    for (let i = start; i <= end; i++) {
        years.push(i);
    }
    return years;
  }

  searchAttendances = () => {
    const { page, rows, search, attendances, year, month } = this.state;
    this.setState({ loading: true });
    getAttendances({ page, rows, search, attendances, year, month }).then(
      
      (res) => {
        this.setState({
          attendances: res.attendances.data,
          page: res.attendances.current_page,
          total: res.attendances.total,
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  markedAttendances = (attendance_id) => {
    const {  markedAttendances } = this.state;
    this.setState({ loading: true });
    getMarkedAttendances(attendance_id).then(
      
      (res) => {
        this.setState({
          markedAttendances: res.markedAttendances,
          loading: false,
        });
      },
    );
    return 'x'
  };

  getRegAttendance = (result) => {
    return (
      result.map((p, index) => {
        return (
        
          <>
            {  p.status == 1 && p.holiday == 0 &&<Tooltip title={'Full Day ' +p.day } color='green'   onClick={() => this.toggleViewAttendance(p)}size="sm">
          <td> <FontAwesomeIcon icon={faCheck}  color='green'  onClick={() => this.toggleViewAttendance(p)}size="sm" /> </td>
        </Tooltip>}
        {  p.status == 1 && p.holiday == 1 &&<Tooltip title={'Holiday ' +p.day } color='orange'   onClick={() => this.toggleViewAttendance(p)}size="sm">
          <td> <FontAwesomeIcon icon={faHome}  color='orange'  onClick={() => this.toggleViewAttendance(p)}size="sm" /> </td>
        </Tooltip>}
           { p.half_day == 1 &&  <td><Tooltip title={'Half Day ' +p.day } color='cyan'   onClick={() => this.toggleViewAttendance(p)}size="sm"> <FontAwesomeIcon color="cyan"
           icon={faCheck}   
           onClick={() => this.toggleViewAttendance(p)}size="sm" /> </Tooltip> </td>}

          { p.status == 0 && p.half_day == 0 && <td><Tooltip title={'Absent Day' +p.day }  color='red'  onClick={() => this.toggleViewAttendance(p)}size="sm"> <FontAwesomeIcon color="red"
           icon={faTimes}   
           onClick={() => this.toggleViewAttendance(p)}size="sm" /> </Tooltip> </td>}

          </>
      
        )

       
      })
      
    )
    

  }
  

  getUnmarkedAttendance = (result, month) => {
    let days_of_month = this.range(1, month);
    let marked_days = result.map((p, index)=>{
      return p.day;
    })
    let unmarked = days_of_month.filter(item => !marked_days.includes(item));
    return (
      unmarked.map((p, index)=> {
        return (
          <Tooltip title={'Day ' +p } color='cyan' >
          <td>-</td>
        </Tooltip>
        )
      })
    )
  }

  onFilter = async (e, filter) => {
    await this.setState({ [filter]: e });
    await this.searchAttendances();
  };

  toggleViewAttendance = (singleAttendance) => {
    this.setState({ singleAttendance});
    this.searchAttendances();
   
  };

  toggleCloseAttendance=(singleAttendance)=>{
    this.setState({ singleAttendance: !this.state.singleAttendance });
  }


  

  render() {
    const { attendances, user, singleAttendance, start_year, year, current_year, month, months,showFilter, total, page, rows, search, loading } = this.state;
    
    return (
      <>
       
       
       
        {loading && <SpinDiv text={"Loading..."} />}
        {singleAttendance && (
          <SingleAttendance
            saved={this.searchAttendances}
            singleAttendance={singleAttendance}
            toggle={this.toggleCloseAttendance}
          />
        )}  
        
      

        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="#users">Attendance</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              
            </div>
          </Col>
        </Row>
        <Row>
          
          <Col md="3">
          <h5 className="mb-0">
          <span style={{fontSize: 20, fontWeight: 'bold',textTransform:"capitalize", paddingTop:10 ,}}>Employee: {user.name}</span></h5>
          </Col>
          <Col md="4">
            <Row>
            {
              <div
                style={{
                  borderTop: "0.5px solid #e9ecef",
                  padding: "0 0 0 20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  overflowX: "auto",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight:3, fontSize: 14 }}>
                    Filter By Date:{" "}
                  </span>
                  <Form.Select
                    value={month}
                    type="select"
                    style={{ marginRight:3, width: "fit-content" }}
                    onChange={(e) => this.onFilter(e.target.value, "month")}
                  >
                    <option value="">Filter Month</option>
                    {months.map((p, index) => (
                      <option value={index+1} key={p}>
                        {p}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
            
                  <Form.Select
                    value={year}
                    type="select"
                    style={{ marginRight:3, width: "fit-content" }}
                    onChange={(e) => this.onFilter(e.target.value, "year")}
                  >
                    <option value="">Filter Year</option>
                    {this.range(start_year, current_year).slice(0).reverse().map((p, index) => (
                      <option value={p} key={p}>
                        {p}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                
              </div>
            }
          </Row>
          </Col>
          <Col md="4" className="">
            <div style={{ display: "flex" }}>
              <Input
                placeholder="Search..."
                autoFocus
                id="show"
                value={search}
                style={{ maxHeight: 45, marginRight: 5, marginBottom: 10 }}
                // onChange={this.handleSearch}
                
              />
            </div>
          </Col>
        </Row>

        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                 
                  <th className="border-0">Month</th>
                  <th className="border-0">Year</th>
                  <th className="border-0">Attendance</th>
                  <th className="border-0">Score</th>
                 
              
                </tr>
              </thead>
              <tbody>
                
                {attendances.map((attendance, key) => {
               
                  return (
                    <tr style={{fontWeight:"bold"}}>
                      
                      
                      
                      <td>{this.monthName(month)}</td> 
                      <td>{year}</td>    
                      <td>{this.getRegAttendance(attendance.attendance_marked)}{this.getUnmarkedAttendance(attendance.attendance_marked, new Date(year, month, 0).getDate())} </td>
                       <td>{attendance.attendance_marked.length}/{new Date(year, month, 0).getDate()}</td>                         
                      
                      
                    </tr>
                  );
                })}
              </tbody>
              <Row>
              <Col md={12} style={{fontWeight:"bold",paddingTop:3}}>
              {attendances.length<1&&
                <div style={{color: '#ccc', alignSelf: 'center', padding: 10, fontSize: 13}}>
                  <i className="fa fa-ban" style={{marginRight: 5}}/>
                  Filter Attendance By Month and Year
                </div>}
             
              </Col>
             
            </Row>
            </Table>
            <Row>
                <Col md={12}>
                {attendances.length > 0 && <Pagination
                  total={total}
                  showTotal={total => `Total ${total} attendances`}
                  onChange={this.onPage}
                  pageSize={rows}
                  current={page}
                />}
                </Col>
            </Row>
            
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default EmployeeAttendance;
