import React, { Component } from "react";
import { FormGroup, CardHeader, Media, Input, Modal, ButtonGroup } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,


} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import { getClients } from "../../services/clientService";
import { getDepartments } from "../../services/departmentService";
import Select from 'react-select';
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Progress, Button } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { AsyncPaginate } from "react-select-async-paginate";
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'

import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { getEmployees } from "../../services/employeeService";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currencies } from "./Currency";
import AddClient from "../clients/AddClient"
import AddCategories from "../categories/AddCategories"
import {getCategories} from "../../services/categoryService"
export class AddProject extends Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);

    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      title: '',
      amount: '',
      progress: 10,
      cost: 0,
      currency: '',
      serial_no:'',
      clients: [],
      categories:[],
      departments: [],
      documents: [],
      employees:[],
      is_deadline:false,
      start_date: moment().startOf('month'),
      deadline: moment().endOf('month'),
      //clients:props.clients,
      //departments:props.departments
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    
    this.getDepartments();
    this.getEmployees();
    this.getCategories();
    this.getClients();

  }


  handleEditorChange(summary) {
    this.setState({ summary });
  }

  getClients = () => {
    this.setState({
      clients: this.props.clients.map((opt) => ({
        label: opt.name,
        value: opt.id,
      }))
    });
  
  };

  getCategories = () => {
    this.setState({
      categories: this.props.categories.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),
    });
  };

  handleRemoveItem = (idx) => () => {
    this.setState({ documents: this.state.documents.filter((s, sidx) => idx !== sidx) });
  }

  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });


  fileChangedHandler = (e) => {
    this.setState({ documents: [...this.state.documents, ...e.target.files] })
  }

  handleInputChange = (item, index) => (selectedSerials) => {

    const items = this.state.cartItem;
    let new_serials = selectedSerials.map(obj => {
      return obj.value
   })
  }


  
  toggleAddClient = () => {
    this.setState({ addClient: !this.state.addClient });
  };

  toggleAddCategory = () => {
    this.setState({ addCategory: !this.state.addCategory });
  };

  getEmployees = () => {
    this.setState({
      employees: this.props.employees.map((opt) => ({
        label: opt.name,
        value: opt.id,
      })),

    });
  };

  getDepartments = () => {
    this.setState({
      departments: this.props.departments.map((opt) => ({
        label: opt.name,
        value: opt.id,
      }))
    });
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  increase = () => {
    let progress = this.state.progress + 10;
    if (progress > 100) {
      progress = 100;
    }
    this.setState({ progress });
  };

  decline = () => {
    let progress = this.state.progress - 10;
    if (progress < 0) {
      progress = 0;
    }
    this.setState({ progress });
  };

  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };


  onSaveProject = async (e) => {
    e.preventDefault();

    await toast.dismiss();
    const { summary,employees_ids,category_id, title, currency, cost, progress, client_id, department_id, start_date, deadline, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
        title: title !== '' && title !== undefined,
        summary: summary !== '' && summary !== undefined,
        start_date: start_date !== '' && start_date !== undefined,
        deadline: deadline !== '' && deadline !== undefined,
        department_id: department_id !== '' && department_id !== undefined,
        client_id: client_id !== '' && client_id !== undefined,
        category_id: category_id !== '' && category_id !== undefined,
        currency: currency !== '' && currency !== undefined,
        cost: cost !== '' && cost !== undefined,
        progress: progress !== '' && progress !== 0,
        employees_ids: employees_ids !== undefined && employees_ids.length > 0,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveProject();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field === "department_id") {
      return "Department is required";
    } else if (field == 'client_id') {
      return "Client is required"
    } else if (field == 'category_id') {
      return "Category is required"
    } else if (field == 'title') {
      return "Title is required"
    } else if (field == 'summary') {
      return "Summary is required"
    } else if (field == 'staert_date') {
      return "Starting Date is required"
    } else if (field == 'deadline') {
      return "Deadline is required";
    }else if (field == 'cost') {
      return "Budget is required";
    }else if (field == 'currency') {
      return "Currency is required";
    }else if(field == 'employees_ids') {
      return "Please assign one or more Employees"
    } 
  };

  handleEmployeeChange = (employees_ids) => {
   

    let new_employees_id = employees_ids.map(obj => {
      return  Number(obj.value)
    })
    this.setState({
      employees_ids: new_employees_id,
    });

  }

  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })

  }

  handleClientChange = (client) => {
    this.setState({ client_id: client.value });
   
  }

  handleCategoryChange = (category) => {
    this.setState({ category_id: category.value });
   
  }

  handleDepartmentChange = (department) => {
    this.setState({ department_id: department.value });

  }

  saveProject = () => {
    this.setState({ saving: true });
    const { client_id, department_id,employees_ids, category_id, title, summary, progress,
      start_date, deadline, is_deadline, currency, cost, documents } = this.state;
    let data = new FormData();
   
    if(is_deadline){
      data.set("deadline", deadline);
     
    }
    data.set("start_date", start_date);
    data.set("client_id", client_id);
    data.set("category_id", category_id);
    data.set("department_id", department_id);
    data.set("title", title);
    data.set("progress", progress);
    data.set("cost", cost);
    data.set("summary", summary);
    data.set("currency", currency);
    let op = employees_ids;
    for (var i in op) {
      data.append(`employees[${i}]`, op[i])
    }
    if(documents.length > 0){
      let op = documents;
      for (var i in op) {
        data.append(`documents[${i}]`, op[i])
      }
    }
    return axios
      .post(
        `${settings.API_URL}addproject`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Project Created");
        },
        (err) => {

          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            if (err.errors) {
              this.showToastError('Wrong File Type')
            }
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );



  };

  loadOptions = (data) => async (search, prevOptions) => {
    await this.sleep(1000);

    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }



    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );
   

    return {
      options: slicedOptions,
      hasMore
    };
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { toggle } = this.props;

    const { saving, addClient, is_deadline, addCategory,categories, employees, title, progress, cost, validation, departments, clients, start_date, deadline, loading } = this.state;
    return (
      
      <>
            {addClient && (
                <AddClient
                    saved={this.getClients}
                    addClient={addClient}
                    toggle={() => this.setState({ addClient: null })}
                />
            )}
             {addCategory && (
                <AddCategories
                    saved={this.getCategories}
                    addCategories={addCategory}
                    toggle={() => this.setState({ addCategory: null })}
                />
            )}
        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5>Create Project</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row>
                <Col md={7} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Project Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      placeholder='Enter Title'
                      value={title}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
                <Col md={5}>

                  <Form.Group style={{marginTop:15}}>
                    <Form.Label>Assign Employees to Project</Form.Label>


                    <AsyncPaginate
                      maxMenuHeight={120}
                      isMulti
                      loadOptions={this.loadOptions(employees)}
                      onChange={this.handleEmployeeChange}
                    />
                  </Form.Group>


                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <Form.Group className="mb-2">
                    <Form.Label>Currency</Form.Label>
                    


                    <Form.Select
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "currency");
                      }}
                      style={{
                        marginRight: 10,
                        width: "100%",
                      }}
                    >

                      <option value="">Select Currency</option>
                      {currencies.length == 0 && ''}
                      {currencies.map((p, index) => (
                        <option value={p.abbrev} key={p}>
                          {p.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Project Budget</Form.Label>
                    <Input
                      className="custom-control-input"
                      placeholder='Enter Project Budget'
                      value={cost}
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "cost");
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>

                  <Form.Group>
                    <Form.Label>Client </Form.Label>


                    <AsyncPaginate
                      maxMenuHeight={150}
                      loadOptions={this.loadOptions(clients)}
                      onChange={this.handleClientChange}
                    />
                  </Form.Group>
                    <ButtonGroup style={{display:'inline-table'}}>
                        <Button
                            variant="outline-primary"
                            size="sm"
                            onClick={() => this.toggleAddClient()}
                        >
                            + New Client
                        </Button>


                    </ButtonGroup>

                </Col>
                
                <Col md={6} style={{ maxHeight: 100 }} >
                  <Form.Group className="mb-2">
                    <Form.Label>Department</Form.Label>
                    <AsyncPaginate
                      maxMenuHeight={150}
                      loadOptions={this.loadOptions(departments)}
                      onChange={this.handleDepartmentChange}
                    />
                    {/* <Select
                      onChange={this.handleDepartmentChange}
                      options={departments}
                    /> */}


                  </Form.Group>

                </Col>
                

              </Row>
             

              <Row>
              <Col md="5">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Deadline
                    </label>
                    <ReactDatetime
                      value={deadline}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'deadline')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                {is_deadline && (
                  <>
                  <Col md="5">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                      value={start_date}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>

                
                  </>
                )}
                 <Col md={3}>
                    <Form.Group className="mb-2">
                      <Form.Label style={{ paddingRight: 10 }}>Allow Deadline</Form.Label>
                      <Input
                        className="custom-control-input"
                        id="is_deadline"
  
                        checked={is_deadline}
                        onChange={async (e) => {
                          await this.onChange(e.target.checked, "is_deadline");
                        }}
                        

                        type="checkbox"
                      />
                    </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Form.Label style={{ paddingRight: 10 }}>Project Progress</Form.Label>
                  <Progress type="circle" onChange={e => this.onChange(e, 'progress')} value={progress} percent={progress} />
                  <Button.Group>
                    <Button onClick={this.decline} icon={<MinusOutlined />} />
                    <Button onClick={this.increase} icon={<PlusOutlined />} />
                  </Button.Group>
                </Col>
                <Col md={6}>

                  <Form.Group>
                    <Form.Label>Category </Form.Label>


                    <AsyncPaginate
                      maxMenuHeight={150}
                      loadOptions={this.loadOptions(categories)}
                      onChange={this.handleCategoryChange}
                    />
                  </Form.Group>
                  <ButtonGroup style={{display:'inline-table'}}>
                      <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={() => this.toggleAddCategory()}
                      >
                          + New Category
                      </Button>


                  </ButtonGroup>

                </Col>
                

              </Row>
              <Row style={{marginBottom:20}}>
              <Col md={6}>
                  <div className="d-xl-flex align-items-center">

                    <div className="company-avatar xl-avatar">

                      {this.state.documents.map((file, key) => (
                        <Button style={{ paddingBottom: 10 }} variant="outline-primary" key={file.name} onClick={this.handleRemoveItem(key)}>
                          {file.name}&nbsp;<span style={{ color: 'red', fontWeight: 'bold', fontSize: 15 }}>x</span>
                        </Button>
                      ))}

                    </div>
                    <div className="file-field">
                      <div className="d-flex justify-content-xl-center ms-xl-3">
                        <div className="d-flex">


                          <input onChange={this.fileChangedHandler} type="file" multiple />
                          <div className="d-md-block text-start">
                            <span className="icon icon-md">
                              <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                            </span>
                            <div className="fw-normal text-dark mb-1">Choose Documents</div>
                            <div className="text-gray small">Pdf, doc, docx, ppt, txt, JPG, GIF or PNG. Max size of 2MB</div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              
              <Row>
                <Col md={12}>
                <Form.Label style={{ paddingRight: 10 }}>Summary</Form.Label>
                  <Editor

                    value={this.state.summary} onEditorChange={this.handleEditorChange}

                    //initialValue="<p>This is the initial content of the editor.</p>"
                    init={{
                      height: 300,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                </Col>

              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveProject}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddProject;
