import settings from './settings';
import { authHeader } from './authHeader';
import { authService } from './authService';


export function getTrainers(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}trainers`, requestOptions).then(authService.handleResponse);
}

export function addTrainer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}addtrainer`, requestOptions).then(authService.handleResponse);
}

export function editTrainer(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${settings.API_URL}updatetrainer/${data.id}`, requestOptions).then(authService.handleResponse);
}

export function deleteTrainer(id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };
    return fetch(`${settings.API_URL}deletetrainer/${id}`, requestOptions).then(authService.handleResponse);
}


