import React, { Component } from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import Select from 'react-select';
import { faEnvelope, faPhone, faLock, faPencilAlt, faAddressCard, faUnlockAlt, faEyeSlash, faEye, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import axios from 'axios'
import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import AddClient from "../clients/AddClient";
import AddPayment from "./AddPayment";
import EditPayment from "./EditPayment";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { getInvoice } from "../../services/invoiceService";
import { InputNumber } from 'antd';
import { getProject } from "../../services/projectService";


export class EditInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      search: '',
      validation: {},
      show: false,
      edit: false,
      submitted: false,
      invoice: [],
      items: [],
      user: JSON.parse(localStorage.getItem('user')),
      payments: [],
      total_amount: 0,
      previous_payment: 0,
      balance: 0,
      project_id: props.match.params.id,
      hideNav: false,
      project: {},
      showInvoice:false,
      showPayment:true,


    };
  }

  componentDidMount() {
  
    toast.configure({ hideProgressBar: true, closeButton: false });
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.getInvoice()
    this.state.user.admin == 1 &&   this.getProject();
  }

  resize() {
    this.setState({ hideNav: window.innerWidth <= 760 });
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  getProject = () => {
    const { project_id } = this.state;
    this.setState({ loading: true })
    getProject(project_id).then(
      (res) => {
        this.setState({
          project: res.project
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  onSaveInvoice = async (e) => {
    e.preventDefault();

    await toast.dismiss();
    const { invoice, project } = this.state;
    const { validation, invoice_no, project_id, amount_paid, cashier_id, due_date, issued_date } = invoice;

    this.setState({ submitted: true });
    // let check_payment = amount_paid > project.cost;
    await this.setState({
      validation: {
        ...validation,
        invoice_no: invoice_no !== '',
        project_id: project_id !== '',
        due_date: due_date !== '',
        issued_date: issued_date !== '',
        amount_paid: amount_paid !== 0 && amount_paid !== '' && amount_paid !== null,

      }
    })
    if (Object.values(this.state.validation).every(Boolean)) {
      this.setState({ submitted: false })
      this.saveInvoice();
    } else {
      const errorss = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errorss.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      );
    }
  };


  validationRules = field => {
    if (field === "invoice_no") {
      return "Invoice number is required";
    } else if (field === "issued_date") {
      return "Issue date is required";
    } else if (field === "due_date") {
      return "Due date is required";
    } else if (field === 'amount_paid') {
      return "Deposit field is required";
    } else if (field === 'project_id') {
      return "Project is required";
    }
  }


  saveInvoice = () => {
    this.setState({ saving: true });
    const { invoice } = this.state;
    const { invoice_no, project_id, total_payment, due_date, issued_date } = invoice;

    let data = new FormData();
    data.set("invoice_no", invoice_no);
    data.set("project_id", project_id);
    data.set("due_date", due_date);
    data.set("issued_date", issued_date);
    data.set("amount_paid", total_payment);


    return axios
      .post(
        `${settings.API_URL}updateinvoice/${this.state.invoice.id}`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      )
      .then((res) => {
        this.setState({
          saving: false,
          invoice_no: '',
          amount_paid: 0,
          project_id: '',
        });
        this.showToast("Invoice Updated");
        // this.getInvoiceId();
        // this.getInvoice();
        this.props.history.push(`/invoices`)


      })
      .catch((err) => {
        if (err) {
          toast.dismiss();
          toast.configure({ hideProgressBar: true, closeButton: false });
          this.showToastError('An Invoice with the Project Name already exist')

          this.setState({ saving: false });
        }
      });
  };





  onChange = (e, state) => {
    const { invoice } = this.state

    this.setState({ invoice: { ...invoice, [state]: e } })
  }

  toggleEditPayment = (payment) => {
    this.setState({ payment });
    this.getInvoice();


  };


  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "white" }}>{msg}</div>);
  };


  toggleEdit = () => {
    this.setState({ edit: !this.state.edit });
  };

  getInvoice = () => {
    const { project_id } = this.state;
    this.setState({ loading: true })
    getInvoice(project_id).then(

      (res) => {
        this.setState({
          invoice: res.invoice,
          payments: res.payments,
          items: res.items,
          loading: false,
          edit: false
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };


  toggleAddPayment = () => {
    this.setState({ addPayment: !this.state.addPayment });
  };


  toggleAddClient = () => {
    this.setState({ addClient: !this.state.addClient });
  };


  formatCurrency(x) {
    if (x !== null && x !== 0 && x !== undefined) {
      const parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return `${parts.join(".")}`;
    }
    return '0';
  }
  toggleInvoiceMenu = () => {
    this.setState({showInvoice: true, showPayment:false});
}

togglePaymentMenu = () => {
    this.setState({showPayment: true, showInvoice:false});
}






  render() {

    const { project, showPayment, showInvoice, saving, hideNav, addPayment, user, company, payment, submitted, edit, payments, loading, invoice } = this.state;
    console.log(this.state.invoice.invoice_no)
    return (
      <>


        {loading && <SpinDiv text={"Loading..."} />}


        {addPayment && (
          <AddPayment
            saved={this.getInvoice}
            addPayment={addPayment}
            payments={payments}
            invoice={invoice}
            toggle={() => this.setState({ addPayment: null })}
          />
        )}

        {payment && (
          <EditPayment
            saved={this.getInvoice}
            payment={payment}
            payments={payments}
            invoice={invoice}
            toggle={() => this.setState({ payment: null })}
          />
        )}
        <Row style={{}}>
          <Col lg="12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
              <div className="d-block mb-4 mb-md-0">
                <Breadcrumb
                  listProps={{
                    className: " breadcrumb-text-dark text-primary",
                  }}
                >
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="/invoices">Invoices</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="btn-toolbar mb-2 mb-md-0">
                {!hideNav && <ButtonGroup>
                  <>
                      
                     <Button variant="outline-primary" style={{ fontSize: 18, fontWeight: 'bold' }} size="sm" onClick={() => this.togglePaymentMenu()}>Payments</Button>
                     {user.admin == 1 && (<>
                      <Button variant="outline-primary" style={{ fontSize: 18, fontWeight: 'bold' }} size="sm" onClick={() => this.toggleInvoiceMenu()}>Invoice</Button>
                    <Button variant="outline-primary" size="sm" onClick={() => this.toggleAddPayment()}>
                      + New Payment

                    </Button>
                     </>)}
                    </>

                </ButtonGroup>}

              </div>
            </div>
          </Col>
        </Row>
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Row>
              {showInvoice && (<>
                <Row>

                  <Col md={10}>

                    <Row style={{ marginBottom: 20 }}>
                      <Col md={2}>
                        <ButtonGroup>
                          {((user.admin === 1) && payments.length <= 1) && (

                            <Button color={edit ? "primary" : "success"}
                              onClick={this.toggleEdit}
                              size="sm" variant="outline-primary">
                              {edit ? "Discard Changes" : "+ Edit Invoice"}



                            </Button>
                          )}
                        </ButtonGroup>
                      </Col>
                      <Col md={4} style={{ fontSize: 20, fontWeight: 'bold' }}>
                        Amount :<span >{project.currency}</span>{this.formatCurrency(project.cost)}
                      </Col>


                      <Col md={3} style={{ fontSize: 20, fontWeight: 'bold' }}>
                        Paid:  <span>{project.currency}</span>{this.formatCurrency(invoice.total_payment)}
                      </Col>
                      <Col md={3} style={{ fontSize: 20, fontWeight: 'bold' }}>
                        Balance: <span>{project.currency}</span>{this.formatCurrency(invoice.total_balance)}
                      </Col>




                    </Row>

                    <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '20px 15px', borderRadius: 7 }}>
                      <Col md={4} style={{ marginBottom: 20, }}>

                        <Form.Group className="mb-2">
                          <Form.Label > Invoice</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </InputGroup.Text>
                            <Input

                              type="text" placeholder="Enter Invoice No"
                              name='invoice_no'
                              value={invoice.invoice_no}
                              onChange={async (e) => {
                                await this.onChange(e.target.value, "invoice_no");
                              }}
                              required


                            />
                          </InputGroup>

                        </Form.Group>

                      </Col>
                      <Col md={4} style={{ marginBottom: 20, }}>
                        <Form.Label>Project title</Form.Label>
                        <InputGroup>

                          <Input

                            type="text"
                            value={project.title}
                            disabled

                          />
                        </InputGroup>


                      </Col>


                    </Row>

                    <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '20px 15px', borderRadius: 7 }}>
                      <Col md={4} style={{ marginBottom: 20, }}>

                        <Form.Group className="mb-2">
                          <Form.Label>Project Cost</Form.Label>
                          <InputGroup>

                            <Input

                              type="text"
                              value={this.formatCurrency(project.cost)}
                              disabled

                            />
                          </InputGroup>

                        </Form.Group>

                      </Col>
                      <Col md={4} style={{ marginBottom: 20, }}>

                        <Form.Group className="mb-2">
                          <Form.Label>Amount Received</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              {project.currency}
                            </InputGroup.Text>
                            <InputNumber

                              style={{ width: 'auto', height: 40, paddingTop: 5, borderRadius: 5, fontSize: 18 }}
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              value={invoice.total_payment}
                              onChange={e => this.onChange(e, 'total_payment')}
                            />

                          </InputGroup>

                        </Form.Group>

                      </Col>
                    </Row>

                    <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '20px 15px', borderRadius: 7 }}>
                      <Col md="4" style={{ marginBottom: 20, }}>
                        <FormGroup className="form-date">

                          <Form.Label > Date </Form.Label>
                          <ReactDatetime
                            value={moment(invoice.issued_date).format('MMM DD, YYYY') || ''}
                            dateFormat={'MMM D, YYYY'}
                            closeOnSelect
                            onChange={e => this.onChange(e, 'issued_date')}
                            inputProps={{
                              required: true,
                              className: 'form-control date-width'
                            }}
                            timeFormat={false}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4" style={{ marginBottom: 20, }}>
                        <FormGroup className="form-date">
                          <Form.Label > Due Date</Form.Label>
                          <ReactDatetime
                            value={moment(invoice.due_date).format('MMM DD, YYYY') || ''}
                            dateFormat={'MMM D, YYYY'}
                            closeOnSelect
                            onChange={e => this.onChange(e, 'due_date')}
                            inputProps={{
                              required: true,
                              className: 'form-control date-width'
                            }}
                            timeFormat={false}
                          />
                        </FormGroup>
                      </Col>



                    </Row>
                    <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '15px 2px', borderRadius: 7 }}>
                      <Col md={4}>

                        <Form.Group className="mb-2">
                          <Form.Label>Total Purchase</Form.Label>
                          <InputGroup>

                            <Input

                              type="text"
                              value={invoice.currency + this.formatCurrency(project.cost + '.00')}
                              disabled

                            />
                          </InputGroup>

                        </Form.Group>

                      </Col>
                     
                    </Row>

                  </Col>
                </Row>

                <Row style={{ border: '1px #eee solid', padding: '10px 5px 0px', margin: '15px 2px', borderRadius: 7 }}>

                  {edit && (<Row>

                    <Col md={8}>
                    </Col>
                    <Col md={4}>

                      <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                        <Col md={12}>
                          Total Cost:  <span style={{ fontSize: 15 }}>{invoice.currency}</span>{this.formatCurrency(project.cost)}
                        </Col>
                      </Row>
                      <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                        <Col md={12}>
                          Amount Received:  <span style={{ fontSize: 15 }}>{invoice.currency}</span>{this.formatCurrency(invoice.amount_paid)}
                        </Col>
                      </Row>
                      <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                        <Col md={12}>
                          Balance: <span style={{ fontSize: 15 }}>{invoice.currency}</span>{this.formatCurrency(project.cost - invoice.amount_paid)}
                        </Col>
                      </Row>
                      <Row style={{ fontSize: 20, fontWeight: 'bold' }}>
                        <Col md={12}>
                          <Button variant="outline-primary" size="md" disabled={saving} onClick={this.onSaveInvoice}>
                            + Update Invoice

                          </Button>
                        </Col>
                      </Row>
                    </Col>

                  </Row>)}



                </Row>
              </>)}

              {showPayment && (<>
                { <Row>
                  <h5 style={{ fontWeight: 'bold', marginTop: 15 }}>Payments</h5>
                  <Table
                    responsive
                    className="table-centered table-nowrap rounded mb-0"
                  >
                    <thead className="thead-light">
                      <tr>
                        <th className="border-0">Invoice No</th>
                        <th className="border-0">Amount</th>
                        <th className="border-0">Paid</th>
                        {/* <th className="border-0">Balance</th> */}
                        <th className="border-0">Transaction Date</th>
                      </tr>
                    </thead>
                    <tbody>

                      {payments.map((payment, key) => {

                        return (
                          <tr style={{ fontWeight: "bold" }}>

                            <td >{invoice.invoice_no}</td>
                            <td >{invoice.currency}{this.formatCurrency(payment.amount)}</td>
                            <td >{invoice.currency}{this.formatCurrency(payment.amount_paid)}</td>
                            {/* <td >{invoice.currency}{this.formatCurrency(payment.balance)}</td> */}
                            <td>{moment(payment.created_at).format('MMM DD YYYY')}</td>

                            <td>
                              <ButtonGroup>
                                {((user.admin === 1) && payments.length >= 1) && (

                                  <Button
                                    variant="outline-primary"
                                    onClick={() => this.toggleEditPayment(payment)}
                                    size="md"
                                  >
                                    Edit
                                  </Button>
                                )}



                              </ButtonGroup>
                            </td>

                          </tr>
                        );
                      })}
                      <tr style={{ fontWeight: "bold" }}>
                        <td colSpan={2}></td>
                        <td>  Total Payments: <span>{invoice.currency}&nbsp;</span>{this.formatCurrency(invoice.total_payment)}</td>
                        <td>  Balance: <span>{invoice.currency}&nbsp;</span>{this.formatCurrency(invoice.total_balance)}</td>
                      </tr>
                    </tbody>

                  </Table>
                </Row>

              }

              </>)}
              

            </Row>


          </Card.Body>
        </Card>
      </>
    );
  }
}

export default EditInvoice;
