import React, { Component } from "react";
import { FormGroup, CardHeader, Media, Input, Modal, ButtonGroup } from "reactstrap";
import {
  Col,
  Row,
  Card,
  Form,


} from "@themesberg/react-bootstrap";
import "@pathofdev/react-tag-input/build/index.css";
import SpinDiv from "../components/SpinDiv";
import { toast } from "react-toastify";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Button, Select } from 'antd';
import { AsyncPaginate } from "react-select-async-paginate";
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'

import settings from "../../services/settings";
import { authHeader } from "../../services/authHeader";
import { authService } from "../../services/authService";
import { getEmployees } from "../../services/employeeService";
import { getProjects, getProjectMembers } from "../../services/projectService"
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currencies } from "./Currency";
import AddClient from "../clients/AddClient"
import { Multiselect } from 'multiselect-react-dropdown';

export class AddTask extends Component {
  constructor(props) {
    super(props);
    this.handleEditorChange = this.handleEditorChange.bind(this);

    this.state = {
      search: "",
      page: 1,
      rows: 10,
      loading: false,
      title: '',
      employees: [],
      projects: [],
      start_date: moment().startOf('month'),
      deadline: moment().endOf('month'),
      employees_id: [],
      project_id:'',
      status: false
    };
  }

  componentDidMount() {
    toast.configure({ hideProgressBar: true, closeButton: false });
    //this.getEmployees();
    this.getProjects();

  }
  


  handleEditorChange(description) {
    this.setState({ description });
  }

  getEmployees = () => {
    this.setState({ loading: true, });
    const { project_id } = this.state;
    getProjectMembers({ project_id }).then(

      (res) => {
        console.log(res)
        this.setState({
          employees: res.employees.map((opt) => ({
            name: opt.name,
            id: opt.id,
          })),
          loading: false,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };

  getProjects = () => {
    this.setState({
      projects: this.props.projects.filter(p => p.progress < 100).map((opt) => ({
        label: opt.title,
        value: opt.id,
      }))
    });
  
  };

  // getProjects = () => {
  //   this.setState({ loading: true });
  //   getProjects().then(

  //     (res) => {
  //       console.log(res)
  //       this.setState({
  //         projects: res.projects.data.filter(p => p.progress < 100).map((opt) => ({
  //           label: opt.title,
  //           value: opt.id,
  //         })),
  //         loading: false,
  //       });
  //     },
  //     (error) => {
  //       this.setState({ loading: false });
  //     }
  //   );
  // };



  sleep = ms =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, ms);
    });



  loadOptions =  (data) => async (search, prevOptions) => {
    
    console.log(this.state.employees)

    let filteredOptions;
    if (!search) {
      filteredOptions = data;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = data.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore
    };
  };





  onChange = (e, state) => {
    this.setState({ [state]: e });
  };


  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };


  onSaveTask = async (e) => {
    e.preventDefault();

    await toast.dismiss();
    const { description, title, project_id, status, employees_id, start_date, deadline, validation } = this.state;
    console.log(status)
    await this.setState({
      validation: {
        ...validation,
        title: title !== '' && title !== undefined,
        description: description !== '' && description !== undefined,
        project_id: project_id !== '' && project_id !== undefined,
        start_date: start_date !== '' && start_date !== undefined,
        deadline: deadline !== '' && deadline !== undefined,
        employees_id: employees_id !== undefined && employees_id.length > 0,
        status: status !== '' && status !== undefined,
      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.saveTask();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });

      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      )
    }
  };


  validationRules = (field) => {
    if (field == 'employees_id') {
      return "Please assign one or more Employees"
    } else if (field == 'title') {
      return "Title is required"
    } else if (field == 'description') {
      return "description is required"
    } else if (field == 'staert_date') {
      return "Starting Date is required"
    } else if (field == 'deadline') {
      return "Deadline is required";
    } else if (field == 'status') {
      return "Status is required";
    } else if (field == 'project_id') {
      return "Project is required";
    }
  };

  dateFilter = async (e, state) => {
    await this.setState({ [state]: e })

  }

  onSelect(selectedList, selectedItem) {

}

onRemove(selectedList, removedItem) {
  
}



  handleEmployeeChange = (employees_id) => {

   if(employees_id.length > 0){
    let new_employees_id = employees_id.map(obj => {
      return Number(obj.split(' ')[0])
    })
    this.setState({
      employees_id: new_employees_id,
    });
   } else {
     return []
   }
    

  }

  handleProjectChange = (project) => {
    //this.setState({ project_id: project.value });
    
    this.setState({employees_id:[], title:''})
  
  }

  setStatus(event) {
    console.log(event.target.value);
    this.setState({status:event.target.value})
  }

  saveTask = () => {
    this.setState({ saving: true });
    const { project_id, title, description, status,
      start_date, deadline, employees_id } = this.state;
    console.log(title)
    let data = new FormData();
    data.set("start_date", start_date);
    data.set("title", title);
    data.set("deadline", deadline);
    data.set("project_id", project_id);
    data.set("status", status);
    data.set("description", description);
    let op = employees_id;
    for (var i in op) {
      data.append(`employees[${i}]`, op[i])
    }

    return axios
      .post(
        `${settings.API_URL}addtask`,
        data,
        {
          headers: authHeader(),
        },
        authService.handleResponse
      ).then(
        (res) => {
          this.setState({ saving: false });
          this.props.toggle();
          this.showToast("Task Created");
        },
        (err) => {

          if (err) {
            toast.dismiss();
            toast.configure({ hideProgressBar: true, closeButton: false });
            this.showToastError('Task could not be created')
            this.setState({ saving: false });
          }
          this.setState({ loading: false });
        }
      );



  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20 }}>{msg}</div>);
  };

  render() {
    const { toggle } = this.props;

    const { saving, title, project_id, projects, status, employees, validation, start_date, deadline, loading } = this.state;
    return (

      <>

        <Modal
          className="modal-dialog modal-dialog-centered"
          isOpen
          toggle={() => !loading && !saving && toggle}
          style={{ maxWidth: 800 }}
        >
          {loading && <SpinDiv text={"loading..."} />}
          {saving && <SpinDiv text={"Saving..."} />}
          <div className="modal-header" style={{ padding: "1rem" }}>
            <div className="btn-toolbar mb-2 mb-md-0">
              <h5>Create Task</h5>
            </div>

            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              
              <Row>
                <Col md={6}>

                  <Form.Group>
                    <Form.Label>Add Project </Form.Label>


                    <AsyncPaginate

                      loadOptions={this.loadOptions(projects)}
                      onInputChange={this.handleProjectChange}
                      
                      onChange={async (property, value) => {
                        console.log(property);
                        await this.setState({
                          project_id: property.value,
                          selectedTitle: property.label,
                        
                        });
                        await this.getEmployees();
                      }}
                    />
                  </Form.Group>


                </Col>
                <Col md={6}>
                  {project_id && <Form.Group>
                   <div>
                   <Form.Label>Assign Task to Members </Form.Label>
                   </div>
                   

                      <Select  mode="multiple" 
                        defaultValue={this.state.employees_id} 
                        allowClear onChange={this.handleEmployeeChange} style={{ width: 200 }}>
                        <option value="">Select Employees</option>
                          {employees.length == 0 && ''}
                          {employees.map((p, index) => (
                            <option key={p.id+' '+p.name}>
                              {p.name}
                            </option>
                          ))}
                        </Select>
                  </Form.Group>
                  }
                  
                </Col>





              </Row>
              <Row>
                <Col md={12} style={{ paddingTop: 15 }}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Task Title</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="title"
                      
                      placeholder='Enter Title'
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "title");
                      }}
                      type="text"
                    />
                  </Form.Group>

                </Col>
              </Row>


              <Row>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Start Date
                    </label>
                    <ReactDatetime
                      value={start_date}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'start_date')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup className="form-date">
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Due Date
                    </label>
                    <ReactDatetime
                      value={deadline}
                      dateFormat={'MMM D, YYYY'}
                      closeOnSelect
                      onChange={e => this.dateFilter(e, 'deadline')}
                      inputProps={{
                        required: true,
                        className: 'form-control date-width'
                      }}
                      style={{ width: '100%' }}
                      //isValidDate={(current)=>{return (current.isAfter(start_date)||current.isSame(start_date));}}
                      timeFormat={false}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                <div onChange={this.setStatus.bind(this)}>
                <Form.Label style={{ paddingRight: 10 }}>Completed </Form.Label>
                  <input type="radio" value="1" name="status"/> 
                  <Form.Label style={{ paddingRight: 10 }}> &nbsp; &nbsp;Inprogess </Form.Label>
                  <input type="radio" value="0" name="status"/>
                </div>
                 

                 

                </Col>
                


              </Row>
              <Row style={{ marginTop: 50 }}>
                <Col md={12}>
                  <Editor

                    value={this.state.description}
                    onEditorChange={this.handleEditorChange}

                    //initialValue="<p>This is the initial content of the editor.</p>"
                    init={{
                      height: 300,
                      menubar: true,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent | ' +
                        'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                </Col>

              </Row>
              <Row style={{ marginTop: "10px" }}>
                <Col md={12}>
                  <div>
                    <Button
                      variant="primary"
                      size="sm"
                      style={{ marginTop: "10px", float: "right" }}
                      disabled={saving}
                      onClick={this.onSaveTask}
                    >
                      Save
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      data-dismiss="modal"
                      type="button"
                      disabled={saving}
                      style={{ marginTop: "10px", float: "right" }}
                      onClick={toggle}
                    >
                      {" "}
                      Close
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Modal>
      </>
    );
  }
}

export default AddTask;
