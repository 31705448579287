import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from "reactstrap";
import { Col, Row, Form, ButtonGroup, Card, Image, Spinner, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Profile1 from "../../assets/img/team/profile-picture-1.jpg";
import ProfileCover from "../../assets/img/profile-cover.jpg";
import { toast } from "react-toastify";
import moment from "moment";
import { addCompanyProfile, getCompany } from "../../services/companyService"
import ReactDatetime from "react-datetime";
import SpinDiv from "../components/SpinDiv";
import Resizer from "react-image-file-resizer";
export class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      company: {},
      loading: false,
      saving: false,
    };
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
  }

  componentDidMount() {
    this.getCompany()
  }

  getCompany = () => {

    this.setState({ loading: true });
    getCompany().then(

      (res) => {
        console.log(res)
        this.setState({
          loading: false,
          company: res.company,
        });
      },
      (error) => {
        this.setState({ loading: false });
      }
    );
  };




  onSave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const { validation, company, newImage, imageType, imageSize } = this.state;
    console.log(company.name)
    const { name, email, phone, address, city, country, currency,
       website } = company;
    await this.setState({
      validation: {
        ...validation,
        name: name !== '' && name !== undefined,
        email: email !== ''  && email !== undefined,
        phone: phone !== '' && phone !== undefined,
        address: address !== '' && address !== undefined,
        website: website !== '' && website !== undefined,
        

      },
     
    });
    
    if (Object.values(this.state.validation).every(Boolean)) {
      this.save();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      toast(
        <div style={{ padding: "10px 20px" }}>
          <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>Errors:</p>
          {errors.map((v) => (
            <p key={v} style={{ margin: 0, fontSize: 14, color: "red" }}>
              * {this.validationRules(v)}
            </p>
          ))}
        </div>
      );
    }
  };

  validationRules = (field) => {
    if (field === "name") {
      return "Name is required";
    } else if (field === "address") {
      return "Address is required";
    } else if (field === "phone") {
      return "Phone is required";
    } else if (field === "email") {
      return "Email is required";
    } else if (field === "website") {
      return "Website Url is required";
    }
  
  };

  showToast = (msg) => {
    toast(<div style={{ padding: 20, color: "success" }}>{msg}</div>);
  };

  toggleEditCompany = () => {
    this.setState({ editCompany: !this.state.editCompany });
  };

  onChange = (e, state) => {
    const { company } = this.state;
    this.setState({ company: { ...company, [state]: e } });
  };

  fileChangedHandler(event) {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
      this.setState({
        imageType: event.target.files[0].type,
        imageSize: event.target.files[0].size
      });
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "JPEG",
          100,
          0,
          (uri) => {
            console.log(uri);
            this.setState({ newImage: uri });
            //this.setState({imageType:event.target.files[0].type})
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
      }
    }
  }


  save = () => {
    this.setState({ saving: true });

    const { company, newImage, validation } = this.state;
    const { name, email, phone, address, logo_url, website } = company;
    this.setState({ saving: true })
  
    addCompanyProfile({
      name: name,
      logo_url: logo_url,
      email: email,
      website: website,
      phone: phone,
      address: address,
      data: newImage === null || undefined ? '':newImage,
    }).then(
      (res) => {
        console.log(res);
        this.setState({ loading: false, saving: false });
        toast.dismiss();
        toast.configure({ hideProgressBar: true, closeButton: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "green" }}>Company Settings Updated</p>

          </div>
        );

        this.getCompany();
      },
      (error) => {
        console.log(error);
        this.setState({ saving: false });
        toast(
          <div style={{ padding: "10px 20px" }}>
            <p style={{ margin: 0, fontWeight: "bold", color: "red" }}>An error occurred</p>

          </div>
        );
      }
    );
  };




  render() {
    const { photo, saving, loading, company, editCompany } = this.state;

    return (
      <>

        {loading && <SpinDiv text={"Loading..."} />}
        {saving && <SpinDiv text={"saving..."} />}
        <Row>
          <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Row>
                <Col className="text-right" md={12}>
                  {company && (
                    <ButtonGroup>

                      <Button
                        variant={editCompany ? "secondary" : "primary"}
                        onClick={this.toggleEditCompany}
                        size="sm"
                      >
                        {editCompany ? "Discard Changes" : "Edit Company Profile"}
                      </Button>
                    </ButtonGroup>


                  )}
                </Col>
              </Row>
              <Card.Body>
                <h5 className="mb-4">General information</h5>
                <Form>
                  <Row>
                    <Col md={6} className="mb-3">
                      <Form.Group id="firstName">
                        <Form.Label>Company Name</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={company.name || ''}
                          disabled={!editCompany}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "name");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-3">
                      <Form.Group id="emal">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={company.email}
                          disabled={!editCompany}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "email");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    

                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Website</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={company.website}
                          disabled={!editCompany}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "website");
                          }}
                        />

                    </Col>
                    
                  </Row>
                  
                  <Row>
                    
                    <Col md={6} className="mb-3">
                      <Form.Group id="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control
                          required
                          type="text"
                          value={company.phone || ''}
                          disabled={!editCompany}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "phone");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <div className="d-xl-flex align-items-center">
                        <img src={this.state.newImage} alt="" />
                        <div className="company-avatar xl-avatar">
                          {console.log(company.logo_url)}
                          <Image fluid rounded src={company.logo_url !== null ? company.logo_url : photo} />
                        </div>
                        <div className="file-field">
                          <div className="d-flex justify-content-xl-center ms-xl-3">
                            <div className="d-flex">
                              <span className="icon icon-md">
                                <FontAwesomeIcon icon={faPaperclip} className="me-3" />
                              </span>

                              <input type="file" onChange={this.fileChangedHandler} />
                              <div className="d-md-block text-start">
                                <div className="fw-normal text-dark mb-1">Choose Image</div>
                                <div className="text-gray small">JPG, GIF or PNG. Max size of 800K</div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>
                    
                  </Row>
                  
                  <Row>
                  <Col md={6} className="mb-3">
                      <Form.Group id="address">
                        <Form.Label>Address</Form.Label>

                        <Input
                          required
                          type="textarea"
                          rows={5}
                          value={company.address}
                          disabled={!editCompany}
                          onChange={async (e) => {
                            await this.onChange(e.target.value, "address");
                          }}
                        />
                      </Form.Group>
                    </Col>
                    
                   
                  </Row>
                  
                
                  {editCompany && (
                    <div className="mt-3">
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={saving}
                        onClick={this.onSave}>
                        Save
                      </Button>
                    </div>
                  )}
                </Form>
              </Card.Body>
            </Card>
          </Col>

          
        </Row>
      </>
    );
  }
}

export default Profile