import AwardIndex from "./pages/award/AwardIndex";
import PromotionIndex from "./pages/promotion/PromotionIndex";
import TerminationIndex from "./pages/termination/TerminationIndex";
import ResignationIndex from "./pages/resignation/ResignationIndex";
import { faAward, faGift, faHandScissors, faUnlock } from "@fortawesome/free-solid-svg-icons";
import AwardTypeIndex from "./pages/awardtype/AwardTypeIndex";

const p = JSON.parse(localStorage.getItem('permissions'));
const permissions = p !== null ?  p.map((p, index)=>{ return p.name}) :[]
export let hrRoute = [

  ...(permissions.includes('show promotion') === true ?  [ { path: "/promotions",component: PromotionIndex, title:"Promotions",icon:faGift },] : []),
  ...(permissions.includes('show resignation') === true ?  [  { path: "/resignations",component: ResignationIndex, title:"Resignations",icon:faUnlock},] : []),
  
   
    // { path: "/terminations",component: TerminationIndex, title:"Terminations",icon:faHandScissors },
  
   
    

  ];