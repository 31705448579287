import React from "react";
import {
  Col,
  Row,
  Nav,
  Card,
  Table,
  Form,
  Button,
  ButtonGroup,
  Breadcrumb,
  InputGroup,
  Dropdown,

} from "@themesberg/react-bootstrap";
import { FormGroup, CardHeader, Media, Input, Modal } from "reactstrap";
import { toast } from 'react-toastify';

import { rejectLeave } from "../../services/leaveService";
import SpinDiv from "../components/SpinDiv";

class RejectLeave extends React.Component {
	constructor(props) {
		super(props);
    this.state = {
      leave: props.rejectLeave,
      loading: false,
      search: '',
      validation: {},
      name: ''
    };
	}

  componentDidMount() {
    toast.configure({hideProgressBar: true, closeButton: false});
  }

  showToast = msg => {
    toast(<div style={{ padding: 20, color: "green" }}>{msg}</div>);
  }
  showToastError = (msg) => {
    toast(<div style={{ padding: 20, color: "red" }}>{msg}</div>);
  };

  onChange = (e, state) => {
    this.setState({ [state]: e });
  };

  onRejectLeave = async (e) => {
    e.preventDefault();
    await toast.dismiss();
    const {reject_reason, validation } = this.state;
    await this.setState({
      validation: {
        ...validation,
       
        reject_reason: reject_reason !== '' && reject_reason !== undefined,

      },
    });
    if (Object.values(this.state.validation).every(Boolean)) {
      this.onReject();
    } else {
      const errors = Object.keys(this.state.validation).filter((id) => {
        return !this.state.validation[id];
      });
      //await toast.configure({hideProgressBar: true, closeButton: false});
      toast.dismiss();
      toast.configure({ hideProgressBar: true, closeButton: false });
      
          toast(
            <div style={{ padding: "10px 20px" }}>
              <p style={{ margin: 0, fontWeight: "bold",color:"red" }}>Errors:</p>
              {errors.map((v) => (
                <p key={v} style={{ margin: 0, fontSize: 14,color:"red" }}>
                  * {this.validationRules(v)}
                </p>
              ))}
            </div>
          )
    }
  };

  onReject = () => {
    this.setState({loading: true});
    const {leave, reject_reason} = this.state;
    rejectLeave({
      id: leave.id,
      reject_reason: reject_reason,
    })
      .then(res => {
          console.log(res)
          this.setState({loading: false});
          this.props.saved();
          this.props.toggle();
          this.showToast('leave Rejected')
      },
    	error => {
        this.showToastError('leave cannot be Rejected')
        this.setState({loading: false});
    	});
  }

  validationRules = (field) => {
    if (field === "reject_reason") {
      return "Rejection Reason is required";
    } 
  };

 

  render() {
    const {toggle } = this.props
    const {loading,saving, leave} = this.state;
    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={leave!=null}
          toggle={() => !loading&&toggle}
          style={{maxWidth: 600}}
        >
          {saving&&<SpinDiv text={'rejecting...'} />}
          <div className="modal-header" style={{padding: '1rem'}}>
            <h5 className="modal-title" id="exampleModalLabel">
              Reject leave - {leave.name}
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body" style={{border: '1px solid #eee', fontSize:15}}>
            Are you sure you want to reject this Leave? <br/>
            This action is irreversible !
          </div>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <Row >
                <Col md={12}>
                  <Form.Group className="mb-2">
                    <Form.Label style={{ paddingRight: 10 }}>Reason for Rejection</Form.Label>
                    <Input
                      className="custom-control-input"
                      id="reason"
                      placeholder='Rejection reason'
                      onChange={async (e) => {
                        await this.onChange(e.target.value, "reject_reason");
                      }}
                      type="textarea"
                    />
                  </Form.Group>

                </Col>
              </Row>
            </Card.Body>
          </Card>
          
          <div className="modal-footer" style={{padding: '1rem'}}>
            <Button color="success" type="button" disabled={loading} size="sm"
              onClick={this.onRejectLeave}
              style={{backgroundColor: '#EC3237', borderColor: '#EC3237', color: '#fff'}}>
              Reject
            </Button>
            <Button
              size="sm"
              color="secondary"
              data-dismiss="modal"
              type="button"
              disabled={loading}
              onClick={toggle}
            >
              Cancel
            </Button>
           
          </div>
        </Modal>
      </>
    );
  }
}

export default RejectLeave;
